import { postRequest } from "../../utils/axios";
import {
  SET_OPEN_L_CASES,
  SET_CLOSED_L_CASES,
  SET_OPEN_L_CASES_LOADING,
  SET_CLOSED_L_CASES_LOADING,
  RESET_L_CASES
} from "../actionTypes";
// import { entityTypeAndID } from "../../utils/entityTypeAndID";
import { store } from '../../App'
import { axiosErrorHandler } from "../../utils/axios";
import { getGopStats } from "./view.actions";



export const setLoadingOpenLCases = (boolean) => {
  return {
    type: SET_OPEN_L_CASES_LOADING,
    payload: boolean
  }
}
export const setLoadingClosedLCases = (boolean) => {
  return {
    type: SET_CLOSED_L_CASES_LOADING,
    payload: boolean
  }
}

export const setOpenLCases = (cases) => {
  return {
    type: SET_OPEN_L_CASES,
    payload: cases
  }
}
export const setClosedLCases = (cases) => {
  return {
    type: SET_CLOSED_L_CASES,
    payload: cases
  }
}

export const getLCases = ( type ) => {
  return async dispatch => {
    try {
      // console.log('type', type.toUpperCase());
      if (type === 'open') {
        dispatch(setLoadingOpenLCases(true))
      } else if (type === 'closed') {
        dispatch(setLoadingClosedLCases(true))
      }
      let resp = await postRequest(
        `/gop/get-claims-for-l1-l3`,
        { data: { value: { caseProgress: type.toUpperCase() }} }
      )
      console.log('type ===', type, 'getLCases resp ==', resp.data)
      if (type === 'open') {
        dispatch(setOpenLCases(resp.data))
        dispatch(setLoadingOpenLCases(false))
      } else if (type === 'closed') {
        dispatch(setClosedLCases(resp.data))
        dispatch(setLoadingClosedLCases(false))
      }

    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getLCases - ', error)
      if (type === 'open') {
        dispatch(setLoadingOpenLCases(false))
      } else if (type === 'closed') {
        dispatch(setLoadingClosedLCases(false))
      }
    }
  }
}


export const updateLCases = ({ state, claims, type }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(
        `/gop/update-claims-for-l1-l3/${state}`,
        {
          data: {
            value: {
              claims,
            }
          }
        }
      )
      console.log('updateLCases resp', resp.data)
      dispatch(getGopStats());
      if (type === 'open') {
        dispatch(setOpenLCases(resp.data))
      } else if (type === 'closed') {
        dispatch(setClosedLCases(resp.data))
      }
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateLCases - ', error)
    }
  }
}


export const resetLCases = () => {
  return {
    type: RESET_L_CASES
  }
}