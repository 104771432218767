import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Grid, TextField,
  Checkbox, FormControlLabel, Button, FormControl
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getValidationPasswordRules, updateValidationPasswordRules } from '../../../redux/actions/entities.actions';
import { PINK_COLOR } from '../../../constants/colors';
import { IMSTestPasswordInput } from '../../../shared';

const CheckboxControlWrapper = ({ ruleObj, handleChange, index, handleChangeValue }) => {
  const {
    title = '',
    key = '',
    is_active = false,
    value = 0
  } = ruleObj || {}

  return (
    <Grid container pb={2}>
      <Grid item xs={12} sm={4}>
        <FormControlLabel
          control={
            <Checkbox color='secondary' checked={is_active} name={key}
              onChange={(event) => { handleChange(event, index) }} />
          }
          label={title}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField size="small" type="number"
          disabled={!is_active}
          value={value} InputProps={{
            inputProps: {
              min: 0
            }
          }}
          name={key}
          onChange={(event) => { handleChangeValue(event.target.value, index) }} />
      </Grid>
    </Grid >
  )
}

const IMSPasswordManagement = () => {
  const dispatch = useDispatch()
  const selectedCompany = useSelector(state => state.entities.selectedCompany)
  const validationPasswordRules = useSelector(state => state.entities.validationPasswordRules)
  const {
    passwordRulesShort = []
  } = validationPasswordRules || {}

  const [draftRules, setDraftRules] = useState(passwordRulesShort); 

  useEffect(() => {
    // console.log('selectedCompany - ', selectedCompany)
    if (selectedCompany?.keys?.table) {
      dispatch(getValidationPasswordRules());
    }
  }, [selectedCompany])

  useEffect(() => {
    setDraftRules(passwordRulesShort)
  }, [validationPasswordRules])

  const handleChangeCheckBox = (e, index) => {
    // console.log("handleChangeCheckBox key - ", e.target.name)
    let tempState = draftRules
    tempState[index].is_active = e.target.checked
    if (!e.target.checked) {
      tempState[index].value = passwordRulesShort[index].value
    }
    setDraftRules([...tempState])
  }

  const handleChangeValue = (value, index) => {
    let tempState = draftRules
    tempState[index].value = parseInt(value)
    setDraftRules([...tempState])
  }

  return (
    <Box sx={{
      padding: "35px"
    }}>
      <Box display='flex' flexDirection='row' alignItems='center' pb={3} pt={1}>
        <Typography sx={{ color: "#053ad3", fontSize: "48px" }}>
          Password Management
        </Typography>
      </Box>
      {draftRules?.length > 0 && <Box>
        <Box>
          <FormControl component="fieldset" fullWidth variant="standard">
            <Typography component="legend" sx={{ color: PINK_COLOR, fontSize: 16, fontWeight: 'bold' }} >Password Options</Typography>
            {
              draftRules?.map(
                (item, index) => <CheckboxControlWrapper
                  index={index}
                  key={item.key}
                  ruleObj={item}
                  handleChange={handleChangeCheckBox}
                  handleChangeValue={handleChangeValue} />)
            }
          </FormControl>
        </Box>
        <Box>
          <Button
            color='secondary' variant='contained'
            sx={{ height: 'fit-content' }}
            onClick={() => { dispatch(updateValidationPasswordRules(draftRules)) }}
          >Save</Button>
          <Box mt={2}>
            <IMSTestPasswordInput />            
          </Box>
        </Box>
      </Box>
      }
    </Box >
  );
};

export default IMSPasswordManagement;