import COMMANDS from "../constants/commands";
import { updateOneAndSelectedGop, setOpenModalConfirmUpdateBill, setModalConfirmUpdateBill } from "../redux/actions/gop.actions";
import { setAuthSelectRoleDialogOpen } from "../redux/actions/auth.actions";

const commandHandler = ({ dispatch, navigate, commands = [] }) => {
  for (const command of commands) {
    switch (command.cmd_type) {
      case COMMANDS.UPDATE_GOP: {
        dispatch(updateOneAndSelectedGop(command.gop, false))
        break;
      }
      case COMMANDS.REDIRECT_TO_PAGE: {
        navigate(command.path, { state: { ...command?.params } })
        break;
      }
      case COMMANDS.BILL_SAVE_CANCEL_CONFIRM_MODAL: {
        dispatch(setModalConfirmUpdateBill({ msg: command.msg, params: command.params }));
        dispatch(setOpenModalConfirmUpdateBill(true));
        break;
      }
      case COMMANDS.SHOW_SELECT_ACTOR_MODAL: {
        dispatch(setAuthSelectRoleDialogOpen({ isOpen: true, actors: command?.params?.actors }));
        break;
      }
      case COMMANDS.REPLACE_PAGE_WITH: {
        navigate(command.path, { replace: true, state: { ...command?.params } })
        break;
      }
      default:
        console.log("commandHandler: command is not on the list:", command.cmd_type)
    }
  }
}

export default commandHandler
