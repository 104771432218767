import React, { useState, useEffect } from "react";
import {
  Box, Button, IconButton, Typography, Paper, TableContainer, Table, TableHead, TableRow, TableCell,
  TableBody, TableFooter, TablePagination, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import FileSaver from "file-saver";
import { PageContainer, DrawerContainer, PageBodyContainer, IMSDrawer, ButtonAddDocument } from "../../shared";
import { useNavigate, useLocation } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector } from "react-redux";
import { setLoadingUploadDocument } from "../../redux/actions/view.actions";
import { createMasterFileItem, getAllMasterFiles, masterFileChangeState } from "../../redux/actions/documents.actions";
import { getBlob, getDownloadMasterFileLink } from "../../utils/upload";
import { MASTER_FILE_BTN, MASTER_FILE_DROPDOWNS } from "../../constants/viewConfig";
import ConfirmModal from "../../shared/ConfirmModal";

const MasterFilePage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const itemDocuments = useSelector((state) => state?.documents?.masterFileDocuments);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [masterFileStatus, setMasterFileStatus] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
const viewConfig = useSelector((state) => state.view.mainViewConfig);
  // const [itemDocuments, setItemDocuments] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUploadAcceptedFiles = (files) => {
    files.forEach(file => {
      dispatch(setLoadingUploadDocument(true))
      dispatch(createMasterFileItem({ file }))
    })
  }

  const handleDownloadFile = async (item) => {
    let link = await getDownloadMasterFileLink(item.keys.master_file_id, item.value.s3_uuid)
    let blob = await getBlob(link)
    FileSaver.saveAs(blob, item.value.filename)
  }

  const onOpenConfirmModal = () => {
    setIsConfirmModalOpen(true)
  }

  const onCloseConfirmModal = () => {
    setIsConfirmModalOpen(false)
    setMasterFileStatus('')
  }

  const onConfirmModal = () => {
    // dispatch(masterFileChangeState({ master_file_id, state: masterFileStatus.state }))
    setIsConfirmModalOpen(false)
  }

  const RenderTableRow = ({ item, index }) => {
    const {
      keys: {
        master_file_id = ''
      } = {},
      value: {
        a_dropdown_case_statuses = [],
        a_status_title = '',
        filename = '',
        a_updated_at_ddmmyyyyhhmmss = '',
        a_viewConfig: { show = [] } = {},
        updated_by: { name = '' } = {}
      } = {}
    } = item || {}
    return (
      <TableRow>
        <TableCell component="th" scope="row">
          <IconButton onClick={() => handleDownloadFile(item)} aria-label="download">
            <DownloadIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          {filename}
        </TableCell>
        <TableCell align="left">
          {a_status_title}
        </TableCell>
        <TableCell style={{ width: 160 }} align="left">
          {name}
        </TableCell>
        <TableCell align="center">
          {a_updated_at_ddmmyyyyhhmmss}
        </TableCell>
        <TableCell align="right">
          {show.includes(MASTER_FILE_DROPDOWNS.MASTER_FILE_STATUS) && <FormControl
            size="small"
            variant="outlined"
            sx={{ minWidth: 210, m: 2 }}>
            <InputLabel id={`master-file-change-status-${index}`}>ACTION</InputLabel>
            <Select
              labelId={`master-file-change-status-${index}`}
              id={`master-file-change-status-outlined-${index}`}
              value={masterFileStatus}
              onChange={(event) => {
                setMasterFileStatus(event.target.value)
                dispatch(masterFileChangeState({ master_file_id, state: event.target.value.state }))
              }}
              label={'STATUS'}
              variant={'outlined'}
            >
              {/* {loading && <Box  display='flex' flex={1} justifyContent='center'> <CircularProgress /> </Box>} */}
              {!!a_dropdown_case_statuses && a_dropdown_case_statuses?.length > 0 && a_dropdown_case_statuses.map((itemStatus, key) =>
                <MenuItem key={key} value={itemStatus}>{itemStatus.action_title}</MenuItem>
              )}
            </Select>
          </FormControl>
          }
        </TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    dispatch(getAllMasterFiles());
  }, [])

  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={
          user.owner_type === "provider"
            ? "Inpatient Management System"
            : "Clinic Portal"
        }
        underline={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Box sx={{ padding: "35px", paddingTop: "10px" }}>
          <Typography sx={{ color: "#053ad3", fontSize: "48px", pr: 8 }}>
            Master File
          </Typography>
          <Box>
            {viewConfig?.show && viewConfig?.show?.includes(MASTER_FILE_BTN.ADD_NEW) && <ButtonAddDocument handleUploadAcceptedFiles={handleUploadAcceptedFiles} />}
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} size="small" aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '10%', fontWeight: 'bold' }}>File</TableCell>
                      <TableCell sx={{ width: '30%', fontWeight: 'bold' }} align="left">File Name</TableCell>
                      <TableCell sx={{ width: '15%', fontWeight: 'bold' }} align="left">Status</TableCell>
                      <TableCell sx={{ width: '15%', fontWeight: 'bold' }} align="left">Updated by</TableCell>
                      <TableCell sx={{ width: '15%',  fontWeight: 'bold' }} align="center">Updated at</TableCell>
                      <TableCell sx={{ width: '15%',fontWeight: 'bold' }} align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? itemDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : itemDocuments
                    ).map((item, index) => {
                      return (<RenderTableRow key={index} item={item} index={index} />)
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={5}
                        count={itemDocuments.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
        {/* <ConfirmModal
          open={isConfirmModalOpen}
          handleClickBtnCancel={onCloseConfirmModal}
          handleClickBtnConfirm={onConfirmModal}
          type={'CONFIRM_GOP_STATUS_CHANGE'}
          actionTitle={caseStatus.action_title}
          confirmation_msg={(show?.includes(GOP_TITLE.POLICY_EXPIRED) && ['GOP_APPROVED', 'GOP_APPROVED_WITH_CONDITIONS'].includes(caseStatus?.state)) ? state_to_confirmation_msg?.[caseStatus?.state]?.join('') : caseStatus?.confirmation_msg}
        /> */}
      </PageBodyContainer>
    </PageContainer>
  );
};

export default MasterFilePage;
