import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button,
  FormControl, InputLabel, Select,
  MenuItem, ListSubheader
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AddDocumentModal, DocumentCategory, FileViewer } from '../../../shared';
import { createGop, uploadFile } from '../../../redux/actions/gop.actions';
import { useNavigate } from 'react-router-dom';
import { getDownloadFileUrl } from '../../../utils/upload';


const DropdownSection = ({ section }) => (
  <>
    <ListSubheader>{section.sectionTitle}</ListSubheader>
    {section?.files?.map?.((file, index) => (<MenuItem key={index.toString()} value={file}>{file.filename}</MenuItem>))}
  </>
)


const getAllDocsFromGopDocs = (gop_docs) => {
  const docsArr = [];

  gop_docs.forEach((sections) => sections?.files?.forEach?.((file) => docsArr.push(file)));
  return docsArr.filter((item) => item);
}

const Documents = ({ gop_id, gop_docs }) => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState('');
  const [showFileViewer, setShowFileViewer] = useState(false)
  const [filePreviewLink, setFilePreviewLink] = useState('')
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    setDocs(getAllDocsFromGopDocs(gop_docs));
  }, [gop_docs])

  useEffect(() => {
    if (!filePreviewLink) {
      setShowFileViewer(false)
    } else {
      setShowFileViewer(true)
    }
  }, [filePreviewLink]);

  useEffect(() => {
    setFilePreviewLink('');
    if (selectedFile) {
      openDownloadFileLink(selectedFile);
    }
  }, [selectedFile])

  const openDownloadFileLink = async (file) => {
    let link = await getDownloadFileUrl(gop_id, file.s3_uuid)
    setFilePreviewLink(link)
  }

  return (
    <Box flex={1} sx={{display: 'flex', flexDirection: 'column'}}>
      <FormControl sx={{ m: 1, maxWidth: 240 }}>
        <InputLabel htmlFor="grouped-select">Documents</InputLabel>
        <Select value={selectedFile} onChange={(event) => {console.log('event', event); setSelectedFile(event.target.value)}} defaultValue="" id="grouped-select" label="Grouping">
          <MenuItem value={''}>
            <em>None</em>
          </MenuItem>
          {docs?.map?.((file, index) => (<MenuItem key={index.toString()} value={file}>{file.filename}</MenuItem>))}
        </Select>
      </FormControl>
      {showFileViewer && <FileViewer hide={() => setSelectedFile('')} link={filePreviewLink}></FileViewer>}
    </Box >
  );
};

export default Documents;