import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from "react-hook-form";


const AutocompleteDropdown = ({ control, itemKey = '', label, ...rest }) => {
  return (
    <Controller
      name={itemKey}
      control={control}
      render={({ field }) => <Autocomplete
        {...field}
        {...rest}
        fullWidth
        sx={{ pb: 1, pt: 2 }}
        renderInput={(params) => <TextField multiline {...params} label={label} />}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.title}
            </li>
          );
        }}
        getOptionLabel={(option) => option?.title ? option.title : ''}
        onChange={(event, newValue) => {
          field.onChange(newValue)
        }}
        isOptionEqualToValue={(option, value) => option?.value === value?.value }

        // groupBy={(option) => option[0].toUpperCase()}
        // renderGroup={(params) => params}
      />}
    />
    
  );
}

export default AutocompleteDropdown;
