import { connect, JSONCodec } from "../../node_modules/nats.ws/lib/src/mod.js";
import { setNatsConnectedStatusRejected, setNatsConnectedStatus, setNatsConnection } from '../redux/actions/auth.actions'
import { store } from '../App'
import { getAllNotifications, addNewNotification, setUnseenNotificationsCount } from '../redux/actions/notifications.action';
import { getAllGops, updateWhereWithWhatByGopID } from '../redux/actions/gop.actions';
import {setPricelist} from '../redux/actions/pricelist.actions';

let nc;
const jsonc = JSONCodec();

export const connectToServer = async (natsConfig) => {
  try {
    if (store.getState().auth.natsConnected === true) {
      await closeConnection();
      console.log('reconnect NATS');
    }
    console.log('connecting', natsConfig);
    if (natsConfig && Object.keys(natsConfig).length === 0) {
      return
    }
    nc = await connect({
      servers: natsConfig.config.servers,
      user: natsConfig.config.user,
      pass: natsConfig.config.pass,
      // debug: true,
      maxReconnectAttempts: -1,
      name: 'CP-WEB'
    });
    console.log('CONNECTED', nc.getServer());
    store.dispatch(setNatsConnectedStatus(true));
    nc.closed()
      .then((err) => {
        if (err) {
          console.error(`service exited because of error: ${err.message}`);
        }
        store.dispatch(setNatsConnectedStatusRejected(err ? err.message : ''));
      });
    const notificationSubscription = nc.subscribe(natsConfig.subjects.notificationSbj);
    // const masterFileSubscription = nc.subscribe(natsConfig.subjects.masterFileNotificationSbj);
    const gopSubscription = nc.subscribe(natsConfig.subjects.gopSbj);
    const pricelistSubscription = nc.subscribe(natsConfig.subjects.pricelistSbj);


    console.log(`notificationSubscription is ${notificationSubscription.isClosed() ? 'closed' : 'open'}`);
    console.log(`gopSubscription is ${gopSubscription.isClosed() ? 'closed' : 'open'}`);
    console.log(`pricelistSubscription is ${pricelistSubscription.isClosed() ? 'closed' : 'open'}`);

    (async () => {
      for (
        let iter = notificationSubscription[Symbol.asyncIterator](), step = await iter.next();
        !step.done;
        step = await iter.next()
      ) {   
        let decodeObj = jsonc.decode(step.value.data);
        console.log(`[${notificationSubscription.getProcessed()}]: ${JSON.stringify(decodeObj)}`);
        store.dispatch(getAllGops());
        store.dispatch(addNewNotification(decodeObj));
      }
      console.log("subscription closed");
    })();

    // (async () => {
    //   for (
    //     let iter = masterFileSubscription[Symbol.asyncIterator](), step = await iter.next();
    //     !step.done;
    //     step = await iter.next()
    //   ) {
    //     let decodeObj = jsonc.decode(step.value.data);
    //     console.log(`[${masterFileSubscription.getProcessed()}]: ${JSON.stringify(decodeObj)}`);
    //     // store.dispatch(getAllGops());
    //     store.dispatch(addNewNotification(decodeObj));
    //   }
    //   console.log("subscription closed");
    // })();
    (async () => {
      for (
        let iter = gopSubscription[Symbol.asyncIterator](), step = await iter.next();
        !step.done;
        step = await iter.next()
      ) {   
        let decodeObj = jsonc.decode(step.value.data);
        // console.log(`'gopSubscription' [${gopSubscription.getProcessed()}]: ${JSON.stringify(decodeObj)}`);
        console.log(`gopSubscription: [${gopSubscription.getProcessed()}]:`, decodeObj);
        store.dispatch(updateWhereWithWhatByGopID(decodeObj));
        // store.dispatch(addNewNotification(decodeObj));
      }
      console.log("subscription closed");
    })();
    (async () => {
      for (
          let iter = pricelistSubscription[Symbol.asyncIterator](), step = await iter.next();
          !step.done;
          step = await iter.next()
      ) {
        let decodeObj = jsonc.decode(step.value.data);
        // console.log(`'gopSubscription' [${gopSubscription.getProcessed()}]: ${JSON.stringify(decodeObj)}`);
        console.log(`pricelistSubscription: [${pricelistSubscription.getProcessed()}]:`, decodeObj);
        store.dispatch(setPricelist(decodeObj));

      }
      console.log("subscription closed");
    })();
  }
  catch (err) {
    console.log(`error connecting to ${err}`);
    store.dispatch(setNatsConnectedStatusRejected(err));
  }
}

export const publishSubject = (subject, obj) => {
  try {
    console.log("NATS Service: publishSubject - ", subject);
    console.log("NATS Service: publishSubject obj - ", obj);
    nc.publish(subject, jsonc.encode(obj));
  } catch (e) {
    console.error('natsPublish', e)
  }
}

export const closeConnection = async () => {
  // console.log('NATS Service: close connection');
  try {
    if (!nc) {
      return
    }
    await nc.close();
    console.log('NATS Service: connection closed');
  } catch (err) {
    console.log('NATS Service: error was not closed', err);
  }
}



// export default connectToServer;