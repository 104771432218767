import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenModalDocument } from '../redux/actions/documents.actions';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AddDocumentModal, DocumentCategory } from '.';


// TODO what is it??? to remove medical reports hardcode
const categories = [{
  title: "BILLS",
  category_key: "BILLS"
}, {
  title: "MEDICAL REPORTS",
  category_key: "MEDICAL_REPORTS"
},
{
  title: "MEDICAL REPORTS",
  category_key: "MEDICAL_REPORTS2"
}]

const Documents = () => {
  const dispatch = useDispatch();
  const openModalDocument = useSelector(state => state.documents.modals?.openModalDocument)

  return (
    <Box>
      <Typography sx={{ color: "#053ad3", fontSize: "16px", pr: 8 }}>
        Documents
      </Typography>
      {categories?.map?.((categoryObj, index) => {
        return (<DocumentCategory category={categoryObj} index={index} key={categoryObj.category_key} />)
      })}
      <AddDocumentModal open={openModalDocument} handleOpen={(boolean) => dispatch(setOpenModalDocument(boolean))} />
    </Box >
  );
};

export default Documents;