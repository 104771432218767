import React, { useEffect, useState } from 'react';
import {
  Box, List, Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getDownloadFileUrl } from '../../../../../../utils/upload';
import { deleteFile } from '../../../../../../redux/actions/gop.actions';
import ConfirmModal from '../../../../../../shared/ConfirmModal';
import { DocumentFileItem } from '../../../../../../shared';

const BillFilesSection = ({ bill }) => {
  const dispatch = useDispatch()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [selectedFile, setselectedFile] = useState(null)
  const {
    a_files = []
  } = bill || {}

  const openFile = async (file) => {
    let link = await getDownloadFileUrl(file.gop_id, file.s3_uuid)
    const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const onCloseConfirmModal = () => {
    setIsConfirmModalOpen(false)
    setselectedFile(null)
  }

  const onConfirmModal = () => {
    dispatch(deleteFile(selectedFile))
    setIsConfirmModalOpen(false)
    setselectedFile(null)
  }

  return (
    <Box display='flex' alignItems='center' flexDirection='row' p={1}>
      <Box pl={1} flex={1}>
        <List>
          {
            a_files?.map?.((category) => {
              const { files = [] } = category || {};
              return (
                files?.map((file) => {
                  return (
                    <DocumentFileItem
                      key={file.file_id}
                      file={file}
                      openFile={openFile}
                      confirmationDelete={(file) => {
                        setselectedFile(file)
                        setIsConfirmModalOpen(true)
                      }} />
                  )
                })
              )
            })
          }
        </List>
      </Box>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleClickBtnCancel={onCloseConfirmModal}
        handleClickBtnConfirm={onConfirmModal}
        type={'CONFIRM_DELETE_FILE'}
        actionTitle={''}
        confirmation_msg={''}
      />
    </Box>
  )
}

export default BillFilesSection;
