import * as React from 'react';

import {
  Typography,
  AccordionSummary, Box, CircularProgress, Accordion, AccordionDetails, Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { LIGHTENED_PINK } from '../../../constants/colors';
import { useSelector } from "react-redux";
import { COLORS } from "../../../constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GOP_POLICY_DETAILS_LIST, LOADING_STATE } from "../../../constants/viewConfig";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import PolicyCoverageTreatmentData from "../../Cases/components/GopDetails/PolicyCoverageTreatmentData";
import PolicyScheduleOfBenefits from "../../Cases/components/GopDetails/PolicyScheduleOfBenefits";
import PolicyInpatientCatastropheData from "../../Cases/components/GopDetails/PolicyInpatientCatastropheData";
import PolicyExclusionsData from "../../Cases/components/GopDetails/PolicyExclusionsData";
import ClaimsHistoryCard from "../../Cases/components/GopDetails/ClaimsHistoryCard";

const StyledAccordionSummary = ({ title = '', control = '', loadingState = '' }) => {
  return (
    <AccordionSummary
      sx={{
        color: COLORS.LIGHT_BLUE_COLOR,
        borderBottom: "1px solid #e5e5e5",
        padding: 0,
      }}
      expandIcon={<ExpandMoreIcon color='secondary' />}
      aria-controls={"panel-content-" + control}
      id={"panel-header-" + control}
    >
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', mr: 2 }}>
        <Typography>{title}</Typography>
        {loadingState === LOADING_STATE.IS_LOADING && <CircularProgress sx={{ alignSelf: 'center' }} size={'16px'} />}
        {loadingState === LOADING_STATE.LOADING_FAILED && <CancelIcon sx={{ color: COLORS.PINK_COLOR }} />}
        {loadingState === LOADING_STATE.LOADING_SUCCESSFUL && <DoneIcon />}
      </Box>
    </AccordionSummary>
  )
}

const PolicyDetails = ({ activeStep, handleNext, handleBack }) => {
  const draftObj = useSelector(state => state.gop.draftObj);
  const { data: claimsHistory = [], loadingState: loadingClaimsHistory = '' } = draftObj?.value?.claimsHistory || {};
  const { data: inpatientCatastropheInfo = [], loadingState: loadingInpatientCatastropheInfo = '' } = draftObj?.value?.policyInpatientCatastropheInfo || {};
  const { data: exclusions = [], loadingState: loadingExclusions = '' } = draftObj?.value?.policyExclusions || {};
  const { data: coverageTreatment = [], loadingState: loadingCoverageTreatment = '' } = draftObj?.value?.policyCoverageTreatment || {};
  const { data: SOB = [], loadingState: loadingSOB = '' } = draftObj?.value?.policySOB || [];
  const show = useSelector(state => state.gop.draftObj?.value?.a_viewConfig?.show) || [];
  // console.log('draftGop', draftObj)

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>
        {activeStep?.showBackBtn &&
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, color: '#00b7cd' }}
          >
            <ArrowBackIcon sx={{ margin: '0 9px 0 0', color: '#eb0781' }} />
            Back
          </Button>
        }
        <Box sx={{ flex: '1 1 auto' }} />

        {activeStep?.showNextBtn &&
          <Button onClick={handleNext} sx={{ color: '#00b7cd' }}>
            Next
            <ArrowForwardIcon sx={{ margin: '0 0 0 9px', color: '#eb0781' }} />
          </Button>
        }
      </Box>
      <Typography pb={2} pt={3} sx={{
        color: LIGHTENED_PINK,
        fontSize: '18px',
        fontWeight: 'bold'
      }}>Policy Details</Typography>
      <Box p={2} display='flex' flexDirection='column'>
        {(show.includes?.(GOP_POLICY_DETAILS_LIST.COVERAGE) || show.includes?.(GOP_POLICY_DETAILS_LIST.TREATMENT)) &&
          <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
            <StyledAccordionSummary title='COVERAGE / TREATMENT' loadingState={loadingCoverageTreatment} control='inpatient-info' />
            <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
              {coverageTreatment?.length > 0 && coverageTreatment.map((item, index) => (
                <PolicyCoverageTreatmentData key={index.toString()} row={item} />
              ))}
            </AccordionDetails>
          </Accordion>}

        {show.includes?.(GOP_POLICY_DETAILS_LIST.SOB) &&
          <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
            <StyledAccordionSummary title='SCHEDULE OF BENEFITS' loadingState={loadingSOB} control='schedule-of-benefits' />
            <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
              {SOB?.length > 0 && <PolicyScheduleOfBenefits rows={SOB} />}
            </AccordionDetails>
          </Accordion>
        }
        {(show.includes?.(GOP_POLICY_DETAILS_LIST.INPATIENT) || show.includes?.(GOP_POLICY_DETAILS_LIST.CATASTROPHE)) &&
          <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
            <StyledAccordionSummary title='INPATIENT / CATASTOPHE INFORMATION' loadingState={loadingInpatientCatastropheInfo} control='inpatient-info' />
            <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
              {inpatientCatastropheInfo?.length > 0 && inpatientCatastropheInfo.map((itemCategory, index) => (
                <PolicyInpatientCatastropheData key={index.toString()} sectionData={itemCategory} />
              ))}
            </AccordionDetails>
          </Accordion>}
        {show.includes?.(GOP_POLICY_DETAILS_LIST.EXCLUSIONS) &&
          <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
            <StyledAccordionSummary title='EXCLUSIONS' loadingState={loadingExclusions} control='exclusions' />
            <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
              {exclusions?.length > 0 && exclusions.map((itemCategory, index) => (
                <PolicyExclusionsData key={index.toString()} sectionData={itemCategory} />
              ))}
            </AccordionDetails>
          </Accordion>}
        {show.includes?.(GOP_POLICY_DETAILS_LIST.CLAIMS_HISTORY) &&
          <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
            <StyledAccordionSummary title='CLAIMS HISTORY' loadingState={loadingClaimsHistory} control='claim-history' />
            <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
              {claimsHistory?.length > 0 && claimsHistory?.map?.((item, index) =>
                <ClaimsHistoryCard key={index.toString()} claim={item} />)}
            </AccordionDetails>
          </Accordion>}
      </Box>
    </React.Fragment>
  );
}

export default PolicyDetails