import * as React from 'react';
import {
  Box, Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useDispatch, useSelector } from "react-redux";
import { createGop } from "../../../redux/actions/gop.actions";
import DocumentsDetailsTab from '../../Cases/components/GopDetails/DocumentsDetailsTab'
import { useNavigate } from 'react-router-dom';

const DocumentsStep = ({ activeStep, handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newGopCreateLoading = useSelector(state => state?.view?.loading?.newGopCreate || false)
  const gop_id = useSelector(state => state.gop?.draftIdGop)
  const gop_docs = useSelector(state => state.gop?.selectedGopDocs)

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>
        {activeStep?.showBackBtn &&
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, color: '#00b7cd' }}
          >
            <ArrowBackIcon sx={{ margin: '0 9px 0 0', color: '#eb0781' }} />
            Back
          </Button>
        }
        <Box sx={{ flex: '1 1 auto' }} />

        {activeStep?.showNextBtn &&
          <Button onClick={handleNext} sx={{ color: '#00b7cd' }}>
            Next
            <ArrowForwardIcon sx={{ margin: '0 0 0 9px', color: '#eb0781' }} />
          </Button>
        }
      </Box>
      <DocumentsDetailsTab gop_id={gop_id} gop_docs={gop_docs} />
      <Box mt={3}>
        <LoadingButton
          loading={newGopCreateLoading}
          color='secondary'
          sx={{
            fontSize: '14px',
            fontWeight: '500',
            width: 345,
          }}
          onClick={() => { dispatch(createGop({ navigate })) }}
          variant="contained">Create New GOP</LoadingButton>
      </Box>
    </React.Fragment>
  );
}

export default DocumentsStep