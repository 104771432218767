import { Snackbar, Alert } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromSnackbar, setSnackBarOpen } from '../redux/actions/view.actions';

const ErrorSnackbar = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isSnackBarOpen = useSelector(state => state.view.isSnackBarOpen);
  const snackbarErrorMessage = useSelector(state => state.view.snackbarErrorMessage);
  const snackbarMessages = useSelector(state => state.view.snackbarMessages);

  const addSnackbarVariant = ({ message, variant, id }) => {
    // variant could be success, error, warning, info, or default
    console.log(message, variant)
    enqueueSnackbar(message, { variant });
    dispatch(removeFromSnackbar(id))
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackBarOpen(false, ''));
  };

  useEffect(() => {
    console.log("snackbarMessages -", snackbarMessages)
    if (snackbarMessages?.length) {
      snackbarMessages.forEach(it => {
        addSnackbarVariant(it)
      })
    }
  }, [snackbarMessages])

  return (
    <Snackbar open={isSnackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity="error">
        {snackbarErrorMessage}
      </Alert>
    </Snackbar>
  )
}


export default ErrorSnackbar;
