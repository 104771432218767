import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { DrawerContainer, PageContainer } from '../../shared';
import { ForgotPasswordStep1, ForgotPasswordStep2, ForgotPasswordStep3 } from './components/';


const steps = ['Step 1', 'Step 2', 'Step 3'];

const ForgotPassword = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const step = parseInt(searchParams.get('step'));
  const [activeStep, setActiveStep] = useState(step || 1);
  const [forgotPassState, setForgotPassState] = useState({
    email: '',
    oldPassword: '',
    newPassword: ''
  })

  useEffect(() => {
    setActiveStep(step)
  }, [step])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    searchParams.set('step', activeStep + 1);
    setSearchParams(searchParams)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    searchParams.set('step', activeStep - 1);
    setSearchParams(searchParams)
  };

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return <ForgotPasswordStep1 location={location} from={from} forgotPassState={forgotPassState} setForgotPassState={setForgotPassState} />
      case 2:
        return <ForgotPasswordStep2 location={location} from={from} forgotPassState={forgotPassState} setForgotPassState={setForgotPassState} />
      case 3:
        return <ForgotPasswordStep3 location={location} from={from} forgotPassState={forgotPassState} setForgotPassState={setForgotPassState} />
      default:
        return <ForgotPasswordStep1 location={location} from={from} forgotPassState={forgotPassState} setForgotPassState={setForgotPassState} />
    }
  }

  const handleBackToLogin = () => {
    navigate('/auth/login', { replace: true })
    setForgotPassState({
      email: '',
      oldPassword: '',
      newPassword: ''
    })
  }


  return (
    <PageContainer>
      <DrawerContainer
        isLogo={false}
        description=""
        underline={false}
      // isBack={true}
      >
      </DrawerContainer>
      <Container maxWidth="lg" sx={{ mt: 2, mb: 1 }}>
        <Button onClick={handleBackToLogin} sx={{ color: '#00b7cd', fontWeight: 'bold' }}>
          <ArrowBackIcon sx={{ margin: '0 9px 0 0', color: '#eb0781' }} />
          BACK TO LOGIN
        </Button>
        <Grid container sx={{ mt: 2, mb: 2, justifyContent: 'center' }}>
          <Box sx={{ width: '600px' }}>
            <Typography color='primary' sx={{
              fontSize: '30px',
              fontWeight: 'bold',
              textAlign: 'center'
            }}>FORGOT PASSWORD</Typography>
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0, flex: 1 }}>

              {activeStep > 1 && < Button
                color="inherit"
                disabled={activeStep === 1}
                onClick={handleBack}
                sx={{ mr: 1, color: '#00b7cd' }}
              >
                <ArrowBackIcon sx={{ margin: '0 9px 0 0', color: '#eb0781' }} />
                Back
              </Button>
              }
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep !== steps.length && <Button onClick={handleNext} sx={{ color: '#00b7cd' }}>
                Next
                {activeStep === steps.length ? '' : <ArrowForwardIcon sx={{ margin: '0 9px 0 0', color: '#eb0781' }} />}
              </Button>}

            </Box> */}
            <Stepper sx={{ mt: 3, mb: 1, }} activeStep={activeStep - 1}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step sx={{ color: 'secondary' }} key={label}  {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <React.Fragment>
              {getStepContent(activeStep)}
            </React.Fragment>
          </Box>
        </Grid>
      </Container>
    </PageContainer>
  );
};

export default ForgotPassword;
