import { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from "react-hook-form";


const AutocompleteDropdown = ({ control, itemKey = '', disabled = false, label, error, rules, ...rest }) => {

  return (
    <Controller
      name={itemKey}
      control={control}
      rules={rules}
      render={({ field }) => {
        // console.log("options - ", rest.options)
        // console.log("field - ", field)
        // if (field.name === 'section') {
        //   console.log(rest?.options?.find(o => o.stats === field.value?.stats))
        // }
        return (<Autocomplete
          {...field}
          {...rest}
          disabled={disabled}
          value={typeof field.value === 'object' ? rest?.options?.find(o => o.stats === field.value?.stats) : (field?.value || undefined)}
          fullWidth
          sx={{ minWidth: '200px' }}
          renderInput={(params) => <TextField multiline {...params} error={error} label={label} />}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.title}
              </li>
            );
          }}
          getOptionLabel={(option) => option?.title ? option.title : ''}
          onChange={(event, newValue, reason) => {
            field.onChange(newValue)
          }}
        // isOptionEqualToValue={(option, value) => option?.value === value?.value }

        // groupBy={(option) => option[0].toUpperCase()}
        // renderGroup={(params) => params}
        />)
      }
      }
    />

  );
}

export default AutocompleteDropdown;
