import React, { useEffect } from 'react';
import {
    Box, Typography, IconButton, Modal, Divider, Dialog, DialogTitle, DialogContent, List,
    ListItem, ListItemText,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { setChooseProfileModalOpen } from "../redux/actions/profiles.actions";
import { setAuth } from "../redux/actions/auth.actions";
import { setView } from "../redux/actions/view.actions";
import { getAllNotifications } from "../redux/actions/notifications.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";




const ChooseProfileModal = () => {
    const chooseProfileModalOpen = useSelector(state => state.profiles.chooseProfileModalOpen);
    const profiles = useSelector(state => state.profiles.profiles);
    const dispatch = useDispatch();
    const navigate = useNavigate();

   const handleOpenClose = (bool) => {
       dispatch(setChooseProfileModalOpen(bool))
   }
    useEffect(() => {
        console.log('chooseProfileModalOpen', chooseProfileModalOpen)

    }, [chooseProfileModalOpen]);

    useEffect(() => {
        console.log('profiles', profiles)

    }, [profiles]);
    return (
        <Dialog scroll='paper' fullWidth maxWidth={'sm'} open={chooseProfileModalOpen} onClose={()=> handleOpenClose(false)}>
            <DialogTitle>Select Role</DialogTitle>
            <DialogContent dividers>
                <List>
                    {profiles?.map?.((profile, index) =>
                        <div key={profile?.auth?.user?.actor + index.toString}>
                            <ListItem button
                                      onClick={() => {
                                          dispatch(setAuth(profile?.auth));
                                          dispatch(setView(profile?.view));
                                          dispatch(getAllNotifications());
                                          navigate(profile?.view?.mainViewConfig?.loginPage?.path, { replace: true });
                                          handleOpenClose(false);

                                          // dispatch(loginEmailPass({ email, password, navigate, from, selectedActor: actor?.value }))
                                          // // dispatch(loginWithoutOtp({ username, actors: [actor]}))
                                          // onClose()
                                      }}>
                                <ListItemText primary={profile?.auth?.user?.actorTitle} />
                            </ListItem>
                            <Divider />
                        </div>
                    )}
                </List>
            </DialogContent>
        </Dialog>
    );

};

export default ChooseProfileModal;