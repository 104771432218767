import React, { useEffect } from 'react';
import { Box, Modal, CircularProgress, DialogTitle, DialogContent, List, Dialog } from '@mui/material';
import { ChooseProfileModal, Header } from '.';
import { useDispatch, useSelector } from "react-redux";
import { setMainPageLoading } from '../redux/actions/view.actions';
// import greyBackgroundBanner from "../media/backgroundImages/greyBackgroundBanner.png";


const PageContainer = ({ children }) => {
  const dispatch = useDispatch();
  const mainPageLoading = useSelector((state) => state?.view?.loading?.mainPageLoading || false)

  useEffect(() => {
    dispatch(setMainPageLoading(false))
  }, [])

  return (
    <Box 
      display='flex'
      flex={1}
      // background image needed for auth 
      // sx={{ backgroundColor: 'background.paper', backgroundImage: `url(${greyBackgroundBanner})`}}
      sx={{ backgroundColor: 'background.paper', overflowY: 'none' }}
      flexDirection='row'
    >
      {children}
      <Modal open={mainPageLoading} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress /> 
      </Modal>
        <ChooseProfileModal/>
    </Box>
  );
};


export const PageBodyContainer = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Header />
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1,
       overflowY: 'scroll',
        }}>
        {children}
      </Box>

    </Box>

  )
}


export default PageContainer;