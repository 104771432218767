import { axiosErrorHandler, postRequest } from "../../utils/axios";
import {
  SET_FEES,
  RESET_DRAFT_FEES,
  UPDATE_DRAFT_FEE_ITEM,
  UPDATE_DRAFT_FEE_SUBITEM,
  SET_SELECTOR_SUBITEMS_MODAL_OPEN,
  SET_SUBITEMS_SELECTOR_CONFIG,
  SET_LOADING_SAVE_FEES,
  SET_LOADING_SAVE_ONE_FEE,
  SET_LOADING_SUBMIT_FEES,
  SET_LOADING_GET_FEES,
  SET_FEE_REMARKS,
  SET_CAT_EXTERNAL_FEES_REMARKS,
  SET_CAT_INTERNAL_FEES_REMARKS,
  SET_DISALLOWED_ITEMS,
  SET_DISALLOWED_ITEMS_LOADING,
  SET_DIS_ITEMS_REMARKS,
  SET_PRICELIST_ACTIVE_ITEMS_DROPDOWN
} from "../actionTypes";
import { store } from "../../App";
import deepCopy from "../../utils/deepCopy";
import commandHandler from "../../utils/commandHandler";
import { setAllPricelists } from "./pricelist.actions";

export const updateDraftFeeItem = ({ sectionKey, feeKey, valueKey, value, type }) => {
  // console.log('updateDraftFeeItem')
  return {
    type: UPDATE_DRAFT_FEE_ITEM,
    payload: {
      type,
      sectionKey,
      feeKey,
      valueKey,
      value
    },
  };
}

export const updateDraftFeeSubitem = ({ sectionKey, parentKey, subitemKey, value, type }) => {
  // console.log('updateDraftFeeSubitem')
  return {
    type: UPDATE_DRAFT_FEE_SUBITEM,
    payload: {
      type,
      sectionKey,
      parentKey,
      subitemKey,
      value
    },
  };
}

export const setSelectorModalOpen = (boolean) => {
  return {
    type: SET_SELECTOR_SUBITEMS_MODAL_OPEN,
    payload: boolean,
  };
}

export const setSubitemsSelectorConfig = ({ options, title, sectionKey, parentKey, subitemKey }) => {
  return {
    type: SET_SUBITEMS_SELECTOR_CONFIG,
    payload: {
      options,
      title,
      sectionKey,
      parentKey,
      subitemKey
    },
  }
}

export const setFees = ({ type, ...data }) => {
  return {
    type: SET_FEES,
    payload: {
      data,
      type
    },
  };
}

export const setDisallowedItems = (data) => {
  return {
    type: SET_DISALLOWED_ITEMS,
    payload: {
      ...data,
    },
  };
}


export const setDisallowedItemsLoading = (boolean) => {
  return {
    type: SET_DISALLOWED_ITEMS_LOADING,
    payload: boolean,
  };
}



export const getDisallowedItems = ({ gop_id }) => {
  return async dispatch => {
    try {
      // if (!type) {
      //   throw new Error('Fees type is missing.')
      // }
      dispatch(setDisallowedItemsLoading(true))
      let resp = await postRequest(`/gop/${gop_id}/get-disallowed-items`, { data: {} })
      console.log('getDisallowedItems resp ==', resp.data)
      dispatch(setDisallowedItems({ ...resp.data }))
      dispatch(setDisallowedItemsLoading(false))
    } catch (err) {
      dispatch(setDisallowedItemsLoading(false))
      const error = axiosErrorHandler(err);
      console.error('error - getDisallowedItems - ', error)
    }
  }
}

export const saveDisallowedItems = ({ gop_id, disallowed_items }) => {
  return async dispatch => {
    try {
      // if (!type) {
      //   throw new Error('Fees type is missing.')
      // }
      dispatch(setDisallowedItemsLoading(true))
      let resp = await postRequest(`/gop/${gop_id}/save-disallowed-items`, { data: { value: { disallowed_items } } })
      console.log('saveDisallowedItems resp ==', resp.data)
      dispatch(setDisallowedItems({ ...resp.data }))
      dispatch(setDisallowedItemsLoading(false))
    } catch (err) {
      dispatch(setDisallowedItemsLoading(false))
      const error = axiosErrorHandler(err);
      console.error('error - saveDisallowedItems - ', error)
    }
  }
}


export const setDisItemsRemarks = (remarks) => {
  return {
    type: SET_DIS_ITEMS_REMARKS,
    payload: remarks
  }
}

export const getExternalRemarksDisItems = ({ remarks_section_id }) => {
  return async dispatch => {
    try {
      // dispatch(setLoadingSaveOneFee(true))
      let resp = await postRequest(`remarks/${remarks_section_id}/get-all`)
      console.log('getExternalRemarksDisItems resp ==', resp.data)
      dispatch(setDisItemsRemarks(resp.data))
      // dispatch(setLoadingSaveOneFee(false))
    } catch (err) {
      // dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - getExternalRemarksDisItems - ', error)
    }
  }
}

export const saveExternalRemarkDisItems = ({ remarks_section_id, text }) => {
  return async dispatch => {
    try {
      // dispatch(setLoadingSaveOneFee(true))
      if (text) {
        let resp = await postRequest(`remarks/${remarks_section_id}/remark/add`, {
          data: {
            value: {
              post: { text }
            }
          }
        })
        console.log('saveExternalRemarkDisItems resp ==', resp.data)
        dispatch(setDisItemsRemarks(resp.data))
      }
      // dispatch(setLoadingSaveOneFee(false))
    } catch (err) {
      // dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - saveExternalRemarkDisItems - ', error)
    }
  }
}



export const getFees = ({ gop_id, type }) => {
  return async dispatch => {
    try {
      if (!type) {
        throw new Error('Fees type is missing.')
      }
      dispatch(setLoadingGetFees(true))
      let resp = await postRequest(`/gop/${gop_id}/fees/${type}/get`, { data: {} })
      console.log('getFees resp ==', resp.data)
      dispatch(setFees({ ...resp.data, type }))
      dispatch(setLoadingGetFees(false))
    } catch (err) {
      dispatch(setLoadingGetFees(false))
      const error = axiosErrorHandler(err);
      console.error('error - getFees - ', error)
    }
  }
}




export const saveFees = ({ gop_id, values, finalize, navigate, type }) => {
  return async dispatch => {
    const setLoading = ({ finalize, value }) => {
      try {
        if (finalize === '1') {
          dispatch(setLoadingSubmitFees(value))
        } else if (finalize === '0') {
          dispatch(setLoadingSaveFees(value))
        }

      } catch (e) {
        const error = axiosErrorHandler(e);
        console.error('error - saveFees - ', error)
      }
    }
    try {
      if (!type) {
        throw new Error('Fees type is missing.')
      }
      setLoading({ finalize, value: true })
      const feesFromState = deepCopy(store.getState().fees[type].fees);
      const feesTitle = store.getState().fees.title;
      feesFromState.forEach((section) => {
        section.items.forEach((item) => {
          item.claimed_amt = values[section.k][item.k].claimed_amt
          item.payable_amt = values[section.k][item.k].payable_amt
          item.paid_amt = values[section.k][item.k].paid_amt
          item?.sub_items?.forEach?.((subitem) => {
            item[subitem.k] = typeof values[section.k][item.k][subitem.k] === 'object' && values[section.k][item.k][subitem.k]?.hasOwnProperty?.('value')
              ? values[section.k][item.k][subitem.k].value
              : values[section.k][item.k][subitem.k]
          })
        })
      })
      // console.log('feesFromState', feesFromState)
      let resp = await postRequest(`/gop/${gop_id}/fees/${type}/save`, { data: { finalize, value: { FEES: feesFromState, selected_tab: feesTitle } } })
      console.log('saveFees resp ==', resp.data)
      dispatch(setFees({ ...resp.data, type }))
      commandHandler({ dispatch, navigate, commands: resp.data.CMDS })
      setLoading({ finalize, value: false })
    } catch (err) {
      setLoading({ finalize, value: false })
      const error = axiosErrorHandler(err);
      console.error('error - saveFees - ', error)
    }
  }
}

// TODO save one fee add type (inpatient || catastrophe)

export const saveOneFee = ({ fee, values, sectionKey, gop_id, type }) => {
  return async dispatch => {
    try {
      if (!type) {
        throw new Error('Fees type is missing.')
      }
      dispatch(setLoadingSaveOneFee(true))
      const feesFromState = deepCopy(store.getState().fees[type].fees);
      const feesTitle = store.getState().fees.title;
      const sectionIndex = feesFromState.findIndex((item) => item.k === sectionKey);
      const feeIndex = feesFromState[sectionIndex].items.findIndex((item) => item.k === fee.k);

      feesFromState[sectionIndex].items[feeIndex] = { ...feesFromState[sectionIndex].items[feeIndex], ...values }
      let resp = await postRequest(
        `/gop/${gop_id}/fees/${type}/save`,
        {
          data: {
            finalize: '0',
            value: { FEES: feesFromState, selected_tab: feesTitle }
          }
        }
      )
      console.log('saveOneFee resp ==', resp.data)
      dispatch(setFees({ ...resp.data, type }))
      dispatch(setLoadingSaveOneFee(false))
    } catch (err) {
      dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - saveOneFee - ', error)
    }
  }
}

export const getExternalRemarks = ({ remarks_section_id }) => {
  return async dispatch => {
    try {
      // dispatch(setLoadingSaveOneFee(true))
      let resp = await postRequest(`remarks/${remarks_section_id}/get-all`)
      console.log('getExternalRemarks resp ==', resp.data)
      dispatch(setFeeRemarks(resp.data))
      // dispatch(setLoadingSaveOneFee(false))
    } catch (err) {
      // dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - getExternalRemarks - ', error)
    }
  }
}

export const saveExternalRemark = ({ remarks_section_id, text }) => {
  return async dispatch => {
    try {
      // dispatch(setLoadingSaveOneFee(true))
      if (text) {
        let resp = await postRequest(`remarks/${remarks_section_id}/remark/add`, {
          data: {
            value: {
              post: { text }
            }
          }
        })
        console.log('saveExternalRemark resp ==', resp.data)
        dispatch(setFeeRemarks(resp.data))
      }
      // dispatch(setLoadingSaveOneFee(false))
    } catch (err) {
      // dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - saveExternalRemark - ', error)
    }
  }
}


export const setFeeRemarks = (remarks) => {
  return {
    type: SET_FEE_REMARKS,
    payload: remarks
  }
}


export const getCatExternalFeesRemarks = ({ remarks_section_id }) => {
  return async dispatch => {
    try {
      // dispatch(setLoadingSaveOneFee(true))
      let resp = await postRequest(`remarks/${remarks_section_id}/get-all`)
      console.log('getCatExternalFeesRemarks resp ==', resp.data)
      dispatch(setCatExternalFeesRemarks(resp.data))
      // dispatch(setLoadingSaveOneFee(false))
    } catch (err) {
      // dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - getCatExternalFeesRemarks - ', error)
    }
  }
}

export const saveCatExternalFeesRemark = ({ remarks_section_id, text }) => {
  return async dispatch => {
    try {
      // dispatch(setLoadingSaveOneFee(true))
      if (text) {
        let resp = await postRequest(`remarks/${remarks_section_id}/remark/add`, {
          data: {
            value: {
              post: { text }
            }
          }
        })
        console.log('saveCatExternalFeesRemark resp ==', resp.data)
        dispatch(setCatExternalFeesRemarks(resp.data))
      }
      // dispatch(setLoadingSaveOneFee(false))
    } catch (err) {
      // dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - saveCatExternalFeesRemark - ', error)
    }
  }
}


export const setCatExternalFeesRemarks = (remarks) => {
  return {
    type: SET_CAT_EXTERNAL_FEES_REMARKS,
    payload: remarks
  }
}



export const getCatInternalFeesRemarks = ({ remarks_section_id }) => {
  return async dispatch => {
    try {
      // dispatch(setLoadingSaveOneFee(true))
      let resp = await postRequest(`remarks/${remarks_section_id}/get-all`)
      console.log('getCatInternalFeesRemarks resp ==', resp.data)
      dispatch(setCatInternalFeesRemarks(resp.data))
      // dispatch(setLoadingSaveOneFee(false))
    } catch (err) {
      // dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - getCatInternalFeesRemarks - ', error)
    }
  }
}

export const saveCatInternalFeesRemark = ({ remarks_section_id, text }) => {
  return async dispatch => {
    try {
      // dispatch(setLoadingSaveOneFee(true))
      if (text) {
        let resp = await postRequest(`remarks/${remarks_section_id}/remark/add`, {
          data: {
            value: {
              post: { text }
            }
          }
        })
        console.log('saveCatInternalFeesRemark resp ==', resp.data)
        dispatch(setCatInternalFeesRemarks(resp.data))
      }
      // dispatch(setLoadingSaveOneFee(false))
    } catch (err) {
      // dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - saveCatInternalFeesRemark - ', error)
    }
  }
}


export const setCatInternalFeesRemarks = (remarks) => {
  return {
    type: SET_CAT_INTERNAL_FEES_REMARKS,
    payload: remarks
  }
}

export const resetDraftFees = () => {
  return {
    type: RESET_DRAFT_FEES
  }
}




export const setLoadingSaveFees = (boolean) => {
  return {
    type: SET_LOADING_SAVE_FEES,
    payload: boolean,
  };
}

export const setLoadingSaveOneFee = (boolean) => {
  return {
    type: SET_LOADING_SAVE_ONE_FEE,
    payload: boolean,
  };
}

export const setLoadingSubmitFees = (boolean) => {
  return {
    type: SET_LOADING_SUBMIT_FEES,
    payload: boolean,
  };
}

export const setLoadingGetFees = (boolean) => {
  return {
    type: SET_LOADING_GET_FEES,
    payload: boolean,
  };
}

export const getPriceListItem = ({ gop_id = '', item = '' }) => {
  // return async dispatch => {
  return new Promise(async (resolve, reject) => {
    try {
      // dispatch(setLoadingSaveOneFee(true))
      let resp = await postRequest(`/price-list/get-item`, { gop_id, item })
      console.log('getPriceListItem resp ==', resp.data)
      // dispatch(setDisItemsRemarks(resp.data))
      // dispatch(setLoadingSaveOneFee(false))
      resolve(resp.data)
    } catch (err) {
      // dispatch(setLoadingSaveOneFee(false))
      const error = axiosErrorHandler(err);
      console.error('error - getPriceListItem - ', error)
      reject(err);
    }
  })

}

export const getPricelistActiveItemsDropdown = () => {
  return async dispatch => {
    try {
      // dispatch(setGetPricelistsLoading(true));
      let resp = await postRequest(
          `/price-list/get-active-items-list`,
          {}
      )
      console.log('getPricelistActiveItemsDropdown ==', resp.data);
      dispatch(setPricelistActiveItemsDropdown(resp.data?.activeItemsDropdown))
      // dispatch(setGetPricelistsLoading(false));
    } catch (err) {
      // dispatch(setGetPricelistsLoading(false));
      const error = axiosErrorHandler(err);
      console.error('getPricelistActiveItemsDropdown - ', error)
    }
  }
};
export const setPricelistActiveItemsDropdown = (activeItemsDropdownArr) => {
  return {
    type: SET_PRICELIST_ACTIVE_ITEMS_DROPDOWN,
    payload: activeItemsDropdownArr
  }
};
