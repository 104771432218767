import { axiosErrorHandler, postRequest } from "../../utils/axios";
import {
  LOG_OUT, SET_AUTH_TOKEN, SET_AUTH_USER, SET_CONNECTED_STATUS, SET_CONNECTED_STATUS_REJECTED, SET_NATS_CONFIG, SET_USERNAME,
  SET_PROVIDER_USERS, SET_PROVIDER_ROLES, SET_AUTH_SELECT_ROLE_DIALOG_OPEN, SET_AUTH_REDUCER
} from "../actionTypes";
import { setMainViewConfig, setView } from "./view.actions";
import { getAllNotifications } from "./notifications.action";
import { addOrUpdateProfile, setChooseProfileModalOpen } from "./profiles.actions";
import { getAllGops } from "./gop.actions";
import commandHandler from "../../utils/commandHandler";
import { store } from '../../App'

export const setAuthUser = (user) => {
  return {
    type: SET_AUTH_USER,
    payload: user
  }
}

export const setUsername = (username) => {
  return {
    type: SET_USERNAME,
    payload: username
  }
}

export const setToken = (token) => {
  return {
    type: SET_AUTH_TOKEN,
    payload: token
  }
}

export const setNatsConfig = (data) => {
  return {
    type: SET_NATS_CONFIG,
    payload: data,
  }
}

export const setNatsConnectedStatus = (boolean) => {
  return {
    type: SET_CONNECTED_STATUS,
    payload: boolean,
  }
}

export const setNatsConnectedStatusRejected = (errMessage) => {
  return {
    type: SET_CONNECTED_STATUS_REJECTED,
    payload: errMessage
  }
}

export const setProviderUsers = (users) => {
  return {
    type: SET_PROVIDER_USERS,
    payload: users
  }
}

export const setProviderRoles = (roles) => {
  return {
    type: SET_PROVIDER_ROLES,
    payload: roles
  }
}


export const loginEmailPass = ({ email, password, navigate, from, selectedActor = '' }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/user/login`, { data: { email, password, selectedActor } })
      console.log('login resp ==', resp.data);
      if (resp?.data?.profiles?.length) {
        for (let profile of resp?.data?.profiles) {
          dispatch(addOrUpdateProfile({auth: profile.auth, view: profile.view}));
        }

        if (resp?.data?.profiles?.length === 1) {
          dispatch(setAuth(resp?.data?.profiles[0]?.auth));
          dispatch(setView(resp?.data?.profiles[0]?.view));
          dispatch(getAllNotifications());
          const navigateTo = resp?.data?.profiles[0].view?.mainViewConfig?.loginPage?.path ? resp?.data?.profiles[0].view?.mainViewConfig?.loginPage?.path : from;
          navigate(navigateTo, { replace: true });
        } else {
          dispatch(setChooseProfileModalOpen(true))
        }
      } else if (resp?.data?.CMDS) {
      commandHandler({ dispatch, navigate, commands: resp.data.CMDS })
    }
    else {
        throw new Error('loginEmailPass error');
      }
      // dispatch(setUsers(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - login - ', error)
    }
  }
}

export const savePassword = ({ email, oldPassword, newPassword, navigate, from }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/user/change-password`, { data: { value: { email, oldPassword, newPassword } } })
      console.log('change-password resp ==', resp.data)
      if (resp?.data?.profiles?.length) {
        for (let profile of resp?.data?.profiles) {
          dispatch(addOrUpdateProfile({auth: profile.auth, view: profile.view}));
        }

        if (resp?.data?.profiles?.length === 1) {
          dispatch(setAuth(resp?.data?.profiles[0]?.auth));
          dispatch(setView(resp?.data?.profiles[0]?.view));
          dispatch(getAllNotifications());
          const navigateTo = resp?.data?.profiles[0].view?.mainViewConfig?.loginPage?.path ? resp?.data?.profiles[0].view?.mainViewConfig?.loginPage?.path : from;
          navigate(navigateTo, { replace: true });
        } else {
          dispatch(setChooseProfileModalOpen(true))
        }
      }

      else {
        throw new Error('User has no profiles');
      }
      // dispatch(setUsers(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - login - ', error)
    }
  }
}

export const forgotPasswordDoAction = ({ action, email = '', oldPassword = '', newPassword = '', navigate, from }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/user/forgot-password/do-action/${action}`, { data: { value: { email, oldPassword, newPassword } } })
      console.log('change-password resp ==', resp.data)
      if (resp?.data?.profiles?.length) {
        for (let profile of resp?.data?.profiles) {
          dispatch(addOrUpdateProfile({auth: profile.auth, view: profile.view}));
        }

        if (resp?.data?.profiles?.length === 1) {
          dispatch(setAuth(resp?.data?.profiles[0]?.auth));
          dispatch(setView(resp?.data?.profiles[0]?.view));
          dispatch(getAllNotifications());
          const navigateTo = resp?.data?.profiles[0].view?.mainViewConfig?.loginPage?.path ? resp?.data?.profiles[0].view?.mainViewConfig?.loginPage?.path : from;
          navigate(navigateTo, { replace: true });
        } else {
          dispatch(setChooseProfileModalOpen(true))
        }
      } else if (resp?.data?.CMDS) {
        commandHandler({ dispatch, navigate, commands: resp.data.CMDS })
      } else {
        throw new Error('forgotPasswordDoAction error');
      }
      // dispatch(setUsers(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - login - ', error)
    }
  }
}

export const getProviderUsers = ({ entityType, entityID }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/entity/${entityType}/${entityID}/user/get-all`, { data: {} })
      console.log('getProviderUsers resp ==', resp.data)
      dispatch(setProviderUsers(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getProviderUsers - ', error)
    }
  }
}

export const getProviderRoles = ({ entityType, entityID }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/entity/${entityType}/${entityID}/role/get-all`, { data: {} })
      console.log('getProviderRoles resp ==', resp.data)
      dispatch(setProviderRoles(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getProviderRoles - ', error)
    }
  }
}

export const createProviderUser = ({ user, entityID, entityType, roles }) => {
  return async dispatch => {
    try {
      console.log('createProviderUser', user, entityID, entityType);
      let resp = await postRequest(`/entity/${entityType}/${entityID}/user/create`, {
        data: { value: user }
      })
      console.log('createProviderUser resp ==', resp)
      await Promise.all(roles.map(async (role) => {
        let respRole = await postRequest(`/role/${role.keys.role_id}/user/${resp.data.keys.user_id}/add`, {})
        console.log('add user to role resp', respRole)
      }))
      dispatch(getProviderUsers({ entityType: store.getState().auth?.user?.owner_type, entityID: store.getState().auth?.user?.owner_id }))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - createProviderUser - ', error)
    }
  }
}

export const updateProviderUser = ({ user, user_id, entityID, entityType, role }) => {
  return async dispatch => {
    try {
      console.log('updateProviderUser', user, user_id, entityID, entityType);
      let resp = await postRequest(`/entity/${entityType}/${entityID}/user/${user_id}/update`, {
        data: { value: user }
      })
      console.log('updateProviderUser resp ==', resp)
      let respUpdateRole = await postRequest(`/role/${role.keys.role_id}/user/${resp.data.keys.user_id}/add-exclusively`, {})
      console.log('respUpdateRole', respUpdateRole)
      dispatch(getProviderUsers({ entityType: store.getState().auth?.user?.owner_type, entityID: store.getState().auth?.user?.owner_id }))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateProviderUser - ', error)
    }
  }
}

export const userProviderActivityDoAction = (action, user) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/user/${user.keys.user_id}/do_action/${action}`, { data: {} })
      console.log('userProviderActivityDoAction resp ==', resp.data)
      dispatch(getProviderUsers({ entityType: store.getState().auth?.user?.owner_type, entityID: store.getState().auth?.user?.owner_id }))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - userProviderActivityDoAction - ', error)
    }
  }
}

export const updateProviderPermissionRole = ({ selectedRole, entityType, entityID, newPermissions }) => {
  return async dispatch => {
    try {
      const roleID = selectedRole && selectedRole?.keys?.role_id ? selectedRole.keys.role_id : '';
      let resp = await postRequest(`/role/${roleID}/permission/update`, {
        data: {
          value: { permissions: newPermissions }
        }
      })
      console.log('updateProviderPermissionRole resp ==', resp.data)
      dispatch(getProviderRoles({ entityType, entityID }))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateProviderPermissionRole - ', error)
    }
  }
}

export const resetProviderPermissionRole = ({ selectedRole, entityType, entityID }) => {
  return async dispatch => {
    try {
      const roleID = selectedRole && selectedRole?.keys?.role_id ? selectedRole.keys.role_id : '';
      let resp = await postRequest(`/role/${roleID}/permission/reset`, {
        data: {
          value: { owner_type: selectedRole.value.owner_type }
        }
      })
      console.log('resetProviderPermissionRole resp ==', resp.data)
      dispatch(getProviderRoles({ entityType, entityID }))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - resetProviderPermissionRole - ', error)
    }
  }
}

export const logOut = () => {
  return {
    type: LOG_OUT,
  }
}

export const setAuthSelectRoleDialogOpen = ({ isOpen, actors = [] }) => {
  return {
    type: SET_AUTH_SELECT_ROLE_DIALOG_OPEN,
    payload: {
      isOpen,
      actors
    },
  };
}


export const setAuth = (obj) => {
  return {
    type: SET_AUTH_REDUCER,
    payload: obj
  }
}
