import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  TextField,
  Grid,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  CircularProgress
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import {
  searchPatientDetails,
  setDraftGopPatientDetails,
  setSearchResultPatientDetails,
  updateDraftGop
} from "../../../redux/actions/gop.actions";
import SearchIcon from "@mui/icons-material/Search";
import { addMessageToSnackbar, setLoadingGopSearchPatient } from "../../../redux/actions/view.actions";
import { LoadingButton } from "@mui/lab";
import ConfirmModal from "../../../shared/ConfirmModal";
import { Controller, useForm } from "react-hook-form";
import { getRulesValidation, getRulesValidationErrorMsg } from "../../../utils/onChangeFunction";

const defaultValues = {
  memberName: "",
  familyName: "",
  nic: "",
  dob: "",
  policyNum: "",
  familyIdMemberId: "",
  cardNumber: ""
}

const TextFieldController = ({ control, nameControl, validationKey, clearErrors, ...rest }) => {
  return (
    <Controller
      name={nameControl}
      control={control}
      defaultValue={''}
      rules={{ validate: { ...getRulesValidation(validationKey) } }}
      render={({
        field: { onChange, value, onBlur, ...restField },
        fieldState: { invalid, error },
      }) => {
        return (<TextField
          sx={{ minWidth: 240, width: "100%" }}
          variant="filled"
          value={value}
          onBlur={(e) => {
            if (!value) {
              clearErrors(nameControl)
            }
          }}
          onChange={onChange}
          error={!!invalid}
          helperText={error ? (error?.message ? error.message : getRulesValidationErrorMsg(validationKey, error?.type)) : null}
          {...restField}
          {...rest}
        />)
      }}
    />
  )
}

const PatientSearchForm = ({ activeStep, handleNext, handleBack }) => {
  const dispatch = useDispatch();
  let searchResult = useSelector((state) => state.gop.searchResultPD);
  let loadingNewGopSearchPatient = useSelector((state) => state.view?.loading?.newGopSearchPatient);
  let draftGop = useSelector((state) => state.gop.draftGop);
  let draftIdGop = useSelector((state) => state.gop.draftIdGop);
  searchResult = searchResult || [];
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState({})

  const { handleSubmit, control, clearErrors, reset } = useForm({
    defaultValues,
    mode: 'all'
  });

  const handleSearchDetails = (formData) => {
    if (moment(formData.dob, 'DD/MM/YYYY').isAfter(moment()) || moment().format('DD/MM/YYYY') === formData.dob) {
      dispatch(addMessageToSnackbar('Cannot be a today and future date.', 'info'))
      return
    }
    dispatch(setLoadingGopSearchPatient(true));
    dispatch(searchPatientDetails(formData));
  };

  const handlerClearSearch = () => {
    reset(defaultValues)
    dispatch(setSearchResultPatientDetails([]));
    dispatch(setLoadingGopSearchPatient(false))
  };

  const handleSelectPatient = (patient) => {
    setSelectedPatient({ ...patient })
    let expiredPolicy = patient.expiryDateStr;
    let isExpired = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').isAfter(moment(expiredPolicy, 'DD/MM/YYYY'))
    if (isExpired) {
      onOpenConfirmModal()
    }
    else {
      let gopPatient = { ...patient };
      dispatch(setDraftGopPatientDetails(gopPatient));
      dispatch(updateDraftGop());
      handleNext();
    }
  };

  const onOpenConfirmModal = () => {
    setIsConfirmModalOpen(true)
  }

  const onCloseConfirmModal = () => {
    setIsConfirmModalOpen(false)
    setSelectedPatient({})
  }

  const onConfirmModal = () => {
    setIsConfirmModalOpen(false)
    dispatch(setDraftGopPatientDetails(selectedPatient));
    dispatch(updateDraftGop());
    handleNext();
  }

  useEffect(() => {
    dispatch(setLoadingGopSearchPatient(false))
    if (draftGop && draftGop.patient) {
      reset({
        memberName: draftGop?.patient?.firstName,
        familyName: draftGop?.patient?.lastName,
        nic: draftGop?.patient?.nic,
        dob: draftGop?.patient?.dob,
        policyNum: draftGop?.patient?.policyNum,
        familyIdMemberId: draftGop?.patient?.familyIdMemberId,
        cardNumber: draftGop?.patient?.cardNumber,
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>
        {activeStep?.showBackBtn &&
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, color: '#00b7cd' }}
          >
            <ArrowBackIcon sx={{ margin: '0 9px 0 0', color: '#eb0781' }} />
            Back
          </Button>
        }
        <Box sx={{ flex: '1 1 auto' }} />

        {activeStep?.showNextBtn &&
          <Button onClick={handleNext} sx={{ color: '#00b7cd' }}>
            Next
            <ArrowForwardIcon sx={{ margin: '0 0 0 9px', color: '#eb0781' }} />
          </Button>
        }
      </Box>
      <Typography
        pb={2}
        pt={3}
        sx={{
          color: "#000",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        Input Patient Details to Search
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldController
            control={control}
            label={'MEMBER NAME'}
            nameControl={'memberName'}
            validationKey={'SEARCH_NAME_VALIDATION'}
            clearErrors={clearErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldController
            control={control}
            label={'FAMILY NAME'}
            nameControl={'familyName'}
            validationKey={'SEARCH_NAME_VALIDATION'}
            clearErrors={clearErrors}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextFieldController
            control={control}
            label={'NIC'}
            nameControl={'nic'}
            validationKey={'SEARCH_NIC_VALIDATION'}
            clearErrors={clearErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldController
            control={control}
            label={'DATE OF BIRTH'}
            nameControl={'dob'}
            validationKey={'GOP_PATIENT_DATE_OF_BIRTH'}
            clearErrors={clearErrors}
            placeholder="dd/mm/yyyy"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldController
            control={control}
            label={'SWAN POLICY NUMBER'}
            nameControl={'policyNum'}
            validationKey={''}
            clearErrors={clearErrors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldController
            control={control}
            label={'FAMILY / MEMBER ID'}
            nameControl={'familyIdMemberId'}
            validationKey={''}
            clearErrors={clearErrors}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextFieldController
            control={control}
            label={'CARD NUMBER'}
            nameControl={'cardNumber'}
            validationKey={'SEARCH_CARD_NUMBER'}
            clearErrors={clearErrors}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" spacing={24}>
        <Grid item xs={6} sm={6}>
          <Button
            onClick={() => {
              handlerClearSearch();
            }}
            sx={{
              marginRight: "20px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
            color="secondary"
            startIcon={<ClearAllIcon />}
            variant="text"
          >
            CLEAR SEARCH
          </Button>
          <LoadingButton
            color="secondary"
            onClick={handleSubmit(handleSearchDetails)}
            loading={loadingNewGopSearchPatient}
            startIcon={<SearchIcon />}
            loadingPosition="start"
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
            }}
            variant="contained"
          >
            SEARCH
          </LoadingButton>
        </Grid>
      </Grid>
      {searchResult?.length ? (
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Member Name</TableCell>
                  <TableCell align="center">Family Name</TableCell>
                  <TableCell align="center">NIC Number</TableCell>
                  <TableCell align="center">Expiry Date</TableCell>
                  <TableCell align="center">Policy No</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResult.map((row, index) => (
                  <TableRow
                    onClick={() => handleSelectPatient(row)}
                    key={index}
                    sx={{
                      "&:hover": { backgroundColor: '#ddd' },
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: 'pointer'
                    }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {row.firstName}
                    </TableCell>
                    <TableCell align="center">{row.lastName}</TableCell>
                    <TableCell align="center">{row.nic}</TableCell>
                    <TableCell align="center">{row.expiryDateStr}</TableCell>
                    <TableCell align="center">{row.policyNum}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : !!loadingNewGopSearchPatient || !draftIdGop ? <CircularProgress /> : null}
      <ConfirmModal
        open={isConfirmModalOpen}
        handleClickBtnCancel={onCloseConfirmModal}
        handleClickBtnConfirm={onConfirmModal}
        type={'CONFIRM_CREATE_GOP_IN_EXPIRED_POLICY'}
        actionTitle={''}
      />
    </React.Fragment>
  );
};

export default PatientSearchForm;
