import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, ButtonBase, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Permissions } from '../media/icons/permissions.svg';
import { ReactComponent as PermissionsBlue } from '../media/icons/permissionsblue.svg';
import { ReactComponent as Users } from '../media/icons/users.svg';
import { ReactComponent as UsersBlue } from '../media/icons/usersblue.svg';
import { ReactComponent as Clinic } from '../media/icons/clinics.svg';
import { ReactComponent as ClinicBlue } from '../media/icons/clinicsblue.svg';
import { ReactComponent as OpenCaseIcon } from '../media/icons/openCase.svg';
import { ReactComponent as OpenCaseBlueIcon } from '../media/icons/openCaseBlue.svg';
import { ReactComponent as ClosedCaseIcon } from '../media/icons/closedCase.svg';
import { ReactComponent as ClosedCaseBlueIcon } from '../media/icons/closedCaseBlue.svg';
import { ReactComponent as PendingCaseIcon } from '../media/icons/pendingCase.svg';
import { ReactComponent as PendingCaseBlueIcon } from '../media/icons/pendingCaseBlue.svg';
import { getGopStats, setGopStatsLoading } from '../redux/actions/view.actions';
import { CASE_PROGRESS, SIDE_DOCK } from '../constants/viewConfig';
import { store } from '../App'


const IMSDrawer = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gopStats = useSelector(state => state.view.gopStats);
  const {
    show = []
  } = useSelector(state => state.view.mainViewConfig)

  const sharedSx = {
    borderRadius: 0,
    width: '100%',
    justifyContent: 'flex-start',
    // borderColor: 'primary.main',
    textAlign: 'left'
  }

  const DrawerGroupItem = ({ text, selected, onClick }) => (
    <Button
      sx={{
        borderBottom: 3,
        borderColor: selected ? '#00b7cd' : 'transparent',
        justifyContent: 'flex-start',
        marginTop: 4,
        fontWeight: 'bold',
        color: 'white',
        paddingLeft: 0,
        ...sharedSx,
      }}
      onClick={onClick}
    >{text}</Button>
  );

  const DrawerListItem = ({ activeIcon, inactiveIcon, text, text2 = '', selected, onClick }) => (
    <ButtonBase
      onClick={onClick}
      sx={{
        display: 'flex',
        borderRight: 3,
        borderColor: selected ? '#00b7cd' : 'transparent',
        backgroundColor: selected ? '#dafbff' : 'transparent',
        color: selected ? '#0038cd' : 'white',
        paddingLeft: 1,
        paddingTop: 1,
        paddingBottom: 1,
        flex: 1,
        marginTop: 1,
        ...sharedSx,
        '&:hover': !selected ? {
          backgroundColor: 'rgba(0, 122, 255, 0.5)',
          color: 'white'
        } : {
          backgroundColor: 'rgba(218, 251, 255, 0.8)',
          color: '#0038cd',
        }
      }}>
      {selected ? activeIcon : inactiveIcon}
      <Box display='flex' flex={1} flexDirection='row' justifyContent='space-between'>
        <Typography pl={1}>{text}</Typography>
        <Typography pr={selected ? '13px' : 2}>{text2}</Typography>
      </Box>
    </ButtonBase>
  );

  useEffect(() => {
    dispatch(setGopStatsLoading(false));
  }, [])

  useEffect(() => {
    if (!store.getState().view.gopStatsLoading) {
      dispatch(getGopStats())
    }
  }, [location.pathname])

  return (
    <Box>
      {show.includes(SIDE_DOCK.OVERVIEW) && <DrawerGroupItem
        text='Overview'
        onClick={() => navigate('/cases/overview')}
        selected={location.pathname === '/cases/overview'}
      />}
      {show.includes(SIDE_DOCK.GROUP_MANAGEMENT) && <DrawerGroupItem
        text='Group Management'
        onClick={() => navigate('/companyGroups')}
        selected={location.pathname === '/companyGroups' || location.pathname === '/clinics' || location.pathname === '/'}
      />}
      {show.includes(SIDE_DOCK.GROUP_MANAGEMENT) && show.includes(SIDE_DOCK.COMPANY_GROUPS) && <DrawerListItem
        text={'Company Groups'}
        activeIcon={<ClinicBlue />}
        inactiveIcon={<Clinic />}
        onClick={() => navigate('/companyGroups')}
        selected={location.pathname === '/companyGroups' || location.pathname === '/'}
      />}
      {show.includes(SIDE_DOCK.GROUP_MANAGEMENT) && show.includes(SIDE_DOCK.CLINICS) && <DrawerListItem
        text={'Clinics'}
        activeIcon={<ClinicBlue />}
        inactiveIcon={<Clinic />}
        onClick={() => navigate('/clinics')}
        selected={location.pathname === '/clinics'}
      />}
      {show.includes(SIDE_DOCK.CASES_CLAIMS) && <DrawerGroupItem
        text='Cases / Claims'
        selected={location.pathname === '/cases/in_progress'
          || location.pathname === '/cases/awaiting_my_action'
          || location.pathname === '/cases/open'
          || location.pathname === '/cases/closed'
          || location.pathname === '/lcases/open'
          || location.pathname === '/lcases/closed'
        } />}
      {show.includes(SIDE_DOCK.CASES_CLAIMS) && show.includes(SIDE_DOCK.CASES_CLAIMS_AWAITING_MY_ACTION) && <DrawerListItem
        text={'Awaiting My Action'}
        text2={gopStats?.awaitingMyActionQty}
        activeIcon={<PendingCaseBlueIcon />}
        inactiveIcon={<PendingCaseIcon />}
        onClick={() => navigate('/cases/awaiting_my_action', { state: { type: CASE_PROGRESS.AWAITING_MY_ACTION } })}
        selected={location.pathname === '/cases/awaiting_my_action'}
      />}
      {show.includes(SIDE_DOCK.CASES_CLAIMS) && show.includes(SIDE_DOCK.CASES_CLAIMS_IN_PROGRESS) && <DrawerListItem
        text={'In Progress'}
        text2={gopStats?.inProgressQty}
        activeIcon={<PendingCaseBlueIcon />}
        inactiveIcon={<PendingCaseIcon />}
        onClick={() => navigate('/cases/in_progress', { state: { type: CASE_PROGRESS.IN_PROGRESS } })}
        selected={location.pathname === '/cases/in_progress'}
      />}
      {show.includes(SIDE_DOCK.CASES_CLAIMS) && show.includes(SIDE_DOCK.CASES_CLAIMS_OPEN) && <DrawerListItem
        text={'Open'}
        text2={gopStats?.openQty}
        activeIcon={<OpenCaseBlueIcon />}
        inactiveIcon={<OpenCaseIcon />}
        onClick={() => navigate('/cases/open', { state: { type: CASE_PROGRESS.OPEN } })}
        selected={location.pathname === '/cases/open'}
      />}
      {show.includes(SIDE_DOCK.CASES_CLAIMS) && show.includes(SIDE_DOCK.CASES_CLAIMS_CLOSED) && <DrawerListItem
        text={'Closed'}
        text2={gopStats?.closedQty}
        activeIcon={<ClosedCaseBlueIcon />}
        inactiveIcon={<ClosedCaseIcon />}
        onClick={() => navigate('/cases/closed', { state: { type: CASE_PROGRESS.CLOSED } })}
        selected={location.pathname === '/cases/closed'}
      />}
      {show.includes(SIDE_DOCK.CASES_CLAIMS) && show.includes(SIDE_DOCK.CASES_CLAIMS_OPEN_L) && <DrawerListItem
        text={'Open'}
        text2={gopStats?.openQty}
        activeIcon={<OpenCaseBlueIcon />}
        inactiveIcon={<OpenCaseIcon />}
        onClick={() => navigate('/lcases/open')}
        selected={location.pathname === '/lcases/open'}
      />}
      {show.includes(SIDE_DOCK.CASES_CLAIMS) && show.includes(SIDE_DOCK.CASES_CLAIMS_CLOSED_L) && <DrawerListItem
        text={'Closed'}
        text2={gopStats?.closedQty}
        activeIcon={<ClosedCaseBlueIcon />}
        inactiveIcon={<ClosedCaseIcon />}
        onClick={() => navigate('/lcases/closed')}
        selected={location.pathname === '/lcases/closed'}
      />}
      {show.includes(SIDE_DOCK.USERS_PERMISSIONS) && <DrawerGroupItem text='Users & permissions'
        selected={location.pathname === '/ims/users' || location.pathname === '/ims/permissions'} />}
      {show.includes(SIDE_DOCK.USERS_PERMISSIONS) && show.includes(SIDE_DOCK.USERS_PERMISSIONS_USERS) &&
        <DrawerListItem
          text={'Users'}
          activeIcon={<UsersBlue />}
          inactiveIcon={<Users />}
          onClick={() => navigate('/ims/users')}
          selected={location.pathname === '/ims/users'}
        />}
      {show.includes(SIDE_DOCK.USERS_PERMISSIONS) && show.includes(SIDE_DOCK.USERS_PERMISSIONS_PERMISSIONS_RULES) &&
        <DrawerListItem text={'Permissions & Rules'}
          activeIcon={<PermissionsBlue />}
          inactiveIcon={<Permissions />}
          onClick={() => navigate('/ims/permissions')}
          selected={location.pathname === '/ims/permissions'}
        />}
      {show.includes(SIDE_DOCK.REPORTS) && <DrawerGroupItem text='Reports' />}
      {show.includes(SIDE_DOCK.MASTER_FILE) && <DrawerGroupItem text='Master File'
        onClick={() => navigate('/master-file')}
        selected={location.pathname === '/master-file'} />}
        {show.includes(SIDE_DOCK.PRICE_LIST) && <DrawerGroupItem
            text='Price lists'
            onClick={() => navigate('/pricelist')}
            selected={location.pathname === '/pricelist'} />}
    </Box>
  );
};

export default IMSDrawer;