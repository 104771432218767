import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Box, Button, FilledInput, FormControl, InputLabel, TextField, Typography } from "@mui/material";
import ErrorMessage from "../../../shared/ErrorMessage";
import { forgotPasswordDoAction } from "../../../redux/actions/auth.actions";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { getRulesValidation, getRulesValidationErrorMsg } from '../../../utils/onChangeFunction';

const ForgotPasswordStep1 = ({ location, from, forgotPassState, setForgotPassState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = 'INSERT_EMAIL';
  const { handleSubmit, control, setValue, formState: { errors }, getValues } = useForm({
    defaultValues: {
      email: '',
      oldPasswird: '',
      newPAssword: ''
    },
    mode: 'all'
  });

  const handleSubmitForm = (formData) => {
    console.log("formData: ", formData);
    dispatch(forgotPasswordDoAction({ action, email: formData.email, navigate, from }))
  };

  useEffect(() => {
    setValue('email', forgotPassState.email, { shouldValidate: false, shouldDirty: false })
    setValue('oldPasswird', forgotPassState.oldPassword, { shouldValidate: true, shouldDirty: true })
    setValue('newPAssword', forgotPassState.newPAssword, { shouldValidate: true, shouldDirty: true })
  }, [])

  return (<Box pt={5} sx={{
    width: "500px",
    display: "flex",
    flexDirection: 'column',
    margin: "0 auto",
    minHeight: "100px",
  }}>
    <Box sx={{ margin: "20px auto" }}>
      <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Please Enter Registered Email id to send password reset instruction</Typography>
    </Box>

    <Controller
      control={control}
      name={'email'}
      rules={{ validate: { ...getRulesValidation('USER_MGMT_USERNAME') } }}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => {
        return (<TextField
          label={'EMAIL'}
          value={value}
          placeholder="Insert your username"
          sx={{ minWidth: 240, width: "100%" }}
          variant="filled"
          onChange={(e) => {
            onChange(e)
            setForgotPassState({ ...forgotPassState, email: e.target.value })
          }}
          error={!!invalid}
          helperText={error ? (error?.message ? error.message : getRulesValidationErrorMsg('USER_MGMT_USERNAME', error?.type)) : null}
        />)
      }} />

    <Button
      variant="contained"
      disabled={!getValues().email || errors?.email}
      sx={{
        height: "36px",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#00b7cd",
        borderRadius: 0,
        m: "20px 0",
        color: "#fff",
      }}
      onClick={handleSubmit(handleSubmitForm)}
    >
      Send email
    </Button>
  </Box>
  );
}

export default ForgotPasswordStep1;
