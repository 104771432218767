import React, { useEffect, useState } from 'react';
import {
  Card, TextField, Autocomplete, CardContent, Typography, Grid
} from '@mui/material';
import _ from 'lodash';
// import EditableSelectControl from './EditableSelectControl';
import SelectorNestedValue from './SelectorNestedValue';

const DisableTextField = ({ value = '', label = '', disabled = true }) => {
  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      fullWidth
      multiline
      variant="filled"
    />
  )
}

const HealthCareStatsComponent = ({ label = '', statsValue = '', propertyName = '', unfilteredItems = [], onChange = () => { }, isDisabled = true }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedStatsValue, setSelectedStatsValue] = useState('');
  const [HEALTHCARE_STATS_A, setHealthCareStatsA] = useState({});
  const [HEALTHCARE_STATS_B, setHealthCareStatsB] = useState({});
  const [HEALTHCARE_STATS_C, setHealthCareStatsC] = useState({});
  const mappedStatsItemA = _.uniqBy(unfilteredItems, 'title1')
  const [filteredStatsA, setFilteredStatsA] = useState(mappedStatsItemA);
  const [filteredStatsB, setFilteredStatsB] = useState([]);
  const [filteredStatsC, setFilteredStatsC] = useState([]);

  const onChangeHealthCareStatsA = (valueObj = {}) => {
    setSelectedStatsValue('');
    setHealthCareStatsA({ ...valueObj });
    setHealthCareStatsB({});
    setHealthCareStatsC({});
    setFilteredStatsC([]);
  }

  const onChangeHealthCareStatsB = (valueObj = {}) => {
    setSelectedStatsValue('');
    setHealthCareStatsB({ ...valueObj });
    setHealthCareStatsC({});
  }

  const onChangeHealthCareStatsC = (valueObj = {}) => {
    setSelectedStatsValue(valueObj);
    setHealthCareStatsC({ ...HEALTHCARE_STATS_C, ...valueObj })
    if (valueObj?.value1 && valueObj?.value2 && valueObj?.value3 && valueObj?.value) {
      onChange(valueObj);
    }
  }




  useEffect(() => {
    if (HEALTHCARE_STATS_A?.value1) {
      const filteredData = unfilteredItems.filter((value, index, self) => 
        self.findIndex(v => v.value1 === value.value1 && HEALTHCARE_STATS_A?.value1 === v.value1 && v.value2 === value.value2) === index
      );
      setFilteredStatsB(filteredData);
    }
  }, [HEALTHCARE_STATS_A])

  useEffect(() => {
    if (HEALTHCARE_STATS_B?.value2) {
      setFilteredStatsC(unfilteredItems.filter((item) => (item.value1 === HEALTHCARE_STATS_B.value1 && item.value2 === HEALTHCARE_STATS_B.value2)))
    }
  }, [HEALTHCARE_STATS_B])


  useEffect(() => {
    setSelectedStatsValue(statsValue);
    setHealthCareStatsA(mappedStatsItemA.find((item) => statsValue?.value1 === item.value1));
    if (statsValue?.value1 && statsValue?.value2) {
      const filteredDataB = unfilteredItems.filter((value, index, self) => 
        self.findIndex(v => v.value1 === value.value1 && statsValue?.value1 === v.value1 && v.value2 === statsValue.value2) === index
      );
      setHealthCareStatsB(filteredDataB.find((item) => item.value2 === statsValue.value2));
    } else {
      setHealthCareStatsB({})
    }
    setHealthCareStatsC(statsValue);
    if (!isDisabled) {
      setFilteredStatsA(mappedStatsItemA)
    }
  }, [statsValue])

  return (
    <Card variant="outlined">
      <CardContent sx={{ padding: '16px 4px 16px 4px' }} >
        {isDisabled && <DisableTextField label={label} value={selectedStatsValue?.title} disabled={isDisabled} />}
        {!isDisabled && <Grid container spacing={1.5} >
          <Grid item xs={12} sm={12}>
            <Autocomplete
              value={selectedStatsValue}
              onChange={(event, selectValue) => {
                onChange(selectValue)
              }}
              inputValue={searchValue}
              onInputChange={(event, newInputValue) => {
                setSearchValue(newInputValue);
              }}
              id={propertyName + '-search-control'}
              options={unfilteredItems}
              getOptionLabel={(option) => {
                return option?.title ? option.title : ''
              }}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              renderInput={(params) => <TextField multiline {...params} label={label} />}
            /></Grid>
          <Grid item xs={12} sm={4}>
            <SelectorNestedValue
              valueObj={HEALTHCARE_STATS_A}
              titleExtractor={(item) => item.title1}
              propertyName={propertyName + 'A'}
              label={label + 'A'}
              items={filteredStatsA}
              isDisabled={false}
              onChange={onChangeHealthCareStatsA}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectorNestedValue
              valueObj={HEALTHCARE_STATS_B}
              titleExtractor={(item) => item.title2}
              propertyName={propertyName + 'B'}
              label={label + 'B'}
              items={filteredStatsB}
              isDisabled={!HEALTHCARE_STATS_A?.value1}
              onChange={onChangeHealthCareStatsB}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectorNestedValue
              valueObj={HEALTHCARE_STATS_C}
              titleExtractor={(item) => item.title3}
              propertyName={propertyName + 'C'}
              label={label + 'C'}
              items={filteredStatsC}
              isDisabled={!HEALTHCARE_STATS_B?.value2}
              onChange={onChangeHealthCareStatsC}
            />
          </Grid>
        </Grid>
        }
      </CardContent>
    </Card>
  )
}

export default HealthCareStatsComponent