export const LIGHT_BLUE_COLOR = '#00b7cd';
export const GREEN_COLOR = '#045c5f';
export const PINK_COLOR = '#eb0781';
export const GRAY_COLOR = 'rgba(59, 64, 69, 0.87)';
export const DISABLED_COLOR = '#b6b9bb';
export const TEXT_FIELD_BG_COLOR = "rgb(238, 239, 240)";
export const TEXT_FIELD_PLACEHOLDER_COLOR = "rgba(59, 64, 69)";
export const LIGHTENED_PINK = '#eb0781';
export const LIGHTENED_DISABLED = 'rgba(0, 0, 0, 0.38)';
export const LIGHTENED_BLUE = '#b1e9f0';
export const ACTIVE_COLOR = '#42ad00';
export const INACTIVE_COLOR = '#ff7a00';
