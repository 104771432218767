import React, { useEffect, useRef } from "react";
import { Box, Fab } from "@mui/material";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    ChooseProfileModal,
    DrawerContainer,
    IMSDrawer,
    PageBodyContainer,
    PageContainer,
    SuccessAddModal,
} from "../../shared";
import { CaseCard, GopDetails } from "./components";
import { Add } from "@mui/icons-material";
import { getAllGops, resetDraftGop, setGopDocsLength, setOpenModalGOPSuccess, } from "../../redux/actions/gop.actions";
import { GOP_BTN } from "../../constants/viewConfig";
// import { setMainPageLoading } from "../../redux/actions/view.actions";



const Cases = () => {
  const dispatch = useDispatch();
  // const { type } = useParams();
  let location = useLocation();
  const { type = '' } = location?.state || {};
  const user = useSelector((state) => state.auth.user);
  const viewConfig = useSelector((state) => state.view.mainViewConfig);
  const navigate = useNavigate();
  const allGops = useSelector((state) => state.gop.allGops);
  const selectedGop = useSelector((state) => state.gop.selectedGop);
  const openModalGOPSuccess = useSelector(
    (state) => state.gop?.modals?.openModalGOPSuccess
  );
  const handleNavigate = (route) => {
    navigate(route);
  };
  const pageTopRef = useRef();



  useEffect(() => {
    // console.log('type', type)
    if (!selectedGop?.value?.a_viewConfig?.case_progress?.includes?.(type)) {
      pageTopRef.current.scrollIntoView({ behavior: "smooth" });
    }
    dispatch(getAllGops());
    dispatch(setGopDocsLength(0));
  }, [type]);

  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={
          user.owner_type === "provider"
            ? "Inpatient Management System"
            : "Clinic Portal"
        }
        underline={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Box ref={pageTopRef} sx={{ padding: "35px", paddingTop: "10px" }}>
          {/*<Box*/}
          {/*  display="flex"*/}
          {/*  flexDirection="row"*/}
          {/*  alignItems="center"*/}
          {/*  pl={4}*/}
          {/*  pr={8}*/}
          {/*  pb={3}*/}
          {/*  pt={1}*/}
          {/*>*/}
          {/*  <Typography*/}
          {/*    ref={pageTopRef}*/}
          {/*    sx={{*/}
          {/*      color: "#053ad3",*/}
          {/*      fontSize: "48px",*/}
          {/*      pr: 8,*/}
          {/*      textTransform: "capitalize",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {type.replace("_", " ")} Cases*/}
          {/*  </Typography>*/}
          {/*  <TextField*/}
          {/*    id="input-with-icon-textfield"*/}
          {/*    InputProps={{*/}
          {/*      startAdornment: (*/}
          {/*        <InputAdornment position="start">*/}
          {/*          <SearchIcon sx={{ color: "#eb0781" }} />*/}
          {/*        </InputAdornment>*/}
          {/*      ),*/}
          {/*    }}*/}
          {/*    variant="standard"*/}
          {/*  />*/}
          {/*</Box>*/}
          <Box display="flex" flexDirection="row">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: "420px",
              }}
              style={{
                height: "750px",
                paddingRight: "20px",
                overflow: "auto",
              }}
            >
              {allGops
                ?.filter?.((item) => item?.value?.a_viewConfig?.case_progress?.includes?.(type))
                ?.map?.((item, index) => (
                  <CaseCard
                    selected={item?.keys?.gop_id === selectedGop?.keys?.gop_id}
                    key={"caseCard" + index.toString()}
                    gop={item}
                  />
                ))}
            </Box>
            {selectedGop?.keys?.gop_id && <GopDetails type={type} />}
          </Box>
        </Box>
        <SuccessAddModal
          open={openModalGOPSuccess}
          handleOpen={(boolean) => dispatch(setOpenModalGOPSuccess(boolean))}
          type={"GOP"}
          handleClickBtnSuccess={() => {
            dispatch(setOpenModalGOPSuccess(false));
            dispatch(resetDraftGop());
            handleNavigate("/cases/newgop");
          }}
        />
        {viewConfig?.show && viewConfig?.show?.includes(GOP_BTN.ADD_NEW) && (
          <Fab
            style={{
              position: "fixed",
              bottom: 42,
              right: 42,
              fontFamily: "Globerbold",
            }}
            variant="extended"
            color={"secondary"}
            onClick={() => {
              dispatch(resetDraftGop());
              handleNavigate("/cases/newgop");
            }}
          >
            <Add />
            ADD NEW
          </Fab>
        )}

      </PageBodyContainer>
        <ChooseProfileModal/>
    </PageContainer>
  );
};

export default Cases;
