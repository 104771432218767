import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Dialog,
  IconButton,
  TextField,
  Button
} from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { addNewContact, updateContact } from '../../../redux/actions/entities.actions';
import { getRulesValidation, getRulesValidationErrorMsg } from '../../../utils/onChangeFunction';


const TextFieldControl = ({ control, nameControl, validationKey, ...rest }) => {
  return (
    <Controller
      name={nameControl}
      control={control}
      defaultValue={''}
      rules={{ validate: { ...getRulesValidation(validationKey) } }}
      render={({
        field: { onChange, ...restField },
        fieldState: { invalid, error },
      }) => {
        return (<TextField
          sx={{ minWidth: 240, width: "100%" }}
          variant="filled"
          onChange={onChange}
          error={!!invalid}
          helperText={error ? (error?.message ? error.message : getRulesValidationErrorMsg(validationKey, error?.type)) : null}
          {...restField}
          {...rest}
        />)
      }}
    />
  )
}

const AddEditContactModal = ({ open, handleOpen, selectedContact }) => {
  const dispatch = useDispatch();
  const [formType, setFormType] = useState('add')
  const defaultValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: ''
  }
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues,
    mode: 'all'
  });

  const handleClickSave = (data) => {
    console.log("form data - ", data);
    if (formType === 'add') {
      dispatch(addNewContact({
        contact: { ...data }
      }))
    }
    else if (formType === 'edit') {
      if (selectedContact) {
        dispatch(updateContact({
          contact: { ...data },
          contact_id: selectedContact?.keys?.contact_id
        }))
      }
    }
  }

  useEffect(() => {
    if (selectedContact) {
      setFormType('edit')
      setValue('firstName', selectedContact?.value?.firstName, { shouldValidate: true, shouldDirty: true })
      setValue('lastName', selectedContact?.value?.lastName, { shouldValidate: true, shouldDirty: true })
      setValue('email', selectedContact?.value?.email, { shouldValidate: true, shouldDirty: true })
      setValue('phone', selectedContact?.value?.phone, { shouldValidate: true, shouldDirty: true })
    }
    else {
      setFormType('add')
      reset(defaultValues)
    }
  }, [selectedContact])

  return (
    <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={() => handleOpen(false)}>
      <Box pl={3} pr={3} pt={3} pb={3}>
        <Box display='flex' flexDirection='row' pb={2} justifyContent='space-between'>
          <Typography sx={{ color: 'primary.main', fontSize: 24, fontFamily: 'Globersemibold', textTransform: 'uppercase' }}>
            {formType === 'add' ? 'Add New Contact' : 'Edit Contact'}
          </Typography>
          <IconButton onClick={() => handleOpen(false)}>
            <CloseIcon color='secondary' />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(handleClickSave)}>
          <Grid container spacing={4}>

            {/* first row */}
            <Grid item xs={6}>
              <TextFieldControl
                control={control}
                nameControl={'firstName'}
                label={'First Name'}
                validationKey={'NAME_VALIDATION'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldControl
                control={control}
                nameControl={'lastName'}
                label={'Last Name'}
                validationKey={'NAME_VALIDATION'}
              />
            </Grid>

            {/* second row */}
            <Grid item xs={6}>
              <TextFieldControl
                control={control}
                nameControl={'email'}
                label={'Email'}
                validationKey={'USER_MGMT_USERNAME'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldControl
                control={control}
                nameControl={'phone'}
                label={'Phone Number'}
                validationKey={'MOBILE_NUMBER_VALIDATION'}
              />
            </Grid>

            {/* buttons row */}
            <Grid item xs={12}>
              <Box display='flex' flexDirection='row' justifyContent='flex-end' mt={2} >
                <Button
                  color='secondary'
                  disabled={false}
                  variant='contained'
                  type='submit'
                >{formType === 'add' ? 'Add Contact' : 'Save'}</Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
};

export default AddEditContactModal;