import {
  SET_AUTH_TOKEN,
  SET_AUTH_USER,
  LOG_OUT,
  SET_CONNECTED_STATUS_REJECTED,
  SET_CONNECTED_STATUS,
  SET_NATS_CONFIG,
  SET_PROVIDER_USERS,
  SET_PROVIDER_ROLES,
  SET_AUTH_SELECT_ROLE_DIALOG_OPEN, SET_AUTH_REDUCER
} from '../actionTypes';

const INITIAL_STATE = {
  projectVersion: '0.0.25',
  token: '',
  user: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    owner_type: '',
    owner_id: '',
    created_by: '',
    isActive: '',
    a_role_names: [],
    user_id: '',
    actor: ''
  },
  actors: [],
  providerUsers: [],
  providerRoles: [],
  natsConfiguration: {},
  natsConnected: false,
  natsErrorMessage: '',
  isSelectRoleDialogOpen: false,
};


export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case SET_AUTH_TOKEN:
      return { ...state, token: action.payload }
    case SET_NATS_CONFIG:
      return {
        ...state,
        natsConfiguration: action.payload
      };
    case SET_CONNECTED_STATUS:
      return {
        ...state,
        natsErrorMessage: '',
        natsConnected: action.payload
      };
    case SET_CONNECTED_STATUS_REJECTED:
      return {
        ...state,
        natsErrorMessage: action.payload,
        natsConnected: false,
      };
    case SET_PROVIDER_USERS:
      return {
        ...state,
        providerUsers: action.payload
      };
    case SET_PROVIDER_ROLES:
      return {
        ...state,
        providerRoles: action.payload
      };
    case LOG_OUT:
      return { ...INITIAL_STATE };
    case SET_AUTH_SELECT_ROLE_DIALOG_OPEN:
      return {
        ...state,
        isSelectRoleDialogOpen: action.payload.isOpen,
        actors: !!action.payload.actors && action.payload.actors.length > 0 ? action.payload.actors : state.actors
      };
    case SET_AUTH_REDUCER:
      return { ...state, ...action.payload }
    default:
      return state;
  }
};

