import React, { useEffect, useState } from 'react';
import {
  Box, ListItem, ListItemButton, Typography,
  ListItemText, IconButton,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ReactComponent as ClaimHandlerIcon } from '../media/icons/claimhandler.svg'
import { useDispatch, useSelector } from 'react-redux';
import { BILLS } from '../constants/viewConfig';
import FileSaver from 'file-saver';
import { getBlob, getDownloadFileUrl } from '../utils/upload';

const TextTypography = ({ text = '' }) => {
  return (
    <Typography sx={{ color: '#979797', fontSize: '12px', marginRight: '12px', marginLeft: '5px' }}>{text}</Typography>
  )
}

const DocumentFileItem = ({ file, openFile = () => { }, confirmationDelete = () => { } }) => {
  const dispatch = useDispatch()
  const {
    file_id = '',
    bill_id = '',
    a_title = '',
    filename = '',
    a_created_at_ddmmyyyy = '',
    created_by_name = '',
    a_viewConfig = {}
  } = file || {}

  const handleDownloadFile = async (file) => {
    let link = await getDownloadFileUrl(file.gop_id, file.s3_uuid)
    let blob = await getBlob(link)
    FileSaver.saveAs(blob, file.filename)
  }

  return (
    <ListItem sx={{ paddingLeft: '8px', paddingRight: '8px' }}>
      {a_viewConfig?.show?.includes?.(BILLS.REMOVE_FILE_BTN) &&
        <IconButton aria-label="remove"
          onClick={() => {
            confirmationDelete(file)
          }}
        >
          <DeleteForeverIcon color='error' />
        </IconButton>}
      <IconButton aria-label="download"
        onClick={() => handleDownloadFile(file)}
      >
        <DownloadIcon />
      </IconButton>
      <ListItemButton onClick={() => {
        if (filename?.includes('.xls') || filename?.includes('.xlsx')) {
          return;
        }
        openFile(file);
      }}>
        <PictureAsPdfIcon color='secondary' />
        <Box ml={'10px'} alignItems='start'>
          <ListItemText>{a_title}</ListItemText>
          <Box display='flex' flexDirection='row' alignItems='center' mt={'10px'}>
            <ClaimHandlerIcon width={16} height={16} sx={{ color: '#979797', }} />
            <TextTypography text={created_by_name} />
            <AccessTimeIcon sx={{ color: '#979797', width: '16px', height: '16px' }} />
            <TextTypography text={a_created_at_ddmmyyyy} />
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  )
}

export default DocumentFileItem;
