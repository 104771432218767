import { axiosErrorHandler, postRequest } from "../../utils/axios";
import {
ADD_PROFILE_TO_PROFILES,
  SET_CHOOSE_PROFILE_MODAL_OPEN
} from "../actionTypes";
// import { entityTypeAndID } from "../../utils/entityTypeAndID";
import { store } from '../../App'


export const addOrUpdateProfile = (obj) => {
  return {
    type: ADD_PROFILE_TO_PROFILES,
    payload: obj
  }
}

export const setChooseProfileModalOpen = (bool) => {
  return {
    type: SET_CHOOSE_PROFILE_MODAL_OPEN,
    payload: bool
  }
}

//
// export const createMasterFileItem = ({ file }) => {
//   return async dispatch => {
//     try {
//       const user = store.getState()?.auth?.user;
//       const presignUrlResponse = await getPresignedUrlForMasterFile(file, user.owner_type, user.owner_id);
//       console.log('createMasterFileItem resp ==', presignUrlResponse.data)
//       await uploadFileToDrive(presignUrlResponse.data, file)
//       dispatch(setLoadingUploadDocument(false))
//       dispatch(getAllMasterFiles())
//     } catch (err) {
//       const error = axiosErrorHandler(err);
//       console.error('error - createMasterFileItem - ', error)
//       dispatch(setLoadingUploadDocument(false))
//     }
//   }
// }
