import React, { useEffect } from 'react';
import { Box, Paper, Typography, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setSelectedGroup } from '../../../redux/actions/entities.actions';
// import greyBackgroundBanner from "../media/backgroundImages/greyBackgroundBanner.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Documents } from '../../../shared';

const CompanyCases = ({ entity }, item) => {

  return (
    <Box sx={{
      padding: "35px",

    }}>
      <Box display='flex' flexDirection='row' alignItems='center' pl={4} pr={8} pb={3} pt={1}>
        <Typography sx={{ color: "#053ad3", fontSize: "48px", pr: 8 }}>
          Cases/Claims
        </Typography>
      </Box>
      <Documents />
    </Box >
  );
};

export default CompanyCases;