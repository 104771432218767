import React, { useEffect, useState } from 'react';
import {
  Box, Accordion, Typography, AccordionSummary, AccordionDetails, List, ListItem, IconButton, ListItemButton, ListItemText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { COLORS } from "../constants";
import { ButtonAddDocument, DocumentFileItem } from '.';
import { deleteFile, uploadFile } from '../redux/actions/gop.actions';
import { setLoadingUploadDocument } from '../redux/actions/view.actions';
import ConfirmModal from './ConfirmModal';

const TextTypography = ({ text = '' }) => {
  return (
    <Typography sx={{ color: '#979797', fontSize: '12px', marginRight: '12px', marginLeft: '5px' }}>{text}</Typography>
  )
}

const DocumentCategory = ({ section, index, openFile, gop_id, bill_id }) => {
  const dispatch = useDispatch();
  const a_viewConfig = useSelector(state => state.gop?.selectedGop?.value?.a_viewConfig) || {}

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [selectedFile, setselectedFile] = useState(null)

  const handleUploadAcceptedFiles = (files) => {
    files.forEach(file => {
      dispatch(setLoadingUploadDocument(true))
      dispatch(uploadFile({ gop_id, file, section: section?.section, bill_id }))
    })
  }

  const onCloseConfirmModal = () => {
    setIsConfirmModalOpen(false)
    setselectedFile(null)
  }

  const onConfirmModal = () => {
    dispatch(deleteFile(selectedFile))
    setIsConfirmModalOpen(false)
    setselectedFile(null)
  }

  return (
    <Box flex={1}>
      <Accordion sx={{ boxShadow: "none", }}>
        <AccordionSummary
          classes={{
            expanded: 'accordionExpand'
          }}
          sx={{
            color: COLORS.LIGHTENED_DISABLED,
            borderBottom: "1px solid #e5e5e5",
            padding: 0,
            '&.accordionExpand': {
              color: COLORS.LIGHT_BLUE_COLOR
            }
          }}
          expandIcon={<ExpandMoreIcon color='secondary' />}
          aria-controls={"panel-content-" + index}
          id={"panel-header-" + index}
        >
          {!!section?.sectionTitle ? <Typography>{section?.files.length ? section.sectionTitle + ' - ' + section?.files.length : section.sectionTitle}</Typography> : null}
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {
              section?.files?.map?.((file, index) => {
                return (
                  <DocumentFileItem key={file.file_id}
                    file={file}
                    openFile={openFile}
                    confirmationDelete={(file) => {
                      setselectedFile(file)
                      setIsConfirmModalOpen(true)
                    }} />
                )
              })
            }
          </List>
          <ButtonAddDocument handleUploadAcceptedFiles={handleUploadAcceptedFiles} />
        </AccordionDetails>
      </Accordion>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleClickBtnCancel={onCloseConfirmModal}
        handleClickBtnConfirm={onConfirmModal}
        type={'CONFIRM_DELETE_FILE'}
        actionTitle={''}
        confirmation_msg={''}
      />
    </Box >
  );
};

export default DocumentCategory;