import { axiosErrorHandler, postRequest } from "./axios";
const axios = require("axios");


export const getPresignUrlForUpload = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      let resp = await postRequest(`/upload/upload-logo`, { data: { value: { filename: file.name } } })
      console.log('getPresignUrlForUpload resp ==', resp.data);
      let responseData = resp.data;
      resolve(responseData)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPresignUrlForUpload - ', error)
      reject(error);
    }
  })
}

export const getPresignUrlForUploadDocFile = (gop_id, file, section, bill_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let resp = await postRequest(`/gop/${gop_id}/file/get_upload_link`, {
        data: { value: { filename: file.name, section, bill_id } }
      })
      console.log('getPresignUrlForUploadDocFile resp ==', resp.data);
      let responseData = resp.data;
      resolve(responseData)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPresignUrlForUploadDocFile - ', error)
      reject(error);
    }
  })
}

export const getPresignedUrlForMasterFile = (file, entityType, entityId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let resp = await postRequest(`/entity/${entityType}/${entityId}/master-file/create`, {
        data: { value: { filename: file.name } }
      })
      console.log('getPresignedUrlForMasterFile resp ==', resp.data);
      let responseData = resp.data;
      resolve(responseData)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPresignedUrlForMasterFile - ', error)
      reject(error);
    }
  })
}

export const getPresignedUrlForPricelist = (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      let resp = await postRequest(`/price-list/wb/create`, {
        filename: file.name,
      })
      console.log('getPresignedUrlForMasterFile resp ==', resp.data);
      let responseData = resp.data;
      resolve(responseData)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPresignedUrlForMasterFile - ', error)
      reject(error);
    }
  })
}

export const getPublicUrlForLogo = (uuid) => {
  return new Promise(async (resolve, reject) => {
    try {
      let resp = await postRequest(`/upload/get-logo-url`, { data: { value: { uuid: uuid } } })
      console.log('getPublicUrlForLogo resp ==', resp.data);
      let responseData = resp.data;
      resolve(responseData)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPublicUrlForLogo - ', error)
      reject(error);
    }
  })
}

export const getDownloadFileUrl = (gop_id, uuid) => {
  return new Promise(async (resolve, reject) => {
    try {
      let resp = await postRequest(`/gop/${gop_id}/file/get_download_link`, { data: { value: { s3_uuid: uuid } } })
      console.log('getDownloadFileUrl resp ==', resp.data);
      let responseData = resp.data;
      resolve(responseData.data.link)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getDownloadFileUrl - ', error)
      reject(error);
    }
  })
}

export const getDownloadMasterFileLink = (masterfile_id, uuid) => {
  return new Promise(async (resolve, reject) => {
    try {
      let resp = await postRequest(`/master-file/${masterfile_id}/file/get_download_link`, { data: { value: { s3_uuid: uuid } } })
      console.log('getDownloadMasterFileLink resp ==', resp.data);
      let responseData = resp.data;
      resolve(responseData.data.link)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getDownloadMasterFileLink - ', error)
      reject(error);
    }
  })
}

export const uploadFileToDrive = (uploadData, file) => {
  return new Promise(async (resolve, reject) => {
    try {
      let formData = new FormData();
      Object.keys(uploadData.form_data.fields).forEach(key => {
        formData.append(key, uploadData.form_data.fields[key]);
      });
      formData.append('file', file);
      let response = await axios({
        method: 'POST',
        baseURL: '',
        url: uploadData.form_data.url,
        data: formData,
        onUploadProgress: (p) => {
          // store.dispatch(setUploadFileProgress(p.loaded / p.total))
          // console.log('uploadFileToDrive progress', p.loaded / p.total)
        },
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' }
      })

      if (response.status !== 204) {
        console.log('Failed to upload to S3 bucket');
        reject("Failed to upload");
      }
      else {
        console.log("File uploaded");
        resolve();
      }
    }
    catch (err) {
      const error = axiosErrorHandler(err);
      console.log('uploadFileToDrive err', error)
      reject(error);
    }
  })
}

export const getBlob = (link) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(link, {
        responseType: 'blob',
      })
      resolve(response.data)
    }
    catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getBlob - ', error)
      reject(error);
    }
  })
}
