import React from 'react';
import {
  TextField
} from '@mui/material';
import { Controller } from "react-hook-form";


const ControllerTextField = ({ control, itemKey = '', isNum, ...rest }) => {
  return (
    <Controller
      name={itemKey}
      control={control}
      rules={isNum ? {
        pattern: '[0-9]+'
      } : {}}
      render={({
        field: { onChange, ...restField },
        fieldState: { invalid },
      }) => <TextField
          multiline
          error={invalid}
          onChange={(value) => {
            // console.log('value', value.target.value)
            if (isNum) {
              onChange(value.target.value.replace(/[^0-9.]/g, ''))
            } else {
              onChange(value.target.value)
            }
          }}
          {...restField}
          {...rest}
        />}
    />
  )
}

export default ControllerTextField;
