import {
  SET_OPEN_MODAL_DOCUMENT,
  SET_DOCUMENT_CATEGORY,
  RESET_DOCUMENTS,
  SET_MASTER_FILE_DOCUMENTS,
  SET_PRICELIST_DRAFT,
  SET_SAVE_PRICELIST_MODAL_OPEN,
  SET_ALL_PRICELISTS,
  SET_SAVE_PRICELIST_LOADING,
  SET_GET_PRICELISTS_LOADING,
  SET_PRICELIST
} from '../actionTypes';

const INITIAL_STATE = {
  documents: [],
  category_selected: "",
  masterFileDocuments: [],
  modals: {
    openModalDocument: false,

  },
  pricelistDraft: {},
  savePricelistModalOpen: false,
  allPricelists: [],
  savePricelistLoading: false,
  getPricelistsLoading: false
};


export const documentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DOCUMENT_CATEGORY:
      return { ...state, category_selected: action.payload }
    case SET_OPEN_MODAL_DOCUMENT:
      return { ...state, modals: { ...state.modals, openModalDocument: action.payload } }
    case SET_MASTER_FILE_DOCUMENTS:
      return { ...state, masterFileDocuments: action.payload }
    case RESET_DOCUMENTS:
      return { ...INITIAL_STATE }
    case SET_PRICELIST_DRAFT:
      return { ...state, pricelistDraft: action.payload }
    case SET_SAVE_PRICELIST_MODAL_OPEN:
      return { ...state, savePricelistModalOpen: action.payload }
    case SET_ALL_PRICELISTS:
      return { ...state, allPricelists: action.payload }
    case SET_SAVE_PRICELIST_LOADING:
      return { ...state, savePricelistLoading: action.payload }
    case SET_GET_PRICELISTS_LOADING:
      return { ...state, getPricelistsLoading: action.payload }
    case SET_PRICELIST:
      const { wb_id } = action.payload;
      const allPricelists = [...state.allPricelists];
      const foundIndex =  allPricelists.findIndex(pl=> pl.wb_id === wb_id);
        if (foundIndex !== -1) {
          allPricelists[foundIndex] = action.payload
          }
      return { ...state, allPricelists };
        default:
      return state;
  }
};

