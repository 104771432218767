import React from 'react';
import {
  Box, Typography, IconButton, Modal, Divider
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

const RowContainer = ({ cols = [] }) => {
  return (
    <Box mb={1} sx={{ display: 'flex' }}>
      {cols?.map((col, colIndex) => {
        return (
          <ColContainer key={colIndex.toString()} {...col} />
        )
      })}
    </Box>
  )
}

const ColContainer = ({ position = 'left', width = '100%', fontWeight = 'normal', fontSize = '14px', delimiter = false, t = '' }) => {
  if (delimiter) {
    return (<Divider sx={{ width }} />)
  }
  else {
    return (
      <Typography sx={{ width, fontSize, fontWeight, textAlign: position }} >{t}</Typography>
    )
  }
}

const PackageDialog = ({ openModal, handleClose, rows = [] }) => {
  return (
    <Modal
      sx={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        left: 'inherit',
        maxWidth: ' 480px',
        minWidth: '300px'
      }}
      hideBackdrop={true}
      open={openModal}
      onClose={handleClose}
      disableEnforceFocus={true}
      disableScrollLock={true}
      disablePortal={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        maxHeight: '540px',
        overflow: 'hidden',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 2,
        overflowY: 'scroll'
      }}>
        <IconButton sx={{ position: 'absolute', right: 10, top: 10 }} size="small" onClick={handleClose}>
          <CloseOutlined fontSize="inherit" />
        </IconButton>
        <Box sx={{}}>
          {
            rows?.map((cols, index) => (
              <RowContainer key={index.toString()} cols={cols} />
            ))
          }
        </Box>
      </Box>
    </Modal >
  );
};

export default PackageDialog;