import React, { useState, useEffect } from "react";
import { Button, Grid, Paper, Typography, Box, Fab } from "@mui/material";
import { CasesAndClaimsTableIMS, CasesAndClaimsTableClinic } from "./components";
import { PageContainer, PageBodyContainer, DrawerContainer, IMSDrawer, ChooseProfileModal } from '../../shared';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Add } from "@mui/icons-material";
import { getAllGops, setAllGop, getOverviewStats, resetDraftGop } from "../../redux/actions/gop.actions";
import { ClaimsAndCasesChart, AllocationsChart } from './components'
import { GOP_BTN } from '../../constants/viewConfig';



const Overview = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const clinicOrIms = useSelector(state => state.view.mainViewConfig.IMS_PORTAL_OR_CLINIC_PORTAL)
  const navigate = useNavigate();
  const viewConfig = useSelector(state => state.view.mainViewConfig)


  const handleNavigate = (route) => {
    navigate(route);
  }

  // useEffect(() => {
  //   console.log('clinicOrIms', clinicOrIms)
  // }, [clinicOrIms])

  useEffect(() => {
    dispatch(setAllGop([]));
    dispatch(getAllGops());
    dispatch(getOverviewStats())
  }, [])

  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={user.owner_type === 'provider' ? 'Inpatient Management System' : 'Clinic Portal'}
        underline={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              m={4}
              component="h1"
              variant="h4"
              color={"#053AD3"}
              gutterBottom
            >
              Overview
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
          </Grid> */}
        </Grid>
        <Grid container spacing={3}>
          {/* Claims / Cases Chart */}
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                m: 2,
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <ClaimsAndCasesChart />
            </Paper>
          </Grid>

          {/* Allocations chart */}
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                m: 2,
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <AllocationsChart />
            </Paper>
          </Grid>
          {/* Cases / Claims Table */}
          <Grid item xs={12}>
            <Paper sx={{ m: 2, p: 2, mb: 10, display: "flex", flexDirection: "column" }} >
              {clinicOrIms === 'CLINIC_PORTAL' && <CasesAndClaimsTableClinic />}
              {clinicOrIms === 'IMS_PORTAL' && <CasesAndClaimsTableIMS />}
            </Paper>
          </Grid>
        </Grid>
        {(viewConfig?.show && viewConfig?.show?.includes(GOP_BTN.ADD_NEW)) &&
          <Fab style={{ position: 'fixed', bottom: 42, right: 42, fontFamily: 'Globerbold' }} variant="extended" color={'secondary'}
            onClick={() => {
              dispatch(resetDraftGop());
              handleNavigate('/cases/newgop')
            }}>
            <Add />
            ADD NEW
          </Fab>
        }

      </PageBodyContainer>
    </PageContainer>
  );
};

export default Overview;
