import React, { useState, useEffect } from 'react';
import {
  Box, Button, Tab, Tabs, Typography, TextField
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createRoleForEntity, getRoles, setNewPermissions, setSelectedRoleId, updateRoleByID, resetRoleByID, setSelectedPermission } from '../../../../redux/actions/entities.actions';
import { entityTypeAndID } from '../../../../utils/entityTypeAndID';
import { DropdownWithValue } from '../../../../shared'
import BoxWithPermissions from './BoxWithPermissions';
import { Controller, useForm } from 'react-hook-form';
import { getRulesValidation, getRulesValidationErrorMsg } from '../../../../utils/onChangeFunction';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const CompanyPermissions = () => {
  const dispatch = useDispatch();
  const roles = useSelector(state => state.entities.roles)
  const rolePermissions = useSelector(state => state.entities.rolePermissions);
  const selectedCompany = useSelector(state => state.entities.selectedCompany)
  const [value, setTabsValue] = useState(0);
  let [isDisplayRoleControl, setIsDisplayRoleControl] = useState(false);
  let [selectedRole, setSelectedRole] = useState({});

  const { handleSubmit, control, reset, formState: { isValid } } = useForm({
    defaultValues: {
      role: ''
    },
    mode: 'all'
  });

  useEffect(() => {
    if (selectedCompany?.keys?.table) {
      dispatch(getRoles(entityTypeAndID(selectedCompany)))
    }
  }, [selectedCompany])


  useEffect(() => {
    if (!!roles?.length) {
      console.log('selectedRole', selectedRole);
      dispatch(setNewPermissions(!!selectedRole?.value?.permissions?.value?.permissions ? selectedRole?.value?.permissions?.value?.permissions : []));
      dispatch(setSelectedRoleId(!!selectedRole?.keys?.role_id ? selectedRole.keys.role_id : ''))
      setSelectedRole(roles?.find((it) => { return it?.keys?.role_id === selectedRole?.keys?.role_id }))
    }
  }, [selectedRole, roles])


  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const submitAddRoleName = (formData) => {
    console.log("submitAddRoleName: ", formData)
    dispatch(createRoleForEntity({
      ...formData,
      ...entityTypeAndID(selectedCompany)
    }))
    reset({ role: '' }, {})
    setTimeout(() => dispatch(getRoles(entityTypeAndID(selectedCompany))), [1500])
  }

  const handleSetNewPermissions = ({ allocatedIndex, actionIndex, type }) => {
    dispatch(setSelectedPermission({ allocatedIndex, actionIndex, type }))
  }

  return (
    <Box sx={{
      padding: "35px",

    }}>
      <Box display='flex' flexDirection='row' alignItems='center' pl={4} pr={8} pb={3} pt={1}>
        <Typography sx={{ color: "#053ad3", fontSize: "48px", pr: 8 }}>
          Permissions and Rules
        </Typography>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: 'column',
        flex: 1,
      }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="User Access" />
            <Tab label="Reports" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box sx={{ display: "flex", flex: 1, flexDirection: 'column' }}>
            {/* User roles */}
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              marginX: 1,
              mb: 2,
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}>
              <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center'>
                <Typography sx={{
                  my: 1,
                  mx: 4,
                  width: 'fit-content',
                  fontSize: "16px",
                  fontWeight: "bold",
                  textTransform: 'uppercase'
                }}>User Roles</Typography>
                <Box display='flex' flexDirection='row' mr={3} alignItems='center' >
                  <DropdownWithValue
                    options={roles}
                    onChange={setSelectedRole}
                    value={selectedRole}
                    label='Select Role'
                    sx={{ mx: 4, my: 1 }}
                  />
                  <Button
                    color='secondary' variant='contained'
                    sx={{ m: 1, height: 'fit-content' }}
                    onClick={() => { dispatch(updateRoleByID()) }}
                  >Save</Button>
                  <Button
                    color='secondary' variant='contained'
                    sx={{ m: 1, height: 'fit-content' }}
                    onClick={() => { dispatch(resetRoleByID(selectedRole)) }}
                  >Reset role</Button>
                </Box>
              </Box>
              <Box mx={3} sx={{ flex: 1, display: 'flex' }} justifyContent='center' flexDirection='column' >
                {!isDisplayRoleControl &&
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => setIsDisplayRoleControl(true)}
                    sx={{
                      color: "#eb0781",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}>
                    Add new user role
                  </Button>
                }
                {isDisplayRoleControl &&
                  <>
                    <Box sx={{ flex: 1 }}>
                      <Controller
                        name={'role'}
                        control={control}
                        rules={{ validate: { ...getRulesValidation('NAME_VALIDATION') } }}
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid, error },
                        }) => {
                          return (<TextField
                            fullWidth
                            value={value}
                            variant="filled"
                            label="New User Role"
                            placeholder="Add new user role"
                            onChange={onChange}
                            error={!!invalid}
                            helperText={error ? (error?.message ? error.message : getRulesValidationErrorMsg('NAME_VALIDATION', error?.type)) : null}
                          />)
                        }}
                      />
                    </Box>
                    <Box>
                      <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleSubmit(submitAddRoleName)}
                        disabled={!isValid}
                        sx={{
                          color: "#eb0781",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}>
                        Add
                      </Button>
                      <Button startIcon={<HighlightOffIcon />}
                        onClick={() => {
                          reset({ role: '' })
                          setIsDisplayRoleControl(false)
                        }}
                        sx={{
                          color: "#4cccdb",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}>
                        Cancel
                      </Button>
                    </Box>
                  </>
                }
              </Box>
            </Box>
            {/* Permissions Boxes */}
            <Box display='flex' flexWrap='wrap' flexDirection='row' pt={3} borderTop={1} borderColor='rgba(0, 0, 0, 0.12)'>
              <BoxWithPermissions key={'denyBox'} effect={'deny'} title={'Denied'} boxType={'deny'}
                permissions={rolePermissions}
                handleSetNewPermissions={handleSetNewPermissions}
              />
              <BoxWithPermissions key={'unallocatedBox'} effect={''} title={'Unallocated'} boxType={'unallocated'}
                permissions={rolePermissions}
                handleSetNewPermissions={handleSetNewPermissions}
              />
              <BoxWithPermissions key={'allowBox'} effect={'allow'} title={'Allowed'} boxType={'allow'}
                permissions={rolePermissions}
                handleSetNewPermissions={handleSetNewPermissions}
              />
            </Box>
          </Box>

        </TabPanel>
        <TabPanel value={value} index={1}>
          Reports
        </TabPanel>
      </Box >
    </Box >
  );
};

export default CompanyPermissions;