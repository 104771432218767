import {
  ADD_PROFILE_TO_PROFILES,
  SET_CHOOSE_PROFILE_MODAL_OPEN
} from '../actionTypes';

const INITIAL_STATE = {
  profiles: [],
  chooseProfileModalOpen: false
};


export const profilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case ADD_PROFILE_TO_PROFILES:
      const isDifferentAccount = !!state.profiles.filter(profile => profile?.auth?.user?.email !== action.payload?.auth?.user?.email).length;
      const profiles = isDifferentAccount ? [] : [...state.profiles];

      const existingItemIndex = profiles.findIndex(profile => profile?.auth?.user?.actor === action.payload?.auth?.user?.actor);
      if (existingItemIndex !== -1) {
        profiles[existingItemIndex] = action.payload
      } else {
        profiles.push(action.payload)
      }
      return {
        ...state, profiles

      }
    case SET_CHOOSE_PROFILE_MODAL_OPEN:
      return {
        ...state, chooseProfileModalOpen: action.payload
      }
    default:
      return state;
  }
};

