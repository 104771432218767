import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Typography
} from '@mui/material';
import _ from 'lodash';
import { getIC10CodesFromCache } from '../cache/ic10codes';
// import PropTypes from 'prop-types';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8;
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const renderRow = (props) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    // top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} style={inlineStyle}>
      {dataSet[1].title}
    </Typography>
  );
}
const useResetCache = (data) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}
// Adapter for react-window
const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getItemHeightByTitle).reduce((a, b) => a + b, 0);
  };

  const getItemHeightByTitle = (child) => {
    let titleLength = child[1].title.length;
    let titleRows = Math.ceil(titleLength / 64) ? Math.ceil(titleLength / 64) : 1

    return titleRows * itemSize;
  }


  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          // itemSize={(index) => getChildSize(itemData[index])}
          itemSize={(index) => getItemHeightByTitle(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const DisableTextField = ({ value = '', label = '', disabled = true }) => {
  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      fullWidth
      multiline
      variant="filled"
    />
  )
}

const MultipleIC10CodesDropdownControl = ({ label = '', value = '', onChange = () => { }, isDisabled = true, variant = 'outlined' }) => {

  let options = getIC10CodesFromCache()
  const [textFieldValue, setTextFieldValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [defaultValue, setDefaultValue] = useState([])
  useEffect(() => {
    if (value && value.length) {
      console.log("MultipleIC10CodesDropdownControl value - ", value)
      if (typeof value === 'string') {
        let splitItems = value.split(';')
        let selectedItems = _.filter(options, (item) => {
          return splitItems?.includes(item.value)
        })
        setDefaultValue(selectedItems)
        setTextFieldValue(_.map(selectedItems, 'title').join(';'))
      }
      else {
        setDefaultValue(value)
        setTextFieldValue(_.map(value, 'title').join(';'))
      }
    }
    else {
      setDefaultValue([])
      setTextFieldValue('')
    }
  }, [value])
  return (
    <>
      {isDisabled && <DisableTextField label={label} value={textFieldValue} disabled={isDisabled} />}
      {!isDisabled &&
        <Autocomplete
          multiple
          id="virtualize-ic10codes"
          // sx={{ width: 480 }}
          value={defaultValue}
          disableListWrap
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          options={options}
          renderInput={(params) => <TextField variant={variant} {...params} label={label} />}
          renderOption={(props, option) => [props, option]}
          getOptionLabel={(option) => option?.title ? option.title : ''}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          onChange={(event, selectValue) => {
            console.log("selectValue ", selectValue)
            if (selectValue) {
              // setSelectedItems(selectValue);
              let indexNotChosen = _.findIndex(selectValue, (it) => it.value === '')
              if (indexNotChosen !== -1) {
                onChange([])
              } else {
                onChange(selectValue)
              }
            }
          }}
        />
      }
    </>
  )
}

export default MultipleIC10CodesDropdownControl
