import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Tabs, Tab,
  Typography, InputAdornment, TextField,
  CircularProgress, Button, 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PageContainer, PageBodyContainer, DrawerContainer, IMSDrawer } from '../../shared';
import { Add } from "@mui/icons-material";
import DocumentsPreview from './components/DocumentsPreview'
import CaptureHeader from './components/CaptureHeader';
import FeesForm from './components/FeesForm';
import FeeDetails from './components/FeeDetails';
import { useForm } from "react-hook-form";
import { saveFees, getFees, setFees, setLoadingSaveFees, setLoadingSubmitFees, setLoadingSaveOneFee } from '../../redux/actions/fees.actions';
import InpatientTab from './components/InpatientTab';
import CatastropheTab from './components/CatastropheTab';
import { GOP_FEES_TABS } from '../../constants/viewConfig';
import ArrowBack from '@mui/icons-material/ArrowBack';



const createDefaultValuesFromFees = (fees) => {
  const defaultValues = {};

  fees.forEach((section, sectionIndex) => {
    defaultValues[section.k] = {};
    section?.items?.forEach?.((fee, feeIndex) => {
      defaultValues[section.k][fee.k] = {
        claimed_amt: fees[sectionIndex].items[feeIndex].claimed_amt === null ? '' : fees[sectionIndex].items[feeIndex].claimed_amt,
        payable_amt: fees[sectionIndex].items[feeIndex].payable_amt === null ? '' : fees[sectionIndex].items[feeIndex].payable_amt,
        paid_amt: fees[sectionIndex].items[feeIndex].paid_amt === null ? '' : fees[sectionIndex].items[feeIndex].paid_amt
      }
      if (fee.sub_items?.length > 0) {
        fee.sub_items.forEach((subitem) => {
          // defaultValues[section.k][fee.k][subitem.k] = subitem.type === 'DROPDOWN' ? null : '';
          if (subitem.type === 'DROPDOWN' &&
            (typeof fees[sectionIndex].items[feeIndex][subitem.k] === "string"
              || typeof fees[sectionIndex].items[feeIndex][subitem.k] === "number"
              || fees[sectionIndex].items[feeIndex][subitem.k])) {
            const dropdownValue = subitem?.items?.find?.(item => item.value === fees[sectionIndex].items[feeIndex][subitem.k])
            defaultValues[section.k][fee.k][subitem.k] = dropdownValue;
          } else
          if (subitem.type === 'DROPDOWN' && !fees[sectionIndex].items[feeIndex][subitem.k]) {
            defaultValues[section.k][fee.k][subitem.k] = null
          }   else {
            defaultValues[section.k][fee.k][subitem.k] = !fees[sectionIndex].items[feeIndex][subitem.k] ? '' : fees[sectionIndex].items[feeIndex][subitem.k]
          }
          // defaultValues[section.k][fee.k][subitem.k] = 
          //   subitem.type === 'DROPDOWN' && !fees[sectionIndex].items[feeIndex][subitem.k]
          //     ? null
          //     : !fees[sectionIndex].items[feeIndex][subitem.k] ? '' : fees[sectionIndex].items[feeIndex][subitem.k];
        })
      }
    })
  })
  return defaultValues;
}


const CaptureData = () => {
  const dispatch = useDispatch()
  // const { type } = useParams();
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate();
  const fees = useSelector(state => state.fees?.draftFees) || []
  // const loading = useSelector(state => state.fees?.loading) || {}
  const selectedGop = useSelector(state => state.gop.selectedGop);
  // const selectedGopDocs = useSelector(state => state.gop.selectedGopDocs);
  // const feesTitle = useSelector(state => state.fees?.title) || []
  const a_viewConfig = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig);
  const [selectedTab, setSelectedTab] = useState(a_viewConfig?.show?.includes?.(GOP_FEES_TABS.INPATIENT) ? 'inpatient' : 'catastrophe');
  const defaultValues = createDefaultValuesFromFees(fees);
  // const { control, handleSubmit, errors, reset, getValues } = useForm({ defaultValues });
  // const [isFeeDetailsOpen, setIsFeeDetailsOpen] = useState(false);
  // const [selectedFeeDetails, setSelectedFeeDetails] = useState({});
  // const [selectedFeeDetailsSectionKey, setSelectedFeeDetailsSectionKey] = useState('');


  useEffect(() => {
    // dispatch(setFees({ FEES: [], title: ''}))
    dispatch(setLoadingSaveFees(false))
    dispatch(setLoadingSubmitFees(false))
    dispatch(setLoadingSaveOneFee(false))
  }, [])


  useEffect(() => {
    console.log('selectedGop', selectedGop);
    if (selectedGop?.keys?.gop_id) {
      // dispatch(getFees(selectedGop?.keys?.gop_id));
    }
  }, [selectedGop?.keys?.gop_id])

  // useEffect(() => {
  //   reset(createDefaultValuesFromFees(fees))
  //   if (selectedFeeDetailsSectionKey && selectedFeeDetails?.k) {
  //     const newSelected = fees?.find?.(item => item.k === selectedFeeDetailsSectionKey)?.items?.find?.(item => item.k === selectedFeeDetails.k);
  //     // console.log('newSelected fee', newSelected)
  //     setSelectedFeeDetails(!!newSelected ? newSelected : {})
  //   }
  // }, [fees])

  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={user.owner_type === 'provider' ? 'Inpatient Management System' : 'Clinic Portal'}
        underline={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Box sx={{ padding: "35px" }}>
          {/*<Box display='flex' flexDirection='row' alignItems='center' pl={4} pr={8} pb={3} pt={1}>*/}
          {/*  <Typography sx={{ color: "#053ad3", fontSize: "48px", pr: 8, textTransform: 'capitalize' }}>*/}
          {/*    {type.replace('_', ' ')} Cases*/}
          {/*  </Typography>*/}
          {/*  <TextField*/}
          {/*    id="input-with-icon-textfield"*/}
          {/*    InputProps={{*/}
          {/*      startAdornment: (*/}
          {/*        <InputAdornment position="start">*/}
          {/*          <SearchIcon sx={{ color: "#eb0781" }} />*/}
          {/*        </InputAdornment>*/}
          {/*      ),*/}
          {/*    }}*/}
          {/*    variant="standard"*/}
          {/*  />*/}
          {/*</Box>*/}
          <Box display={'flex'} flexDirection='row' >
            <Button color='secondary' variant="text" startIcon={<ArrowBack />}
              sx={{
                fontWeight: "bold",
                fontSize: '14px',
                mr: 2
              }}
              onClick={() => { navigate(-1) }}
            >
              Back
            </Button>
            <Tabs value={selectedTab} onChange={(event, value) => {
              console.log('value', value);
              setSelectedTab(value)}
            }>
              { a_viewConfig?.show?.includes?.(GOP_FEES_TABS.INPATIENT) && 
                <Tab
                  sx={{ fontFamily: 'Globersemibold', letterSpacing: '1.5px', paddingLeft: "14px", paddingRight: "14px" }}
                  label="inpatient"
                  value='inpatient'
              />}
              { a_viewConfig?.show?.includes?.(GOP_FEES_TABS.CATASTROPHE) &&
                <Tab
                  sx={{ fontFamily: 'Globersemibold', letterSpacing: '1.5px', paddingLeft: "14px", paddingRight: "14px" }}
                  label="catastrophe"
                  value='catastrophe'
                />}
            </Tabs>
          </Box>
          {/* content */}
          {selectedTab === 'inpatient' && <InpatientTab />}
          {selectedTab === 'catastrophe' && <CatastropheTab />}

          {/* <CaptureHeader
            isFeeDetailsOpen={isFeeDetailsOpen}
            handleCancel={() => { reset(createDefaultValuesFromFees(fees)) }}
            handleSave={() => { dispatch(saveFees({ gop_id: selectedGop?.keys?.gop_id, finalize: '0', values: getValues(), navigate})) }}
            handleSubmit={() => { dispatch(saveFees({ gop_id: selectedGop?.keys?.gop_id, finalize: '1', values: getValues(), navigate})) }}
          />
          <Box display='flex' flexDirection='row'>
            <Box sx={{ display: "flex", flexDirection: 'column', minWidth: '520px' }}>
              <DocumentsPreview gop_id={selectedGop?.keys?.gop_id} gop_docs={selectedGopDocs} />
            </Box >
            {isFeeDetailsOpen && <FeeDetails selectedFeeDetails={selectedFeeDetails} selectedFeeDetailsSectionKey={selectedFeeDetailsSectionKey} setIsFeeDetailsOpen={setIsFeeDetailsOpen} viewConfig={selectedGop?.value?.a_viewConfig} />}
            {!isFeeDetailsOpen && (loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees) && <CircularProgress />}
            {!isFeeDetailsOpen && !(loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees) && <FeesForm control={control} setIsFeeDetailsOpen={setIsFeeDetailsOpen} setSelectedFeeDetails={setSelectedFeeDetails} setSelectedFeeDetailsSectionKey={setSelectedFeeDetailsSectionKey} />}
          </Box> */}
        </Box >
      </PageBodyContainer>
    </PageContainer>
  );
};

export default CaptureData;