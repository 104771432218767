import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Button,
  TableContainer, Table, TableHead,
  TableRow, TableCell, TableBody,
  Paper, Fab, Grid
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Add } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { CreateCompanyGroupModal } from '../../CompanyGroups/components';
import { useSelector, useDispatch } from 'react-redux';
import ClinicsCard from './ClinicsCard';
import { getContactsForGroup, setOpenModalEntityEdit, setOpenModalEntity, setOpenModalEntitySuccess, setOpenModalAddEditContact } from '../../../redux/actions/entities.actions';
import { entityTypeAndID } from '../../../utils/entityTypeAndID';
import { SuccessAddModal } from '../../../shared';
import EditEntityModal from './EditEntityModal'
import { ENTITY_BTN } from '../../../constants/viewConfig';
import { AddEditContactModal } from '.';

const TextItem = ({ title = '', value = '' }) => {
  return (
    <Box display='flex' alignItems='center' flexDirection='row' p={1} >
      <Box display='flex' alignItems='flex-start' flexDirection='column' >
        <Typography sx={{ fontFamily: 'Globersemibold', color: 'primary.main', letterSpacing: '1.5px', textTransform: 'uppercase', fontSize: 10 }}>{title}</Typography>
        <Typography sx={{ fontFamily: 'Globerregular', fontSize: 16, wordBreak: 'break-word' }}>{value}</Typography>
      </Box>
    </Box>
  )
}

const CompanyOverview = () => {
  const dispatch = useDispatch()
  // const [openModal, setOpenModal] = useState(false);
  const viewShowControl = useSelector(state => state.view?.mainViewConfig?.show);
  const selectedCompany = useSelector(state => state.entities.selectedCompany);
  const selectedCompanyContacts = useSelector(state => state.entities.selectedCompanyContacts);
  const openModalEntity = useSelector(state => state.entities.modals?.openModalEntity)
  const openModalEntitySuccess = useSelector(state => state.entities.modals?.openModalEntitySuccess)
  const openModalEntityEdit = useSelector(state => state.entities.modals?.openModalEntityEdit)
  const openModalAddEditContact = useSelector(state => state.entities.modals?.openModalAddEditContact)
  let [selectedContact, setSelectedContact] = useState(null);
  const {
    child_entities = [],
    value: {
      address = '',
      description = '',
      email = '',
      phone = ''
    } = {}
  } = selectedCompany || {}

  useEffect(() => {
    console.log('selected company', selectedCompany)
    if (selectedCompany?.keys?.table) {
      dispatch(getContactsForGroup(entityTypeAndID(selectedCompany)));
    }
  }, [selectedCompany])

  useEffect(() => {
    if (selectedContact && selectedCompanyContacts.length) {
      let contact = selectedCompanyContacts.find((it) => selectedContact?.keys?.contact_id === it.keys?.contact_id)
      setSelectedContact(contact)
    }
  }, [selectedCompanyContacts])

  const handleSelectContact = (contact) => {
    if (selectedContact?.keys?.contact_id === contact.keys?.contact_id) {
      setSelectedContact(null)
    }
    else {
      setSelectedContact(contact)
    }
  }

  const isSelectedContact = (contact) => {
    return selectedContact?.keys?.contact_id === contact.keys?.contact_id
  }

  return (
    <Box sx={{
      padding: "35px",

    }}>
      <Box display='flex' flexDirection='row' alignItems='center' >
        <Typography sx={{ color: "#053ad3", fontSize: "36px" }}>
          {selectedCompany?.value?.name}
        </Typography>
      </Box>

      {/* GROUP DETAILS */}
      <Box mt={2} mb={2}>
        {/* GROUP INFO */}
        <Box sx={{ width: 325 }}>
          <Grid container>
            <Grid item xs={12}>
              <TextItem title={'address'} value={address} />
            </Grid>
            <Grid item xs={4}>
              <TextItem title={'phone'} value={phone} />
            </Grid>
            <Grid item xs={8}>
              <TextItem title={'email'} value={email} />
            </Grid>
            <Grid item xs={12} >
              <Box display='flex' alignItems='center' flexDirection='row' p={1} >
                <Box display='flex' alignItems='flex-start' flexDirection='column' >
                  <Typography sx={{ fontFamily: 'Globersemibold', color: 'primary.main', letterSpacing: '1.5px', textTransform: 'uppercase', fontSize: 10 }}>short description</Typography>
                  <Typography sx={{
                    fontFamily: 'Globerregular', fontSize: 16, wordBreak: 'break-word',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical'
                  }}>{description}</Typography>
                </Box>
              </Box>
            </Grid>
            {viewShowControl?.includes(ENTITY_BTN.ENTITY_BTN_EDIT_ENTITY) &&
              <Grid item xs={12} mt={2}>
                <Button
                  startIcon={<EditIcon />}
                  onClick={() => dispatch(setOpenModalEntityEdit(true))}
                  variant="text"
                  color="secondary"
                  sx={{ textTransform: 'uppercase', fontWeight: 'bold' }} >Edit Information</Button>
              </Grid>
            }

          </Grid>
        </Box>
      </Box>

      <Paper
        sx={{
          display: "flex",
          flexDirection: 'column',
          flex: 1,
          pb: 2,
        }}
        elevation={3}
      >
        <Box sx={{
          display: "flex",
          flexDirection: 'row',
          flex: 1,
          justifyContent: "space-between",
          alignItems: 'center',
          p: 1
        }}>
          <Typography sx={{ color: 'primary.main', fontSize: '24px' }}>
            Contacts
          </Typography>
          <Box>
            {viewShowControl?.includes(ENTITY_BTN.ENTITY_BTN_ADD_CONTACT) &&
              <Button startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  setSelectedContact(null);
                  dispatch(setOpenModalAddEditContact(true))
                }}
                sx={{
                  color: "#eb0781",
                  fontWeight: "bold",
                  '&:hover': {
                    color: '#eb0781'
                  }
                }}>
                Add
              </Button>
            }
            {viewShowControl?.includes(ENTITY_BTN.ENTITY_BTN_EDIT_CONTACT) &&
              <Button startIcon={<EditIcon />}
                disabled={!selectedContact ? true : false}
                onClick={() => {
                  dispatch(setOpenModalAddEditContact(true))
                }}
                sx={{
                  color: "#eb0781",
                  fontWeight: "bold",
                  '&:hover': {
                    color: '#eb0781'
                  }
                }}>
                Edit
              </Button>
            }
          </Box>
        </Box>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#e0f6f9' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left">First Name</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left">Last Name</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left">Email</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left">Phone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedCompanyContacts?.map?.((row, index) => (
                <TableRow
                  selected={isSelectedContact(row)}
                  key={index.toString()}
                  classes={{
                    selected: 'rowSelected'
                  }}
                  sx={{
                    cursor: "pointer",
                    '&:last-child td, &:last-child th': { border: 0 },
                    '& td': { fontSize: "16px", },
                    '&.rowSelected td': {
                      color: "#eb0781",
                      fontWeight: "bold"
                    }
                  }}
                  onClick={() => { handleSelectContact(row) }}
                >
                  <TableCell align="left">{row.value?.firstName}</TableCell>
                  <TableCell align="left">{row.value?.lastName}</TableCell>
                  <TableCell align="left">{row.value?.email}</TableCell>
                  <TableCell align="left">{row.value?.phone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper >
      {child_entities?.length > 0 && <Box sx={{ color: 'primary.main', fontSize: '24px', pt: 4, pl: 8 }}>
        Entities
      </Box>}
      <Box pr={1} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {child_entities?.length > 0 && child_entities.map?.((entity, index) => <ClinicsCard entity={entity} key={index.toString()} />)}
      </Box>
      {selectedCompany?.value?.parent_type !== 'clinic' && viewShowControl?.includes(ENTITY_BTN.ENTITY_BTN_ADD_ENTITY) && <Fab
        sx={{ position: 'fixed', bottom: 42, right: 42, fontFamily: 'Globerbold' }}
        variant="extended"
        color={'secondary'}
        onClick={() => dispatch(setOpenModalEntity(true))}>
        <Add />
        ADD NEW ENTITY
      </Fab>}
      <CreateCompanyGroupModal open={openModalEntity} handleOpen={(boolean) => dispatch(setOpenModalEntity(boolean))} />
      <EditEntityModal open={openModalEntityEdit} handleOpen={(boolean) => dispatch(setOpenModalEntityEdit(boolean))} />
      <AddEditContactModal selectedContact={selectedContact} open={openModalAddEditContact} handleOpen={(boolean) => dispatch(setOpenModalAddEditContact(boolean))} />
      <SuccessAddModal
        open={openModalEntitySuccess}
        handleOpen={(boolean) => dispatch(setOpenModalEntitySuccess(boolean))}
        type={'ENTITY'}
        handleClickBtnSuccess={() => dispatch(setOpenModalEntity(true))} />
    </Box >
  );
};

export default CompanyOverview;