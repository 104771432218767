import React, { useEffect } from 'react';
import { Box, Paper, Typography, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setSelectedGroup } from '../../../redux/actions/entities.actions';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import greyBackgroundBanner from "../media/backgroundImages/greyBackgroundBanner.png";


const EntityItem = ({ item, entLevel = 0 }) => (
  <Box borderBottom={1} borderColor={'rgba(0, 0, 0, 0.25)'} p={1} pl={4 + entLevel} width='100%'>
    <Typography
      sx={{
        fontFamily: 'Globersemibold',
        fontSize: 24,
        color: 'secondary.main',
        userSelect: 'none',
        textAlign: 'left'
      }}>
      {item.value.name}
    </Typography>
  </Box>
)


const CompanyGroupCard = ({ group }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    child_entities_qty,
    child_entities = [],
  } = group || {}
  const {
    name = '',
    logoUrl = 'https://dev-swan-drive.s3.eu-central-1.amazonaws.com/CP_DEV_LOGO/c-care.png'
  } = group?.value || {};

  // useEffect(() => {
  //   console.log('groupCard' , group)
  // }, [group])

  return (
    <ButtonBase
      sx={{ padding: 2 }}
      onClick={() => {
        dispatch(setSelectedGroup(group));
        navigate('/companyDetails/overview')
      }}>
      <Paper
        elevation={5}
        sx={{
          marginTop: 3, marginBottom: 3, marginLeft: 5,
          width: 300, height: 360,
          borderRadius: 5,
          overflow: 'hidden'
        }}>
        <Box
          sx={{
            backgroundColor: 'primary.main',
            height: '90px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <Typography sx={{
            fontFamily: 'Globersemibold',
            fontSize: 24,
            color: 'white',
            userSelect: 'none',
            paddingRight: 3
          }}>
            {name}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between' pr={3}>
          <Paper elevation={2} sx={{ p: 1, ml: 3, mt: -6 }}>
            <img src={logoUrl} width={90} height={90} />
          </Paper>
          {!!child_entities_qty && <Typography>{child_entities_qty} Clinics</Typography>}
        </Box>
        {child_entities?.length > 0 && child_entities.map((item, index) => {
          return (
            <Box key={index.toString()}>
              <EntityItem item={item} />
              {item.child_entities?.length > 0 && item.child_entities.slice(0, 2).map((entity, index) =>
                <EntityItem key={entity.value?.name + index} item={entity} entLevel={2} />)}
            </Box>
          )
        })}
        {child_entities?.length <= 3 ?
          <></> :
          <Box display='flex' flexDirection='row' justifyContent='flex-end'
            sx={{ marginTop: '12px', marginRight: '20px' }}>
            <Typography
              color={'secondary'}
              sx={{
                fontWeight: 'bold', textTransform: 'uppercase',
                alignItems: 'center', marginRight: '15px'
              }}>
              View All
            </Typography>
            <ArrowForwardIcon color='secondary' />
          </Box>
        }
      </Paper>
    </ButtonBase>
  );
};

export default CompanyGroupCard;