import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button,
  Table, TableHead, TableRow,
  TableCell, TableBody, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, useTheme
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { DISALLOWED_ITEMS } from '../../../constants/viewConfig';
import { saveDisallowedItems } from '../../../redux/actions/fees.actions';

const VettingInstructionListItemsDialog = ({ openDialogListItems, handleCloseDialog, disallowedItems = [], setDisallowedItems, unsavedChangesExist = false }) => {
  const dispatch = useDispatch();
  const {
    show = [],
  } = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig) || {}
  const gop_id = useSelector(state => state.gop?.selectedGop?.keys?.gop_id) || ''
  const disallowedItemsLoading = useSelector(state => state.fees?.disallowedItemsLoading) || false
  const [selectedItem, setSelectedItem] = useState()
  const theme = useTheme();

  useEffect(() => {
    setSelectedItem(null)
  }, [openDialogListItems])

  return (
    <Dialog
      fullWidth={true}
      maxWidth='sm'
      open={openDialogListItems}
      onClose={handleCloseDialog}
    >
      <DialogTitle>
        <Typography color='primary' sx={{ fontSize: '20px', fontWeight: 'bold' }}>Claim Vetting Instructions list</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          color='secondary'
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: '0' }}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Item</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Remarks</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Section</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Disallowed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {disallowedItems.map((row, index) => (
              <TableRow
                onClick={() => {
                  if (selectedItem?.item_id === row?.item_id) {
                    setSelectedItem(null)
                  } else {
                    setSelectedItem(row)
                  }
                }}
                key={index}
                classes={{
                  root: row?.a_deleted ? 'strikeout' : ''
                }}
                sx={{
                  backgroundColor: selectedItem?.item_id === row?.item_id ? theme.palette.primary.light : '#FFF',
                  '& td': {
                    position: 'relative'
                  },
                  "&:hover": { backgroundColor: selectedItem?.item_id === row?.item_id ? theme.palette.primary.light : '#ddd' },
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: 'pointer',
                  '&.strikeout td:before': {
                    content: '" "',
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    borderBottom: '1px solid #111',
                    width: '100%'
                  }
                }}
              >
                <TableCell align="left">{row?.item?.title}</TableCell>
                <TableCell align="left">{row?.remark?.title}</TableCell>
                <TableCell align="left">{row?.section?.title}</TableCell>
                <TableCell align="left">{row?.disallowed}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        {show?.includes?.(DISALLOWED_ITEMS.SAVE_BTN) &&
          <Button
            variant='outlined'
            color='secondary'
            sx={{ fontWeight: 'bold' }}
            disabled={!unsavedChangesExist}
            onClick={() => {
              if (!disallowedItemsLoading) {
                dispatch(saveDisallowedItems({ gop_id, disallowed_items: disallowedItems }))
              }
            }}>Save</Button>
        }
        {(show?.includes?.(DISALLOWED_ITEMS.REMOVE_BTN) && selectedItem && selectedItem?.a_deleted) ?
          <Button
            variant='contained'
            color='secondary'
            disabled={!selectedItem}
            onClick={() => {
              setDisallowedItems(disallowedItems.map((item, index) => {
                if (item?.item_id === selectedItem?.item_id && item.a_deleted) {
                  item.a_deleted = false
                  item.a_unsavedChangesExist = false
                }
                return item
              }))
            }}>Undeleted</Button>
          :
          <Button
            variant='contained'
            color='secondary'
            disabled={!selectedItem}
            onClick={() => {
              setDisallowedItems(disallowedItems.map((item, index) => {
                if (item?.item_id === selectedItem?.item_id) {
                  item.a_deleted = true
                  item.a_unsavedChangesExist = true
                }
                return item
              }))
            }}>Remove</Button>
        }
      </DialogActions>
    </Dialog >
  )
};

export default VettingInstructionListItemsDialog;