import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Button, useTheme, Checkbox, ButtonBase,
  CircularProgress, MenuItem, Select, FormControl, InputLabel, Grid, ListItem, List, ListItemText, IconButton, ListItemSecondaryAction, TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PageContainer, PageBodyContainer, DrawerContainer, IMSDrawer, FileViewer } from '../../shared';
import { useForm } from "react-hook-form";
import {
  getDisallowedItems, saveDisallowedItems,
  setDisallowedItems as setDisItems,
  getExternalRemarksDisItems, saveExternalRemarkDisItems, setDisItemsRemarks, getPriceListItem, getPricelistActiveItemsDropdown
} from '../../redux/actions/fees.actions';
import { DISALLOWED_ITEMS, REMARKS, } from '../../constants/viewConfig';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { ControllerTextField, AutocompleteDropdown } from './components/FormComponents';
import { v4 as uuidv4 } from 'uuid';
import EastIcon from '@mui/icons-material/East';
import PackageDialog from './components/PackageDialog';
import RemarksDrawer from './components/RemarksDrawer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import VettingInstructionListItemsDialog from './components/VettingInstructionListItemsDialog';
import { getDownloadFileUrl } from '../../utils/upload';
import EditIcon from '@mui/icons-material/Edit';
import { addMessageToSnackbar } from '../../redux/actions/view.actions';

const DisallowedItems = () => {
  const dispatch = useDispatch()
  const theme = useTheme();
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate();
  const {
    disallowed_items = [],
    sections = [],
    externalRemarksSectionId = '',
  } = useSelector(state => state.fees?.disallowedItems) || {}
  const pricelistActiveItemsDropdown = useSelector(state => state.fees?.pricelistActiveItemsDropdown) || []
  const disallowedItemsLoading = useSelector(state => state.fees?.disallowedItemsLoading) || false
  const externalRemarksDisItems = useSelector(state => state.fees?.externalRemarksDisItems) || []
  const selectedGop = useSelector(state => state.gop.selectedGop);
  const gop_id = useSelector(state => state.gop?.selectedGop?.keys?.gop_id) || ''
  const selectedGopDocs = useSelector(state => state.gop.selectedGopDocs);
  const {
    show = [],
  } = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig) || {};
  const defaultValues = {
    item: '',
    remark: '',
    section: undefined,
    claimed: '',
    disallowed: '',
    refunded: '',
  };
  const { control, handleSubmit, formState: { errors }, reset, getValues, setValue, watch } = useForm(defaultValues);
  const [disallowedItems, setDisallowedItems] = useState(disallowed_items);
  const watchingSection = watch('section');
  const watchingItem = watch('item');
  const watchingClaimed = watch('claimed');
  const watchingDisallowed = watch('disallowed');
  const watchingRefunded = watch('refunded');
  const [calcRefunded, setCalcRefunded] = useState(false)
  const [calcDisallowed, setCalcDisallowed] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [packageData, setPackageData] = useState({})
  const [openRemarksDrawer, setOpenRemarksDrawer] = useState(false)
  const [openDialogListItems, setOpenDialogListItems] = useState(false)
  const [docs, setDocs] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [showFileViewer, setShowFileViewer] = useState(false)
  const [filePreviewLink, setFilePreviewLink] = useState('')
  const [isEditDisallowedItem, setIsEditDisallowedItem] = useState(false)
  const [selectedDisallowedItemForEdit, setSelectedDisallowedItemForEdit] = useState(null)
  const [unsavedChangesExist, setUnsavedChangesExist] = useState(false)

  console.log("disallowedItems - ", disallowedItems)

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleOpenDrawer = () => setOpenRemarksDrawer(true);
  const handleCloseDrawer = () => setOpenRemarksDrawer(false);

  const loadPriceListItem = async () => {
    try {
      let packageData = await getPriceListItem({ gop_id, item: watchingItem.value })
      if (packageData?.found) {
        setPackageData(packageData);
        handleOpen()
        if (packageData?.data?.price) {
          setCalcDisallowed(true)
          setCalcRefunded(false)
          setValue('refunded', parseFloat(packageData?.data?.price.replace(/,/g, "")))
        }

      }
    } catch (err) {

    }
  }
  const getAllDocsFromGopDocs = (gop_docs) => {
    const docsArr = [];
    gop_docs.forEach((sections) => sections?.files?.forEach?.((file) => docsArr.push(file)));
    return docsArr.filter((item) => item);
  }

  const handlerUpdateDisallowedItem = () => {
    let values = getValues();
    console.log("values : ", values)
    let copyItems = [...disallowedItems]
    let editetItemIndex = copyItems.findIndex((it) => it.item_id === selectedDisallowedItemForEdit.item_id)
    if (editetItemIndex >= 0) {
      copyItems[editetItemIndex] = {
        ...copyItems[editetItemIndex],
        remark: { value: values.remark, title: values.remark },
        section: values.section,
        claimed: parseInt(values.claimed),
        disallowed: parseInt(values.disallowed),
        refunded: parseInt(values.refunded),
        a_unsavedChangesExist: true
      }
      setDisallowedItems(copyItems)
    }
    else {
      dispatch(addMessageToSnackbar('Update not possible, item not found!'))
    }
    setSelectedDisallowedItemForEdit(null)
    setIsEditDisallowedItem(false)
    reset(defaultValues);
  }

  const openDownloadFileLink = async (file) => {
    let link = await getDownloadFileUrl(gop_id, file.s3_uuid)
    setFilePreviewLink(link)
  }
  useEffect(() => {
    dispatch(getPricelistActiveItemsDropdown())
  }, [])


  useEffect(() => {
    dispatch(setDisItems({
      disallowed_items: [],
      sections: [],
      externalRemarksSectionId: ''
    }));
    dispatch(setDisItemsRemarks([]))
  }, [])

  useEffect(() => {
    if (!!externalRemarksSectionId) {
      dispatch(getExternalRemarksDisItems({ remarks_section_id: externalRemarksSectionId }))
    }
  }, [externalRemarksSectionId])

  useEffect(() => {
    setDisallowedItems(disallowed_items);
  }, [disallowed_items])

  useEffect(() => {
    let findUnsavedItem = disallowedItems?.find((it) => it?.a_unsavedChangesExist)
    if (findUnsavedItem) {
      setUnsavedChangesExist(true)
    }
    else {
      setUnsavedChangesExist(false)
    }
  }, [disallowedItems])

  useEffect(() => {
    if (watchingClaimed === 0) {
      setValue('disallowed', 0)
      setValue('refunded', 0)
    }

    if (calcRefunded && watchingClaimed >= 0 && (watchingClaimed - watchingDisallowed) >= 0) {
      setValue('refunded', watchingClaimed - watchingDisallowed)
    }
    if (calcDisallowed && watchingClaimed >= 0 && (watchingClaimed - watchingRefunded) >= 0) {
      setValue('disallowed', watchingClaimed - watchingRefunded)
    }

  }, [calcRefunded, calcDisallowed, watchingClaimed, watchingDisallowed, watchingRefunded])

  useEffect(() => {
    // console.log('selectedGop', selectedGop);
    if (selectedGop?.keys?.gop_id) {
      dispatch(getDisallowedItems({ gop_id: selectedGop?.keys?.gop_id }));
    }
  }, [selectedGop?.keys?.gop_id])

  useEffect(() => {
    if (watchingItem && watchingItem.value) {
      handleClose()
      loadPriceListItem()
    }
    else {
      if (openModal) {
        handleClose()
      }
    }
  }, [watchingItem])

  useEffect(() => {
    setDocs(getAllDocsFromGopDocs(selectedGopDocs));
  }, [selectedGopDocs])

  useEffect(() => {
    if (!filePreviewLink) {
      setShowFileViewer(false)
    } else {
      setShowFileViewer(true)
    }
  }, [filePreviewLink]);

  useEffect(() => {
    setFilePreviewLink('');
    if (selectedFile) {
      openDownloadFileLink(selectedFile);
    }
  }, [selectedFile])

  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={user.owner_type === 'provider' ? 'Inpatient Management System' : 'Clinic Portal'}
        underline={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Box sx={{ padding: "24px" }}>
          <Box display={'flex'} flexDirection='row' justifyContent='space-between'>
            <Button color='secondary' variant="text" startIcon={<ArrowBack />}
              sx={{
                fontWeight: "bold",
                fontSize: '14px',
                mr: 2
              }}
              onClick={() => { navigate(-1) }}
            >
              Back
            </Button>
            <Button
              sx={{ fontWeight: 'bold', color: '#002f87' }}
              variant='text'
              startIcon={<ArrowBackIosIcon color='primary' />}
              onClick={handleOpenDrawer}>Remarks</Button>
          </Box>
          <Box sx={{ display: 'flex', my: 2 }}>
            <FormControl sx={{ maxWidth: 240, flex: 1 }}>
              <InputLabel htmlFor="grouped-select">Documents</InputLabel>
              <Select fullWidth value={selectedFile} onChange={(event) => { console.log('event', event); setSelectedFile(event.target.value) }} defaultValue="" id="grouped-select" label="Grouping">
                <MenuItem value={''}>
                  <em>None</em>
                </MenuItem>
                {docs?.map?.((file, index) => (<MenuItem key={index.toString()} value={file}>{file.filename}</MenuItem>))}
              </Select>
            </FormControl>
          </Box>
          {/* content */}
          <Box display='flex' flexDirection='row'>
            <Box sx={{ display: "flex", flexDirection: 'column', flex: 1, minWidth: '420px', position: 'relative' }}>
              {/* <DocumentsPreview gop_id={selectedGop?.keys?.gop_id} gop_docs={selectedGopDocs} /> */}
              {showFileViewer && <FileViewer hide={() => setSelectedFile('')} link={filePreviewLink}></FileViewer>}
              <PackageDialog rows={packageData?.layout} handleClose={handleClose} openModal={openModal} />
            </Box >
            <Box
              sx={{
                backgroundColor: '#fafafa',
                marginLeft: 1,
                flex: 1,
                maxWidth: '440px'
              }}>
              <Box flexDirection={'column'}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', m: 3 }}>Claim Vetting Instructions</Typography>
                {show?.includes?.(DISALLOWED_ITEMS.INPUTS) ? <Box wrap='wrap' px={2}>
                  {!isEditDisallowedItem &&
                    <AutocompleteDropdown
                      loading={false}
                      watchingField={watchingItem}
                      options={pricelistActiveItemsDropdown}
                      rules={{ required: true }}
                      control={control}
                      error={errors?.item?.type === 'required'}
                      disabled={isEditDisallowedItem}
                      itemKey={'item'}
                      label={'Item'}
                    />
                  }
                  {isEditDisallowedItem &&
                    <TextField
                      variant='outlined'
                      label='Item'
                      disabled={true}
                      value={selectedDisallowedItemForEdit?.item?.title}
                      fullWidth
                    />
                  }
                  <ControllerTextField
                    control={control}
                    rules={{ required: true }}
                    itemKey={'remark'}
                    error={errors?.remark?.type === 'required'}
                    isNum={false}
                    label={'Remarks'}
                    sx={{ mb: 2, mt: 2 }}
                    fullWidth
                    multiline
                    variant="outlined" />
                  <AutocompleteDropdown
                    loading={disallowedItemsLoading}
                    watchingField={watchingSection}
                    options={sections}
                    rules={{ required: true }}
                    control={control}
                    error={errors?.section?.type === 'required'}
                    itemKey={'section'}
                    label={'Section'}
                  />
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <ControllerTextField
                          control={control}
                          rules={{ required: true }}
                          itemKey={'claimed'}
                          error={errors?.claimed?.type === 'required'}
                          isNum={true}
                          label={'Claimed'}
                          sx={{ mt: 2 }}
                          fullWidth
                          variant="outlined" />
                      </Grid>
                      <Grid item xs={6}>
                        <ControllerTextField
                          control={control}
                          rules={{
                            required: true, validate: () => {
                              return !calcRefunded ? true : calcRefunded && (getValues("claimed") - getValues("disallowed")) >= 0;
                            }
                          }}
                          itemKey={'disallowed'}
                          error={errors?.disallowed?.type === 'required' || errors?.disallowed?.type === 'validate'}
                          isNum={true}
                          label={'Disallowed'}
                          sx={{ mt: 2 }}
                          fullWidth
                          variant="outlined" />
                      </Grid>
                    </Grid>
                    <ControllerTextField
                      control={control}
                      rules={{
                        required: true, validate: () => {
                          return !calcDisallowed ? true : calcDisallowed && (getValues("claimed") - getValues("refunded")) >= 0;
                        }
                      }}
                      itemKey={'refunded'}
                      error={errors?.refunded?.type === 'required' || errors?.refunded?.type === 'validate'}
                      isNum={true}
                      label={'Refunded'}
                      sx={{ mt: 2 }}
                      fullWidth
                      variant="outlined" />
                    <Box sx={{ mt: 2 }}>
                      <ButtonBase onClick={() => {
                        setCalcDisallowed(!calcDisallowed)
                        setCalcRefunded(!calcRefunded)
                      }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Typography>Calculate amount to be Disallowed</Typography>
                          <Checkbox color='secondary' checked={calcDisallowed} />
                        </Box>
                      </ButtonBase>
                      <ButtonBase onClick={() => {
                        setCalcDisallowed(!calcDisallowed)
                        setCalcRefunded(!calcRefunded)
                      }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <Typography>Calculate amount to be Refunded</Typography>
                          <Checkbox color='secondary' checked={calcRefunded} onChange={(e) => {
                            setCalcRefunded(e.target.value)
                            setCalcDisallowed(!e.target.value)
                          }} />
                        </Box>
                      </ButtonBase>
                    </Box>
                  </Box>
                  {!isEditDisallowedItem &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2 }} >
                      {show?.includes?.(DISALLOWED_ITEMS.ADD_BTN) ? <Button
                        variant='contained'
                        color='secondary'
                        fullWidth
                        sx={{ mr: 2, fontWeight: 'bold' }}
                        onClick={handleSubmit(() => {
                          const values = getValues();
                          setDisallowedItems([...disallowedItems, {
                            item_id: uuidv4(),
                            item: { ...values.item },
                            remark: { value: values.remark, title: values.remark },
                            section: values.section,
                            claimed: parseInt(values.claimed),
                            disallowed: parseInt(values.disallowed),
                            refunded: parseInt(values.refunded),
                            a_unsavedChangesExist: true
                          }])
                          reset(defaultValues);
                        })}>ADD</Button> : null}

                      {show?.includes?.(DISALLOWED_ITEMS.SAVE_BTN) ? <Button
                        variant='outlined'
                        fullWidth
                        color='secondary'
                        sx={{ fontWeight: 'bold' }}
                        disabled={disallowedItems?.length === 0 || !unsavedChangesExist}
                        onClick={() => {
                          if (!disallowedItemsLoading) {
                            dispatch(saveDisallowedItems({ gop_id, disallowed_items: disallowedItems }))
                          }
                        }}>Save {disallowedItemsLoading ? <CircularProgress color='secondary' size={16} sx={{ mt: 0.5, mb: 0.5, mx: 2, }} /> : ''}</Button> : null}

                    </Box>
                  }
                  {isEditDisallowedItem &&
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2 }} >
                      {show?.includes?.(DISALLOWED_ITEMS.ADD_BTN) ? <Button
                        variant='contained'
                        color='secondary'
                        fullWidth
                        sx={{ mr: 2, fontWeight: 'bold' }}
                        onClick={handleSubmit(handlerUpdateDisallowedItem)}>Update</Button> : null}

                      {show?.includes?.(DISALLOWED_ITEMS.SAVE_BTN) ? <Button
                        variant='outlined'
                        fullWidth
                        color='secondary'
                        sx={{ fontWeight: 'bold' }}
                        onClick={() => {
                          setIsEditDisallowedItem(false)
                          setSelectedDisallowedItemForEdit(null)
                          reset(defaultValues);
                        }}>Cancel </Button> : null}

                    </Box>
                  }
                </Box> : null}
                <Box px={2}>
                  <Button variant="text"
                    disableRipple={true}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      color: '#000'
                    }}
                    onClick={() => {
                      setOpenDialogListItems(true)
                      setIsEditDisallowedItem(false)
                      setSelectedDisallowedItemForEdit(null)
                      reset(defaultValues);
                    }}
                    fullWidth
                    endIcon={disallowedItemsLoading ? <CircularProgress color='secondary' size={16} sx={{ mt: 0.5, mb: 0.5, mx: 2, }} /> : <EastIcon color='secondary' />}>
                    Items
                  </Button>
                  <Box>
                    <List>
                      {disallowedItems.map((row, index) => (
                        <ListItem key={index.toString()} sx={{
                          textDecoration: row?.a_deleted ? 'line-through' : 'normal'
                        }}>
                          <ListItemText >{row?.item?.title}</ListItemText>
                          {
                            (!isEditDisallowedItem && !row?.a_deleted) ? <ListItemSecondaryAction sx={{ right: 0 }}>
                              <IconButton edge="edit" aria-label="edit"
                                onClick={() => {
                                  setIsEditDisallowedItem(true)
                                  setSelectedDisallowedItemForEdit(row)
                                  setValue('item', row.item)
                                  setValue('remark', row?.remark?.value)
                                  setValue('section', row?.section)
                                  setValue('claimed', row?.claimed)
                                  setValue('disallowed', row?.disallowed)
                                  setValue('refunded', row?.refunded)
                                }}
                              >
                                <EditIcon color='secondary' />
                              </IconButton>
                            </ListItemSecondaryAction>
                              : null
                          }
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* {!isFeeDetailsOpen && (loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees)
              && <CircularProgress />} */}
          </Box>
        </Box >
        <RemarksDrawer
          openRemarksDrawer={openRemarksDrawer}
          handleCloseDrawer={handleCloseDrawer}
          remarks_section_id={externalRemarksSectionId}
          allRemarks={externalRemarksDisItems}
          saveRemark={saveExternalRemarkDisItems}
          viewConstant={REMARKS.DISALLOWED_ITEMS_EXTERNAL}
        />
        <VettingInstructionListItemsDialog
          openDialogListItems={openDialogListItems}
          handleCloseDialog={() => { setOpenDialogListItems(false) }}
          disallowedItems={disallowedItems}
          setDisallowedItems={setDisallowedItems}
          unsavedChangesExist={unsavedChangesExist}
        />
      </PageBodyContainer>
    </PageContainer >
  );
};

export default DisallowedItems;