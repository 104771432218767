import React, { useEffect, useRef } from 'react';
import { Box, Paper, Typography, ButtonBase, Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ReactComponent as ClaimIcon } from '../../../media/icons/claimhandler.svg'
import { useDispatch } from 'react-redux';
import { assignToMeGop, getGopDocs, setSelectedGop } from '../../../redux/actions/gop.actions';
import { useNavigate } from 'react-router-dom';
import { VIEW_CONFIG } from '../../../constants';


const TextItem = ({ title, value, timeIcon = false, claimIcon = false }) => {
  return (
    !!value ? (<Box display='flex' alignItems='center' flexDirection='row' p={1} >
      {timeIcon && <AccessTimeIcon />}
      {claimIcon && <ClaimIcon />}
      <Box display='flex' alignItems='flex-start' flexDirection='column' pl={1}>
        <Typography sx={{ fontFamily: 'Globersemibold', color: 'primary.main', letterSpacing: '1.5px', textTransform: 'uppercase', fontSize: 10 }}>{title}</Typography>
        <Typography sx={{ fontFamily: 'Globerregular', fontSize: 14 }}>{value}</Typography>
      </Box>
    </Box>) : (<></>)
  )
}

const PolicyExpiredTitle = () => {
  return (
    <Box display='flex' alignItems='flex-start'  flexDirection='column' justifyContent='center' pl={1}>      
      <Typography sx={{ fontFamily: 'Globersemibold', fontSize: 14, color: '#d32f2f' }}>Policy Expired</Typography>
    </Box>
  )
}

const StatusItem = ({ color, status }) => {
  return (
    <Box sx={{ backgroundColor: color, borderRadius: '20px', height: 'fit-content', padding: '4px 12px 4px 12px', alignSelf: 'center' }}>
      <Typography sx={{ fontSize: 12, color: 'white', textTransform: 'uppercase' }}>{status}</Typography>
    </Box>
  )
}

const CaseCard = ({ selected = false, gop }) => {
  const dispatch = useDispatch();
  const gopRef = useRef();
  const navigate = useNavigate();

  const {
    keys: {
      gop_id = ''
    } = {},
    value: {
      patient: {
        firstName = '',
        lastName = '',

      } = {},
      caseDetails: {
        patientId = ''
      } = {},
      // a_clinic = {
      //   name: clinicName = ''
      // },
      assigned_to_clinic_claim_admin = {},
      assigned_to_ims_claim_handler = {},
      assigned_to_ims_pcl = {},
      assigned_to_ims_medical_officer = {},
      a_status_title = '',
      a_status_color = 'orange',
      a_created_at_ddmmyyyy = '',
      a_viewConfig: {
        show = [],
        // case_progress = []
      } = {}
    } = {}
  } = gop || {};

  useEffect(() => {
    if (gopRef && selected) {
      // console.log('gop id -', gop_id)
      gopRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected])

  return (
    <ButtonBase
      ref={gopRef}
      onClick={() => {
        const newSelectedGop = { ...gop };
        console.log('selectedGop', newSelectedGop)
        dispatch(setSelectedGop(newSelectedGop));
        dispatch(getGopDocs())
      }}>
      <Paper
        elevation={selected ? 20 : 1}
        sx={{
          backgroundColor: selected ? "#ddd" : "white",
          marginTop: 3, marginBottom: 3, marginLeft: 2,
          width: 400, borderRadius: '5px', overflow: 'hidden',
        }}>
        {/* gop id */}
        <Box display='flex' flexDirection='row' justifyContent='space-between' pt={2} pl={2} pr={4} pb={1}>
          <TextItem title={'GOP ID'} value={gop?.keys.gop_id} />
          {show?.includes(VIEW_CONFIG.GOP_TITLE.POLICY_EXPIRED) &&
            <PolicyExpiredTitle />
          }
        </Box>
        {/* first row */}
        <Box display='flex' flexDirection='row' justifyContent='space-between' pb1={2} pl={2} pr={4}>
          <TextItem title={'first name'} value={firstName} />
          <TextItem title={'last name'} value={lastName} />
          <TextItem title={'reference'} value={patientId} />
        </Box>
        {/* second row */}
        <Box display='flex' flexDirection='row' justifyContent='space-between' pl={2} pr={4} pb={1}>
          {/* <TextItem title={'clinic'} value={clinicName} /> */}
          <TextItem title={'claim admin'} value={assigned_to_clinic_claim_admin?.name} />
          <TextItem title={'added on'} value={a_created_at_ddmmyyyy} />
          <StatusItem status={a_status_title} color={a_status_color} />
        </Box>
        {/* third row */}
        <Box display='flex' flexDirection='row' justifyContent='space-between' pl={2} pr={4} pb={1}>
          <TextItem title={'Claim Handler'} value={assigned_to_ims_claim_handler?.name} />
          {show.includes(VIEW_CONFIG.GOP_BTN.ASSIGN_TO_ME) && <Button
            variant='contained'
            color='secondary'
            sx={{ m: 2 }}
            onClick={() => {
              dispatch(setSelectedGop(gop));
              dispatch(assignToMeGop({ navigate }))
            }}>
            assign to me
          </Button>}
        </Box>
      </Paper>
    </ButtonBase>
  );
};

export default CaseCard;