import React from "react";
import {
  Dialog,
  Box,
  Typography,
  // IconButton,
  Button
} from "@mui/material";
import { useDispatch } from "react-redux";

const successModalData = {
  "GROUP": {
    headerTitle: "New Company Group successfully added!",
    contentRows: [
      {
        text: "You have successfully added a group to Swan Inpatient Management System",
        fontWeight: 'bold'
      },
      {
        text: "Please add the entities to be  associated with newly created  group.",
        fontWeight: 'normal'
      }
    ],
    textBtnAdd: "Add new entity to group"
  },
  "ENTITY": {
    headerTitle: "New entity successfully added!",
    contentRows: [
      {
        text: "Proceed to add a new items to the group or add user to the newly created item.",
        fontWeight: 'bold'
      }
    ],
    textBtnAdd: "Add new entity"
  },
  "GOP": {
    headerTitle: "New Guarantee of Payment created and under process",
    contentRows: [
      {
        text: "We have well receive the Guarantee of payment request we will revert back.",
        fontWeight: 'bold'
      },
      {
        text: "Would you like to add another case.",
        fontWeight: 'bold'
      }
    ],
    textBtnAdd: "Add new case"
  }
}

const SuccessAddModal = ({ open, handleOpen, type, handleClickBtnSuccess }) => {
  const dispatch = useDispatch();
  // console.log("Type - ", type);

  const renderHeaderModal = () => {
    let data = successModalData[type];
    return (
      <Typography color='secondary' sx={{
        fontSize: 24,
        fontWeight: 'bold'
      }}>
        {data?.headerTitle}
      </Typography>
    )
  }

  return (
    <Dialog maxWidth={'xs'} fullWidth={true} open={open} onClose={() => handleOpen(false)}>
      <Box pl={4} pr={4} pt={5} pb={5}>
        <Box>
          {renderHeaderModal()}
        </Box>
        <Box pt={3} pb={3} display='flex' flexDirection='column'>
          {
            successModalData[type]?.contentRows?.map((item, index) => {
              return (
                <Typography key={index} sx={{
                  fontWeight: item.fontWeight,
                  fontSize: '14px'
                }}>
                  {item.text}
                </Typography>
              )
            })
          }
        </Box>
        <Box display='flex' justifyContent='space-around'>
          <Button color='secondary'
            variant="text" sx={{
              fontWeight: "bold",
              fontSize: '14px',
            }}
            onClick={() => handleOpen(false)}
          >
            Later
          </Button>
          <Button sx={{
            fontWeight: "bold",
            fontSize: '14px',
            color: '#fff',
          }}
            onClick={() => handleClickBtnSuccess()}
            color='info'
            variant="contained"
          >
            {successModalData[type].textBtnAdd}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
};

export default SuccessAddModal