import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Box, Button, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Typography } from "@mui/material";
import { forgotPasswordDoAction } from "../../../redux/actions/auth.actions";
import { useNavigate } from "react-router-dom";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const ForgotPasswordStep2 = ({ location, from, forgotPassState, setForgotPassState }) => {
  const action = 'INSERT_OTP';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [showPassword, setShowPassword] = useState(false);

  const handleChangePassword = (event) => {
    setForgotPassState({ ...forgotPassState, oldPassword: event.target.value })
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (<Box pt={5} sx={{
    width: "500px",
    display: "flex",
    flexDirection: 'column',
    margin: "0 auto",
    minHeight: "100px",
  }}>
    <Box sx={{ margin: "20px auto", display: "flex", flexDirection: 'row', }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 'bold'
        }}>Use the <span style={{ color: "#eb0781" }}>One time password (OTP)</span> sent to you by mail</Typography>
    </Box>
    <FormControl
      fullWidth
      variant="filled"
      sx={{
        m: "20px 0",
      }}
    >
      <InputLabel
        htmlFor="password"
        sx={{
          fontSize: "12px",
          color: "#00b7cd",
          fontWeight: "bold",
        }}
      >
        ONE TIME PASSWORD (OTP)
      </InputLabel>
      <FilledInput
        id="password"
        type={showPassword ? "text" : "password"}
        placeholder="Insert password"
        value={forgotPassState.oldPassword}
        onChange={handleChangePassword}
        sx={{
          color: "rgba(59, 64, 69, 0.87)",
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
    <Button
      variant="contained"
      sx={{
        height: "36px",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#00b7cd",
        borderRadius: 0,
        m: "20px 0",
        color: "#fff",
      }}
      disabled={forgotPassState.oldPassword.length === 0}
      onClick={() =>
        dispatch(forgotPasswordDoAction({ action, ...forgotPassState, navigate, from }))
      }
    >
      Go to reset password
    </Button>
  </Box>
  );
}

export default ForgotPasswordStep2;

