// import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HashRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { COLORS } from "./constants";
import {
  CasesPage, Clinics, CompanyDetails,
  CompanyGroups, L1L3CasesPage, LoginPage,
  NewGOP, OverviewGop, FirstLoginPage,
  ForgotPassword, CaptureDataPage as CaptureData,
  MasterFilePage, DisallowedItemsPage, PricelistPage
} from './pages';
import { ErrorSnackbar, QueryFrame } from './shared';
import { CompanyCases, CompanyOverview, CompanyPermissions, CompanyUsers, IMSPasswordManagement } from './pages/CompanyDetails/components';
import { Provider, useDispatch, useSelector } from 'react-redux';
import configureStore from './redux/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { logOut } from './redux/actions/auth.actions';
import { resetEntities } from './redux/actions/entities.actions';
import { resetDocuments } from './redux/actions/documents.actions';
import { resetGop } from './redux/actions/gop.actions';
import { resetViewState } from './redux/actions/view.actions';
import { connectToServer } from './utils/connectNats';
import { resetNotifications } from './redux/actions/notifications.action';
import { setIFrameOpen } from './shared/QueryFrame';
import ImsUsers from './pages/ImsOverview/ImsUsers';
import ProviderPermissions from './pages/ImsOverview/ProviderPermissions';
import { SnackbarProvider } from 'notistack';


const THEME = createTheme({
  palette: {
    primary: {
      main: COLORS.LIGHT_BLUE_COLOR,
      grey: COLORS.GRAY_COLOR,
      light: '#90caf9',
    },
    secondary: {
      main: COLORS.PINK_COLOR
    },
    success: {
      main: COLORS.GREEN_COLOR
    },
    info: {
      main: COLORS.LIGHT_BLUE_COLOR
    }
  },
  typography: {
    "fontFamily": `"Globerregular", "Globersemibold", "Globerbold", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: COLORS.PINK_COLOR,
          },
          '&.Mui-active': {
            color: COLORS.PINK_COLOR,
          },
        },
        text: {
          fill: 'white',
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          "&.MuiInputLabel-shrink": {
            color: COLORS.LIGHT_BLUE_COLOR,
          },
          "&.Mui-disabled": {
            color: COLORS.LIGHT_BLUE_COLOR,
            textTransform: 'uppercase'
          }
        },
        outlined: {
          "&.MuiInputLabel-shrink": {
            color: COLORS.LIGHT_BLUE_COLOR,
          },
          "&.Mui-disabled": {
            color: COLORS.LIGHT_BLUE_COLOR,
            textTransform: 'uppercase'
          }
        },
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: COLORS.GRAY_COLOR,
          backgroundColor: COLORS.TEXT_FIELD_BG_COLOR,
          fontFamily: 'Globersemibold',
          '&:before': {
            borderBottomStyle: 'solid',
            borderBottomWidth: '2px',
            borderBottomColor: '#00b7cd',
          },
          "&.Mui-disabled": {
            backgroundColor: 'white',
            '-webkit-text-fill-color': COLORS.GRAY_COLOR,
          },
        },
        underline: {
          '&.Mui-disabled': {
            '&:before': {
              borderBottomStyle: 'solid',
              borderBottomWidth: '2px',
              borderBottomColor: '#00b7cd',
            },
          }
        },
        input: {
          "&::-webkit-input-placeholder": { /* Chrome/Opera/Safari */
            color: COLORS.TEXT_FIELD_PLACEHOLDER_COLOR,
            opacity: 1
          },
          "&.Mui-disabled": {
            backgroundColor: 'white',
            '-webkit-text-fill-color': COLORS.GRAY_COLOR,
          },
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: COLORS.GRAY_COLOR,
          backgroundColor: COLORS.TEXT_FIELD_BG_COLOR,
          fontFamily: 'Globersemibold'
        },
        input: {
          "&::-webkit-input-placeholder": { /* Chrome/Opera/Safari */
            color: COLORS.GREEN_COLOR,
            opacity: 1
          }
        }
      }
    }

  }

});

const { store, persistor } = configureStore();

function App() {

  const PrivateRoute = ({ children }) => {
    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const projectVersion = useSelector(state => state.auth.projectVersion);
    const natsConfig = useSelector(state => state.auth.natsConfiguration);

    const clearStorage = async () => {
      if (projectVersion !== "0.0.25") {
        await persistor.purge();
        setIFrameOpen(false);
        dispatch(logOut());
        dispatch(resetEntities());
        dispatch(resetDocuments());
        dispatch(resetGop());
        dispatch(resetViewState());
        dispatch(resetNotifications());
        window.location.reload();
      }
    }

    useEffect(() => {
      console.log("Call nats connected")
      connectToServer(natsConfig)
    }, [natsConfig])

    useEffect(() => {
      clearStorage();
    }, [projectVersion])

    return token ? children : <Navigate to="/auth/login" replace={true} />;
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <div className='App'>
        <ThemeProvider theme={THEME}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router>
                <Routes>
                  <Route exact path='/' element={<PrivateRoute> <CompanyGroups /> </PrivateRoute>} />
                  <Route exact path='/clinics' element={<PrivateRoute> <Clinics /> </PrivateRoute>} />
                  <Route exact path='/companyGroups' element={<PrivateRoute> <CompanyGroups /> </PrivateRoute>} />
                  <Route exact path='/lcases/:type' element={<PrivateRoute> <L1L3CasesPage /> </PrivateRoute>} />
                  <Route exact path='/cases/overview' element={<PrivateRoute> <OverviewGop /> </PrivateRoute>} />
                  <Route exact path='/cases/:type' element={<PrivateRoute> <CasesPage /> </PrivateRoute>} />
                  <Route exact path='/cases/captureData' element={<PrivateRoute> <CaptureData /> </PrivateRoute>} />
                  <Route exact path='/cases/disallowedItems' element={<PrivateRoute> <DisallowedItemsPage /> </PrivateRoute>} />
                  <Route exact path='/cases/validateBill' element={<PrivateRoute> <CaptureData /> </PrivateRoute>} />
                  <Route exact path='/cases/viewBill' element={<PrivateRoute> <CaptureData /> </PrivateRoute>} />
                  <Route exact path='/cases/newgop' element={<PrivateRoute> <NewGOP /> </PrivateRoute>} />
                  <Route exact path='/companyDetails' element={<PrivateRoute> <CompanyDetails /> </PrivateRoute>}>
                    <Route path='users' element={<PrivateRoute> <CompanyUsers /> </PrivateRoute>} />
                    <Route path='permissions' element={<PrivateRoute> <CompanyPermissions /> </PrivateRoute>} />
                    <Route path='overview' element={<PrivateRoute> <CompanyOverview /> </PrivateRoute>} />
                    <Route path='cases' element={<PrivateRoute> <CompanyCases /> </PrivateRoute>} />
                    <Route path='password-management' element={<PrivateRoute> <IMSPasswordManagement /> </PrivateRoute>} />
                  </Route>
                  <Route exact path='/auth/login' element={<LoginPage />} />
                  <Route exact path='/auth/change-password' element={<FirstLoginPage />} />
                  <Route exact path='/auth/forgot-password' element={<ForgotPassword />} />
                  <Route exact path='/master-file' element={<PrivateRoute> <MasterFilePage /> </PrivateRoute>} />
                  <Route exact path='/pricelist' element={<PrivateRoute> <PricelistPage /> </PrivateRoute>} />
                  <Route exact path='/ims/users' element={<PrivateRoute> <ImsUsers /> </PrivateRoute>} />
                  <Route exact path='/ims/permissions' element={<PrivateRoute> <ProviderPermissions /> </PrivateRoute>} />
                  {/* <Route element={NotFound} /> */}
                </Routes>
              </Router>
              <ErrorSnackbar />
              <QueryFrame />
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </div>
    </SnackbarProvider>
  );
}

export { store, persistor };
export default App;
