import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { countGopDocs, setSelectedGop, resetSelectedGop, saveEditGop } from '../../../../redux/actions/gop.actions';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
// import ArrowBack from '@mui/icons-material/ArrowBack';
import { GOP_BTN, GOP_TITLE } from '../../../constants/viewConfig';
import { QueryButton } from '../../../shared';




const CaptureHeader = ({ handleSubmit, handleSave, handleCancel, isFeeDetailsOpen, type }) => {
  const loading = useSelector(state => state.fees?.loading) || {}
  const {
    value: {
      a_viewConfig: {
        show = [],
        editable = []
      } = {}
    } = {}
  } = useSelector(state => state.gop.selectedGop);

  const SubmitButtons = () => {
    return (<Box>
      {((type === 'catastrophe' && show?.includes?.(GOP_BTN.SAVE_CAT_BILL_AS_COMPLETED))
        || (type === 'inpatient' && show?.includes?.(GOP_BTN.SAVE_INP_BILL_AS_COMPLETED))) &&
      <Button color='secondary' variant="text"
              sx={{
                fontWeight: "bold",
                fontSize: '14px',
                mr: 2
              }}
              onClick={() => {
                if (!(loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees)) {
                  handleSubmit()
                }
              }}
      >
        Save as Processed
      </Button>}
  
       { ((type === 'catastrophe' && show?.includes?.(GOP_BTN.SAVE_AS_DONE_CAT_BILL))
         || (type === 'inpatient' && show?.includes?.(GOP_BTN.SAVE_AS_DONE_INP_BILL))) &&
       // {show?.includes?.(GOP_BTN.SAVE_AS_DONE_BILL) && !isFeeDetailsOpen &&
      <Button color='secondary' variant="text"
              sx={{
                fontWeight: "bold",
                fontSize: '14px',
                mr: 2
              }}
              onClick={() => {
                if (!(loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees)) {
                  handleSubmit()
                }
              }}
      >
        Save as Done
      </Button>}
  
      {/*{show?.includes?.(GOP_BTN.SAVE_AND_SEND_ADJUDICATION_BILL) && !isFeeDetailsOpen &&*/}
      {((type === 'catastrophe' && show?.includes?.(GOP_BTN.SAVE_AND_SEND_ADJUDICATION_CAT_BILL))
        || (type === 'inpatient' && show?.includes?.(GOP_BTN.SAVE_AND_SEND_ADJUDICATION_INP_BILL))) &&
      <Button color='secondary' variant="text"
              sx={{
                fontWeight: "bold",
                fontSize: '14px',
                mr: 2
              }}
              onClick={() => {
                if (!(loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees)) {
                  handleSubmit()
                }
              }}
      >
        Save and send adjudication
      </Button>}
    </Box>)
  }

  return (
    <Box>
      {/* header */}
      <Box display='flex' flexDirection='row' justifyContent='space-between' p={2}>
        <Box>
        </Box>
        <Box display='flex' flexDirection='row'>
          {show?.includes?.(GOP_BTN.QUERY_ACTION_LIST) && <QueryButton />}
          <SubmitButtons />
          {((type === 'catastrophe' && show?.includes?.(GOP_BTN.SAVE_CAT_BILL))
          || (type === 'inpatient' && show?.includes?.(GOP_BTN.SAVE_INP_BILL))) &&
          <Button color='secondary' variant="text" startIcon={<SaveIcon/>}
                  sx={{
                    fontWeight: "bold",
                    fontSize: '14px',
                    mr: 2
                  }}
                  onClick={() => {
                    if (!(loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees)) {
                      handleSave()
                    }
                  }}
          >
            Save
          </Button>}
          {((type === 'catastrophe' && show?.includes?.(GOP_BTN.CANCEL_CAT_BILL))
          || (type === 'inpatient' && show?.includes?.(GOP_BTN.CANCEL_INP_BILL))) &&
          <Button color='secondary' variant="text" startIcon={<CancelIcon/>}
                  sx={{
                    fontWeight: "bold",
                    fontSize: '14px',
                  }}
                  onClick={() => {
                    if (!(loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees)) {
                      handleCancel();
                    }
                  }}
          >
            Cancel
          </Button>}

          {show?.includes?.(GOP_TITLE.CLAIM_BILL_TO_BE_VALIDATED) &&
            <Typography mt={'6px'} color='secondary' fontFamily={'Globersemibold'}>CLAIM BILL TO BE VALIDATED</Typography>}


        </Box>
      </Box>
    </Box>
  );
};

export default CaptureHeader;