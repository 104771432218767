import React, { useEffect, useState } from 'react';
import {
  Box, Paper, Typography,
  Tabs, Tab, Button, Menu,
  MenuItem, CircularProgress,
} from '@mui/material';
import PolicyDetailsTab from './PolicyDetailsTab'
import PatientDetailsTab from './PatientDetailsTab'
import CaseDetailsTab from './CaseDetailsTab'
import DocumentsDetailsTab from './DocumentsDetailsTab'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { countGopDocs, getOneGop, resetSelectedGop, saveEditGop, getGopDocs } from '../../../../redux/actions/gop.actions';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { DISALLOWED_ITEMS, GOP_BTN, GOP_TITLE } from '../../../../constants/viewConfig';
import commandHandler from "../../../../utils/commandHandler";
// import { publishSubject } from "../../../../utils/connectNats";
import { QueryButton } from '../../../../shared';


const GopDetails = ({ type }) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0)
  const selectedGop = useSelector(state => state.gop?.selectedGop)
  const gop_id = useSelector(state => state.gop?.selectedGop?.keys?.gop_id)
  const gop_docs = useSelector(state => state.gop?.selectedGopDocs)
  const gopsLoading = useSelector(state => state.gop?.gopsLoading) || [];
  const gopDocsLength = useSelector(state => state.gop?.gopDocsLength)
  const a_caseDetailsUnsavedChangesExist = useSelector(state => state.gop?.selectedGop?.value?.caseDetails?.additionalInformation?.a_caseDetailsUnsavedChangesExist)
  const navigate = useNavigate();
  const [gopLoading, setGopLoading] = useState(false);


  useEffect(() => {
    dispatch(countGopDocs())
  }, [gop_docs])

  useEffect(() => {
    if (gopsLoading.findIndex(item => item.gop_id === selectedGop.keys.gop_id) !== -1) {
      setGopLoading(true);
    } else {
      setGopLoading(false);
    }
  }, [gopsLoading, selectedGop.keys?.gop_id])

  useEffect(() => {
    dispatch(getGopDocs())
  }, [selectedGop.keys?.gop_id])

  return (
    <Paper
      elevation={2}
      sx={{
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 3,
        borderRadius: '5px',
      }}>
      {/* header */}
      {(selectedGop?.value?.a_viewConfig?.show?.includes?.(GOP_TITLE.GUARANTEED_AMOUNT) || selectedGop?.value?.a_viewConfig?.show?.includes?.(GOP_BTN.QUERY_ACTION_LIST)) 
        && <Box display='flex' flexDirection='row' justifyContent='space-between' p={2}>
          <Box>
            {selectedGop?.value?.a_viewConfig?.show?.includes?.(GOP_TITLE.GUARANTEED_AMOUNT)
            && <Typography mt={'6px'} fontFamily={'Globersemibold'} color='primary.main'>GUARANTEED AMOUNT: {selectedGop?.value?.caseDetails?.additionalInformation?.estimateAmountFormatted}</Typography>}
          </Box>
          {selectedGop?.value?.a_viewConfig?.show?.includes?.(GOP_BTN.QUERY_ACTION_LIST) && <QueryButton />}
      </Box>}
      <Box display='flex' flexDirection='row' justifyContent='space-between' p={2}>
        {!gopLoading
          ? <Button
            onClick={() => dispatch(getOneGop())}
            color='secondary'
            variant='text'
            sx={{ fontWeight: 'bold', fontSize: '14px', mr: 2 }}
          ><RefreshRoundedIcon /></Button>
          : <CircularProgress color='secondary' sx={{ pt: 2, pb: 0.5, px: 2, mr: 2 }} size={16} />}
        <Box display='flex' flexDirection='row'>
          {selectedGop?.value?.a_viewConfig?.show?.includes?.(GOP_BTN.OPEN_FEES) &&
            selectedGop?.value?.a_viewConfig?.buttons?.[GOP_BTN.OPEN_FEES]?.CMDS && <Button color='secondary' variant="text"
              sx={{ fontWeight: "bold", fontSize: '14px', mr: 2 }}
              onClick={() => { commandHandler({ dispatch, navigate, commands: selectedGop?.value?.a_viewConfig?.buttons?.[GOP_BTN.OPEN_FEES]?.CMDS }) }}
            >
              {selectedGop?.value?.a_viewConfig?.buttons?.[GOP_BTN.OPEN_FEES]?.title}
            </Button>}
          {selectedGop?.value?.a_viewConfig?.show?.includes?.(DISALLOWED_ITEMS.DIS_ITEMS_BTN) &&
            selectedGop?.value?.a_viewConfig?.buttons?.[DISALLOWED_ITEMS.DIS_ITEMS_BTN]?.CMDS && <Button color='secondary' variant="text"
              sx={{ fontWeight: "bold", fontSize: '14px', mr: 2 }}
              onClick={() => { commandHandler({ dispatch, navigate, commands: selectedGop?.value?.a_viewConfig?.buttons?.[DISALLOWED_ITEMS.DIS_ITEMS_BTN]?.CMDS }) }}
            >
              {selectedGop?.value?.a_viewConfig?.buttons?.[DISALLOWED_ITEMS.DIS_ITEMS_BTN]?.title}
            </Button>}
          {selectedGop?.value?.a_viewConfig?.show?.includes?.(GOP_BTN.SAVE_CASE_DETAILS) && a_caseDetailsUnsavedChangesExist &&
            <Button color='secondary' variant="text" startIcon={<SaveIcon />}
              sx={{ fontWeight: "bold", fontSize: '14px', mr: 2 }}
              onClick={() => { dispatch(saveEditGop()) }}
            >
              Save
            </Button>}
          {selectedGop?.value?.a_viewConfig?.show?.includes?.(GOP_BTN.CANCEL_CASE_DETAILS) && a_caseDetailsUnsavedChangesExist &&
            <Button color='secondary' variant="text" startIcon={<CancelIcon />}
              sx={{
                fontWeight: "bold",
                fontSize: '14px',
              }}
              onClick={() => { dispatch(resetSelectedGop(gop_id)) }}
            >
              Cancel
            </Button>}

        </Box>
        <Box pl={2} display='flex' flexDirection='row'>
          <AttachFileIcon color='secondary' />
          <Typography sx={{ pl: 1, fontFamily: 'Globerbold', letterSpacing: '1.5px', textTransform: 'uppercase' }} >{gopDocsLength} Documents</Typography>
        </Box>
      </Box>
      {/* tabs */}
      <Tabs value={selectedTab} onChange={(event, value) => setSelectedTab(value)}>
        <Tab sx={{ fontFamily: 'Globersemibold', letterSpacing: '1.5px', paddingLeft: "14px", paddingRight: "14px" }} label="patient details" />
        <Tab sx={{ fontFamily: 'Globersemibold', letterSpacing: '1.5px', paddingLeft: "14px", paddingRight: "14px" }} label="policy details" />
        <Tab sx={{ fontFamily: 'Globersemibold', letterSpacing: '1.5px', paddingLeft: "14px", paddingRight: "14px" }} label="documents" />
        <Tab sx={{ fontFamily: 'Globersemibold', letterSpacing: '1.5px', paddingLeft: "14px", paddingRight: "14px" }} label="case details" />
      </Tabs>
      {/* content */}
      {selectedTab === 0 && <PatientDetailsTab />}
      {selectedTab === 1 && <PolicyDetailsTab />}
      {selectedTab === 2 && <DocumentsDetailsTab display={'VIEW'} gop_id={gop_id} gop_docs={gop_docs} />}
      {selectedTab === 3 && <CaseDetailsTab type={type} />}

    </Paper>
  );
};

export default GopDetails;