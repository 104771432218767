const IC10_CODES = require("../cache/icd10codes-full.json");

/**
 *
 * @return {{title: string value: string},[]}
 */
const getIC10CodesFromCache = () => {
  return (IC10_CODES.map(item => {
    return {
      value: item.C,
      title: `${item.C} ${item.D}`
    }
  }))
}

module.exports = {
  getIC10CodesFromCache
}
