import React, { useEffect, useState } from 'react';
import {
  Box, TextField
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import moment from 'moment';


const TimePickerComponent = ({ label = '', value = '', valueOnChange = () => { }, isDisabled = false, ...restField }) => {
  const [timeValue, setValue] = useState(new Date());
  const onChange = (date) => {
    let newValue = moment(date).format('HH:mm');
    valueOnChange(newValue)
    setValue(date)
  }
  useEffect(() => {
    if (value) {
      setValue(moment(value, 'HH:mm'))
    }
    else {
      setValue(moment())
      valueOnChange(moment().format('HH:mm'))
    }
  }, [value])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileTimePicker
        ampm={false}
        openTo="hours"
        views={['hours', 'minutes']}
        inputFormat="HH:mm"
        mask="__:__"
        label={label}
        value={timeValue}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} variant='filled' fullWidth {...restField} />}
        disabled={isDisabled}
      />
    </LocalizationProvider>
  )
}

export default TimePickerComponent