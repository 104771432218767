import React, { useEffect } from "react";
import {
  Dialog,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { BILL_TYPE, BILL_TYPE_TITLE } from "../../constants/viewConfig";
import { BillRemarks } from "./components";
import DocumentsDetailsTab from '../../pages/Cases/components/GopDetails/DocumentsDetailsTab'
import {
  createDraftBill,
  updateBill,
  getBillDocs,
  getOneGop,
  setOpenModalConfirmUpdateBill
} from "../../redux/actions/gop.actions";
import ConfirmModal from "../ConfirmModal";

const AddAnotherBillModal = ({ open, handleOpen, handleOnClickSave }) => {
  const dispatch = useDispatch();
  const [billData, setBillData] = useState({ type: '', amount: '' });
  const selectedGop = useSelector(state => state.gop.selectedGop);
  const gop_id = useSelector(state => state.gop.selectedGop?.keys?.gop_id);
  const editable = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.editable) || [];
  const show = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.show) || [];
  const draftBill = useSelector(state => state.gop.draftBill) || {};
  const draftBillLoading = useSelector(state => state.gop.draftBillLoading) || false;
  // const gop_docs = useSelector(state => state.gop?.selectedGopDocs)
  const draftBillDocs = useSelector(state => state.gop?.draftBillDocs) || [];
  const openModalConfirmUpdateBill = useSelector(state => state.gop.modals.openModalConfirmUpdateBill) || false;
  const modalConfirmUpdateBillData = useSelector(state => state.gop.modals.modalConfirmUpdateBill);


  useEffect(() => {
    if (!!selectedGop?.value?.a_viewConfig?.dropdowns?.BILL_TYPE?.defaultValue) {
      setBillData({ ...billData, type: selectedGop?.value?.a_viewConfig?.dropdowns?.BILL_TYPE?.defaultValue, amount: billData?.amount || '' })
    } else {
      setBillData({ ...billData, type: '', amount: billData?.amount || '' })
    }
  }, [selectedGop, open])


  useEffect(() => {
    if (draftBill?.keys?.bill_id) {
      console.log('draftBill?.keys?.bill_id', draftBill?.keys?.bill_id)
      dispatch(getBillDocs({ gop_id, bill_id: draftBill?.keys?.bill_id}))
    }
  }, [draftBill?.keys?.bill_id])

  // useEffect(() => {
  //   // console.log('draftBill', draftBill?.value?.remarks_section_id)
  // }, [draftBill])

  useEffect(() => {
      console.log('billData', billData)

  }, [billData])

  const handleCloseModal = () => {
    handleOpen(false);
  }

  const onCloseUpdateBillConfirmModal = () => {
    dispatch(setOpenModalConfirmUpdateBill(false))
  };

  const onConfirmUpdateBillModal = () => {
    dispatch(updateBill({...billData, params: modalConfirmUpdateBillData?.params}))
    dispatch(setOpenModalConfirmUpdateBill(false))
  }

  return (
    <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleCloseModal}>
      
        {!!draftBillLoading
          ? <Box sx={{ p: 3, display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></Box> 
          : <Box pl={3} pr={3} pt={3} pb={3}>
          <Box display='flex' flexDirection='row' pb={2} justifyContent='space-between' alignItems='center'>
            <Typography sx={{ color: 'secondary.main', fontSize: 20, fontFamily: 'Globersemibold', textTransform: 'uppercase' }}>
              Add Another Bill
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon color='secondary' />
            </IconButton>
          </Box>
          <Box pb={3} >
            <Grid container>
              <Grid item xs={12} sm={6} mr={6}>
                <FormControl variant="filled" sx={{ minWidth: 220 }}>
                  <InputLabel id="demo-simple-select-filled-label">Bill Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={billData.type}
                    onChange={(e) => { setBillData({ ...billData, type: e.target.value }) }}
                  >
                    {selectedGop?.value?.a_viewConfig?.dropdowns?.BILL_TYPE?.items?.map?.((item, index) => 
                      <MenuItem key={index.toString()} value={item?.value}>{item?.title}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} >
                <TextField
                  label="Value of bill"
                  fullWidth
                  variant="filled"
                  value={billData.amount}
                  onChange={(e) => { setBillData({ ...billData, amount: e.target.value }) }}
                />
              </Grid>
              {!!draftBill?.value?.remarks_section_id && <Grid item xs={12} sm={12} >
                <BillRemarks bill={draftBill} editable={editable} show={show} />
              </Grid>}
            </Grid>
          </Box>
          <DocumentsDetailsTab display={'VIEW'} gop_id={gop_id} gop_docs={draftBillDocs} bill_id={draftBill?.keys?.bill_id} />
          <Box display='flex' justifyContent='flex-end'>
            <Button variant="contained" color='secondary'
              startIcon={<CancelIcon />}
              onClick={handleCloseModal}
              sx={{
                fontWeight: 'bold',
                marginRight: '25px'
              }}>
              Cancel
            </Button>
            <Button variant="contained" color='secondary'
              startIcon={<SaveIcon />}
              disabled={!billData.type || !billData.amount}
              onClick={() => {
                dispatch(updateBill(billData))
                }}
              sx={{
                fontWeight: 'bold'
              }}>
              Save
            </Button>
          </Box>
        </Box>
        }
      <ConfirmModal
          open={openModalConfirmUpdateBill}
          handleClickBtnCancel={onCloseUpdateBillConfirmModal}
          handleClickBtnConfirm={onConfirmUpdateBillModal}
          type={'CONFIRM_BILL_UPDATE'}
          actionTitle={'Save bill'}
          confirmation_msg={modalConfirmUpdateBillData?.msg}
      />
    </Dialog >
  )
};

export default AddAnotherBillModal