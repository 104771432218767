import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Dialog,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DropdownWithValue } from "../../../shared";
import { useSelector, useDispatch } from "react-redux";
import { getRulesValidation, getRulesValidationErrorMsg } from "../../../utils/onChangeFunction";
import _ from "lodash";
import { createProviderUser, getProviderRoles, updateProviderUser } from "../../../redux/actions/auth.actions";
import { Controller, useForm } from "react-hook-form";

const ControllerField = ({ control, nameControl, validationKey, ...rest }) => {
  return (
    <Controller
      name={nameControl}
      control={control}
      defaultValue={''}
      rules={{ validate: { ...getRulesValidation(validationKey) } }}
      render={({
        field: { onChange, ...restField },
        fieldState: { invalid, error },
      }) => {
        return (<TextField
          sx={{ minWidth: 240, width: "100%" }}
          variant="filled"
          onChange={onChange}
          error={!!invalid}
          helperText={error ? (error?.message ? error.message : getRulesValidationErrorMsg(validationKey, error?.type)) : null}
          {...restField}
          {...rest}
        />)
      }}
    />
  )
}

const AddProviderUserDialog = ({ open, handleOpen, selectedUser, dialogMode }) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.user);
  const roles = useSelector((state) => state.auth.providerRoles);
  const [selectedRole, setSelectedRole] = useState("");

  // error messages
  const [isSelectedRoleError, setIsSelectedRoleError] = useState({ isError: false, message: '' });

  const defaultValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: ''
  }
  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues,
    mode: 'all'
  });

  const handleSubmitSave = (formData) => {
    console.log("form data - ", formData);
    if (!selectedRole) {
      setIsSelectedRoleError({ isError: true, message: 'Select role' })
      return
    }
    if (dialogMode === "ADD") {
      dispatch(
        createProviderUser({
          user: { ...formData },
          entityType: authUser?.owner_type,
          entityID: authUser?.owner_id,
          roles: [selectedRole],
        })
      );
    }
    else if (dialogMode === "EDIT") {
      dispatch(
        updateProviderUser({
          user: { ...formData },
          user_id: selectedUser.keys.user_id,
          entityType: authUser?.owner_type,
          entityID: authUser?.owner_id,
          role: selectedRole
        })
      );
      handleOpen(false)
    }
  }

  useEffect(() => {
    if (authUser && authUser?.role_names?.includes("IMS ADMIN")) {
      dispatch(getProviderRoles({ entityType: authUser?.owner_type, entityID: authUser?.owner_id }))
    }
  }, [authUser]);

  useEffect(() => {
    if (selectedUser && open) {
      setValue('firstName', selectedUser?.value?.firstName, { shouldValidate: true, shouldDirty: true })
      setValue('lastName', selectedUser?.value?.lastName, { shouldValidate: true, shouldDirty: true })
      setValue('email', selectedUser?.value?.email, { shouldValidate: true, shouldDirty: true })
      setValue('phone', selectedUser?.value?.phone, { shouldValidate: true, shouldDirty: true })
      let role = _.find(roles, (item) => { return item.value.name === selectedUser?.value?.a_role_names?.[0] })
      setSelectedRole(role);
    } else {
      setSelectedRole("");
      reset(defaultValues)
    }
  }, [selectedUser, open]);

  useEffect(() => {
    console.log("role", selectedRole);
    if (selectedRole) {
      setIsSelectedRoleError({ isError: false, message: '' })
    }
  }, [selectedRole]);

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      open={open}
      onClose={() => handleOpen(false)}
    >
      <Box pl={3} pr={3} pt={5} pb={5}>
        <Box
          display="flex"
          flexDirection="row"
          pb={5}
          justifyContent="space-between"
        >
          <Typography
            sx={{
              color: "primary.main",
              fontSize: 24,
              fontFamily: "Globersemibold",
              textTransform: "uppercase",
            }}
          >
            {dialogMode === 'ADD' ? 'Add New User' : 'Edit User'}
          </Typography>
          <IconButton onClick={() => handleOpen(false)}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Box>

        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={4}>
            <Grid item xs={6}>
              <DropdownWithValue
                label="Role"
                options={roles}
                value={selectedRole}
                onChange={setSelectedRole}
                isError={isSelectedRoleError.isError}
              />
              {isSelectedRoleError.isError && <Typography sx={{ color: '#d32f2f', fontSize: '12px', margin: '3px 14px 0 14px' }}>{isSelectedRoleError.message}</Typography>}
            </Grid>
            <Grid item xs={6}></Grid>
            {/* first row */}
            <Grid item xs={6}>
              <ControllerField control={control}
                nameControl={'firstName'}
                validationKey={'NAME_VALIDATION'}
                label={'First Name'}
              />
            </Grid>
            <Grid item xs={6}>
              <ControllerField control={control}
                nameControl={'lastName'}
                validationKey={'NAME_VALIDATION'}
                label={'Last Name'}
              />
            </Grid>

            {/* second row */}
            <Grid item xs={6}>
              <ControllerField control={control}
                nameControl={'email'}
                validationKey={'USER_MGMT_USERNAME'}
                label={'Email'}
                disabled={dialogMode === 'EDIT'}
              />
            </Grid>
            <Grid item xs={6}>
              <ControllerField control={control}
                nameControl={'phone'}
                validationKey={'MOBILE_NUMBER_VALIDATION'}
                label={'Phone Number'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit(handleSubmitSave)}
          >
            {dialogMode === 'ADD' ? "add new user" : "Save"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddProviderUserDialog;
