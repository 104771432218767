import { axiosErrorHandler, postRequest } from "../../utils/axios";
import {
  SET_OPEN_MODAL_DOCUMENT,
  SET_DOCUMENT_CATEGORY,
  RESET_DOCUMENTS,
  SET_MASTER_FILE_DOCUMENTS,
    SET_PRICELIST_DRAFT,
  SET_SAVE_PRICELIST_MODAL_OPEN,
  SET_ALL_PRICELISTS,
  SET_SAVE_PRICELIST_LOADING,
  SET_GET_PRICELISTS_LOADING,
  SET_PRICELIST

} from "../actionTypes";
// import { entityTypeAndID } from "../../utils/entityTypeAndID";
import { store } from '../../App'
import { uploadFileToDrive, getPresignedUrlForPricelist } from '../../utils/upload'
import { setLoadingUploadDocument } from "./view.actions";
import commandHandler from "../../utils/commandHandler";
import { getOneGop, setDraftBill, setOpenModalAddAnotherBill } from "./gop.actions";

export const setOpenModalDocument = (boolean) => {
  return {
    type: SET_OPEN_MODAL_DOCUMENT,
    payload: boolean
  }
}
export const setDocumentCategory = (category) => {
  return {
    type: SET_DOCUMENT_CATEGORY,
    payload: category
  }
}

export const resetDocuments = () => {
  return {
    type: RESET_DOCUMENTS
  }
}

export const setMasterFileDocuments = (files) => {
  return {
    type: SET_MASTER_FILE_DOCUMENTS,
    payload: files
  }
}

export const setPricelistDraft = (pricelistObj) => {
  return {
    type: SET_PRICELIST_DRAFT,
    payload: pricelistObj
  }
};

export const setSavePricelistModalOpen = (bool) => {
  return {
    type: SET_SAVE_PRICELIST_MODAL_OPEN,
    payload: bool
  }
};

export const setAllPricelists = (pricelistsArr) => {
  return {
    type: SET_ALL_PRICELISTS,
    payload: pricelistsArr
  }
};
export const setPricelist = (pricelist) => {
  return {
    type: SET_PRICELIST,
    payload: pricelist
  }
};
export const createPricelistFile = ({ file }) => {
  return async dispatch => {
    try {
      const user = store.getState()?.auth?.user;
      const presignUrlResponse = await getPresignedUrlForPricelist(file);
      console.log('createPricelistFile resp ==', presignUrlResponse.data);
      dispatch(setPricelistDraft(presignUrlResponse?.data?.pricelistWb))
      await uploadFileToDrive(presignUrlResponse.data, file)
      dispatch(setLoadingUploadDocument(false))
      dispatch(setSavePricelistModalOpen(true))

    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - createPricelistFile - ', error)
      dispatch(setLoadingUploadDocument(false))
    }
  }
}

export const savePricelist = () => {
  return async dispatch => {
    dispatch(setSavePricelistLoading(true));
    const draftPricelist = store.getState().documents.pricelistDraft;
    try {
      let resp = await postRequest(
          `/price-list/wb/save`,
          draftPricelist
      )
      console.log('savePricelist ==', resp.data);
      dispatch(setSavePricelistModalOpen(false))
      dispatch(setPricelistDraft({}))
      dispatch(getAllPricelists())
      dispatch(setSavePricelistLoading(false));

    } catch (err) {
      dispatch(setSavePricelistLoading(false));
      const error = axiosErrorHandler(err);
      console.error('error - savePricelist - ', error)
    }
  }
}

export const deletePricelist = ({ wb_id}) => {
  return async dispatch => {
    dispatch(setGetPricelistsLoading(true));
    try {
      let resp = await postRequest(
          `/price-list/wb/delete`,
          {wb_id}
      )
      console.log('deletePricelist ==', resp.data);
      dispatch(getAllPricelists())
    } catch (err) {
      dispatch(setGetPricelistsLoading(false));
      const error = axiosErrorHandler(err);
      console.error('error - deletePricelist - ', error)
    }
  }
}

export const deleteExtractedData = ({ wb_id}) => {
  return async dispatch => {
    dispatch(setGetPricelistsLoading(true));
    try {
      let resp = await postRequest(
          `/price-list/wb/delete-extracted-data`,
          {wb_id}
      )
      console.log('deletePricelist ==', resp.data);
      dispatch(getAllPricelists())
    } catch (err) {
      dispatch(setGetPricelistsLoading(false));
      const error = axiosErrorHandler(err);
      console.error('error - deletePricelist - ', error)
    }
  }
}

export const deactivateReactivatePricelist = ({ wb_id, stateTo}) => {
  return async dispatch => {
    dispatch(setGetPricelistsLoading(true));
    try {
      let resp = await postRequest(
          `/price-list/wb/deactivate-reactivate`,
          {wb_id, stateTo}
      )
      console.log('deactivateReactivatePl ==', resp.data);
      dispatch(getAllPricelists())
    } catch (err) {
      dispatch(setGetPricelistsLoading(false));
      const error = axiosErrorHandler(err);
      console.error('error - deactivateReactivatePl - ', error)
    }
  }
}

export const exctractPricelist = ({ wb_id, chosenExtrTemplates}) => {
  return async dispatch => {
    dispatch(setGetPricelistsLoading(true));
    try {
      let resp = await postRequest(
          `/price-list/wb/extract`,
          {wb_id, chosenExtrTemplates}
      )
      console.log('exctractPricelist ==', resp.data);
      dispatch(getAllPricelists())
    } catch (err) {
      dispatch(setGetPricelistsLoading(false));
      const error = axiosErrorHandler(err);
      console.error('error - exctractPricelist - ', error)
    }
  }
}
export const getAllPricelists = () => {
  return async dispatch => {
    try {
      dispatch(setGetPricelistsLoading(true));
      let resp = await postRequest(
          `/price-list/wb/get-all`,
          {}
      )
      console.log('getAllPricelists ==', resp.data);
      dispatch(setAllPricelists(resp.data?.pricelists))
      dispatch(setGetPricelistsLoading(false));
    } catch (err) {
      dispatch(setGetPricelistsLoading(false));
      const error = axiosErrorHandler(err);
      console.error('getAllPricelists - ', error)
    }
  }
}

export const setSavePricelistLoading = (bool) => {
  return {
    type: SET_SAVE_PRICELIST_LOADING,
    payload: bool
  }
};

export const setGetPricelistsLoading = (bool) => {
  return {
    type: SET_GET_PRICELISTS_LOADING,
    payload: bool
  }
};



