import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  InputLabel,
  FilledInput,
  FormControl,
  InputAdornment,
  IconButton,
  Button, Typography,
} from "@mui/material";
import { PageContainer, DrawerContainer } from "../../shared";
import { useNavigate, useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ReactComponent as SWNLOGOBLUE } from "../../media/logos/swan_logo_blue.svg";
import { forgotPasswordDoAction, loginEmailPass, logOut, setAuthSelectRoleDialogOpen } from "../../redux/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "../../App";
import { resetEntities } from "../../redux/actions/entities.actions";
import { resetDocuments } from "../../redux/actions/documents.actions";
import { resetGop } from "../../redux/actions/gop.actions";
import { resetViewState } from "../../redux/actions/view.actions";
import { onUserGOPInputChange } from "../../utils/onChangeFunction";
import ErrorMessage from "../../shared/ErrorMessage";
import { setIFrameOpen } from "../../shared/QueryFrame";
import SelectRoleDialog from '../../pages/Login/components/SelectRoleDialog';

const LoginPage = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  let from = location.state?.from?.pathname || "/";
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [showPassword, setShowPassword] = useState(false);
  const isSelectRoleDialogOpen = useSelector(state => state.auth.isSelectRoleDialogOpen) || false;

  // error messages
  const [usernameErrors, setUsernameErrors] = useState([]);

  const projectVersion = useSelector((state) => state.auth.projectVersion);

  const clearStorage = async () => {
    if (projectVersion !== "0.0.25") {
      await persistor.purge();
      setIFrameOpen(false);
      dispatch(logOut());
      dispatch(resetEntities());
      dispatch(resetDocuments());
      dispatch(resetGop());
      dispatch(resetViewState());
      window.location.reload();
    }
  };
    const handleSelectRoleDialogClose = () => {
        dispatch(setAuthSelectRoleDialogOpen({ isOpen: false, roles: [] }))
    };

    useEffect(() => {
    clearStorage();
  }, [projectVersion]);

  const handleNavigate = (route) => {
    navigate(route);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const keyPressPasswordHandle = (e) => {
    if (e.keyCode == 13) {
      setPassword(e.target.value);
      dispatch(loginEmailPass({ email, password, navigate, from }));
    }
  };

  const handleForgotPassword = () => {
    const action = 'FORGOT_PASSWORD';
    dispatch(forgotPasswordDoAction({ action, navigate, from }))
  }

  return (
    <PageContainer>
      <DrawerContainer
        isLogo={false}
        description=""
        underline={false}
      // isBack={true}
      >
        {/* <IMSDrawer /> */}
      </DrawerContainer>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Box
          sx={{
            width: "380px",
            display: "flex",
            margin: "0 auto",
            minHeight: "100px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <Box sx={{ margin: "20px auto" }}>
              <SWNLOGOBLUE />
            </Box>

            <FormControl
              variant="filled"
              fullWidth
              sx={{
                m: "20px 0",
              }}
            >
              <InputLabel
                htmlFor="email"
                sx={{
                  fontSize: "12px",
                  color: "#00b7cd",
                  fontWeight: "bold",
                }}
              >
                USERNAME
              </InputLabel>
              <FilledInput
                id="email"
                type="text"
                fullWidth
                placeholder="Insert your username"
                sx={{
                  color: "rgba(59, 64, 69, 0.87)",
                }}
                onChange={(e) => {
                  handleChangeEmail(e);
                  onUserGOPInputChange(
                    e.target.value,
                    "USER_MGMT_USERNAME",
                    setUsernameErrors
                  );
                }}
                value={email}
              />
              {usernameErrors.map(({ isValid, msg }, index) => {
                if (!isValid) {
                  return (
                    <ErrorMessage key={index} message={msg} />
                  );
                }
              })}
            </FormControl>
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                m: "20px 0",
              }}
            >
              <InputLabel
                htmlFor="password"
                sx={{
                  fontSize: "12px",
                  color: "#00b7cd",
                  fontWeight: "bold",
                }}
              >
                PASSWORD
              </InputLabel>
              <FilledInput
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Insert password"
                value={password}
                onChange={handleChangePassword}
                onKeyDown={keyPressPasswordHandle}
                sx={{
                  color: "rgba(59, 64, 69, 0.87)",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Typography sx={{textAlign: 'right', cursor: 'pointer', color: 'red'}} onClick={handleForgotPassword}>Forgot password?</Typography>
            <Button
              variant="contained"
              sx={{
                height: "36px",
                fontSize: "14px",
                fontWeight: "bold",
                backgroundColor: "#00b7cd",
                borderRadius: 0,
                m: "20px 0",
                color: "#fff",
              }}
              disabled={password && email ? false : true}
              onClick={() =>
                dispatch(loginEmailPass({ email, password, navigate, from }))
              }
            >
              Login
            </Button>
          </Box>
        </Box>
      </Box>
        <SelectRoleDialog
            open={isSelectRoleDialogOpen}
            onClose={handleSelectRoleDialogClose}
            email={email}
            password={password}
            navigate={navigate}
            from={from}/>
    </PageContainer>
  );
};

export default LoginPage;
