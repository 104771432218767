import React, { useEffect, useState } from 'react';
import {
  Box, TextField, MenuItem,
  FormControl, Select, InputLabel
} from '@mui/material';
import _ from 'lodash';

const DisableTextField = ({ value = '', label = '', disabled = true }) => {
  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      fullWidth
      variant="filled"
    />
  )
}

const EditableSelectControl = ({ label = '', value = '', propertyName = '', items = [], onChange = () => { }, isDisabled = true }) => {
  const [textFieldValue, setTextFieldValue] = useState('')
  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        setTextFieldValue(value);
      } else {
        let item = _.find(items, { value })
        if (item) {
          setTextFieldValue(item?.title)
        }
      }
    }
    else {
      setTextFieldValue('')
    }
  }, [value, items])
  // console.log('label', label, 'value', value, 'propertyName', propertyName)


  return (
    <>
      {isDisabled && <DisableTextField label={label} value={textFieldValue} disabled={isDisabled} />}
      {!isDisabled &&
        <FormControl disabled={isDisabled}
          variant="outlined" fullWidth >
          <InputLabel id={propertyName + '-label'}>{label}</InputLabel>
          <Select
            labelId={propertyName + '-label'}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            label={label}
            variant={'outlined'}
          >
            {items?.map((item, index) => (
              <MenuItem key={index.toString()} value={item.value}>{item.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    </>
  )
}

export default EditableSelectControl