import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Autocomplete
} from '@mui/material';
import _ from 'lodash';

const DisableTextField = ({ value = '', label = '', disabled = true }) => {
  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      fullWidth
      multiline
      variant="filled"
    />
  )
}

const SearchEditableSelectControl = ({ label = '', valueObj = {}, propertyName = '', items = [], onChange = () => { }, isDisabled = true }) => {
  const initDefault = { value: '', title: "not chosen" }
  const [textFieldValue, setTextFieldValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [defaultValue, setDefaultValue] = useState(initDefault)
  useEffect(() => {
    if (valueObj) {
      let item = _.find(items, { value: valueObj.value })
      console.log('SearchEditableSelectControl item - ', item)
      if (item) {
        setDefaultValue(item)
        setTextFieldValue(item?.title)
      }
      else {
        setDefaultValue(initDefault)
      }
    }
    else {
      setDefaultValue(initDefault)
      setTextFieldValue(initDefault.title)
    }
  }, [valueObj])
  return (
    <>
      {isDisabled && <DisableTextField label={label} value={textFieldValue} disabled={isDisabled} />}
      {!isDisabled &&
        <Autocomplete
          value={defaultValue}
          onChange={(event, selectValue) => {
            console.log('SearchEditableSelectControl selectValue - ', selectValue)
            if (selectValue) {
              onChange(selectValue)
            }
            else {
              onChange(initDefault)
            }
          }}
          inputValue={searchValue}
          onInputChange={(event, newInputValue) => {
            setSearchValue(newInputValue);
          }}
          id={propertyName + '-search-control'}
          options={items}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.title}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          getOptionLabel={(option) => option?.title ? option.title : ''}
          renderInput={(params) => <TextField multiline variant='filled' {...params} label={label} />}
        />
      }
    </>
  )
}

export default SearchEditableSelectControl