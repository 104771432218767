import {
  SET_COMPANY_GROUPS,
  SET_SELECTED_GROUP,
  SET_CLINICS,
  SET_ROLES,
  SET_USERS,
  SET_OPEN_MODAL_ENTITY,
  SET_OPEN_MODAL_ENTITY_SUCCESS,
  SET_OPEN_MODAL_GROUP,
  SET_OPEN_MODAL_GROUP_SUCCESS,
  SET_OPEN_MODAL_USER,
  SET_OPEN_MODAL_ENTITY_EDIT,
  SET_GROUP_CONTACTS,
  RESET_ENTITIES,
  SET_NEW_PERMISSIONS,
  SET_SELECTED_PERMISSION,
  SET_SELECTED_ROLE_ID,
  SET_OPEN_MODAL_ADD_EDIT_CONTACT,
  SET_VALIDATION_PASSWORD_RULES
} from '../actionTypes';

const INITIAL_STATE = {
  companyGroups: [],
  selectedCompany: {},
  validationPasswordRules: {},
  selectedCompanyContacts: [],
  clinics: [],
  roles: [],
  users: [],
  modals: {
    openModalGroup: false,
    openModalGroupSuccess: false,
    openModalEntity: false,
    openModalEntitySuccess: false,
    openModalUser: false,
    openModalEntityEdit: false,
    openModalAddEditContact: false
  },
  rolePermissions: [],
  selectedRoleId: ''
};


export const entitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CLINICS:
      return { ...state, clinics: action.payload }
    case SET_ROLES:
      return { ...state, roles: action.payload }
    case SET_USERS:
      return { ...state, users: action.payload }
    case SET_COMPANY_GROUPS:
      return { ...state, companyGroups: action.payload }
    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedCompany: action.payload,
        clinics: [],
        roles: [],
        users: [],
        selectedCompanyContacts: [],
        validationPasswordRules: {}
      }
    case SET_GROUP_CONTACTS:
      return { ...state, selectedCompanyContacts: action.payload }
    case SET_OPEN_MODAL_ENTITY:
      return { ...state, modals: { ...state.modals, openModalEntity: action.payload, openModalEntitySuccess: false } }
    case SET_OPEN_MODAL_ENTITY_SUCCESS:
      return { ...state, modals: { ...state.modals, openModalEntitySuccess: action.payload } }
    case SET_OPEN_MODAL_GROUP_SUCCESS:
      return { ...state, modals: { ...state.modals, openModalGroupSuccess: action.payload } }
    case SET_OPEN_MODAL_GROUP:
      return { ...state, modals: { ...state.modals, openModalGroup: action.payload, openModalGroupSuccess: false } }
    case SET_OPEN_MODAL_USER:
      return { ...state, modals: { ...state.modals, openModalUser: action.payload } }
    case SET_OPEN_MODAL_ENTITY_EDIT:
      return { ...state, modals: { ...state.modals, openModalEntityEdit: action.payload } }
    case SET_OPEN_MODAL_ADD_EDIT_CONTACT:
      return { ...state, modals: { ...state.modals, openModalAddEditContact: action.payload } }
    case RESET_ENTITIES:
      return { ...INITIAL_STATE }
    case SET_NEW_PERMISSIONS:
      return { ...state, rolePermissions: action.payload }
    case SET_SELECTED_PERMISSION:
      const newArrPermissions = [...state.rolePermissions];
      if (newArrPermissions?.[action.payload.allocatedIndex]?.actions?.[action.payload.actionIndex]) {
        newArrPermissions[action.payload.allocatedIndex].actions[action.payload.actionIndex].effect = action.payload.type
      }
      return { ...state, rolePermissions: newArrPermissions }
    case SET_SELECTED_ROLE_ID:
      return { ...state, selectedRoleId: action.payload }
    case SET_VALIDATION_PASSWORD_RULES:
      return { ...state, validationPasswordRules: action.payload }
    default:
      return state;
  }
};

