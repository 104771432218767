import React, { useState, useEffect } from 'react';
import { Box, Fab, Typography, TextField, InputAdornment } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { PageContainer, PageBodyContainer, DrawerContainer, IMSDrawer } from '../../shared';
import {
  CompanyGroupCard,
  CreateCompanyGroupModal,
  // NewGroupAddedModal
} from './components';
import { Add } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import { getCompanyGroups, setOpenModalGroupSuccess, setOpenModalGroup } from '../../redux/actions/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { entityTypeAndID } from '../../utils/entityTypeAndID';
import { SuccessAddModal } from '../../shared';
import { ENTITY_BTN } from "../../constants/viewConfig";



const CompanyGroups = () => {
  const dispatch = useDispatch();
  const companyGroups = useSelector(state => state.entities.companyGroups)
  const user = useSelector(state => state.auth.user)
  const openModalGroup = useSelector(state => state.entities.modals?.openModalGroup)
  const openModalGroupSuccess = useSelector(state => state.entities.modals?.openModalGroupSuccess)
  const viewShowControl = useSelector(state => state.view?.mainViewConfig?.show);

  useEffect(() => {
    dispatch(getCompanyGroups())    
  }, [])


  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={user?.owner_type === 'provider' ? 'Inpatient Management System' : 'Clinic Portal'}
        // description='Inpatient Management System'
        underline={true}
        // isBack={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Box display='flex' flexDirection='row' alignItems='center' pl={15} pr={8} pb={3} pt={1}>
          <Typography sx={{ color: "#053ad3", fontSize: "48px", pr: 8 }}>
            Company Groups
          </Typography>
          {/*<TextField*/}
          {/*  id="input-with-icon-textfield"*/}
          {/*  InputProps={{*/}
          {/*    startAdornment: (*/}
          {/*      <InputAdornment position="start">*/}
          {/*        <SearchIcon sx={{color: "#eb0781"}} />*/}
          {/*      </InputAdornment>*/}
          {/*    ),*/}
          {/*  }}*/}
          {/*  variant="standard"*/}
          {/*/>*/}
        </Box>
        <Box pl={8} pr={8} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          { companyGroups && companyGroups.length > 0 && companyGroups.map((group, index) => <CompanyGroupCard group={group} key={index.toString()} />)}
        </Box>
      </PageBodyContainer>
      {viewShowControl?.includes(ENTITY_BTN.ENTITY_BTN_ADD_ENTITY) && <Fab
        sx={{ position:'fixed', bottom: 42, right: 42, fontFamily: 'Globerbold'}}
        variant="extended"
        color={'secondary'}
        onClick={() => dispatch(setOpenModalGroup(true))}>
        <Add/>
        CREATE NEW GROUP
      </Fab>}
      <CreateCompanyGroupModal open={openModalGroup} handleOpen={(boolean) => dispatch(setOpenModalGroup(boolean))} type={'group'} />
      <SuccessAddModal 
        open={openModalGroupSuccess} 
        handleOpen={(boolean) => dispatch(setOpenModalGroupSuccess(boolean))} 
        type={'GROUP'} 
        handleClickBtnSuccess={()=> dispatch(setOpenModalGroup(true))} />
    </PageContainer>
  );
};

export default CompanyGroups;