import React, { useEffect, useState } from "react";
import {
  Box, Button, Grid,
  TextField, Typography,
  InputAdornment, IconButton, OutlinedInput,
} from "@mui/material";
import { ControllerTextField } from './FormComponents';
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import { GOP_BTN } from "../../../constants/viewConfig";
import { isNaN } from 'lodash';
import { getExternalRemarks, saveExternalRemark, saveOneFee, setFeeRemarks } from "../../../redux/actions/fees.actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";


const FeeDetails = ({ selectedFeeDetails, setIsFeeDetailsOpen, selectedFeeDetailsSectionKey, GOP_FEES_INPUTS, type }) => {
  const dispatch = useDispatch();
  const selectedGop = useSelector(state => state.gop.selectedGop);
  const a_viewConfig = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig);
  const feeRemarks = useSelector(state => state.fees.feeRemarks) || [];
  const saveOneFeeLoading = useSelector(state => state.fees?.loading?.saveOneFee) || false;
  const defaultValues = {
    claimed_amt: selectedFeeDetails.claimed_amt ? selectedFeeDetails.claimed_amt : 0,
    payable_amt: selectedFeeDetails.payable_amt ? selectedFeeDetails.payable_amt : 0 }
  const { control, handleSubmit, errors, reset, getValues, watch } = useForm({ defaultValues });
  const watchFields = watch(["claimed_amt", "payable_amt"]);
  const [deductibleAmt, setDeductibleAmt] = useState('')
  const [remark, setRemark] = useState('');
  const [allRemarks, setAllRemarks] = useState([]);


  useEffect(() => {
    let payAmt = 0;
    let claimedAmt = 0;

    claimedAmt = !isNaN(parseInt(watchFields[0])) ? parseInt(watchFields[0]) : 0
    payAmt = !isNaN(parseInt(watchFields[1])) ? parseInt(watchFields[1]) : 0
    setDeductibleAmt(claimedAmt - payAmt)
  }, [watchFields])

  useEffect(() => {
    if (selectedFeeDetails?.remarks_section_id) {
      dispatch(getExternalRemarks({ remarks_section_id: selectedFeeDetails?.remarks_section_id }))
    }
  }, [selectedFeeDetails?.remarks_section_id])

  useEffect(() => {
    // console.log('feeRemarks', feeRemarks);
    setAllRemarks(feeRemarks)
  }, [feeRemarks])


  const onBack = () => {
    dispatch(setFeeRemarks([]))
    setIsFeeDetailsOpen(false)
  } 


  return (
    <Box sx={{ backgroundColor: '#fafafa', mb: 3, ml: 1, flex: 1 }}>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 0, mb: 2, mt: 2 }}>
          <Button
            color="inherit"
            sx={{ mr: 1, color: "#00b7cd" }}
            onClick={() => {
              if (!saveOneFeeLoading) onBack()
            }}>
            <ArrowBackIcon sx={{ margin: "0 9px 0 0", color: "#eb0781" }} />
            {selectedFeeDetails.title}
          </Button>
        </Box>
      </Grid>
      {/** Helper Text */}
      <Grid item xs={12}>
        <Typography sx={{
          fontFamily: 'Roboto',
          fontSize: '12px',
          letterSpacing: '1.25px',
          ml: 1, mr: 1, mb: 2
        }}>
          Enter a claimed amount and payable amount,the disallowed amount will be autocalculated based on your entries.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: 1, ml: 1 }}>
          <ControllerTextField
            control={control}
            isNum={true}
            itemKey={`claimed_amt`}
            disabled={!a_viewConfig?.editable?.includes?.(GOP_FEES_INPUTS.CLAIMED)}
            label={'Claimed'}
            sx={{ mr: 2 }}
            variant="filled" />

          <ControllerTextField
            control={control}
            isNum={true}
            itemKey={`payable_amt`}
            disabled={!a_viewConfig?.editable?.includes?.(GOP_FEES_INPUTS.PAYABLE)}
            label={'Payable'}
            sx={{ mr: 2 }}
            variant="filled" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: 3, ml: 1 }}>
          <TextField
            label="MUR 'Disallowed Amount"
            variant="filled"
            value={deductibleAmt}
          />
        </Box>
      </Grid>
      

      {/* Save Cancel Make Query Buttons */}
      <Grid item xs={12}>
        <Box display="flex" ml={2} mb={1}>
          {/* Save Button */}
          {a_viewConfig.show.includes(GOP_BTN.SAVE_ONE_FEE) && <LoadingButton color='secondary' variant="text" startIcon={<SaveIcon />}
            loading={saveOneFeeLoading}
            sx={{ fontWeight: "bold", fontSize: '14px', mr: 2 }}
            onClick={() => { if (!saveOneFeeLoading) dispatch(saveOneFee({ fee: selectedFeeDetails, values: getValues(), sectionKey: selectedFeeDetailsSectionKey, gop_id: selectedGop?.keys?.gop_id, type })) }}
          >
            Save
          </LoadingButton>}
          {/* Cancel Button */}
          {a_viewConfig.show.includes(GOP_BTN.CANCEL_ONE_FEE) && <Button color='secondary' variant="text" startIcon={<CancelIcon />}
            sx={{ fontWeight: "bold", fontSize: '14px' }}
            onClick={() => { if (!saveOneFeeLoading) reset(defaultValues) }}
          >
            Cancel
          </Button>}
        </Box>
      </Grid>
      {/* remarks */}
      {type === 'inpatient' && a_viewConfig?.show?.includes?.(GOP_FEES_INPUTS.EXTERNAL_REMARKS)  && <Box display='flex' flex={1} flexDirection='column' flexWrap='wrap'>
        <Typography fontSize={'18px'} fontFamily={'Globerbold'} color={'primary'} p={3}>Remarks</Typography>
        {a_viewConfig?.editable?.includes?.(GOP_FEES_INPUTS.EXTERNAL_REMARKS) && <OutlinedInput
          placeholder="Type Here"
          // variant="outlined"
          sx={{ ml: 3, mr: 3, mb: 2 }}
          multiline
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                color="primary"
                disabled={!remark}
                onClick={() => {
                  dispatch(saveExternalRemark({ remarks_section_id: selectedFeeDetails?.remarks_section_id, text: remark }))
                  setRemark('');
                }}
                edge="end"
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          }
          value={remark}
          onChange={(value) => setRemark(value.target.value)}
        />}
        {allRemarks?.length > 0 && allRemarks.map((item, index) =>
          <Box
            key={index.toString()}
            sx={{
              ml: 3,
              mr: 3,
              mb: 2,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: 'grey',
              p: 1,
          }}>
            <Typography sx={{ wordBreak: 'break-word'}}>{`${item?.value?.user?.name} ${item?.value?.a_updated_at_ddmmyyyyhhmmss}:`}</Typography>
            <Typography sx={{ wordBreak: 'break-word'}}>{item?.value?.post?.text}</Typography>
          </Box>)}
      </Box>}
    </Box>
  );
};

export default FeeDetails;
