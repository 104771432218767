import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { onPasswordTestInputChange } from '../utils/onChangeFunction';
import { PINK_COLOR } from '../constants/colors';

const IMSTestPasswordInput = () => {
  const validationPasswordRules = useSelector(state => state.entities.validationPasswordRules)
  const [errorsTestPasswordList, setErrorsTestPasswordList] = useState([]);
  const [testPasswordValue, setTestPasswordValue] = useState('');

  return (
    <>
      <Typography sx={{ color: PINK_COLOR, fontSize: 16, fontWeight: 'bold' }}>Test Password</Typography>
      <TextField size="small"
        value={testPasswordValue}
        name='password-test'
        onChange={(e) => {
          onPasswordTestInputChange(
            e.target.value,
            setTestPasswordValue,
            setErrorsTestPasswordList,
            'passwordRulesExpanded',
            validationPasswordRules
          )
        }} />

      {errorsTestPasswordList.map(({ isValid, msg }, index) => {
        return (
          <Typography key={index} sx={{ display: 'flex', margin: '5px 0' }}>
            {isValid ? <CheckIcon fontSize="small" color="success" /> : <CloseIcon fontSize="small" color="error" />}
            {/* <CloseIcon fontSize="small" color="error" />{" "} */}
            <span
              style={{
                color: isValid ? "green" : "#d32f2f",
                fontSize: "13px",
                fontFamily: "Globerbold",
              }}
            >
              {msg}
            </span>
          </Typography>
        );
      })}
    </>
  )
};

export default IMSTestPasswordInput;