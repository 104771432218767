import React, { useEffect, useRef } from 'react';
import { Button, IconButton, Paper, Typography, Box } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import SWANLOGO from '../../../media/logos/swan_logo_blue.svg';
import { useNavigate } from 'react-router-dom';
import { loadGopData } from '../../../redux/actions/gop.actions';
import { useDispatch } from 'react-redux';
import useOnScreen from '../../../utils/useOnScreen';
import { setNotificationStateSeen, setShowNotificationsDrawer } from '../../../redux/actions/notifications.action';
import { getAllMasterFiles} from '../../../redux/actions/documents.actions';

const NotificationsItem = ({
  imageItem, buttons,
  title, timestamp, state, notification_id,
  content, onDeleteClick }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardRef = useRef()
  const isCardVisible = useOnScreen(cardRef)

  // useEffect(() => {
  //   if (isCardVisible && state === 'UNSEEN') {
  //     //TODO Call update notification state to SEEN
  //     console.log('Call update notification state to SEEN for id - ', notification_id);
  //   }
  // }, [isCardVisible])

  const handleNotificationOnClick = () => {
    if (state === 'UNSEEN') {
      dispatch(setNotificationStateSeen(notification_id))
    }
  }

  const handleActionNotification = ({ link_type, link_id }) => {
    // console.log("handleNotificationOnClick link_type - ", link_type)
    // console.log("handleNotificationOnClick link_id - ", link_id)
    if (link_type && link_id) {
      if (link_type === "GOP") {
        dispatch(loadGopData(link_id, navigate))
      }
      if(link_type === "MASTER_FILE") {
        dispatch(setShowNotificationsDrawer(false))
        dispatch(getAllMasterFiles())
        navigate(`/master-file`)
      }
    }
  }

  return (
    <Paper ref={cardRef} elevation={3}
      sx={{ width: '100%', minHeight: "115px", marginBottom: "20px", marginTop: "20px" }}>
      <Box p={"10px"} pl={"16px"} onClick={handleNotificationOnClick}>
        <Box display="flex" >
          <img src={imageItem} height={36} width={36} />

          <Box display='flex' flexDirection='column'
            sx={{
              marginLeft: "10px",
              flexGrow: 1, marginTop: "2px"
            }}>
            <Typography sx={{ color: '#00b7cd', fontFamily: 'Globerbold', fontSize: '12px' }}>{title}</Typography>
            <Typography sx={{ color: '#747474', fontSize: '12px', fontFamily: 'Globersemibold' }}>{timestamp}</Typography>
          </Box>

          <IconButton sx={{ marginRight: "-10px" }} onClick={onDeleteClick}>
            <DeleteOutlined color={'secondary'} />
          </IconButton>
        </Box>

        <Typography sx={{ fontSize: "12px", marginTop: "10px", fontWeight: state === 'UNSEEN' ? 'bold' : '500' }}
          dangerouslySetInnerHTML={{ __html: content }}>
        </Typography>

        {buttons?.map((btn, index) => {
          return (
            <Button key={index} sx={{ fontFamily: 'Globerbold', fontSize: "14px", marginLeft: '-8px' }}
              variant={'text'}
              color={'secondary'}
              onClick={() => handleActionNotification(btn)}>
              {btn.title}
            </Button>
          )
        })}
      </Box>
    </Paper>
  )
}

export default NotificationsItem;
