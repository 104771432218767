import React, { useEffect, useState } from 'react';
import {
  TextField, RadioGroup, Radio,
  FormControl, FormControlLabel, FormLabel
} from '@mui/material';
import _ from 'lodash';

const DisableTextField = ({ value = '', label = '', disabled = true }) => {
  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      fullWidth
      variant="filled"
    />
  )
}

const EditableRadioGroupControl = ({ label = '', value = '', propertyName = '', items = [], onChange = () => { }, isDisabled = true }) => {
  const [textFieldValue, setTextFieldValue] = useState('')
  useEffect(() => {
    if (isDisabled && typeof value === 'string') {
      setTextFieldValue(value);
    } else if (value) {
      let item = _.find(items, { value })
      if (item) {
        setTextFieldValue(item?.title)
      }
    }
  }, [items])
  return (
    <>
      {isDisabled && <DisableTextField label={label} value={textFieldValue} disabled={isDisabled} />}
      {!isDisabled &&
        <FormControl disabled={isDisabled} component="fieldset" >
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup row
            aria-label={propertyName + 'label'}
            name={propertyName + 'ragio-group'}
            value={value}
            onChange={(event) => onChange(event.target.value)}>
            {items.map((item, index) => (
              <FormControlLabel key={index.toString()} value={item.value} control={<Radio />} label={item.title} />
            ))}
          </RadioGroup>
        </FormControl>
      }
    </>
  )
}

export default EditableRadioGroupControl