import _ from "lodash";
import validateItem from "./validate";
import validationRules from "./validations-rules";

const onUserInputChange = (
  value,
  mutatorFunction,
  validator,
  mutatorErrorFunction
) => {
  mutatorFunction(value);
  const { validations } = validateItem(validator, value, validationRules);
  mutatorErrorFunction(validations);
};

const onUserGOPInputChange = (value, validator, mutatorErrorFunction) => {
  const { validations } = validateItem(validator, value, validationRules);
  mutatorErrorFunction(validations);
};

const onPasswordTestInputChange = (value, mutatorFunction, mutatorErrorFunction, validator, validationRules) => {
  mutatorFunction(value);
  if (validationRules && validationRules?.[validator]) {
    const { validations } = validateItem(validator, value, validationRules);
    mutatorErrorFunction(validations);
    // console.log(validations);
  }
};

const getRulesValidation = (key) => {
  let rules = {}
  if (validationRules[key]) {
    validationRules[key]?.forEach(r => {
      rules[r.key] = (value) => {
        if(_.isObject(value) && _.has(value, 'value')){
          value = value.value
        }
        if(value === null){
          return r.msg
        }
        const regexExp = new RegExp(r.regex);
        const valid = regexExp.test(value);
        let message = undefined
        if(!valid){
          message = r.msg
        }
        return message
      }
    })
  }
  return rules
}

const getRulesValidationErrorMsg = (key, type) => {
  // console.log(key, type)
  if (validationRules[key] && type) {
    const findError = validationRules[key]?.find((r) => r.key === type)
    return findError ? findError.msg : '';
  }
  return "Some Error"
}

export {
  onUserInputChange, onUserGOPInputChange, onPasswordTestInputChange, getRulesValidation,
  getRulesValidationErrorMsg
};
