import React, { useRef, useState, useEffect } from 'react';
import { TextField, IconButton } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const OverviewCasesAndClaimsSearch = ({ searchValue, onChangeValue, clearSearch }) => {
  return (
    <>
      <TextField
        sx={{
          width: 360
        }}
        value={searchValue}
        onChange={onChangeValue}
        variant="outlined"
        placeholder="Search"
        InputProps={{
          endAdornment: (
            <IconButton>
              {searchValue.length ? <ClearOutlinedIcon onClick={clearSearch} /> : <SearchOutlinedIcon />}
            </IconButton>
          ),
        }}
      />
    </>
  );
};

export default OverviewCasesAndClaimsSearch;