import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Typography, InputAdornment,
  TextField,
  CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DocumentsPreview from './DocumentsPreview'
import CaptureHeader from './CaptureHeader';
import FeesForm from './FeesForm';
import FeeDetails from './FeeDetails';
import { useForm } from "react-hook-form";
import { saveFees, getFees, setFees, setLoadingSaveFees, setLoadingSubmitFees, setLoadingSaveOneFee } from '../../../redux/actions/fees.actions';
import { GOP_FEES_CATASTROPHE_COLUMNS, GOP_FEES_CATASTROPHE_INPUTS } from '../../../constants/viewConfig';

const createDefaultValuesFromFees = (fees) => {
  const defaultValues = {};

  fees.forEach((section, sectionIndex) => {
    defaultValues[section.k] = {};
    section?.items?.forEach?.((fee, feeIndex) => {
      defaultValues[section.k][fee.k] = {
        claimed_amt: fees[sectionIndex].items[feeIndex].claimed_amt === null ? '' : fees[sectionIndex].items[feeIndex].claimed_amt,
        payable_amt: fees[sectionIndex].items[feeIndex].payable_amt === null ? '' : fees[sectionIndex].items[feeIndex].payable_amt,
        paid_amt: fees[sectionIndex].items[feeIndex].paid_amt === null ? '' : fees[sectionIndex].items[feeIndex].paid_amt
      }
      if (fee.sub_items?.length > 0) {
        fee.sub_items.forEach((subitem) => {
          if (subitem.type === 'DROPDOWN' &&
            (typeof fees[sectionIndex].items[feeIndex][subitem.k] === "string"
              || typeof fees[sectionIndex].items[feeIndex][subitem.k] === "number"
              || fees[sectionIndex].items[feeIndex][subitem.k])) {
            const dropdownValue = subitem?.items?.find?.(item => item.value === fees[sectionIndex].items[feeIndex][subitem.k])
            defaultValues[section.k][fee.k][subitem.k] = dropdownValue;
          } else
          if (subitem.type === 'DROPDOWN' && !fees[sectionIndex].items[feeIndex][subitem.k]) {
            defaultValues[section.k][fee.k][subitem.k] = null
          }   else {
            defaultValues[section.k][fee.k][subitem.k] = !fees[sectionIndex].items[feeIndex][subitem.k] ? '' : fees[sectionIndex].items[feeIndex][subitem.k]
          }
        })
      }
    })
  })
  return defaultValues;
}


const CatastropheTab = () => {
  const dispatch = useDispatch()
  const type = 'catastrophe';
  const navigate = useNavigate();
  const fees = useSelector(state => state.fees?.[type]?.draftFees) || []
  const loading = useSelector(state => state.fees?.loading) || {}
  const selectedGop = useSelector(state => state.gop.selectedGop);
  const selectedGopDocs = useSelector(state => state.gop.selectedGopDocs);
  const defaultValues = createDefaultValuesFromFees(fees);
  const { control, handleSubmit, errors, reset, getValues } = useForm({ defaultValues });
  const [isFeeDetailsOpen, setIsFeeDetailsOpen] = useState(false);
  const [selectedFeeDetails, setSelectedFeeDetails] = useState({});
  const [selectedFeeDetailsSectionKey, setSelectedFeeDetailsSectionKey] = useState('');

  useEffect(() => {
    dispatch(setFees({ FEES: [], title: '', type }))
    dispatch(setLoadingSaveFees(false))
    dispatch(setLoadingSubmitFees(false))
    dispatch(setLoadingSaveOneFee(false))
  }, [])


  useEffect(() => {
    if (selectedGop?.keys?.gop_id) {
      dispatch(getFees({ gop_id: selectedGop?.keys?.gop_id, type }));
    }
  }, [selectedGop?.keys?.gop_id])

  useEffect(() => {
    reset(createDefaultValuesFromFees(fees))
    if (selectedFeeDetailsSectionKey && selectedFeeDetails?.k) {
      const newSelected = fees?.find?.(item => item.k === selectedFeeDetailsSectionKey)?.items?.find?.(item => item.k === selectedFeeDetails.k);
      // console.log('newSelected fee', newSelected)
      setSelectedFeeDetails(!!newSelected ? newSelected : {})
    }
  }, [fees])

  return (
    <Box sx={{ padding: "35px" }}>
      <CaptureHeader
        type={type}
        isFeeDetailsOpen={isFeeDetailsOpen}
        handleCancel={() => { reset(createDefaultValuesFromFees(fees)) }}
        handleSave={() => { dispatch(saveFees({ gop_id: selectedGop?.keys?.gop_id, finalize: '0', values: getValues(), navigate, type })) }}
        handleSubmit={() => { dispatch(saveFees({ gop_id: selectedGop?.keys?.gop_id, finalize: '1', values: getValues(), navigate, type })) }}
      />
      <Box display='flex' flexDirection='row'>
        <Box sx={{ display: "flex", flexDirection: 'column', minWidth: '520px' }}>
          <DocumentsPreview gop_id={selectedGop?.keys?.gop_id} gop_docs={selectedGopDocs} />
        </Box >
        {!isFeeDetailsOpen && !(loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees)
          && <FeesForm
              type={type}
              control={control}
              GOP_FEES_COLUMNS={GOP_FEES_CATASTROPHE_COLUMNS}
              setIsFeeDetailsOpen={setIsFeeDetailsOpen}
              setSelectedFeeDetails={setSelectedFeeDetails}
              setSelectedFeeDetailsSectionKey={setSelectedFeeDetailsSectionKey}
          />}
        {isFeeDetailsOpen
          && <FeeDetails
              type={type}
              GOP_FEES_INPUTS={GOP_FEES_CATASTROPHE_INPUTS}
              selectedFeeDetails={selectedFeeDetails}
              selectedFeeDetailsSectionKey={selectedFeeDetailsSectionKey}
              setIsFeeDetailsOpen={setIsFeeDetailsOpen}
              viewConfig={selectedGop?.value?.a_viewConfig}
          />}
        {!isFeeDetailsOpen && (loading?.getFees || loading?.saveOneFee || loading?.submitLoading || loading?.saveFees) && <CircularProgress />}
      </Box>
    </Box>              


  );
};

export default CatastropheTab;