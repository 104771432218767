import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Header } from '.';
import { useDropzone } from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';

const UploadContainer = ({ selectedFile, setFile, previewUrl }) => {
  const [preview, setPreview] = useState(previewUrl);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDrop: (acceptedFiles) => {
      console.log("acceptedFiles - ", acceptedFiles);
      if (acceptedFiles.length) {
        // let file = Object.assign(acceptedFiles[0], {
        //   preview: URL.createObjectURL(acceptedFiles[0])
        // })
        setPreview(URL.createObjectURL(acceptedFiles[0]));
        setFile(acceptedFiles[0]);

      }
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map(({ file, errors }) => {
        console.log("file - ", file);
        console.log("error - ", errors);
      })
    }
  });

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    // files.forEach(file => URL.revokeObjectURL(file.preview));
    if (preview) {
      URL.revokeObjectURL(preview);
    }
  }, [selectedFile]);

  const handlerClearPreview = () => {
    setPreview('');
    setFile('');
  }

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='row'
    >
      {preview ?
        <Box
          sx={{
            minWidth: 240,
            width: '100%',
            border: '1px dotted #eb0781',
            color: '#eb0781',
            height: '56px'
          }}>
          <Box
            sx={{
              display: 'flex',
              minWidth: 0,
              overflow: 'hidden',
              height: "100%"
            }}>
            <img src={preview}
              style={{
                display: 'block',
                width: 'auto',
                height: '100%',
                margin: "0 auto"
              }} />
            <Button sx={{ color: '#eb0781' }} startIcon={<CancelIcon />} onClick={handlerClearPreview} />
          </Box>
        </Box>
        :
        <Box {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Button
            variant="outlined"
            sx={{
              minWidth: 240,
              width: '100%',
              border: '1px dotted #eb0781',
              color: '#eb0781',
              height: '56px'
            }}><FileUploadIcon sx={{ marginRight: '1rem' }} />UPLOAD LOGO</Button>
        </Box>
      }
    </Box>
  );
};



export default UploadContainer;