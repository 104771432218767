const COMMANDS = {

  UPDATE_GOP: 'UPDATE_GOP',
  REDIRECT_TO_PAGE: 'REDIRECT_TO_PAGE',
  REPLACE_PAGE_WITH: 'REPLACE_PAGE_WITH',
  BILL_SAVE_CANCEL_CONFIRM_MODAL: 'BILL_SAVE_CANCEL_CONFIRM_MODAL',
  SHOW_SELECT_ACTOR_MODAL: 'SHOW_SELECT_ACTOR_MODAL'

}


export default COMMANDS