import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Dialog,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  updateClinicEntity,
  updateCompanyGroupEntity,
} from "../../../redux/actions/entities.actions";
import { useDispatch, useSelector } from "react-redux";
import { UploadContainer } from "../../../shared";
import { getRulesValidation, getRulesValidationErrorMsg } from "../../../utils/onChangeFunction";
import { useForm, Controller } from "react-hook-form";

const ControllerField = ({ control, nameControl, validationKey, ...rest }) => {
  return (
    <Controller
      name={nameControl}
      control={control}
      defaultValue={''}
      rules={{ validate: { ...getRulesValidation(validationKey) } }}
      render={({
        field: { onChange, ...restField },
        fieldState: { invalid, error },
      }) => {
        return (<TextField
          sx={{ minWidth: 240, width: "100%" }}
          variant="filled"
          onChange={onChange}
          error={!!invalid}
          helperText={error ? (error?.message ? error.message : getRulesValidationErrorMsg(validationKey, error?.type)) : null}
          {...restField}
          {...rest}
        />)
      }}
    />
  )
}

const EditEntityModal = ({ open, handleOpen }) => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector((state) => state.entities?.selectedCompany);
  const [selectedFile, setFile] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [formType, setType] = useState("");
  const defaultValues = {
    name: '',
    email: '',
    phone: '',
    address: '',
    description: ''
  }
  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: {},
    mode: 'all'
  });

  const handleSubmitSave = (formData) => {
    console.log("form data - ", formData);
    if (formType === "group") {
      dispatch(
        updateCompanyGroupEntity({
          entity: {
            ...formData,
            logoUrl: selectedCompany?.value?.logoUrl,
          },
          logo: selectedFile,
        })
      );
    }
    else if (formType === "clinic") {
      dispatch(
        updateClinicEntity({
          entity: {
            ...formData,
            category: "clinic",
            logoUrl: selectedCompany?.value?.logoUrl,
          },
          logo: selectedFile,
        })
      );
    }
  }

  useEffect(() => {
    console.log("selectedFile", selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    if (open) {
      if (selectedCompany?.keys?.table && selectedCompany?.keys?.table === "cgs") {
        setType("group");
        reset(defaultValues)
      } else if (selectedCompany?.keys?.table && selectedCompany?.keys?.table === "clinics") {
        setType("clinic");
        reset({ ...defaultValues, clinicCodeOnAs400: '' })
      }

      if (selectedCompany) {
        setValue('name', selectedCompany?.value?.name, { shouldValidate: true, shouldDirty: true })
        setValue('email', selectedCompany?.value?.email, { shouldValidate: true, shouldDirty: true })
        setValue('address', selectedCompany?.value?.address, { shouldValidate: true, shouldDirty: true })
        setValue('phone', selectedCompany?.value?.phone, { shouldValidate: true, shouldDirty: true })
        setValue('description', selectedCompany?.value?.description, { shouldValidate: true, shouldDirty: true })
        if (formType === 'clinic') {
          setValue('clinicCodeOnAs400', selectedCompany?.value?.clinicCodeOnAs400, { shouldValidate: true, shouldDirty: true })
        }
        setPreviewUrl(selectedCompany?.value?.logoUrl);
      }
    }
  }, [open]);

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={open}
      onClose={() => handleOpen(false)}
    >
      <Box pl={3} pr={3} pt={3} pb={3}>
        <Box
          display="flex"
          flexDirection="row"
          pb={2}
          justifyContent="space-between"
        >
          <Typography
            sx={{
              color: "primary.main",
              fontSize: 24,
              fontFamily: "Globersemibold",
              textTransform: "uppercase",
            }}
          >
            {formType === "group" ? "Edit Company group" : "Edit Clinic"}
          </Typography>
          <IconButton onClick={() => handleOpen(false)}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Box>

        <Grid container spacing={4}>
          {/* first row */}
          <Grid item xs={4}>
            <ControllerField
              control={control}
              nameControl={'name'}
              validationKey={'GROUP_NAME_VALIDATION'}
              label={formType === "group" ? "Group Name" : "Clinic Title"}
            />
          </Grid>
          <Grid item xs={4}>
            <ControllerField
              control={control}
              nameControl={'email'}
              validationKey={'USER_MGMT_USERNAME'}
              label={"Email"}
            />
          </Grid>
          <Grid item xs={4}>
            <UploadContainer
              selectedFile={selectedFile}
              setFile={setFile}
              previewUrl={previewUrl}
            />
          </Grid>

          {/* second row */}
          <Grid item xs={4}>
            <ControllerField
              control={control}
              nameControl={'phone'}
              validationKey={'MOBILE_NUMBER_VALIDATION'}
              label={"Phone"}
            />
          </Grid>
          <Grid item xs={8}>
            <ControllerField
              control={control}
              nameControl={'address'}
              validationKey={'ADDRESS_VALIDATION'}
              label={"Address"}
            />
          </Grid>

          {/* third row */}
          {formType === 'clinic' &&
            <Grid item xs={4}>
              <ControllerField
                control={control}
                nameControl={'clinicCodeOnAs400'}
                validationKey={''}
                label={"Clinic Code"}
              />
            </Grid>
          }

          <Grid item xs>
            <ControllerField
              control={control}
              nameControl={'description'}
              validationKey={'DESCRIPTION_VALIDATION'}
              label={"Short Description"}
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit(handleSubmitSave)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default EditEntityModal;
