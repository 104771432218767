import React from 'react';
import { Box, Button, Toolbar, Typography } from '@mui/material';
import { ReactComponent as SWNLOGOWHITE } from '../media/logos/swan_logo_white.svg';
import blueBackgroundBanner from "../media/backgroundImages/blueBackgroundBanner.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';


const DrawerContainer = (props) => {
  const {
    children,
    title = '',
    underline = false,
    description = '',
    isBack = false,
    isLogo = false,
  } = props;
  const navigate = useNavigate();

  return (
    <Box
      display='flex'
      flexDirection='column'
      // flex={1}
      sx={{
        paddingLeft: '48px',
        overflowY: 'scroll',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': { width: 0, background: 'transparent' },
        backgroundSize: 'auto auto',
        backgroundRepeat: 'repeat-y',
        minWidth: 210,
        width: 210,
        backgroundColor: 'primary.main',
        backgroundImage: `url(${blueBackgroundBanner})`
      }}
    >
      {!isBack && <Box mb={1}>
        <Toolbar disableGutters={true} sx={{ borderBottom: underline ? 0.5 : 0, borderColor: 'white', color: 'white' }}>
          {isLogo ? <SWNLOGOWHITE /> : <></>}
        </Toolbar>
        <Typography sx={{ userSelect: "none" }} color='white'>{description}</Typography>
      </Box>}
      {isBack &&
        <Button
          onClick={() => navigate(-1)}
          sx={{
            mb: 1, mt: 3,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <ArrowBackIcon sx={{ color: 'white' }} />
          <Typography color='white' pl={2} >BACK</Typography>
        </Button>}
      {children}
    </Box>
  );
};

export default DrawerContainer;