import React, { useEffect, useState } from 'react';
import {
  Box, Button, FormControl,
  Grid, InputLabel, MenuItem,
  Select, TextField, Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../../../../shared/ConfirmModal";
import {
  addAnotherBillToGop, assignToMeGop,
  changeStatusGop, createDraftBill, resetSelectedGop,
  saveEditGop, setOpenModalAddAnotherBill,
  updateSelectedGop
} from '../../../../../redux/actions/gop.actions';
import { VIEW_CONFIG } from '../../../../../constants';
import {
  AddAnotherBillModal, DatePickerComponent,
  EditableRadioGroupControl, EditableSelectControl,
  HealthCareStatsComponent, SearchEditableSelectControl,
  MultipleSearchSelectedControl,
  MultipleIC10CodesDropdownControl
} from '../../../../../shared';
import {
  CASE_DETAILS_DROPDOWNS,
  GOP_BTN,
  GOP_CASE_DETAILS_SECTION_LIST,
  GOP_CASE_DETAILS_TEXT_FIELDS,
  GOP_DROPDOWNS,
  OTHER_VALUES,
  GOP_TITLE,
  REMARKS
} from '../../../../../constants/viewConfig';
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { onUserGOPInputChange } from '../../../../../utils/onChangeFunction';
import ErrorMessage from '../../../../../shared/ErrorMessage';
import { BillFilesSection, BillRemarks, RemarksGopVettingModal } from './components';
// import VettingRemarks from './components/VettingRemarks';



const StyledTextField = ({
  value = '', label = '', disabled = true, maxLength = '', onChange = () => {
  }
}) => {
  return (
    <TextField
      onChange={(event) => onChange(event.target.value)}
      disabled={disabled}
      label={label}
      value={value}
      fullWidth
      inputProps={{ maxLength: maxLength }}
      // sx={{ m: 2 }}
      variant="filled"
      multiline
    />
  )
}


const TextItem = ({ title = '', value = '', color = '' }) => {
  return (
    <Box display='flex' alignItems='center' flexDirection='row' pt={1}>
      <Box display='flex' alignItems='flex-start' flexDirection='column' pl={2}>
        <Typography
          sx={{ fontFamily: 'Globersemibold', color: 'primary.main', letterSpacing: '1.5px', textTransform: 'uppercase', fontSize: 12,  }}>{title}</Typography>
        <Typography sx={{ fontFamily: 'Globerregular', color: color , fontSize: 14 }}>{value}</Typography>
      </Box>
    </Box>
  )
};

const UpdateInfoItem = ({ date = '', person = '' }) => {
  return (
      <Box display='flex' alignItems='center' flexDirection='row' pt={1} >
        <Box display='flex' alignItems='flex-start' flexDirection='column' pl={2}>
          {/*<Typography sx={{ fontFamily: 'Globersemibold', color: 'primary.main', letterSpacing: '1.5px', textTransform: 'uppercase', fontSize: 12 }}>{title}</Typography>*/}
          <Typography sx={{ fontFamily: 'Globerregular', fontSize: 11 }}>{`Updated ${date}`}</Typography>
          {!!person && <Typography sx={{ fontFamily: 'Globerregular', fontSize: 11 }}>{`By ${person}`}</Typography>}
        </Box>
      </Box>
  )
};




const CaseDetailsTab = ({ type }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const selectedGop = useSelector(state => state.gop.selectedGop);
  const editable = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.editable);
  const show = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.show) || [];
  const state_to_confirmation_msg = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.state_to_confirmation_msg);
  const dropdowns = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.dropdowns);
  const TREATMENT = VIEW_CONFIG.GOP_TEXT_FIELDS.TREATMENT_AND_DOCTOR;
  const SECTION_ADDITIONAL_INFORMATION = GOP_CASE_DETAILS_SECTION_LIST.SECTION_ADDITIONAL_INFORMATION;
  const openModalAddAnotherBill = useSelector(state => state.gop.modals.openModalAddAnotherBill);
  const [caseStatus, setCaseStatus] = useState('')
  const [isRemarksGopVettingModalOpen, setIsRemarksGopVettingModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const additionalInfoControlIsDisabled = editable?.includes(SECTION_ADDITIONAL_INFORMATION) ? false : true

  const [admittingDocFirstNameErrors, setAdmittingDocFirstNameErrors] = useState([]);
  const [admittingDocLastNameErrors, setAdmittingDocLastNameErrors] = useState([]);
  const [otherDocFirstNameErrors, setOtherDocFirstNameErrors] = useState([]);
  const [otherDocLastNameErrors, setOtherDocLastNameErrors] = useState([]);

  const [HEALTHCARE_STATS2, setHealthCareStats2] = useState({})
  const [HEALTHCARE_STATS3, setHealthCareStats3] = useState({})


  const onChangeHealthCareStats1 = (value) => {
    dispatch(updateSelectedGop('additionalInformation', { healthcareStats2: '', healthcareStats3: '' }))
    setHealthCareStats2({})
    setHealthCareStats3({})
    dispatch(updateSelectedGop('additionalInformation', { healthcareStats1: value }))
  }

  const onChangeHealthCareStats2 = (value) => {
    dispatch(updateSelectedGop('additionalInformation', { healthcareStats3: '' }))
    setHealthCareStats3({})
    dispatch(updateSelectedGop('additionalInformation', { healthcareStats2: value }))
  }

  const onOpenConfirmModal = () => {
    setIsRemarksGopVettingModalOpen(false)
    setIsConfirmModalOpen(true)
  }

  const onOpenRemarksGopVettingModal = () => {
    setIsRemarksGopVettingModalOpen(true)
  }

  const onCloseRemarksGopVettingModal = () => {
    setIsRemarksGopVettingModalOpen(false)
    setCaseStatus('')
  }
  const onConfirmRemarksGopVettingModal = () => {
    setIsRemarksGopVettingModalOpen(false)
    onOpenConfirmModal()
  }

  const onCloseConfirmModal = () => {
    setIsConfirmModalOpen(false)
    setCaseStatus('')
  }

  const onConfirmModal = () => {
    dispatch(changeStatusGop({ state: caseStatus.state, type }))
    setCaseStatus('')
    setIsConfirmModalOpen(false)
  }


  const {
    keys: {
      gop_id = '',
    } = {},
    value: {
      a_dropdown_case_statuses = [],
      a_viewConfig: {
        editable_to = {}
      } = {},
      claim: {
        claimNum = '',
        setno = ''
      } = {},
      gop_vetting_remarks_section_id = '',
      caseDetails: {
        a_caseDetailsUnsavedChangesExist = false,
        treatmentAndDoctor: {
          treatmentType = {},
          diagnostics = '',
          admittingDoctor = {},
          otherDoctor = {},
          admittingDoctorNotInList = {},
          otherDoctorNotInList = {},
          clinicComment = '',
          otherDiagnostics
        } = {},
        billing = [
          {
            title: '',
            a_approvedOnStr: '',
            a_valueStr: '',

          },
          {
            title: '',
            a_approvedOnStr: '',
            a_valueStr: ''
          },
        ],
        additionalInformation: {
          cancer = '',
          claimType = '',
          feesType = '',
          dtadv = '',
          dtocc = '',
          estimateAmount = '',
          icd10code = '',
          statsCode1 = '',
          statsCode2 = '',
          statsCode3 = '',
          healthcareStats1 = '',
          healthcareStats2 = '',
          healthcareStats3 = '',
          claimDetails1 = '',
          claimDetails2 = '',
          claimDetails3 = '',
          claimDetails4 = '',
          claimDetails5 = '',
        } = {},
      } = {},
    } = {}
  } = selectedGop || {};

  // console.log('a_caseDetailsUnsavedChangesExist', a_caseDetailsUnsavedChangesExist);


  return (
    <Box p={2}>
      <Typography sx={{ my: 2, mx: 2, fontSize: 16, color: 'secondary.main' }}>Treatment and Doctor</Typography>
      <Grid container spacing={3} p={2}>
        <Grid item xs={12} sm={12}>
          <SearchEditableSelectControl
            valueObj={treatmentType}
            propertyName={'treatmentType'}
            label={'Treatment Type'}
            items={dropdowns?.[GOP_DROPDOWNS.TREATMENT_TYPE]?.items}
            isDisabled={!editable?.includes?.(GOP_DROPDOWNS.TREATMENT_TYPE)}
            onChange={(value) => {
              dispatch(updateSelectedGop('treatmentAndDoctor', { treatmentType: value }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MultipleIC10CodesDropdownControl
            value={diagnostics}
            // propertyName={'diagnostics'}
            label={'diagnosis'}
            isDisabled={!editable?.includes?.(TREATMENT.DIAGNOSTICS)}
            variant={'filled'}
            onChange={(value) => {
              dispatch(updateSelectedGop('treatmentAndDoctor', { diagnostics: value }))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <StyledTextField label='other diagnosis'
            value={otherDiagnostics}
            onChange={(value) => dispatch(updateSelectedGop('treatmentAndDoctor', { otherDiagnostics: value }))}
            disabled={!editable?.includes?.(TREATMENT.OTHER_DIAGNOSTICS)} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SearchEditableSelectControl
            valueObj={admittingDoctor}
            propertyName={'admittingDoctor'}
            label={'  admitting doctor'}
            items={dropdowns?.[GOP_DROPDOWNS.ADMITTING_DOCTOR]?.items}
            isDisabled={!editable?.includes?.(GOP_DROPDOWNS.ADMITTING_DOCTOR)}
            onChange={(value) => {
              dispatch(updateSelectedGop('treatmentAndDoctor', { admittingDoctor: value }));
            }}
          />
          {/* AdmitingDoctor add form for other */}
          {(admittingDoctor && admittingDoctor?.value === OTHER_VALUES.DOCTOR_NOT_IN_LIST) &&
            <Box pt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label='first name'
                    value={admittingDoctorNotInList?.firstName}
                    disabled={!editable?.includes?.(GOP_DROPDOWNS.ADMITTING_DOCTOR)}
                    onChange={(value) => {
                      dispatch(updateSelectedGop('treatmentAndDoctor', {
                        admittingDoctorNotInList: {
                          ...admittingDoctorNotInList,
                          firstName: value
                        }
                      }));
                      onUserGOPInputChange(value, "NAME_VALIDATION", setAdmittingDocFirstNameErrors);
                    }}
                  />
                  {admittingDocFirstNameErrors.map(({ isValid, msg }, index) => {
                    if (!isValid) {
                      return (
                        <ErrorMessage key={index} message={msg} />
                      );
                    }
                  })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label='last name'
                    value={admittingDoctorNotInList?.lastName}
                    disabled={!editable?.includes?.(GOP_DROPDOWNS.ADMITTING_DOCTOR)}
                    onChange={(value) => {
                      dispatch(updateSelectedGop('treatmentAndDoctor', {
                        admittingDoctorNotInList: {
                          ...admittingDoctorNotInList,
                          lastName: value
                        }
                      }));
                      onUserGOPInputChange(value, "NAME_VALIDATION", setAdmittingDocLastNameErrors);
                    }}
                  />
                  {admittingDocLastNameErrors.map(({ isValid, msg }, index) => {
                    if (!isValid) {
                      return (
                        <ErrorMessage key={index} message={msg} />
                      );
                    }
                  })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label='specialty'
                    value={admittingDoctorNotInList?.specialty}
                    disabled={!editable?.includes?.(GOP_DROPDOWNS.ADMITTING_DOCTOR)}
                    onChange={(value) => dispatch(updateSelectedGop('treatmentAndDoctor', {
                      admittingDoctorNotInList: {
                        ...admittingDoctorNotInList,
                        specialty: value
                      }
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label='registration no.'
                    value={admittingDoctorNotInList?.regNo}
                    disabled={!editable?.includes?.(GOP_DROPDOWNS.ADMITTING_DOCTOR)}
                    onChange={(value) => dispatch(updateSelectedGop('treatmentAndDoctor', {
                      admittingDoctorNotInList: {
                        ...admittingDoctorNotInList,
                        regNo: value
                      }
                    }))}
                  />
                </Grid>
              </Grid>
            </Box>
          }
        </Grid>
        <Grid item xs={12} sm={12}>
          <SearchEditableSelectControl
            valueObj={otherDoctor}
            propertyName={'otherDoctor'}
            label={'other doctor'}
            items={dropdowns?.[GOP_DROPDOWNS.OTHER_DOCTOR]?.items}
            isDisabled={!editable?.includes?.(GOP_DROPDOWNS.OTHER_DOCTOR)}
            onChange={(value) => {
              dispatch(updateSelectedGop('treatmentAndDoctor', { otherDoctor: value }));
            }}
          />
          {/* OtherDoctor add form for other */}
          {(otherDoctor && otherDoctor?.value === OTHER_VALUES.DOCTOR_NOT_IN_LIST) &&
            <Box pt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label='first name'
                    value={otherDoctorNotInList?.firstName}
                    disabled={!editable?.includes?.(GOP_DROPDOWNS.OTHER_DOCTOR)}
                    onChange={(value) => {
                      dispatch(updateSelectedGop('treatmentAndDoctor', {
                        otherDoctorNotInList: {
                          ...otherDoctorNotInList,
                          firstName: value
                        }
                      }));
                      onUserGOPInputChange(value, "NAME_VALIDATION", setOtherDocFirstNameErrors);
                    }}
                  />
                  {otherDocFirstNameErrors.map(({ isValid, msg }, index) => {
                    if (!isValid) {
                      return (
                        <ErrorMessage key={index} message={msg} />
                      );
                    }
                  })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label='last name'
                    value={otherDoctorNotInList?.lastName}
                    disabled={!editable?.includes?.(GOP_DROPDOWNS.OTHER_DOCTOR)}
                    onChange={(value) => {
                      dispatch(updateSelectedGop('treatmentAndDoctor', {
                        otherDoctorNotInList: {
                          ...otherDoctorNotInList,
                          lastName: value
                        }
                      }));
                      onUserGOPInputChange(value, "NAME_VALIDATION", setOtherDocLastNameErrors);
                    }}
                  />
                  {otherDocLastNameErrors.map(({ isValid, msg }, index) => {
                    if (!isValid) {
                      return (
                        <ErrorMessage key={index} message={msg} />
                      );
                    }
                  })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label='specialty'
                    value={otherDoctorNotInList?.specialty}
                    disabled={!editable?.includes?.(GOP_DROPDOWNS.OTHER_DOCTOR)}
                    onChange={(value) => dispatch(updateSelectedGop('treatmentAndDoctor', {
                      otherDoctorNotInList: {
                        ...otherDoctorNotInList,
                        specialty: value
                      }
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    label='registration no.'
                    value={otherDoctorNotInList?.regNo}
                    disabled={!editable?.includes?.(GOP_DROPDOWNS.OTHER_DOCTOR)}
                    onChange={(value) => dispatch(updateSelectedGop('treatmentAndDoctor', {
                      otherDoctorNotInList: {
                        ...otherDoctorNotInList,
                        regNo: value
                      }
                    }))}
                  />
                </Grid>
              </Grid>
            </Box>
          }
        </Grid>
        <Grid item xs={12} sm={12}>
          <StyledTextField label='clinic comment'
            value={clinicComment}
            onChange={(value) => dispatch(updateSelectedGop('treatmentAndDoctor', { clinicComment: value }))}
            disabled={!editable?.includes?.(TREATMENT.CLINIC_COMMENT)} />
        </Grid>
      </Grid>

      <Typography sx={{ my: 2, mx: 2, fontSize: 16, color: 'secondary.main' }}>Billing Information</Typography>
      {billing?.length > 0 && billing.map((item, index) => (
        <Grid sx={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '5px', marginBottom: '8px' }} container key={'billing' + index.toString()}>
          <Grid item xs={12} sm={8}>
            <TextItem title={item.title} value={item.a_approvedOnStr} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextItem title='value' value={item.a_amount_str} color={item?.amountColor} />
            {item.amountWarning && <Typography pl={2} sx={{ fontFamily: 'Globerregular', fontSize: 11, color: '#dd0000' }}>{item?.amountWarning}</Typography>}
            <UpdateInfoItem date={item?.a_updated_at_ddmmyyyyhhmmss} person={item?.updatedBy?.name}/>
          </Grid>
          {item?.a_files && item?.a_files?.length > 0 &&
            <Grid item xs={12} sm={12}>
              <BillFilesSection bill={item} />
            </Grid>
          }
          {/*{editable?.includes?.(VIEW_CONFIG.REMARKS.BILL) && <Grid item xs={12}>*/}
          {/*item.a_remarksEditable &&*/}
          {<Grid item xs={12}>
            <BillRemarks bill={item} editable={editable} show={show} />
          </Grid>}
        </Grid>
      ))}

      {/* BTN add another bill */}
      {/* VIEW_CONFIG.GOP_BTN.ADD_ANOTHER_BILL */}
      {show.includes(VIEW_CONFIG.GOP_BTN.ADD_ANOTHER_BILL) &&
        <Box>
          <Button
            startIcon={<AddIcon />}
            variant='text'
            color='secondary'
            sx={{ m: 2, fontSize: "14px", fontWeight: 'bold' }}
            onClick={() => {
              dispatch(createDraftBill())
              dispatch(setOpenModalAddAnotherBill(true))
            }}>
            add another bill
          </Button>
        </Box>}
      {/*END BTN add another bill */}

      {/* Additional information */}
      {(show?.includes(SECTION_ADDITIONAL_INFORMATION) || editable?.includes(SECTION_ADDITIONAL_INFORMATION)) &&
        <Box>
          <Typography sx={{ my: 2, mx: 2, fontSize: 16, color: 'secondary.main' }}>Additional information</Typography>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12} sm={6}>
              <DatePickerComponent label="DATE OF OCCURRENCE" value={dtocc}
                valueOnChange={(newValue) => dispatch(updateSelectedGop('additionalInformation', { dtocc: newValue }))}
                isDisabled={additionalInfoControlIsDisabled} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePickerComponent label="DATE OF ADVICE" value={dtadv}
                valueOnChange={(newValue) => dispatch(updateSelectedGop('additionalInformation', { dtadv: newValue }))}
                isDisabled={additionalInfoControlIsDisabled} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <EditableSelectControl
                value={statsCode1}
                propertyName={'statsCode1'}
                label={'STATS CODE 1'}
                items={dropdowns?.[CASE_DETAILS_DROPDOWNS.STATSCODE1]?.items}
                isDisabled={additionalInfoControlIsDisabled}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { statsCode1: value }))}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <EditableSelectControl
                value={statsCode2}
                propertyName={'statsCode2'}
                label={'STATS CODE 2'}
                items={dropdowns?.[CASE_DETAILS_DROPDOWNS.STATSCODE2]?.items}
                isDisabled={true}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { statsCode2: value }))}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTextField label='stats code 3' value={statsCode3} disabled={true} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EditableSelectControl
                value={feesType}
                propertyName={'feesType'}
                label={'FEES TYPE'}
                items={dropdowns?.[CASE_DETAILS_DROPDOWNS.FEES_TYPE]?.items}
                isDisabled={additionalInfoControlIsDisabled}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { feesType: value }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label='Estimate Amount'
                value={estimateAmount}
                disabled={
                  editable?.includes?.(VIEW_CONFIG.GOP_CASE_DETAILS_TEXT_FIELDS.ESTIMATE_AMOUNT)
                    ? false
                    : additionalInfoControlIsDisabled
                }
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { estimateAmount: value }))} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EditableSelectControl
                value={claimType}
                propertyName={'claimType'}
                label={'CLAIM TYPE'}
                items={dropdowns?.[CASE_DETAILS_DROPDOWNS.CHRONICS_CONGENITAL]?.items}
                isDisabled={additionalInfoControlIsDisabled}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { claimType: value }))}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <EditableRadioGroupControl
                value={cancer}
                propertyName={'cancer'}
                label={'CANCER'}
                items={dropdowns?.[CASE_DETAILS_DROPDOWNS.CANCER]?.items}
                isDisabled={additionalInfoControlIsDisabled}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { cancer: value }))}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <MultipleIC10CodesDropdownControl
                value={icd10code}
                // propertyName={'diagnostics'}
                label={'ICD 10 CODE'}
                isDisabled={additionalInfoControlIsDisabled}
                variant={'outlined'}
                onChange={(value) => {
                  dispatch(updateSelectedGop('additionalInformation', { icd10code: value }))
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <HealthCareStatsComponent
                statsValue={healthcareStats1}
                label={'HEALTHCARE STATS 1'}
                propertyName={'healthcareStats1'}
                isDisabled={additionalInfoControlIsDisabled}
                statsItemA={dropdowns?.[CASE_DETAILS_DROPDOWNS.HEALTHCARE_STATS1]?.items}
                unfilteredItems={dropdowns?.[CASE_DETAILS_DROPDOWNS.HEALTHCARE_STATS1]?.unfilteredItems}
                onChange={(value) => {
                  console.log('additionalInformation healthcareStats1 - ', value)
                  dispatch(updateSelectedGop('additionalInformation', { healthcareStats1: value }))
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <HealthCareStatsComponent
                statsValue={healthcareStats2}
                label={'HEALTHCARE STATS 2'}
                propertyName={'healthcareStats2'}
                isDisabled={additionalInfoControlIsDisabled}
                statsItemA={dropdowns?.[CASE_DETAILS_DROPDOWNS.HEALTHCARE_STATS1]?.items}
                unfilteredItems={dropdowns?.[CASE_DETAILS_DROPDOWNS.HEALTHCARE_STATS1]?.unfilteredItems}
                onChange={(value) => {
                  console.log('additionalInformation healthcareStats2 - ', value)
                  dispatch(updateSelectedGop('additionalInformation', { healthcareStats2: value }))
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <HealthCareStatsComponent
                statsValue={healthcareStats3}
                label={'HEALTHCARE STATS 3'}
                propertyName={'healthcareStats3'}
                isDisabled={additionalInfoControlIsDisabled}
                statsItemA={dropdowns?.[CASE_DETAILS_DROPDOWNS.HEALTHCARE_STATS1]?.items}
                unfilteredItems={dropdowns?.[CASE_DETAILS_DROPDOWNS.HEALTHCARE_STATS1]?.unfilteredItems}
                onChange={(value) => {
                  console.log('additionalInformation healthcareStats3 - ', value)
                  dispatch(updateSelectedGop('additionalInformation', { healthcareStats3: value }))
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <StyledTextField label='DETAILS LINE 1' maxLength={40} value={claimDetails1} disabled={additionalInfoControlIsDisabled}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { claimDetails1: value }))} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <StyledTextField label='DETAILS LINE 2' maxLength={40} value={claimDetails2} disabled={additionalInfoControlIsDisabled}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { claimDetails2: value }))} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <StyledTextField label='DETAILS LINE 3' maxLength={40} value={claimDetails3} disabled={additionalInfoControlIsDisabled}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { claimDetails3: value }))} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <StyledTextField label='DETAILS LINE 4' maxLength={40} value={claimDetails4} disabled={additionalInfoControlIsDisabled}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { claimDetails4: value }))} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <StyledTextField label='DETAILS LINE 5' maxLength={40} value={claimDetails5} disabled={additionalInfoControlIsDisabled}
                onChange={(value) => dispatch(updateSelectedGop('additionalInformation', { claimDetails5: value }))} />
            </Grid>
          </Grid>
        </Box>
      }
      {/* END Additional information */}

      {show.includes(GOP_BTN.SAVE_CASE_DETAILS) && a_caseDetailsUnsavedChangesExist
        &&
        <Button color='secondary' variant="text" startIcon={<SaveIcon />}
          sx={{ fontWeight: "bold", fontSize: '14px', mr: 2 }}
          onClick={() => { dispatch(saveEditGop()) }}
        >
          Save
        </Button>}
      {show.includes(GOP_BTN.CANCEL_CASE_DETAILS) && a_caseDetailsUnsavedChangesExist &&
        <Button color='secondary' variant="text" startIcon={<CancelIcon />}
          sx={{
            fontWeight: "bold",
            fontSize: '14px',
          }}
          onClick={() => { dispatch(resetSelectedGop(gop_id)) }}
        >
          Cancel
        </Button>}
      {/* Display msg for expired policy when gop in state payment assigned*/}
      {show.includes(GOP_TITLE.POLICY_EXPIRED_MESSAGE_BOX) &&
        <Typography mb={1} sx={{ color: '#d32f2f', fontWeight: 'bold' }}>This GOP has been created on an expired policy. Please contact Claim Handler.</Typography>
      }
      {show.includes(CASE_DETAILS_DROPDOWNS.CASE_DETAILS_CASE_STATUS) && !a_caseDetailsUnsavedChangesExist && <FormControl
        variant="outlined"
        sx={{ minWidth: 240, m: 2 }}>
        <InputLabel id="simple-select-outlined-label">CASE STATUS</InputLabel>
        <Select
          labelId="simple-select-outlined-label"
          id="simple-select-outlined"
          value={caseStatus}
          onChange={(event) => {
            setCaseStatus(event.target.value)
            if (editable_to?.[event?.target?.value?.state]?.includes?.(REMARKS.GOP_VETTING)) {
              onOpenRemarksGopVettingModal()
            } else {
              onOpenConfirmModal()
            }
          }}
          label={'CASE STATUS'}
          variant={'outlined'}
        >
          {/* {loading && <Box  display='flex' flex={1} justifyContent='center'> <CircularProgress /> </Box>} */}
          {!!a_dropdown_case_statuses && a_dropdown_case_statuses?.length > 0 && a_dropdown_case_statuses.map((item, key) =>
            <MenuItem key={key} value={item}>{item.action_title}</MenuItem>
          )}
        </Select>
      </FormControl>}
      {show.includes(VIEW_CONFIG.GOP_BTN.ASSIGN_TO_ME) && <Button
        variant='contained'
        color='secondary'
        sx={{ m: 2 }}
        onClick={() => {
          dispatch(assignToMeGop({ type, navigate }))
        }}>
        assign to me
      </Button>}
      {show.includes(GOP_CASE_DETAILS_TEXT_FIELDS.GOP_CASE_DETAILS_TEXT_FIELD_CLAIMNUM) && <Box>
        <Typography>Claim number: {claimNum}</Typography>
      </Box>}
      {show.includes(GOP_CASE_DETAILS_TEXT_FIELDS.GOP_CASE_DETAILS_TEXT_FIELD_SETNO) && <Box>
        <Typography>Setno: {setno}</Typography>
      </Box>}
      {/* {(show.includes(REMARKS.GOP_VETTING) || editable.includes(REMARKS.GOP_VETTING)) && <VettingRemarks remarksSectionId={gop_vetting_remarks_section_id} />} */}
      {/* Modal ADD ANOTHER BILL */}
      {!!openModalAddAnotherBill && <AddAnotherBillModal
        open={openModalAddAnotherBill}
        handleOpen={(boolean) => dispatch(setOpenModalAddAnotherBill(boolean))}
      />}
      <RemarksGopVettingModal
        open={isRemarksGopVettingModalOpen}
        handleClickBtnCancel={onCloseRemarksGopVettingModal}
        remarksSectionId={gop_vetting_remarks_section_id}
        handleClickBtnConfirm={onConfirmRemarksGopVettingModal}
      />
      <ConfirmModal
        open={isConfirmModalOpen}
        handleClickBtnCancel={onCloseConfirmModal}
        handleClickBtnConfirm={onConfirmModal}
        type={'CONFIRM_GOP_STATUS_CHANGE'}
        actionTitle={caseStatus.action_title}
        confirmation_msg={(show?.includes(GOP_TITLE.POLICY_EXPIRED) && ['GOP_APPROVED', 'GOP_APPROVED_WITH_CONDITIONS'].includes(caseStatus?.state)) ? state_to_confirmation_msg?.[caseStatus?.state]?.join('') : caseStatus?.confirmation_msg}
      />

    </Box>
  );
};

export default CaseDetailsTab;
