import React, { useState } from 'react';
import {
  Box, Button, Menu,
  MenuItem, 
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { getChatsForGop, setGopChats } from '../redux/actions/gop.actions';
import { GOP_BTN } from '../constants/viewConfig';
// import commandHandler from "../../../../utils/commandHandler";
import { publishSubject } from "../utils/connectNats";
import { iframeRef, setIFrameOpen } from './QueryFrame';


const QueryButton = () => {
  const dispatch = useDispatch();
  const selectedGop = useSelector(state => state.gop?.selectedGop)
  const gop_id = useSelector(state => state.gop?.selectedGop?.keys?.gop_id)
  const chatsForGop = useSelector(state => state.gop?.chatsForGop) || [];
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const token = useSelector(state => state.auth?.token);


  return selectedGop?.value?.a_viewConfig?.show?.includes?.(GOP_BTN.QUERY_ACTION_LIST) ? (
    <Box>
      <Button color='secondary' variant='contained' onClick={(event) => {
        dispatch(setGopChats([]))
        if (gop_id) {
          dispatch(getChatsForGop({gop_id}))
        }
        setAnchorEl(event.currentTarget)
      }}>
        Query
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setIFrameOpen(true)
            publishSubject(`${process.env.REACT_APP_ENV}.SWAN.CP.Q_CLIENT_PUB.createChat`,
              { token, body: { gop_id, chat_type: "GOP_CHAT", title: gop_id + ' chat' }})} }
        >CREATE CHAT</MenuItem>
        {chatsForGop?.length > 0 && chatsForGop?.map?.(
          chat => 
            <MenuItem
              key={chat?.keys?.chat_id}
              onClick={() => {
                setIFrameOpen(true);
                iframeRef?.current?.contentWindow?.postMessage?.({
                  type: 'OPEN',
                  data: chat
                }, '*')
                setAnchorEl(null);

              }}
            >{`${chat?.value?.a_staticTitle} ${chat?.value?.subject}`}</MenuItem>
        )}
      </Menu>
    </Box>) : (<></>)
};

export default QueryButton;