import React from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  CircularProgress
} from '@mui/material';



const Dropdown = ({ options, onChange, value, label, loading = false, variant = 'outlined', sx = {} }) => {

  return (
    <FormControl
      variant="outlined"
      sx={{ minWidth: 240, ...sx }}>
      <InputLabel id="simple-select-outlined-label">{label}</InputLabel>
      <Select
        labelId="simple-select-outlined-label"
        id="simple-select-outlined"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        label={label}
        variant={variant}
      >
        {loading && <Box  display='flex' flex={1} justifyContent='center'> <CircularProgress /> </Box>}
        {!loading && !!options && !!options.length && options.length > 0 && options.map((item, key) => 
          <MenuItem key={key} value={item}>{item}</MenuItem>
        )}
      </Select>
    </FormControl>
  )
};

export default Dropdown;
