import React, { useState, useEffect } from 'react';
import {
  Box, Button, Typography, TextField
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createRoleForEntity } from '../../redux/actions/entities.actions';
import { DrawerContainer, DropdownWithValue, IMSDrawer, PageBodyContainer, PageContainer } from '../../shared'
import { getProviderRoles, resetProviderPermissionRole, updateProviderPermissionRole } from '../../redux/actions/auth.actions';
import BoxWithPermissions from '../CompanyDetails/components/CompanyPermissions/BoxWithPermissions';
import { Controller, useForm } from 'react-hook-form';
import { getRulesValidation, getRulesValidationErrorMsg } from '../../utils/onChangeFunction';


const ProviderPermissions = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.auth.user)
  const roles = useSelector(state => state.auth.providerRoles)
  let [isDisplayRoleControl, setIsDisplayRoleControl] = useState(false);
  let [selectedRole, setSelectedRole] = useState({});
  let [selectedPermissions, setSelectedPermissions] = useState([]);

  const { handleSubmit, control, reset, formState: { isValid } } = useForm({
    defaultValues: {
      role: ''
    },
    mode: 'all'
  });

  useEffect(() => {
    if (authUser && authUser?.a_role_names?.includes("IMS ADMIN")) {
      dispatch(getProviderRoles({ entityType: authUser?.owner_type, entityID: authUser?.owner_id }))
    }
  }, [authUser])

  useEffect(() => {
    if (!!roles?.length) {
      console.log('selectedRole', selectedRole);
      setSelectedPermissions(!!selectedRole?.value?.permissions?.value?.permissions ? selectedRole?.value?.permissions?.value?.permissions : []);
      setSelectedRole(roles?.find((it) => { return it?.keys?.role_id === selectedRole?.keys?.role_id }))
    }
  }, [selectedRole, roles])

  const submitAddRoleName = (formData) => {
    console.log("submitAddRoleName: ", formData)
    dispatch(createRoleForEntity({
      ...formData,
      entityType: authUser?.owner_type,
      entityID: authUser?.owner_id
    }))
    reset({ role: '' }, {})
    setTimeout(() => dispatch(getProviderRoles({ entityType: authUser?.owner_type, entityID: authUser?.owner_id }), [1500]))
  }

  const handleSetNewPermissions = ({ allocatedIndex, actionIndex, type }) => {
    const newArrPermissions = [...selectedPermissions];
    if (newArrPermissions?.[allocatedIndex]?.actions?.[actionIndex]) {
      newArrPermissions[allocatedIndex].actions[actionIndex].effect = type
    }
    setSelectedPermissions(newArrPermissions)
  }

  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={authUser?.owner_type === 'provider' ? 'Inpatient Management System' : 'Clinic Portal'}
        // description='Inpatient Management System'
        underline={true}
      // isBack={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Box sx={{
          padding: "35px",

        }}>
          <Box >
            <Typography sx={{
              color: "#053ad3",
              fontSize: "48px"
            }}>Permissions and Rules</Typography>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}></Box>
          <Box sx={{
            display: "flex",
            flexDirection: 'column',
            flex: 1,
          }}>
            <Box sx={{ display: "flex", flex: 1, flexDirection: 'column' }}>
              {/* User roles */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                marginX: 1,
                mb: 2,
                justifyContent: 'space-between',
                flexWrap: 'wrap'
              }}>
                <Box display='flex' flexDirection='row' flexWrap='wrap' alignItems='center'>
                  <Typography sx={{
                    my: 1,
                    mx: 4,
                    width: 'fit-content',
                    fontSize: "16px",
                    fontWeight: "bold",
                    textTransform: 'uppercase'
                  }}>User Roles</Typography>
                  <Box display='flex' flexDirection='row' mr={3} alignItems='center'>
                    <DropdownWithValue
                      options={roles}
                      onChange={setSelectedRole}
                      value={selectedRole}
                      label='Select Role'
                      sx={{ mx: 4, my: 1 }}
                    />
                    <Button
                      color='secondary' variant='contained'
                      sx={{ m: 1, height: 'fit-content' }}
                      onClick={() => { dispatch(updateProviderPermissionRole({ selectedRole, newPermissions: selectedPermissions, entityType: authUser?.owner_type, entityID: authUser?.owner_id })) }}
                    >Save</Button>
                    <Button
                      color='secondary' variant='contained'
                      sx={{ m: 1, height: 'fit-content' }}
                      onClick={() => { dispatch(resetProviderPermissionRole({ selectedRole, entityType: authUser?.owner_type, entityID: authUser?.owner_id })) }}
                    >Reset role</Button>
                  </Box>
                </Box>
                <Box mx={3} sx={{ flex: 1, display: 'flex' }} justifyContent='center' flexDirection='column' >
                  {!isDisplayRoleControl &&
                    <Button
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={() => setIsDisplayRoleControl(true)}
                      sx={{
                        color: "#eb0781",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}>
                      Add new user role
                    </Button>
                  }
                  {isDisplayRoleControl &&
                    <>
                      <Box sx={{ flex: 1 }}>
                        <Controller
                          name={'role'}
                          control={control}
                          rules={{ validate: { ...getRulesValidation('NAME_VALIDATION') } }}
                          render={({
                            field: { onChange, value },
                            fieldState: { invalid, error },
                          }) => {
                            return (<TextField
                              fullWidth
                              value={value}
                              variant="filled"
                              label="New User Role"
                              placeholder="Add new user role"
                              onChange={onChange}
                              error={!!invalid}
                              helperText={error ? (error?.message ? error.message : getRulesValidationErrorMsg('NAME_VALIDATION', error?.type)) : null}
                            />)
                          }}
                        />
                      </Box>
                      <Box>
                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={handleSubmit(submitAddRoleName)}
                          disabled={!isValid}
                          sx={{
                            color: "#eb0781",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}>
                          Add
                        </Button>
                        <Button startIcon={<HighlightOffIcon />}
                          onClick={() => {
                            reset({ role: '' })
                            setIsDisplayRoleControl(false)
                          }}
                          sx={{
                            color: "#4cccdb",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                          }}>
                          Cancel
                        </Button>
                      </Box>
                    </>
                  }
                </Box>
              </Box>
              {/* Permissions Boxes */}
              <Box display='flex' flexWrap='wrap' flexDirection='row' pt={3} borderTop={1} borderColor='rgba(0, 0, 0, 0.12)'>
                <BoxWithPermissions key={'denyBox'} effect={'deny'} title={'Denied'} boxType={'deny'}
                  permissions={selectedPermissions}
                  handleSetNewPermissions={handleSetNewPermissions}
                />
                <BoxWithPermissions key={'unallocatedBox'} effect={''} title={'Unallocated'} boxType={'unallocated'}
                  permissions={selectedPermissions}
                  handleSetNewPermissions={handleSetNewPermissions}
                />
                <BoxWithPermissions key={'allowBox'} effect={'allow'} title={'Allowed'} boxType={'allow'}
                  permissions={selectedPermissions}
                  handleSetNewPermissions={handleSetNewPermissions}
                />
              </Box>
            </Box>
          </Box >
        </Box >
      </PageBodyContainer>
    </PageContainer>
  );
};

export default ProviderPermissions;