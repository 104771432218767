import React from "react";
import { Box, Typography, Grid } from "@mui/material";

const PolicyInpatientCatastropheData = ({ sectionData }) => {

  return (
    <Grid container >
      <Grid item xs={12} sm={12}>
        <Typography color='secondary' mt={2} mb={1} sx={{ fontFamily: 'Globersemibold' }}>{sectionData.title}</Typography>
      </Grid>
      {sectionData.items.map((item, index) => (
        <Grid mb={1} container key={'row-' + index.toString()}>
          <Grid item xs={12} sm={4}>
            <Typography sx={{ fontSize: '14px', fontFamily: 'Globersemibold' }}>{item.title}</Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography sx={{ fontSize: '14px', fontFamily: 'Globersemibold' }}>{item.value}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default PolicyInpatientCatastropheData;