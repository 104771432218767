import React, { useState, useEffect } from "react";
import {
    Box, Button, IconButton, Typography, Paper, TableContainer, Table, TableHead, TableRow, TableCell,
    TableBody, TableFooter, TablePagination, FormControl, InputLabel, Select, MenuItem, CircularProgress, Grid
} from "@mui/material";
import FileSaver from "file-saver";
import { PageContainer, DrawerContainer, PageBodyContainer, IMSDrawer, ButtonAddDocument } from "../../shared";
import { useNavigate, useLocation } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector } from "react-redux";
import { setLoadingUploadDocument } from "../../redux/actions/view.actions";
import { getBlob, getDownloadMasterFileLink } from "../../utils/upload";
import { UploadPricelistModal } from "./components";
import {  PRICELIST_BUTTONS, PRICELIST_DROPDOWNS, PRICELIST_WB_STATUS } from "../../constants/viewConfig";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { createPricelistFile, getAllPricelists, deletePricelist,
    exctractPricelist, deleteExtractedData, deactivateReactivatePricelist, setPricelistDraft, setSavePricelistModalOpen } from "../../redux/actions/pricelist.actions";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

const PricelistPage = () => {
  const dispatch = useDispatch();
  const savePricelistModalOpen = useSelector((state) => state?.documents?.savePricelistModalOpen);
  const allPricelists = useSelector((state) => state?.documents?.allPricelists) || [];
  const getPricelistsLoading = useSelector((state) => state?.documents?.getPricelistsLoading) || false;


  const user = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

const viewConfig = useSelector((state) => state.view.mainViewConfig);
  // const [itemDocuments, setItemDocuments] = useState([]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUploadAcceptedFiles = (files) => {
    if (files.length === 1) {
      dispatch(setLoadingUploadDocument(true))
      dispatch(createPricelistFile({file: files[0]}))
    }
  }

  const handleDownloadFile = async (item) => {
    let link = await getDownloadMasterFileLink(item.keys.master_file_id, item.value.s3_uuid)
    let blob = await getBlob(link)
    FileSaver.saveAs(blob, item.value.filename)
  }


  const RenderPricelist= ({ item, index }) => {
      const {
          sheets = [],
          wb_id = '',
          a_status_title = '',
          date_from = '',
          date_to = '',
          filename = '',
          status = '',
          clinic = '',
          a_viewConfig: { show = [], dropdowns = {} } = {},
          viewTemplates = [],
          extrTemplates = [],
          updated_by: { name = '' } = {}
    } = item || {};
    const [chosenExtrTemplates, setChosenExtrTemplates] = useState({});


      return (
       <Box pt={3} pb={3} >
           <Table>
               <TableBody>
                   <TableRow>
                       <TableCell sx={{ width: '80%', fontWeight: 'bold' }} align="left">
                           <Typography sx={{fontSize: "20px", fontWeight: 'bold'}}>{filename}</Typography>
                           <Box display={'flex'} >
                               <Box >
                                   <Typography sx={{ fontSize: '16px', fontFamily: 'Globersemibold', textTransform: 'uppercase', height: 'fit-content', alignSelf: 'center' }}>{a_status_title}</Typography>
                                   <Typography >{`${clinic} ${date_from} - ${date_to}`}</Typography>
                               </Box>

                               <Box pl={1} sx={{alignSelf: 'flex-end'}}>
                                   <Button
                                       onClick={() => {
                                           dispatch(setPricelistDraft(item))
                                           dispatch(setSavePricelistModalOpen(true))
                                       }}
                                       sx={{
                                           color: "#eb0781",
                                           p: '1px',
                                           '& .MuiSvgIcon-root': {
                                               fontSize: '20px',
                                           },
                                       }}><EditIcon /></Button>
                               </Box>

                           </Box>

                       </TableCell>
                       <TableCell sx={{ width: '20%', }} align="right">
                           <Box >
                               {show.includes(PRICELIST_BUTTONS.EXTRACT) && <Button
                                   // startIcon={<AddIcon />}
                                   variant='text'
                                   color='primary'
                                   disabled={getPricelistsLoading}
                                   sx={{ fontSize: "14px", fontWeight: 'bold' }}
                                   onClick={() => {
                                       dispatch(exctractPricelist({wb_id, chosenExtrTemplates}))
                                   }}>
                                   EXTRACT
                               </Button>}
                               {show.includes(PRICELIST_BUTTONS.DEACTIVATE) && <Button
                                   // startIcon={<AddIcon />}
                                   variant='text'
                                   color='primary'
                                   disabled={getPricelistsLoading}
                                   sx={{ fontSize: "14px", fontWeight: 'bold' }}
                                   onClick={() => {
                                       dispatch(deactivateReactivatePricelist({wb_id, stateTo: PRICELIST_WB_STATUS.INACTIVE }))
                                   }}>
                                   DEACTIVATE
                               </Button>}
                               {show.includes(PRICELIST_BUTTONS.REACTIVATE) && <Button
                                   // startIcon={<AddIcon />}
                                   variant='text'
                                   color='primary'
                                   disabled={getPricelistsLoading}
                                   sx={{ fontSize: "14px", fontWeight: 'bold' }}
                                   onClick={() => {
                                       dispatch(deactivateReactivatePricelist({wb_id, stateTo: PRICELIST_WB_STATUS.EXTRACTED_SUCCESSFULLY }))
                                   }}>
                                   ACTIVATE
                               </Button>}
                               {show.includes(PRICELIST_BUTTONS.DELETE_XLS) && <Button
                                   // startIcon={<AddIcon />}
                                   variant='text'
                                   color='secondary'
                                   disabled={getPricelistsLoading}
                                   sx={{ fontSize: "14px", fontWeight: 'bold' }}
                                   onClick={() => {
                                       dispatch(deletePricelist({wb_id}))
                                   }}>
                                   DELETE XLS
                               </Button>}
                               {show.includes(PRICELIST_BUTTONS.DELETE_EXTRACTED_DATA) && <Button
                                   // startIcon={<AddIcon />}
                                   variant='text'
                                   color='secondary'
                                   disabled={getPricelistsLoading}
                                   sx={{ fontSize: "14px", fontWeight: 'bold' }}
                                   onClick={() => {
                                       dispatch(deleteExtractedData({wb_id}))
                                   }}>
                                   DELETE DATA
                               </Button>}</Box>
                       </TableCell>
                   </TableRow>
               </TableBody>


           </Table>
         <TableContainer component={Paper}>
         <Table>
               <TableHead>
                 <TableRow>
                   <TableCell sx={{ width: '30%', fontWeight: 'bold' }} align="left">Sheet name</TableCell>
                   <TableCell sx={{ width: '15%', fontWeight: 'bold' }} align="left">Status</TableCell>
                   <TableCell sx={{ width: '15%',  fontWeight: 'bold' }} align="left">Extraction template</TableCell>
                 </TableRow>
               </TableHead>
             <TableBody>
                 {sheets.map((sheet, index) => { return (
                     <TableRow key={index.toString()+sheet?.sheet_name}>
                         <TableCell>
                             {sheet.sheet_name}
                         </TableCell>
                         <TableCell align="left">
                             {sheet.a_status_title}
                         </TableCell>

                         <TableCell style={{ width: 160 }} align="left">
                             {show.includes(PRICELIST_DROPDOWNS.EXTRACTION_TEMPLATE) && <FormControl
                                 size="small"
                                 variant="outlined"
                                 sx={{ minWidth: 210}}>
                                 <InputLabel id={`pricelist-extraction-template-${index}`}>Choose Template</InputLabel>
                                 <Select
                                     labelId={`pricelist-extraction-template-${index}`}
                                     id={`pricelist-extraction-template-${index}`}
                                     value={chosenExtrTemplates[sheet?.sheet_id] || ''}
                                     onChange={(event) => {
                                         setChosenExtrTemplates({...chosenExtrTemplates, [sheet?.sheet_id]: event.target.value})
                                     }}
                                     label={'View Template'}
                                     variant={'outlined'}
                                 >
                                     {/* {loading && <Box  display='flex' flex={1} justifyContent='center'> <CircularProgress /> </Box>} */}
                                     {dropdowns?.EXTRACTION_TEMPLATES?.items?.map((item, index) => (
                                         <MenuItem key={index.toString()+item} value={item}>{item}</MenuItem>
                                     ))}
                                 </Select>
                             </FormControl>}
                             {!show.includes(PRICELIST_DROPDOWNS.EXTRACTION_TEMPLATE) && sheet.extr_template_id}
                         </TableCell>
                     </TableRow>

                 )})}
             </TableBody>

          </Table>
       </TableContainer>

       </Box>




    )
  }


  useEffect(() => {
    dispatch(getAllPricelists());
  }, [])

    useEffect(() => {
        console.log('allPricelists', allPricelists);
    }, [allPricelists])


  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={
          user.owner_type === "provider"
            ? "Inpatient Management System"
            : "Clinic Portal"
        }
        underline={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Box sx={{ padding: "35px", paddingTop: "10px" }}>
          <Typography sx={{ color: "#053ad3", fontSize: "48px", pr: 8 }}>
            Price lists
          </Typography>

          <Box>
            { <ButtonAddDocument handleUploadAcceptedFiles={handleUploadAcceptedFiles} />}
            <Box>
              {getPricelistsLoading && <Box sx={{align: 'center'}} ><CircularProgress  size={'25px'}/></Box>}
              {allPricelists.map((item, index) => {
                return (<RenderPricelist key={item.wb_id} item={item} index={index}></RenderPricelist>)
              })}

            </Box>
          </Box>
        </Box>
      </PageBodyContainer>
      <UploadPricelistModal open={savePricelistModalOpen} />
    </PageContainer>
  );
};

export default PricelistPage;
