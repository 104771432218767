import React from "react";
import {
  Box,
  Button
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useDropzone } from 'react-dropzone';
import { addMessageToSnackbar, setLoadingUploadDocument } from '../redux/actions/view.actions'
import { LoadingButton } from "@mui/lab";

const ButtonAddDocument = ({ handleUploadAcceptedFiles }) => {
  let loadingUploadDocument = useSelector(state => state.view?.loading?.uploadDocument);
  const dispatch = useDispatch();
  const { getInputProps, open } = useDropzone({
    accept: 'application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    multiple: true,
    onDrop: (acceptedFiles) => {
      // console.log("acceptedFiles - ", acceptedFiles);
      if (acceptedFiles.length) {
        dispatch(setLoadingUploadDocument(true))
        handleUploadAcceptedFiles(acceptedFiles);
      }
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map(({ file, errors }) => {
        console.log("file - ", file);
        console.log("error - ", errors);
        const msg = `${file.name} upload error. ${errors[0].message}`;
        dispatch(setLoadingUploadDocument(false))
        dispatch(addMessageToSnackbar(msg, 'error'))
      })
    }
  });

  return (
    <Box>
      <input {...getInputProps()} />
      <LoadingButton color='secondary'
        startIcon={<AddCircleOutlineIcon />}
        loading={loadingUploadDocument}
        loadingPosition="start"
        onClick={open}
        sx={{
          fontWeight: "bold",
          fontSize: "14px"
        }}>
        Add new Document here
      </LoadingButton>
    </Box>
  )
};

export default ButtonAddDocument