import React, { useState } from "react";
import { Box, Button, Dialog, Typography, TextField } from "@mui/material";
import { saveRemarkPromise } from "../../../../../../redux/actions/gop.actions";
import { addMessageToSnackbar } from "../../../../../../redux/actions/view.actions";
import { useDispatch } from "react-redux";


const StyledTextField = ({
  value = '', label = '', disabled = true, maxLength = '', onChange = () => {
  }
}) => {
  return (
    <TextField
      onChange={(event) => onChange(event.target.value)}
      disabled={disabled}
      label={label}
      value={value}
      fullWidth
      inputProps={{ maxLength: maxLength }}
      // sx={{ m: 2 }}
      variant="filled"
      multiline
    />
  )
}

const RemarksGopVettingModal = ({ open = false, handleClickBtnCancel = () => {}, handleClickBtnConfirm = () => {}, remarksSectionId = '' }) => {
  const dispatch = useDispatch()
  const [remark, setRemarks] = useState('');
  
  return (
    <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleClickBtnCancel}>
      <Box pl={4} pr={4} pt={5} pb={5}>
        <Box>
          <Typography color='secondary' sx={{
            fontSize: 24,
            fontWeight: 'bold',
            textAlign: 'center'
          }}>
            Enter Remarks
          </Typography>
        </Box>
        <StyledTextField
          label={'Remarks'}
          value={remark}
          disabled={false}
          onChange={setRemarks}
        />
        <Box pt={5} display='flex' justifyContent='space-around'>
          <Button
            color='secondary'
            sx={{
              fontWeight: "bold",
              fontSize: '14px',
              width: '100px'
            }}
            onClick={() => {
              setRemarks('')
              handleClickBtnCancel()
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            color='info'
            sx={{
              fontWeight: "bold",
              fontSize: '14px',
              color: '#fff',
              width: '100px'
            }}
            onClick={async () => {
              try {
                if (remark && remarksSectionId) {
                  await saveRemarkPromise({ remarks_section_id: remarksSectionId, text: remark })
                  setRemarks('')
                  handleClickBtnConfirm()
                } else {
                  dispatch(addMessageToSnackbar('Enter remarks', 'info'))
                }
              } catch (e) {

              }
            }}
            variant="contained"
          >
           Confirm 
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
};

export default RemarksGopVettingModal
