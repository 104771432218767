import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DrawerContainer, IMSDrawer, PageBodyContainer, PageContainer } from '../../shared';
import { getLCases, setLoadingClosedLCases, setLoadingOpenLCases, updateLCases } from '../../redux/actions/l1l3cases.actions';
import deepCopy from '../../utils/deepCopy';
import { L_GOP_DROPDOWNS } from '../../constants/viewConfig';
import { store } from '../../App'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ConfirmModal from "../../shared/ConfirmModal";
import { setSelectedGop } from '../../redux/actions/gop.actions';



const getAllSelectedClaims = (groups) => {
  const resultArr = [];
  groups.forEach(group => group.items.forEach(claim => {
    if (claim.selected) {
      resultArr.push(claim);
    }
  }))
  // console.log('resultArr', resultArr);
  return resultArr
}



const createGroupingClaims = (claims) => {
  const groupNames = [... new Set(claims.map(item => item.Payee))];
  const claimsGrouped = groupNames.map(groupName => ({ Payee: groupName, groupOpen: false, items: deepCopy(claims).filter(item => item.Payee === groupName) }))

  claimsGrouped.forEach(item => {
    item.items.forEach(claim => {
      if (claim.canUpdate) {
        if (!item.selected) {
          item.canUpdate = true;
          item.selected = true;
        }
        claim.selected = true;
      }
    })
  })
  console.log('createGroupingClaims', claimsGrouped)
  return claimsGrouped;
}






const L1L3Cases = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { type } = useParams();
  const user = useSelector(state => state.auth.user)
  const loadingOpenCasesL = useSelector(state => state.l1l3Cases.loadingOpenCasesL)
  const loadingClosedCasesL = useSelector(state => state.l1l3Cases.loadingClosedCasesL)
  const {
    dropdowns = {}
  } = useSelector(state => state.view.mainViewConfig)
  const {
    claims: openClaims,
    columns: openColumns,
  } = useSelector(state => state.l1l3Cases.openCasesL)
  const {
    claims: closedClaims,
    columns: closedColumns,
  }  = useSelector(state => state.l1l3Cases.closedCasesL)
  // const navigate = useNavigate();
  const [ allChecked, setAllChecked ] = useState(true);
  const [claimsL, setClaimsL] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [caseStatus, setCaseStatus] = useState('')



  const setGroupOpen = (rowIndex, boolean) => {
    const newAllGops = [...claimsL]

    newAllGops[rowIndex].groupOpen = boolean
    setClaimsL(newAllGops)
  }
  

  const setGroupChecked = (rowIndex, boolean, onlyGroup = false) => {
    const newAllGops = [...claimsL]

    if (newAllGops[rowIndex].canUpdate) {
      newAllGops[rowIndex].selected = boolean
      if (!boolean) {
        setAllChecked(false);
      }
      if (!onlyGroup) {
        newAllGops[rowIndex].items.forEach(item => {
          if (item.canUpdate) {
            item.selected = boolean
          }
        })
      }
    }
    setClaimsL(newAllGops)
  }
  const setClaimChecked = (rowIndex, claimIndex, boolean) => {
    const newAllGops = [...claimsL]

    if (newAllGops[rowIndex].items[claimIndex].canUpdate) {
      newAllGops[rowIndex].items[claimIndex].selected = boolean
    }
    setClaimsL(newAllGops)
  }
  


  useEffect(() => {
    dispatch(setLoadingOpenLCases(false));
    dispatch(setLoadingClosedLCases(false));
  }, [])

  useEffect(() => {
    if (type === 'open' && !store.getState().l1l3Cases.loadingOpenCasesL) {
      dispatch(getLCases(type))
    }
    if (type === 'closed' && !store.getState().l1l3Cases.loadingClosedCasesL) {
      dispatch(getLCases(type))
    }

  }, [type])

  useEffect(() => {
    setColumns(type === 'open' ? openColumns : closedColumns)
  }, [type, openColumns, closedColumns])


  useEffect(() => {
    setClaimsL(type === 'open'
      ? createGroupingClaims(openClaims)
      : createGroupingClaims(closedClaims))
  }, [type, closedClaims, openClaims])



  const onOpenConfirmModal = () => {
    setIsConfirmModalOpen(true)
  }

  const onCloseConfirmModal = () => {
    setIsConfirmModalOpen(false)
    setCaseStatus('')
  }

  const onConfirmModal = () => {
    // console.log('updateLCases', caseStatus.value, claimsL.filter((item) => item.selected === true))
    // console.log('updateLCases', caseStatus.value, getAllSelectedClaims(claimsL))
    dispatch(updateLCases({ state: caseStatus.value,  claims: getAllSelectedClaims(claimsL), type }));
    // dispatch(updateLCases(caseStatus.value, claimsL.filter((item) => item.selected === true)));
    setIsConfirmModalOpen(false)
    setCaseStatus('');
  }


  const CaseStatusDropdown = ({ dropdownStatuses }) => {

    return (
      // show.includes(CASE_DETAILS_DROPDOWNS.CASE_DETAILS_CASE_STATUS) &&
      <FormControl
        variant="outlined"
        sx={{ minWidth: 240, m: 2 }}>
        <InputLabel id="simple-select-outlined-label">CASE STATUS</InputLabel>
        <Select
          labelId="simple-select-outlined-label"
          id="simple-select-outlined"
          value={caseStatus}
          defaultValue={''}
          onChange={(event) => {
            // console.log(' case status', event.target.value)
            setCaseStatus(event.target.value)
            onOpenConfirmModal()
          }}
          label={'CASE STATUS'}
          variant={'outlined'}
        >
          {/* {loading && <Box  display='flex' flex={1} justifyContent='center'> <CircularProgress /> </Box>} */}
          {!!dropdownStatuses && dropdownStatuses?.length > 0 && dropdownStatuses.map((item, key) =>
            <MenuItem key={key} value={item}>{item.title}</MenuItem>
          )}
        </Select>
      </FormControl>
    )
  }


  const Header = () => {
    return (
      <>
        {!!columns && columns.map((item, index) =>
          <TableCell padding='checkbox' sx={{color: 'secondary.main', fontFamily: 'Globerbold', pl: 0, pr: 0, pb: 1, textAlign: item.type !== 'checkbox' ?  'left' :'center' }} key={index.toString()}>
            {item.type === 'checkbox' ?
              <Checkbox  checked={allChecked} onChange={(event) => {
                // const newAllGops = [...claimsL]
                claimsL.forEach((item, rowIndex) => { setGroupChecked(rowIndex, event.target.checked) });
                // setClaimsL(newAllGops)
                setAllChecked(event.target.checked)
              }}/> : item.name}
          </TableCell>)}
        <TableCell padding='checkbox' sx={{color: 'secondary.main', fontFamily: 'Globerbold', pl: 0, pr: 0, pb: 1, }}>Amount to be approved</TableCell>
      </>
    )
  }
  

  const Row = ({ row, rowIndex }) => {
    return (
      <>
        <TableRow hover key={rowIndex.toString()}>
          {columns.map(item =>
            <TableCell sx={{ p: 0, textAlign: item.type !== 'checkbox' ?  'left' :'center' }} key={item.name + rowIndex.toString()}>
              {item.type === 'checkbox' && !row.canUpdate && <Box p={1}><CancelRoundedIcon /></Box>}
              {item.type === 'checkbox' && row.canUpdate &&
                <Checkbox disabled={!row.canUpdate} checked={row.selected} onChange={(event) => setGroupChecked(rowIndex, event.target.checked)} />
              }
              <Box display='flex' flexDirection={'row'} alignItems='center'>
                {item.type !== 'checkbox' && item.name === 'Payee' && <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setGroupOpen(rowIndex, !row.groupOpen)}
                >
                  {row.groupOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>}
                {item.type !== 'checkbox' && row[item.name]}
              </Box>
            </TableCell>)}
            <TableCell sx={{ p: 0}} >
              {row.items.filter((item) => item.selected).length > 0
                ? row.items.filter((item) => item.selected).reduce((prev, cur) => prev + cur.Amount, 0)
                : ''
              }
            </TableCell>
        </TableRow>
        {row.groupOpen && row.items.map((claim, claimIndex) => (
          <TableRow key={rowIndex.toString() + claimIndex} hover>
            {columns.map(item =>
              <TableCell sx={{ p: 0, textAlign: item.type !== 'checkbox' ?  'left' : 'right' }} key={item.name + rowIndex + claimIndex.toString()}
                onClick={() => {
                  if (claim.gop && item.type !== 'checkbox') {
                    dispatch(setSelectedGop(claim.gop))
                    navigate(`/cases/${type}`, { state: { type: 'none' } })
                  }
                }}
              >
                {item.type === 'checkbox' && !claim.canUpdate && <Box p={1}><CancelRoundedIcon /></Box>}
                {item.type === 'checkbox' && claim.canUpdate &&
                  <Checkbox disabled={!claim.canUpdate} checked={claim.selected} onChange={(event) => {
                    setClaimChecked(rowIndex, claimIndex, event.target.checked)
                    if (!event.target.checked) {
                      setGroupChecked(rowIndex, event.target.checked, true)
                      setAllChecked(false)
                    }
                  }} />}
                {item.type !== 'checkbox' && item.name !== 'Payee' && claim[item.name]}
              </TableCell>)}
              <TableCell sx={{ p: 0 }} >{claim.selected === true ? claim.Amount : ''}</TableCell>
          </TableRow>
        ))}
      </>
    )
  }





  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={user.owner_type === 'provider' ? 'Inpatient Management System' : 'Clinic Portal'}
        underline={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, padding: "35px" }}>
          {/*<Box display='flex' flexDirection='row' alignItems='center' pl={4} pr={8} pb={3} pt={1}>*/}
          {/*  <Typography sx={{ color: "#053ad3", fontSize: "48px", pr: 8, textTransform: 'capitalize' }}>*/}
          {/*    {type.replace('_', ' ')} Cases*/}
          {/*  </Typography>*/}
          {/*  <TextField*/}
          {/*    id="input-with-icon-textfield"*/}
          {/*    InputProps={{*/}
          {/*      startAdornment: (*/}
          {/*        <InputAdornment position="start">*/}
          {/*          <SearchIcon sx={{ color: "#eb0781" }} />*/}
          {/*        </InputAdornment>*/}
          {/*      ),*/}
          {/*    }}*/}
          {/*    variant="standard"*/}
          {/*  />*/}
          {/*</Box>*/}
          {((type === 'open' && !loadingOpenCasesL)
            || (type === 'closed' && !loadingClosedCasesL))
            && <Box>
            <Box display='flex' flexDirection='row' justifyContent={'flex-end'}>
              {type === 'open' && dropdowns?.hasOwnProperty?.(L_GOP_DROPDOWNS.L_CASE_STATUS_OPEN) &&
                <CaseStatusDropdown dropdownStatuses={dropdowns[L_GOP_DROPDOWNS.L_CASE_STATUS_OPEN].items} />}
              {type === 'closed' && dropdowns.hasOwnProperty(L_GOP_DROPDOWNS.L_CASE_STATUS_CLOSED) &&
                <CaseStatusDropdown dropdownStatuses={dropdowns[L_GOP_DROPDOWNS.L_CASE_STATUS_CLOSED].items} />}
            </Box>
            <Table display='table'>
              <TableHead>
                <TableRow>
                  <Header />
                </TableRow>
              </TableHead>
              <TableBody>
                {claimsL?.map?.((row, index) => ( <Row key={index.toString()} row={row} rowIndex={index} /> ))}
              </TableBody>
            </Table>
          </Box>}

          {/* loading */}
          {((type === 'open' && loadingOpenCasesL)
            || (type === 'closed' && loadingClosedCasesL))
            && <Box display='flex' flex={1} alignItems='center' justifyContent='center'> <CircularProgress /> </Box>}
        </Box>

        <ConfirmModal
          open={isConfirmModalOpen}
          handleClickBtnCancel={onCloseConfirmModal}
          handleClickBtnConfirm={onConfirmModal}
          type={'CONFIRM_GOP_STATUS_CHANGE'}
          actionTitle={caseStatus.title}
        />
      </PageBodyContainer>
    </PageContainer>
  );
};

export default L1L3Cases;
