import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Drawer, IconButton, OutlinedInput, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';

const RemarksDrawer = ({ openRemarksDrawer = false, handleCloseDrawer, remarks_section_id, allRemarks = [], saveRemark, viewConstant }) => {
  const dispatch = useDispatch()
  const a_viewConfig = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig);
  const authUser = useSelector(state => state.auth.user);
  const [remark, setRemark] = useState('');

  return a_viewConfig?.show?.includes?.(viewConstant) ? (
    <Drawer
      PaperProps={{
        sx: { width: "50%" },
      }}
      anchor={'right'}
      open={openRemarksDrawer}
      onClose={handleCloseDrawer}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          flex: '1'
        }}
      >
        <Box display='flex' alignItems='center'
          pl={3}
          pr={3}
          pt={3}
          pb={3}
        >
          <IconButton onClick={handleCloseDrawer}>
            <ArrowForwardIosIcon color={'primary'} />
          </IconButton>
          <Typography
            sx={{
              color: '#002f87',
              fontSize: "24px",
              fontFamily: 'Globersemibold'
            }}>
            External Remarks
          </Typography>
        </Box>

        {/* CONTENT */}
        <Box display='flex' flexDirection='column' justifyContent='space-between'
          sx={{
            height: '100%',
            overflow: 'hidden',
          }}>
          {/* Scroll content */}
          <Box sx={{
            overflowY: 'scroll',
            padding: '5px 24px 0 24px'
          }}>
            {allRemarks?.length > 0 && allRemarks.map((item, index) =>
              <Paper elevation={3}
                key={index.toString()}
                sx={{
                  p: 3,
                  mb: '20px'
                }}>
                <Box display='flex' alignItems='center' mb={2}>
                  <Avatar sx={{ width: 32, height: 32 }}>{`${item?.value?.user?.name}`.slice(0, 1).toUpperCase()}</Avatar>
                  <Typography sx={{ fontWeight: 'bold', ml: 2 }}>{item?.value?.user?.name}</Typography>
                  <Typography sx={{ ml: 2 }}>{moment(item?.value?.a_updated_at_timestamp).fromNow()}</Typography>
                </Box>
                <Typography sx={{ wordBreak: 'break-word', color: "#67727e" }}>{item?.value?.post?.text}</Typography>
              </Paper>)}
          </Box>
          {/* Remark send */}
          {(a_viewConfig?.show?.includes?.(viewConstant) && remarks_section_id) &&
            <Box
              pl={3}
              pr={3}
              pt={3}
              pb={3}
            >
              <Paper elevation={3}
                sx={{
                  padding: '24px',
                  display: 'flex',
                  alignItems: "flex-start"
                }}
              >
                <Avatar sx={{ width: 32, height: 32 }}>{`${authUser?.firstName}`.slice(0, 1).toUpperCase()}</Avatar>
                <OutlinedInput
                  placeholder="Add a comment..."
                  sx={{ ml: 3, mr: 3 }}
                  multiline
                  minRows={3}
                  fullWidth
                  value={remark}
                  onChange={(value) => setRemark(value.target.value)}
                />
                <Button
                  variant='contained'
                  sx={{ backgroundColor: '#002f87', color: '#fff' }}
                  disabled={!remark}
                  onClick={() => {
                    dispatch(saveRemark({ remarks_section_id, text: remark }))
                    setRemark('');
                  }}>Send</Button>
              </Paper>
            </Box>
          }
        </Box>
      </Box>
    </Drawer >
  ) : (null)
}
export default RemarksDrawer