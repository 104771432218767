import React, { useEffect, useState } from 'react';
import {
  Box, Button, Typography, 
  Accordion, AccordionSummary,
  AccordionDetails, Grid,
  IconButton
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';


const BoxWithPermissions = ({ effect = '', title = 'Unallocated', boxType = 'unallocated', permissions=[], handleSetNewPermissions }) => {
  const [allocated, setAllocated] = useState([]);

  useEffect(() => {
    setAllocated(permissions);
  }, [permissions])


  return (
    <Box sx={{
      width: 350,
      marginRight: 1,
      marginLeft: 1
    }}>
      <Typography sx={{
        fontSize: "16px",
        fontWeight: "bold",
        my: 2,
        textTransform: 'uppercase'
      }}>{title}</Typography>

      <Box sx={{
        border: 1,
        borderRadius: "6px",
        minHeight: '100px',
        borderColor: "rgba(0, 0, 0, 0.38)",
      }}>
        {allocated?.map?.((permission, ind) => {
          return permission.actions?.filter?.(( action ) => action.effect === effect).length > 0  ? (
              <Accordion
                  key={ind.toString()}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent:'start',
                    margin:'0 auto'
                  }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      height:'48px', width:'310px', marginTop:'12px',
                      marginBottom:'10px', marginLeft:'19px', borderRadius:'5px',
                      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 22%), 0px 1px 1px 0px rgb(0 0 0 / 16%), 0px 1px 3px 0px rgb(0 0 0 / 14%)'
                    }}>
                  <Button
                      sx={{
                        height:'48px', width:'280px', margin: '0 auto', justifyContent:'start',
                        padding: '18px 18px 12px 16px'}}
                      onClick={() => {}}>
                    <Grid container sx={{display:'flex', justifyContent:'flex-start'}}>
                      <Grid item xs={1}>
                        <DragIndicatorIcon color='secondary' />
                      </Grid>
                      <Grid item xs={10} sx={{display:'flex', justifyContent:'flex-start', marginLeft:'10px'}}>
                        <Typography sx={{fontSize:'14px'}}>
                          {permission.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </AccordionSummary>
                <AccordionDetails color='primary' sx={{ margin: '0 auto', width:'260px', textAlign:'left'}}>
                  {permission.actions?.map?.((action, index) => action.effect === effect ?
                    <Box key={index.toString()} display='flex' flexDirection='row' mb={1}>
                      {boxType === 'unallocated' && <IconButton color='error' onClick={() => handleSetNewPermissions({ allocatedIndex: ind, actionIndex: index, type: 'deny' })}>
                        <ArrowBackIosNewIcon />
                      </IconButton>}
                      <Button
                        sx={{display:'flex', justifyContent:'start', width:'260px',
                          border: 0, height:'36px',
                          boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 22%), 0px 1px 1px 0px rgb(0 0 0 / 16%), 0px 1px 3px 0px rgb(0 0 0 / 14%)'
                        }}
                        color='primary'
                        onClick={boxType === 'unallocated' ? () => {} : () => handleSetNewPermissions({ allocatedIndex: ind, actionIndex: index, type: '' })}
                        key={action.title + index.toString()}
                      >
                        <DragIndicatorIcon color='secondary'/>
                        <Typography sx={{fontSize:'11px'}}>{action.title}</Typography>
                      </Button>
                      {boxType === 'unallocated' && <IconButton color='info' onClick={() => handleSetNewPermissions({ allocatedIndex: ind, actionIndex: index, type: 'allow' })}>
                        <ArrowForwardIosIcon />
                      </IconButton>}
                    </Box>
                  : null).filter((item) => !!item)}
                </AccordionDetails>
              </Accordion>
          ) : (<></>)
        })}
      </Box>
    </Box>
  )
}

export default BoxWithPermissions;