import React, { useEffect, useState } from 'react';
import {
  Box, TextField
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';


const DatePickerComponent = ({ label, value, valueOnChange, dateformat, isDisabled, disableFutureDate = false, readOnly = false, ...restField }) => {
  const [dateValue, setDateValue] = useState()
  const onChange = (date) => {
    console.log("date -", date);
    let newValue = moment(date).format('DD/MM/YYYY');
    valueOnChange(newValue)
    setDateValue(date)
  }
  useEffect(() => {
    if (value) {
      setDateValue(moment(value, 'DD/MM/YYYY'))
    }
    else {
      setDateValue(null)
    }
  }, [value])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={isDisabled}
        disableFuture={disableFutureDate}
        label={label}
        value={dateValue}
        onChange={onChange}
        inputFormat='dd/MM/yyyy'
        renderInput={(params) => <TextField fullWidth {...params} variant='filled' inputProps={{ ...params.inputProps, readOnly: readOnly }} {...restField} />}
      />
    </LocalizationProvider>
  )
}

export default DatePickerComponent