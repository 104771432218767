import {
  SET_ALL_GOP,
  SET_SELECTED_GOP,
  UPDATE_SELECTED_GOP,
  RESET_SELECTED_GOP,
  SET_GET_ONE_GOP_LOADING,
  RESET_GOP_LOADING,
  SET_GOP_SEARCH_RESULT_PATIENT_DETAILS,
  SET_DRAFT_GOP_PATIENT,
  SET_DRAFT_GOP_CASE_ADMISSION,
  SET_DRAFT_GOP_CASE_TREATMENT,
  SET_DRAFT_GOP_CASE_BILLING,
  SET_DRAFT_GOP_ID,
  SET_OPEN_GOPS,
  SET_PENDING_GOPS,
  SET_CLOSED_GOPS,
  RESET_DRAFT_GOP,
  SET_DRAFT_OBJ,
  SET_SELECTED_GOP_DOCS,
  RESET_GOP,
  UPDATED_ONE_AND_SELECTED_GOP,
  SET_OPEN_MODAL_GOP_SUCCESS,
  SET_SELECTED_GOP_POLICY_DETAILS,
  SET_SELECTED_GOP_CLAIMS_HISTORY,
  SET_POSSIBLE_IMS_CLAIM_HANDLERS,
  SET_ASSIGN_TO_GOP_ID,
  SET_OPEN_MODAL_GOP_ASSIGN_TO,
  SET_GOP_DOCS_LENGTH,
  SET_OVERVIEW_STATS,
  SET_OPEN_MODAL_ADD_ANOTHER_BILL,
  SET_POLICY_INPATIENT_CATASTROPHE_DATA,
  SET_POLICY_EXCLUSIONS_DATA,
  SET_POLICY_COVERAGE_TREATMENT_DATA,
  UPDATE_WHERE_WITH_WHAT_BY_GOP_ID,
  RESET_BILL_REMARKS,
  SET_BILL_REMARKS,
  SET_GOP_CHATS,
  RESET_VETTING_REMARKS,
  SET_VETTING_REMARKS,
  SET_DRAFT_BILL_LOADING,
  SET_DRAFT_BILL,
  SET_DRAFT_BILL_DOCS,
  SET_GOP_BILL_DOCS,
  SET_OPEN_MODAL_CONFIRM_UPDATE_BILL,
  SET_MODAL_CONFIRM_UPDATE_BILL
} from '../actionTypes';
import deepCopy from '../../utils/deepCopy';
import { compareTimeStamp } from '../../utils';

const INITIAL_STATE = {
  allGops: [],
  gopsLoading: [],
  gopOpen: [],
  gopPending: [],
  gopClosed: [],
  draftObj: {},
  draftIdGop: '',
  draftGop: {
    patient: {},
    caseDetails: {
      admission: {
        patientId: '',
        date: '',
        time: '',
        lengthOfStay: '',
        roomType: { title: "not chosen", value: '', },
        roomNumber: ''
      },
      treatmentAndDoctor: {
        treatmentType: { value: '', title: "not chosen" },
        diagnostics: '',
        otherDiagnostics: '',
        admittingDoctor: { value: '', title: "not chosen" },
        otherDoctor: { value: '', title: "not chosen" },
        admittingDoctorNotInList: {
          firstName: "", lastName: "", specialty: "", regNo: ""
        },
        otherDoctorNotInList: {
          firstName: "", lastName: "", specialty: "", regNo: ""
        },
        clinicComment: ''
      },
      billing: [{ type: '', amount: '' }]
    }
  },
  overviewStats: {
    allocations: [],
    casesStatus: []
  },
  searchResultPD: [],
  selectedGop: {
    claimsHistory: {
      loadingState: '',
      timestamp: '',
      data: []
    },
    policyInpatientCatastropheInfo: {
      loadingState: '',
      timestamp: '',
      data: []
    },
    policyExclusions: {
      loadingState: '',
      timestamp: '',
      data: []
    },
    policyCoverageTreatment: {
      loadingState: '',
      timestamp: '',
      data: []
    },
    docs: {
      loadingState: '',
      timestamp: '',
      data: []
    },
    policySOB: {
      loadingState: '',
      timestamp: '',
      data: []
    }
  },
  billRemarks: [],
  vettingRemarks: [],
  selectedGopPolicyDetails: {},
  selectedGopClaimsHistory: [],
  selectedGopPolicyInpatientCatastropheInfo: [],
  selectedGopPolicyExclusions: [],
  selectedGopPolicyCoverageTreatment: [],
  selectedGopDocs: [],
  possibleImsClaimHandlers: [],
  assignToGopID: '',
  gopDocsLength: 0,
  chatsForGop: [],
  draftBill: {},
  draftBillDocs: [],
  draftBillLoading: false,
  modals: {
    openModalGOPSuccess: false,
    openModalAssignTo: false,
    openModalAddAnotherBill: false,
    openModalConfirmUpdateBill: false,
    modalConfirmUpdateBill: {}
  }
};


export const gopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALL_GOP:
      return {
        ...state,
        allGops: action.payload,
        selectedGop: !!action.payload?.find?.(item => state?.selectedGop?.keys?.gop_id === item.keys.gop_id)
          ? deepCopy(action.payload?.find?.(item => state?.selectedGop?.keys?.gop_id === item.keys.gop_id))
          : deepCopy(INITIAL_STATE.selectedGop)
      }
    case SET_SELECTED_GOP:
      return { ...state, selectedGop: deepCopy(action.payload), selectedGopPolicyDetails: {}, selectedGopClaimsHistory: [], billRemarks: [], vettingRemarks: [] }
    case UPDATE_SELECTED_GOP:
      const result = { ...state, selectedGop: { ...state.selectedGop } }
      if (!result.selectedGop.value.caseDetails) {
        result.selectedGop.value.caseDetails = {}
      }
      if (!result.selectedGop.value.caseDetails[action.payload.type]) {
        result.selectedGop.value.caseDetails[action.payload.type] = {}
      }
      result.selectedGop.value.caseDetails[action.payload.type] = {
        ...result.selectedGop.value.caseDetails[action.payload.type],
        ...action.payload.data
      }
      if (['additionalInformation', 'admission', 'treatmentAndDoctor', 'billing'].includes(action.payload.type)) {
        result.selectedGop.value.caseDetails.a_caseDetailsUnsavedChangesExist = true
      }
      return result
    case RESET_SELECTED_GOP:
      const gopWithId = state.allGops.find((item) => item.keys.gop_id === action.payload);
      // console.log('gopWithId = ', gopWithId)
      return { ...state, selectedGop: deepCopy(gopWithId) }
    case SET_SELECTED_GOP_DOCS:
      return { ...state, selectedGopDocs: action.payload }
    case SET_DRAFT_BILL_DOCS:
      return { ...state, draftBillDocs: action.payload }
    case SET_SELECTED_GOP_POLICY_DETAILS:
      return { ...state, selectedGopPolicyDetails: action.payload }
    case SET_SELECTED_GOP_CLAIMS_HISTORY:
      return { ...state, selectedGop: { ...state.selectedGop, value: { ...state.selectedGop.value, claimsHistory: action.payload } } }
    case SET_POLICY_INPATIENT_CATASTROPHE_DATA:
      return { ...state, selectedGop: { ...state.selectedGop, value: { ...state.selectedGop.value, policyInpatientCatastropheInfo: action.payload } } }
    case SET_POLICY_EXCLUSIONS_DATA:
      return { ...state, selectedGop: { ...state.selectedGop, value: { ...state.selectedGop.value, policyExclusions: action.payload } } }
    case SET_POLICY_COVERAGE_TREATMENT_DATA:
      return { ...state, selectedGop: { ...state.selectedGop, value: { ...state.selectedGop.value, policyCoverageTreatment: action.payload } } }
    case SET_GOP_SEARCH_RESULT_PATIENT_DETAILS:
      return { ...state, searchResultPD: action.payload }
    case RESET_DRAFT_GOP:
      return { ...state, draftGop: INITIAL_STATE.draftGop, draftIdGop: '' }
    case SET_DRAFT_GOP_ID:
      return { ...state, draftIdGop: action.payload }
    case SET_DRAFT_OBJ:
      return { ...state, draftObj: action.payload }
    case SET_DRAFT_GOP_PATIENT:
      return { ...state, draftGop: { ...state.draftGop, patient: action.payload } }
    case SET_DRAFT_GOP_CASE_ADMISSION:
      return { ...state, draftGop: { ...state.draftGop, caseDetails: { ...state.draftGop.caseDetails, admission: { ...state.draftGop.caseDetails.admission, ...action.payload } } } }
    case SET_DRAFT_GOP_CASE_TREATMENT:
      return { ...state, draftGop: { ...state.draftGop, caseDetails: { ...state.draftGop.caseDetails, treatmentAndDoctor: { ...state.draftGop.caseDetails.treatmentAndDoctor, ...action.payload } } } }
    case SET_DRAFT_GOP_CASE_BILLING:
      const draftNewBilling = [...state.draftGop.caseDetails.billing];
      draftNewBilling[0] = { ...draftNewBilling[0], ...action.payload };
      return { ...state, draftGop: { ...state.draftGop, caseDetails: { ...state.draftGop.caseDetails, billing: draftNewBilling } } }
    case SET_OPEN_GOPS:
      return { ...state, gopOpen: action.payload }
    case SET_ASSIGN_TO_GOP_ID:
      return { ...state, assignToGopID: action.payload }
    case SET_PENDING_GOPS:
      return { ...state, gopPending: action.payload }
    case SET_CLOSED_GOPS:
      return { ...state, gopClosed: action.payload }
    case SET_OPEN_MODAL_GOP_SUCCESS:
      return { ...state, modals: { ...state.modals, openModalGOPSuccess: action.payload } }
    case SET_OPEN_MODAL_GOP_ASSIGN_TO:
      return { ...state, modals: { ...state.modals, openModalAssignTo: action.payload } }
    case SET_POSSIBLE_IMS_CLAIM_HANDLERS:
      return { ...state, possibleImsClaimHandlers: action.payload }
    case SET_GOP_DOCS_LENGTH:
      return { ...state, gopDocsLength: action.payload }
    case SET_OPEN_MODAL_ADD_ANOTHER_BILL:
      return { ...state, modals: { ...state.modals, openModalAddAnotherBill: action.payload } }
    case SET_OPEN_MODAL_CONFIRM_UPDATE_BILL:
      return { ...state, modals: { ...state.modals, openModalConfirmUpdateBill: action.payload } }
    case SET_MODAL_CONFIRM_UPDATE_BILL:
      return { ...state, modals: { ...state.modals, modalConfirmUpdateBill: action.payload } }
    case UPDATED_ONE_AND_SELECTED_GOP:
      const updatedAllGops = [...state.allGops];
      // allGops
      if (action.payload.isDraft) {
        updatedAllGops.push(action.payload.gop)
      } else {
        const indexOfOne = updatedAllGops.findIndex((item) => item.keys.gop_id === action.payload.gop?.keys?.gop_id);
        if (indexOfOne >= 0) {
          updatedAllGops[indexOfOne] = deepCopy(action.payload.gop);
        }
      }

      return {
        ...state,
        selectedGop: action.payload.gop,
        allGops: updatedAllGops
      }
    case UPDATE_WHERE_WITH_WHAT_BY_GOP_ID:
      const { gop_id, where, what } = action.payload;
      const updatedAllGops2 = [...state.allGops]
      const indexOfOne = updatedAllGops2.findIndex((item) => item.keys.gop_id === gop_id);
      let newSelectedGop = state.selectedGop;

      if (indexOfOne >= 0 && compareTimeStamp(updatedAllGops2?.[indexOfOne]?.value?.[where]?.timestamp, what?.timestamp)) {
        updatedAllGops2[indexOfOne].value[where] = deepCopy({ ...updatedAllGops2[indexOfOne].value[where], ...what });
        if (newSelectedGop?.keys?.gop_id === gop_id && newSelectedGop.value) {
          newSelectedGop.value[where] = deepCopy({ ...newSelectedGop.value[where], ...what });
        }
      }
      const newUpdatedDraftObj = { ...state.draftObj }
      if (newUpdatedDraftObj?.keys?.gop_id === gop_id) {
        newUpdatedDraftObj.value[where] = deepCopy({ ...newUpdatedDraftObj.value[where], ...what })
      }


      return {
        ...state,
        selectedGop: newSelectedGop,
        allGops: updatedAllGops2,
        draftObj: newUpdatedDraftObj
      }
    case SET_GET_ONE_GOP_LOADING:
      if (action?.payload?.gop_id) {
        if (action.payload.status === true) {
          return { ...state, gopsLoading: [...state.gopsLoading, action.payload] }
        } else if (action.payload.status === false) {
          return { ...state, gopsLoading: state.gopsLoading.filter(item => item.gop_id !== action.payload.gop_id) }
        }
      }
      return { ...state }
    case RESET_BILL_REMARKS:
      return { ...state, billRemarks: [] }
    case SET_BILL_REMARKS:
      const newBillRemarks = deepCopy(state.billRemarks);
      const billRemarkIndex = newBillRemarks.findIndex(item => item.remarks_section_id === action.payload.remarks_section_id)

      if (billRemarkIndex >= 0) {
        newBillRemarks[billRemarkIndex] = action.payload;
      } else {
        newBillRemarks.push(action.payload);
      }
      return { ...state, billRemarks: newBillRemarks }
    case RESET_VETTING_REMARKS:
      return { ...state, vettingRemarks: [] }
    case SET_VETTING_REMARKS:
      return { ...state, vettingRemarks: action.payload }
    case SET_GOP_CHATS:
      return { ...state, chatsForGop: action.payload }
    case SET_DRAFT_BILL_LOADING:
      return { ...state, draftBillLoading: action.payload }
    case SET_DRAFT_BILL:
      return { ...state, draftBill: action.payload }
    case RESET_GOP_LOADING:
      return { ...state, gopsLoading: INITIAL_STATE.gopsLoading }
    case SET_OVERVIEW_STATS:
      return { ...state, overviewStats: action.payload }
    case SET_GOP_BILL_DOCS: {
      const { docs, gop_id, bill_id } = action.payload;
      if (state.selectedGop?.keys?.gop_id === gop_id) {
        const updatedAllGops2 = [...state.allGops]
        const indexOfOne = updatedAllGops2.findIndex((item) => item?.keys?.gop_id === gop_id);
        let selectedGop = updatedAllGops2[indexOfOne]
        let billing = selectedGop?.value?.caseDetails?.billing;
        if (billing) {
          const billIndex = billing.findIndex(it => it?.bill_id === bill_id)
          const bill = billing[billIndex];
          let docsFilter = docs.filter((it) => it.files.length > 0)
          bill.a_files = docsFilter

          return { ...state, allGops: updatedAllGops2, selectedGop: deepCopy({ ...selectedGop }) }
        }
      }
      return { ...state }
    }
    case RESET_GOP:
      return { ...INITIAL_STATE }
    default:
      return state;
  }
};

