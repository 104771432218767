import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

let iframeRef = null;
let setIFrameOpen = null;

const QueryFrame = ({}) => {
  iframeRef = useRef();
  const token = useSelector(state => state.auth.token);
  const [isFrameVisible, setIsFrameVisible] = useState(false)

  setIFrameOpen = (boolean) => setIsFrameVisible(boolean);
  const eventListenerHandler = (event) => {
    // Do we trust the sender of this message?
    // console.log('event', event)
    // if (event.origin !== "https://dev-swan-query-web.swanforlife.link")
    // return;
    // console.log('event', event)
    if (event?.data?.type === 'INIT') {
      iframeRef?.current?.contentWindow?.postMessage?.({
        type: 'INIT',
        data: token
      }, '*')
    }
    if (event?.data?.type === 'CLOSE') {
      setIsFrameVisible(false);
    }
  }

  useEffect(() => {
    window.addEventListener("message", eventListenerHandler);
    return () => window.removeEventListener('message', eventListenerHandler)
  }, [])

  useEffect(() => {
    if (token && iframeRef) {
      iframeRef?.current?.contentWindow?.postMessage?.({
        type: 'INIT',
        data: token
      }, '*')
    }
    if (!token) {
      iframeRef?.current?.contentWindow?.postMessage?.({
        type: 'RESET'
      }, '*')
    }
  }, [token, iframeRef])

  return (
    <iframe style={{
      position: "fixed",
      bottom: 8,
      left: 8,
      width: '370px',
      height: '500px',
      borderRadius: '25px',
      display: isFrameVisible ? 'block' : 'none',
      borderWidth: 1,
      zIndex: 999
    // }} ref={iframeRef} src={'https://dev-swan-query-web.swanforlife.link'} />
    // }} ref={iframeRef} src={'http://localhost:3002/'} />
    }} ref={iframeRef} src={process.env.REACT_APP_IFRAME_URL} />
  )
};

export { iframeRef, setIFrameOpen };

export default QueryFrame;

