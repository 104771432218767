import React, { useState, useEffect } from 'react';
import {
  Box, Button, Typography,
  TableContainer, Table, TableHead,
  TableRow, TableCell, TableBody,
  InputAdornment, TextField
} from '@mui/material';
// import SortIcon from '@mui/icons-material/Sort';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as SortIcon } from '../../../media/icons/sort.svg';
// import { useNavigate } from 'react-router-dom';
import { AddUserModal } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, setOpenModalUser, userActivityDoAction } from '../../../redux/actions/entities.actions';
import { entityTypeAndID } from '../../../utils/entityTypeAndID';
import { ENTITY_BTN } from '../../../constants/viewConfig';


const CompanyUsers = () => {
  const dispatch = useDispatch()
  const users = useSelector(state => state.entities.users)
  const openModalUser = useSelector(state => state.entities.modals?.openModalUser)
  const selectedCompany = useSelector(state => state.entities.selectedCompany)
  const viewShowControl = useSelector(state => state.view?.mainViewConfig?.show);
  let [selectedUser, setSelectedUser] = useState(null);
  let [dialogMode, setDialogMode] = useState('ADD');
  // console.log("viewShowControl - ", viewShowControl)

  useEffect(() => {
    if (selectedCompany?.keys?.table) {
      dispatch(getUsers(entityTypeAndID(selectedCompany)))
    }
  }, [selectedCompany])

  const handleSelectUser = (user) => {
    // console.log(user)
    if (selectedUser?.keys?.user_id === user.keys?.user_id) {
      setSelectedUser(null)
      setDialogMode('ADD')
    }
    else {
      setDialogMode('EDIT')
      setSelectedUser(user)
    }
  }

  const isSelectedUser = (user) => {
    return selectedUser?.keys?.user_id === user.keys?.user_id
  }

  const activeBtn = (user) => {
    return (
      <Typography sx={{
        color: "#fff",
        backgroundColor: user?.value?.a_status_color ? user?.value?.a_status_color : "#42ad00",
        textAlign: 'center',
        borderRadius: 20,
        padding: "3px"
      }}>{user?.value?.a_status_title}</Typography>
    )
  }

  const handleDeactivateUser = () => {
    if (selectedUser) {
      dispatch(userActivityDoAction(selectedUser.value.a_state_to_available, selectedUser))
    }
  }
  const handleReactivateUser = () => {
    if (selectedUser) {
      dispatch(userActivityDoAction(selectedUser.value.a_state_to_available, selectedUser))
    }
  }

  const renderUserRow = (user, index) => {
    return (
      <TableRow
        selected={isSelectedUser(user)}
        key={user.value?.firstName + user.value?.lastName + index.toString()}
        classes={{
          selected: 'rowSelected'
        }}
        sx={{
          cursor: "pointer",
          '&:last-child td, &:last-child th': { border: 0 },
          '& td': { fontSize: "16px", },
          '&.rowSelected td': {
            color: "#eb0781",
            fontWeight: "bold"
          }
        }}
        onClick={() => { handleSelectUser(user) }}
      >
        <TableCell align="left">{user.value?.firstName}</TableCell>
        <TableCell align="left">{user.value?.lastName}</TableCell>
        <TableCell align="left">{user.value?.email}</TableCell>
        <TableCell align="left">{user.value?.phone}</TableCell>
        <TableCell align="left">{!!user.value?.a_role_names?.join?.(', ') ? user.value?.a_role_names?.join?.(', ') : ''}</TableCell>
        <TableCell align="right">
          {activeBtn(user)}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Box sx={{
      padding: "35px",

    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: "center"
      }}>
        <Box sx={{
          width: "50%"
        }}>
          <Typography sx={{
            color: "#053ad3",
            fontSize: "48px"
          }}>Users</Typography>
        </Box>
        {/*<Box sx={{*/}
        {/*  width: "50%"*/}
        {/*}}>*/}
        {/*  <TextField*/}
        {/*    id="input-with-icon-textfield"*/}
        {/*    InputProps={{*/}
        {/*      startAdornment: (*/}
        {/*        <InputAdornment position="start">*/}
        {/*          <SearchIcon sx={{ color: "#eb0781" }} />*/}
        {/*        </InputAdornment>*/}
        {/*      ),*/}
        {/*    }}*/}
        {/*    variant="standard"*/}
        {/*  />*/}
        {/*</Box>*/}
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: 'column',
        flex: 1,
      }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
          {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Clinic A" />
            <Tab label="Clinic B" />
          </Tabs> */}
        </Box>
        {/* <TabPanel value={value} index={0}> */}
        <Box sx={{
          display: "flex",
          flexDirection: 'row',
          flex: 1,
          justifyContent: "space-between"
        }}>
          {/*<Box>*/}
          {/*  <Button startIcon={<SortIcon />}*/}
          {/*    sx={{*/}
          {/*      color: "#000",*/}
          {/*      fontWeight: "bold",*/}
          {/*    }}>*/}
          {/*    Filter*/}
          {/*  </Button>*/}
          {/*</Box>*/}
          <Box>
            {viewShowControl?.includes(ENTITY_BTN.ENTITY_BTN_ADD_USER) &&
              <Button startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  setSelectedUser(null);
                  setDialogMode('ADD');
                  dispatch(setOpenModalUser(true));
                }}
                sx={{
                  color: "#eb0781",
                  fontWeight: "bold",
                  '&:hover': {
                    color: '#eb0781'
                  }
                }}>
                Add
              </Button>
            }
            {viewShowControl?.includes(ENTITY_BTN.ENTITY_BTN_EDIT_USER) &&
              <Button startIcon={<EditIcon />}
                disabled={!selectedUser ? true : false}
                onClick={() => {
                  dispatch(setOpenModalUser(true))
                }}
                sx={{
                  color: "#eb0781",
                  fontWeight: "bold",
                  '&:hover': {
                    color: '#eb0781'
                  }
                }}>
                Edit
              </Button>
            }
            {viewShowControl?.includes(ENTITY_BTN.ENTITY_BTN_DELETE_USER) &&
              <Button startIcon={<DeleteIcon />}
                disabled={!selectedUser ? true : false}
                sx={{
                  color: "#eb0781",
                  fontWeight: "bold",
                  '&:hover': {
                    color: '#eb0781'
                  }
                }}>
                Delete
              </Button>
            }
            {(selectedUser && selectedUser?.value?.a_viewConfig?.show.includes(ENTITY_BTN.ENTITY_BTN_DEACTIVATED_USER)) &&
              <Button startIcon={<DeleteIcon />}
                disabled={!selectedUser ? true : false}
                onClick={() => { handleDeactivateUser() }}
                sx={{
                  color: "#eb0781",
                  fontWeight: "bold",
                  '&:hover': {
                    color: '#eb0781'
                  }
                }}>
                Deactivate
              </Button>
            }
            {(selectedUser && selectedUser?.value?.a_viewConfig?.show.includes(ENTITY_BTN.ENTITY_BTN_REACTIVATION_USER)) &&
              <Button startIcon={<DeleteIcon />}
                disabled={!selectedUser ? true : false}
                onClick={() => { handleReactivateUser() }}
                sx={{
                  color: "#eb0781",
                  fontWeight: "bold",
                  '&:hover': {
                    color: '#eb0781'
                  }
                }}>
                Reactivate
              </Button>
            }
          </Box>
        </Box>
        <TableContainer >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left">First Name</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left">Last Name</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left">Email</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left">Phone</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left">User Roles</TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 16 }} align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.length > 0 && users.map(renderUserRow)}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </TabPanel> */}
        {/* <TabPanel value={value} index={1}>
          Clinic B
        </TabPanel> */}
      </Box>
      <AddUserModal open={openModalUser} dialogMode={dialogMode} user={selectedUser} handleOpen={(boolean) => dispatch(setOpenModalUser(boolean))} />
    </Box >
  );
};

export default CompanyUsers;