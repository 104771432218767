import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Container, Grid
} from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer, PageBodyContainer, DrawerContainer, IMSDrawer } from '../../shared';
import { PatientSearchForm, PolicyDetails, CaseDetailsForm, DocumentsStep } from './components'
import { createDraftGop, resetDraftGop } from '../../redux/actions/gop.actions';
import DocumentsDetailsTab from '../Cases/components/GopDetails/DocumentsDetailsTab'

const steps = [{
  index: 0,
  label: 'Patient Details',
  isOptional: false,
  showNextBtn: false,
  showBackBtn: false
}, {
  index: 1,
  label: 'Policy Details',
  isOptional: false,
  showNextBtn: true,
  showBackBtn: true
}, {
  index: 2,
  label: 'Case Details',
  isOptional: false,
  showNextBtn: true,
  showBackBtn: true
}, {
  index: 3,
  label: 'Upload Documents',
  isOptional: false,
  showNextBtn: false,
  showBackBtn: true
}]

const NewGOP = () => {
  const dispatch = useDispatch();
  // const gop_id = useSelector(state => state.gop?.draftIdGop)
  // const gop_docs = useSelector(state => state.gop?.selectedGopDocs)
  const user = useSelector(state => state.auth.user)
  const [activeStep, setActiveStep] = useState(steps[0]);

  useEffect(() => {
    dispatch(resetDraftGop());
    dispatch(createDraftGop());
  }, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => steps[prevActiveStep.index + 1]);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => steps[prevActiveStep.index - 1]);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <PatientSearchForm activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
      case 1:
        return <PolicyDetails activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
      case 2:
        return <CaseDetailsForm activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
      case 3:
        return <DocumentsStep activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={user.owner_type === 'provider' ? 'Inpatient Management System' : 'Clinic Portal'}
        underline={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Container maxWidth="lg" sx={{ mt: 2, mb: 1 }}>
          <Grid container sx={{ mt: 2, mb: 2 }}>
            <Box sx={{ width: '85%', marginLeft: '100px' }}>
              <Typography color='primary' sx={{
                fontSize: '24px',
                fontWeight: 'bold',
                textAlign: 'center'
              }}>New Guarantee of Payment (GOP)</Typography>
              <Stepper sx={{ mt: 3, mb: 1, }} activeStep={activeStep.index}>
                {steps.map((stepItem, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step sx={{ color: 'secondary' }} key={stepItem.label}  {...stepProps}>
                      <StepLabel {...labelProps}>{stepItem.label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              <React.Fragment>
                {getStepContent(activeStep?.index)}
              </React.Fragment>
            </Box>
          </Grid>
        </Container>
      </PageBodyContainer>
    </PageContainer>
  );
};

export default NewGOP;