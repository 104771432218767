import React, { useEffect, useState } from 'react';
import {
  Box, TextField, MenuItem,
  FormControl, Select, InputLabel
} from '@mui/material';
import _ from 'lodash';

const DisableTextField = ({ value = '', label = '', disabled = true }) => {
  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      fullWidth
      multiline
      variant="filled"
    />
  )
}

const SelectorNestedValue = ({
  label = '', valueObj = {}, keyName = '',
  titleExtractor = () => '', items = [],
  onChange = () => { }, isDisabled = true,
}) => {

  return (
    <>
      {isDisabled && <DisableTextField label={label} value={valueObj?.title} disabled={isDisabled} />}
      {!isDisabled &&
        <FormControl disabled={isDisabled}
          variant="filled" fullWidth >
          <InputLabel id={keyName + '-label'}>{label}</InputLabel>
          <Select
            labelId={keyName + '-select'}
            value={JSON.stringify(valueObj)}
            onChange={(event) => onChange(JSON.parse(event.target.value))}
            label={label}
            variant={'filled'}
            multiline
          >
            {items?.map((item, index) => (
              <MenuItem key={index.toString()} value={JSON.stringify(item)}>{titleExtractor(item)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    </>
  )
}

export default SelectorNestedValue