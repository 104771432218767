import React, { useEffect, useState } from "react";
import { Box, Button, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Typography } from "@mui/material";
import { DrawerContainer, PageContainer } from "../../shared";
import { useLocation, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ReactComponent as SWNLOGOBLUE } from "../../media/logos/swan_logo_blue.svg";
import { savePassword } from "../../redux/actions/auth.actions";
import { useDispatch } from "react-redux";
import ErrorMessage from "../../shared/ErrorMessage";

const FirstLoginPage = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  let from = location.state?.from?.pathname || "/";
  let [email, setEmail] = useState("");
  let [oldPassword, setOldPassword] = useState("");
  let [newPassword, setNewPassword] = useState("");
  let [showPassword, setShowPassword] = useState(false);
  let [confirmPassword, setConfirmPassword] = useState("");
  let [showConfirmPassword, setShowConfirmPassword] = useState(false);


  // error messages
  const [usernameErrors, setUsernameErrors] = useState([]);

  useEffect(() => {
    setEmail(location.state?.email)
    setOldPassword(location.state?.old_password)
  }, [location.state])

  const isValidPasswords = () => {
    return !!(newPassword.length && confirmPassword.length && (newPassword === confirmPassword));
  };

  const handleChangePassword = (event) => {
    setNewPassword(event.target.value);
  };
  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const keyPressNewPasswordHandle = (e) => {
    if (e.keyCode === 13) {
      setNewPassword(e.target.value);
      dispatch(savePassword({ email, oldPassword, newPassword, navigate, from }));
    }
  };

  const keyPressNewPasswordConfirmHandle = (e) => {
    if (e.keyCode === 13) {
      setConfirmPassword(e.target.value);
      dispatch(savePassword({ email, oldPassword, newPassword, navigate, from }));
    }
  };

  return (
    <PageContainer>
      <DrawerContainer
        isLogo={false}
        description=""
        underline={false}
        // isBack={true}
      >
        {/* <IMSDrawer /> */}
      </DrawerContainer>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Box
          sx={{
            width: "380px",
            display: "flex",
            margin: "0 auto",
            minHeight: "100px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <Box sx={{ margin: "20px auto" }}>
              <SWNLOGOBLUE />
            </Box>
            <Box sx={{ margin: "20px auto" }}>
              <Typography color='primary' sx={{ fontSize: '24px', fontWeight: 'bold' }}>Please enter new password</Typography>
            </Box>

            <FormControl
              variant="filled"
              fullWidth
              sx={{
                m: "20px 0",
              }}
            >
              <InputLabel
                htmlFor="email"
                sx={{
                  fontSize: "12px",
                  color: "#00b7cd",
                  fontWeight: "bold",
                }}
              >
                USERNAME
              </InputLabel>
              <FilledInput
                id="email"
                type="text"
                fullWidth
                readOnly={true}
                placeholder="Your username"
                sx={{
                  color: "rgba(59, 64, 69, 0.87)",
                }}
                value={email}
              />
              {usernameErrors.map(({ isValid, msg }, index) => {
                if (!isValid) {
                  return (
                    <ErrorMessage key={index} message={msg} />
                  );
                }
              })}
            </FormControl>
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                m: "20px 0",
              }}
            >
              <InputLabel
                htmlFor="password"
                sx={{
                  fontSize: "12px",
                  color: "#00b7cd",
                  fontWeight: "bold",
                }}
              >
                PASSWORD
              </InputLabel>
              <FilledInput
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Insert password"
                value={newPassword}
                onChange={handleChangePassword}
                onKeyDown={keyPressNewPasswordHandle}
                sx={{
                  color: "rgba(59, 64, 69, 0.87)",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                m: "20px 0",
              }}
            >
              <InputLabel
                htmlFor="password"
                sx={{
                  fontSize: "12px",
                  color: "#00b7cd",
                  fontWeight: "bold",
                }}
              >
                CONFIRM PASSWORD
              </InputLabel>
              <FilledInput
                id="password"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Insert password"
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                onKeyDown={keyPressNewPasswordConfirmHandle}
                sx={{
                  color: "rgba(59, 64, 69, 0.87)",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant="contained"
              sx={{
                height: "36px",
                fontSize: "14px",
                fontWeight: "bold",
                backgroundColor: "#00b7cd",
                borderRadius: 0,
                m: "20px 0",
                color: "#fff",
              }}
              disabled={!isValidPasswords()}
              onClick={() =>
                dispatch(savePassword({ email, oldPassword, newPassword, navigate, from }))
              }
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default FirstLoginPage;
