import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Grid,
  Box, Button, MenuItem, Autocomplete
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { LIGHTENED_PINK } from "../../../constants/colors.js";
import {
  setDraftGopCaseAdmission,
  setDraftGopCaseTreatment,
  setDraftGopCaseBilling,
} from "../../../redux/actions/gop.actions.js";
import { addMessageToSnackbar } from '../../../redux/actions/view.actions'
import { useDispatch, useSelector } from "react-redux";
import { getRulesValidation, onUserGOPInputChange } from "../../../utils/onChangeFunction";
import ErrorMessage from "../../../shared/ErrorMessage";
import { GOP_DROPDOWNS, OTHER_VALUES } from "../../../constants/viewConfig.js";
import DatePickerComponent from "../../../shared/DatePickerComponent.jsx";
import {
  EditableSelectControl,
  TimePickerComponent,
  MultipleIC10CodesDropdownControl
} from "../../../shared";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";

const StyledTextFieldComponent = ({ name = '', label = '', value = '', onChange = () => { },
  disabled = true, custom_style = {}, }) => {
  return (
    <TextField
      onChange={(event) => onChange(event.target.value)}
      label={label}
      value={value}
      fullWidth
      multiline
      variant="filled"
      id={name}
      name={name}
      autoComplete={name}
      disabled={disabled}
      sx={{ ...custom_style }}
      className='custom-style'
    />
  )
}

const AutocompleteController = ({ control, nameControl, options, rules = {}, label = '', handleChange = () => { }, ...rest }) => {
  return (
    <Controller
      name={nameControl}
      control={control}
      rules={rules}
      defaultValue={{ value: '', title: "not chosen" }}
      render={({ field: { value, ...field }, fieldState: { invalid, error } }) => {
        // console.log("AutocompleteController error - ", error)
        // console.log("AutocompleteController value - ", value)
        return (<Autocomplete
          {...field}
          {...rest}
          fullWidth
          options={options}
          value={value}
          renderInput={(params) => {
            return (
              <TextField multiline {...params} label={label} variant='filled'
                error={!!invalid}
                helperText={error && error?.message ? error?.message : null}
              />
            )
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.title}
              </li>
            );
          }}
          getOptionLabel={(option) => option?.title ? option.title : ''}
          onChange={(event, newValue) => {
            field.onChange(newValue)
            handleChange(newValue)
          }}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
        />)
      }}
    />
  )
}

const SelectController = ({ control, nameControl, rules = {}, options, clearErrors, handleChange = () => { }, ...rest }) => {
  return (
    <Controller
      name={nameControl}
      control={control}
      rules={rules}
      defaultValue={{ value: '', title: "not chosen" }}
      render={({
        field: { onBlur, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        // console.log("SelectController error - ", error)
        // console.log("SelectController value - ", value)
        return (
          <TextField
            sx={{ minWidth: 240, width: "100%" }}
            variant="filled"
            select
            value={JSON.stringify(value)}
            onChange={(event) => {
              onChange(JSON.parse(event.target.value))
              handleChange(JSON.parse(event.target.value))
            }}
            error={!!invalid}
            helperText={error && error?.message ? error?.message : null}
            {...field}
            {...rest}
          >
            {options?.map((item, index) => (
              <MenuItem key={index.toString()} value={JSON.stringify(item)}>{item.title}</MenuItem>
            ))}
          </TextField>
        )
      }}
    />
  )
}

const TextFieldController = ({ control, nameControl, validationKey, clearErrors, rules = {}, handleChange = () => { }, ...rest }) => {
  return (
    <Controller
      name={nameControl}
      control={control}
      rules={{ ...rules, validate: { ...getRulesValidation(validationKey) } }}
      render={({
        field: { onBlur, onChange, value, ...restField },
        fieldState: { invalid, error },
      }) => {
        return (<TextField
          sx={{ width: "100%" }}
          variant="filled"
          value={value}
          onChange={(event) => {
            onChange(event.target.value)
            handleChange(event.target.value)
          }}
          error={!!invalid}
          helperText={error && error?.message ? error?.message : null}
          {...restField}
          {...rest}
        />)
      }}
    />
  )
}

const CaseDetailsForm = ({ activeStep, handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const caseDetails = useSelector((state) => state.gop.draftGop.caseDetails);
  const draftObj = useSelector((state) => state.gop.draftObj);
  const dropdowns = useSelector((state) => state.gop.draftObj.value.a_viewConfig.dropdowns);

  const { handleSubmit, control, clearErrors, reset, setValue } = useForm({
    defaultValues: {
      admission: {
        lengthOfStay: '',
        patientId: '',
        roomNumber: '',
        date: '',
        roomType: { value: '', title: "not chosen" },
        time: moment().format('HH:mm'),
      },
      treatmentAndDoctor: {
        treatmentType: '',
        // diagnostics: '',
        otherDiagnostics: '',
        admittingDoctor: '',
        otherDoctor: '',
        clinicComment: '',
        admittingDoctorNotInList: {
          firstName: '',
          lastName: '',
          specialty: '',
          regNo: ''
        },
        otherDoctorNotInList: {
          firstName: "",
          lastName: "",
          specialty: "",
          regNo: ""
        }
      },
      billing: {
        amount: ''
      }
    },
    mode: 'all'
  });

  useEffect(() => {
    if (!!draftObj?.value?.a_viewConfig?.dropdowns?.BILL_TYPE?.defaultValue) {
      dispatch(
        setDraftGopCaseBilling({
          type: draftObj?.value?.a_viewConfig?.dropdowns?.BILL_TYPE
            ?.defaultValue,
        })
      );
    }
  }, [draftObj]);

  useEffect(() => {
    console.log(caseDetails)
    setValue('admission.patientId', caseDetails?.admission.patientId)
    setValue('admission.date', caseDetails?.admission.date)
    setValue('admission.time', caseDetails?.admission.time)
    setValue('admission.lengthOfStay', caseDetails?.admission.lengthOfStay)
    setValue('admission.lengthOfStay', caseDetails?.admission.lengthOfStay)
    setValue('admission.roomType', caseDetails?.admission.roomType)
    setValue('admission.roomNumber', caseDetails?.admission.roomNumber)
    setValue('treatmentAndDoctor.treatmentType', caseDetails?.treatmentAndDoctor?.treatmentType)
    setValue('treatmentAndDoctor.otherDiagnostics', caseDetails?.treatmentAndDoctor?.otherDiagnostics)
    setValue('treatmentAndDoctor.admittingDoctor', caseDetails?.treatmentAndDoctor?.admittingDoctor)
    setValue('treatmentAndDoctor.otherDoctor', caseDetails?.treatmentAndDoctor?.otherDoctor)
    setValue('treatmentAndDoctor.clinicComment', caseDetails?.treatmentAndDoctor?.clinicComment)
    setValue('treatmentAndDoctor.admittingDoctorNotInList', caseDetails?.treatmentAndDoctor?.admittingDoctorNotInList)
    setValue('billing.amount', caseDetails?.billing?.[0]?.amount)
  }, [caseDetails])

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>
        {activeStep?.showBackBtn &&
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, color: '#00b7cd' }}
          >
            <ArrowBackIcon sx={{ margin: '0 9px 0 0', color: '#eb0781' }} />
            Back
          </Button>
        }
        <Box sx={{ flex: '1 1 auto' }} />

        {activeStep?.showNextBtn &&
          <Button onClick={handleSubmit((data) => {
            console.log(data)
            if (caseDetails?.treatmentAndDoctor?.diagnostics?.length === 0) {
              dispatch(addMessageToSnackbar("Please select Diagnosis from list or enter other diagnosis", 'info'))
              return
            }
            handleNext()
          })} sx={{ color: '#00b7cd' }}>
            Next
            <ArrowForwardIcon sx={{ margin: '0 0 0 9px', color: '#eb0781' }} />
          </Button>
        }
      </Box>
      <Typography
        pb={2}
        pt={3}
        sx={{
          color: LIGHTENED_PINK,
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        Admission Information
      </Typography>
      <Grid container spacing={4}>
        {/* Patient Admission ID */}
        <Grid item xs={12} sm={6}>
          <TextFieldController
            label={'Patient Admission ID'}
            control={control}
            nameControl={'admission.patientId'}
            rules={{
              required: "Please enter patient admission ID."
            }}
            clearErrors={clearErrors}
            handleChange={(value) => {
              dispatch(setDraftGopCaseAdmission({ patientId: value }))
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "3px" }} spacing={4}>
        {/* Date of Admission */}
        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name={'admission.date'}
            rules={{
              required: "Please select date of admission."
            }}
            render={({ field: { value, onChange, ...field }, fieldState: { error, invalid } }) => {
              return (<DatePickerComponent
                {...field}
                label="Date of Admission"
                value={value}
                disableFutureDate={true}
                valueOnChange={(newValue) => {
                  onChange(newValue)
                  dispatch(setDraftGopCaseAdmission({ date: newValue }))
                }}
                readOnly={true}
                error={!!invalid}
                helperText={error && error?.message ? error?.message : null}
                isDisabled={false}
              />)
            }}
          />
        </Grid>
        {/* Time */}
        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name={'admission.time'}
            rules={{
              required: "Please select time."
            }}
            render={({ field: { value, onChange, ...field }, fieldState: { error, invalid } }) => {
              // console.log("TimePickerComponent error ", error)
              // console.log("TimePickerComponent value ", value)
              return (
                <TimePickerComponent
                  {...field}
                  label={'Time'}
                  isDisabled={false}
                  value={value}
                  error={!!invalid}
                  helperText={error && error?.message ? error?.message : null}
                  valueOnChange={(value) => {
                    onChange(value)
                    dispatch(setDraftGopCaseAdmission({ time: value }))
                  }}
                />
              )
            }}
          />
        </Grid>
        {/* Expected Length of Stay */}
        <Grid item xs={12} sm={4}>
          <TextFieldController
            label={'Expected Length of Stay'}
            control={control}
            nameControl={'admission.lengthOfStay'}
            validationKey={'CLINIC_LENGTH_STAY'}
            clearErrors={clearErrors}
            handleChange={(value) => {
              dispatch(setDraftGopCaseAdmission({ lengthOfStay: value }));
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "3px" }} spacing={4}>
        {/* Room Type */}
        <Grid item xs={12} sm={6}>
          <SelectController
            control={control}
            nameControl={'admission.roomType'}
            label={'Room Type'}
            options={dropdowns?.[GOP_DROPDOWNS.ROOM_TYPE]?.items}
            rules={{ validate: { ...getRulesValidation('CLINIC_ROOM_TYPE') } }}
            fullWidth
            handleChange={(value) => {
              dispatch(setDraftGopCaseAdmission({ roomType: value }));
            }}
          />
        </Grid>
        {/* Room No. */}
        <Grid item xs={12} sm={6}>
          <TextFieldController
            label={'Room No.'}
            control={control}
            nameControl={'admission.roomNumber'}
            validationKey={'CLINIC_ROOM_NUMBER'}
            clearErrors={clearErrors}
            handleChange={(value) => {
              dispatch(setDraftGopCaseAdmission({ roomNumber: value }));
            }}
          />
        </Grid>
      </Grid>

      <Typography pb={2} pt={2}
        sx={{
          color: LIGHTENED_PINK,
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        Treatment and doctor
      </Typography>

      <Grid container spacing={4}>
        {/* Treatment Type */}
        <Grid item xs={12} sm={4}>
          <SelectController
            control={control}
            nameControl={'treatmentAndDoctor.treatmentType'}
            label={'Treatment Type'}
            options={dropdowns?.[GOP_DROPDOWNS.TREATMENT_TYPE]?.items}
            rules={{ validate: { ...getRulesValidation('GOP_TREATMENT_TYPE') } }}
            fullWidth
            handleChange={(value) => {
              dispatch(setDraftGopCaseTreatment({ treatmentType: value }));
            }}
          />
        </Grid>
        {/* Diagnosis */}
        <Grid item xs={12} sm={8}>
          <MultipleIC10CodesDropdownControl
            value={caseDetails?.treatmentAndDoctor?.diagnostics}
            label={'Diagnosis'}
            isDisabled={false}
            variant={'filled'}
            onChange={(value) => {
              dispatch(setDraftGopCaseTreatment({ diagnostics: value }))
            }}
          />
        </Grid>
        {/* Other Diagnosis */}
        <Grid item xs={12} sm={6}>
          <TextFieldController
            label={'Other Diagnosis'}
            control={control}
            nameControl={'treatmentAndDoctor.otherDiagnostics'}
            validationKey={''}
            clearErrors={clearErrors}
            handleChange={(value) => {
              dispatch(setDraftGopCaseTreatment({ otherDiagnostics: value }));
            }}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ marginTop: "3px" }} spacing={4}>
        {/* Admitting Doctor */}
        <Grid item xs={12} sm={6}>
          <AutocompleteController
            control={control}
            nameControl={'treatmentAndDoctor.admittingDoctor'}
            label={'Admitting Doctor'}
            options={dropdowns?.[GOP_DROPDOWNS.ADMITTING_DOCTOR]?.items}
            rules={{ validate: { ...getRulesValidation('GOP_ADMITTING_DOCTOR') } }}
            handleChange={(value) => {
              dispatch(setDraftGopCaseTreatment({ admittingDoctor: value }));
            }}
          />
          {/* AdmitingDoctor add form for other */}
          {(caseDetails?.treatmentAndDoctor?.admittingDoctor &&
            caseDetails?.treatmentAndDoctor?.admittingDoctor?.value === OTHER_VALUES.DOCTOR_NOT_IN_LIST) &&
            <Box pt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldController
                    label={'First Name'}
                    control={control}
                    nameControl={'treatmentAndDoctor.admittingDoctorNotInList.firstName'}
                    validationKey={'NAME_VALIDATION'}
                    clearErrors={clearErrors}
                    handleChange={(value) => {
                      dispatch(setDraftGopCaseTreatment({
                        admittingDoctorNotInList: {
                          ...caseDetails?.treatmentAndDoctor?.admittingDoctorNotInList,
                          firstName: value
                        }
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldController
                    label={'Last Name'}
                    control={control}
                    nameControl={'treatmentAndDoctor.admittingDoctorNotInList.lastName'}
                    validationKey={'NAME_VALIDATION'}
                    clearErrors={clearErrors}
                    handleChange={(value) => {
                      dispatch(setDraftGopCaseTreatment({
                        admittingDoctorNotInList: {
                          ...caseDetails?.treatmentAndDoctor?.admittingDoctorNotInList,
                          lastName: value
                        }
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldController
                    label={'Specialty'}
                    control={control}
                    nameControl={'treatmentAndDoctor.admittingDoctorNotInList.specialty'}
                    validationKey={''}
                    clearErrors={clearErrors}
                    handleChange={(value) => {
                      dispatch(setDraftGopCaseTreatment({
                        admittingDoctorNotInList: {
                          ...caseDetails?.treatmentAndDoctor?.admittingDoctorNotInList,
                          specialty: value
                        }
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldController
                    label={'Registration no.'}
                    control={control}
                    nameControl={'treatmentAndDoctor.admittingDoctorNotInList.regNo'}
                    validationKey={''}
                    clearErrors={clearErrors}
                    handleChange={(value) => {
                      dispatch(setDraftGopCaseTreatment({
                        admittingDoctorNotInList: {
                          ...caseDetails?.treatmentAndDoctor?.admittingDoctorNotInList,
                          regNo: value
                        }
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          }
          {/* END AdmitingDoctor add form for other */}
        </Grid>
        {/* Other Doctor */}
        <Grid item xs={12} sm={6}>
          <AutocompleteController
            control={control}
            nameControl={'treatmentAndDoctor.otherDoctor'}
            label={'Other Doctor'}
            options={dropdowns?.[GOP_DROPDOWNS.OTHER_DOCTOR]?.items}
            handleChange={(value) => {
              dispatch(setDraftGopCaseTreatment({ otherDoctor: value }));
            }}
          />
          {/* OtherDoctor add form for other */}
          {(caseDetails?.treatmentAndDoctor?.otherDoctor &&
            caseDetails?.treatmentAndDoctor?.otherDoctor?.value === OTHER_VALUES.DOCTOR_NOT_IN_LIST) &&
            <Box pt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextFieldController
                    label={'First Name'}
                    control={control}
                    nameControl={'treatmentAndDoctor.otherDoctorNotInList.firstName'}
                    validationKey={'NAME_VALIDATION'}
                    clearErrors={clearErrors}
                    handleChange={(value) => {
                      dispatch(setDraftGopCaseTreatment({
                        otherDoctorNotInList: {
                          ...caseDetails?.treatmentAndDoctor?.otherDoctorNotInList,
                          firstName: value
                        }
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldController
                    label={'Last Name'}
                    control={control}
                    nameControl={'treatmentAndDoctor.otherDoctorNotInList.lastName'}
                    validationKey={'NAME_VALIDATION'}
                    clearErrors={clearErrors}
                    handleChange={(value) => {
                      dispatch(setDraftGopCaseTreatment({
                        otherDoctorNotInList: {
                          ...caseDetails?.treatmentAndDoctor?.otherDoctorNotInList,
                          lastName: value
                        }
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldController
                    label={'Specialty'}
                    control={control}
                    nameControl={'treatmentAndDoctor.otherDoctorNotInList.specialty'}
                    validationKey={''}
                    clearErrors={clearErrors}
                    handleChange={(value) => {
                      dispatch(setDraftGopCaseTreatment({
                        otherDoctorNotInList: {
                          ...caseDetails?.treatmentAndDoctor?.otherDoctorNotInList,
                          specialty: value
                        }
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldController
                    label={'Registration no.'}
                    control={control}
                    nameControl={'treatmentAndDoctor.otherDoctorNotInList.regNo'}
                    validationKey={''}
                    clearErrors={clearErrors}
                    handleChange={(value) => {
                      dispatch(setDraftGopCaseTreatment({
                        otherDoctorNotInList: {
                          ...caseDetails?.treatmentAndDoctor?.otherDoctorNotInList,
                          regNo: value
                        }
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          }
          {/* END OtherDoctor add form for other */}
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "3px" }} spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextFieldController
            label={'Clinic Comment'}
            control={control}
            nameControl={'treatmentAndDoctor.clinicComment'}
            validationKey={'ADDITIONAL_DETAILS_VALIDATION'}
            clearErrors={clearErrors}
            handleChange={(value) => {
              dispatch(setDraftGopCaseTreatment({ clinicComment: value }));
            }}
          />
        </Grid>
      </Grid>

      <Typography pb={2} pt={2}
        sx={{
          color: LIGHTENED_PINK,
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        Billing Information
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <EditableSelectControl
            value={caseDetails?.billing?.[0]?.type}
            propertyName={'bill-type'}
            label={'Bill Type'}
            items={draftObj?.value?.a_viewConfig?.dropdowns?.BILL_TYPE?.items}
            isDisabled={draftObj?.value?.a_viewConfig?.dropdowns?.BILL_TYPE?.items.length === 1}
            onChange={(value) => {
              dispatch(setDraftGopCaseBilling({ type: value }));
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldController
            label={'Value of bill'}
            control={control}
            nameControl={'billing.amount'}
            validationKey={'CLINIC_VALUE_OF_BILL'}
            clearErrors={clearErrors}
            handleChange={(value) => {
              dispatch(setDraftGopCaseBilling({ amount: value }));
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CaseDetailsForm;
