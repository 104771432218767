import {
  SET_SNACKBAR_OPEN,
  RESET_VIEW,
  SET_MAIN_VIEW_CONFIG,
  SET_GOP_STATS,
  SET_GOP_STATS_LOADING,
  SET_LOADING_GOP_SEARCH_PATIENT,
  SET_LOADING_UPLOAD_DOCUMENT,
  SET_LOADING_GOP_CREATE,
  SET_LOADING_MAIN_PAGE,
  ADD_MESSAGE_TO_SNACKBAR,
  REMOVE_MESSAGE_FROM_SNACKBAR,
  SET_VIEW
} from '../actionTypes';

const INITIAL_STATE = {
  snackbarMessages: [],
  snackbarErrorMessage: '',
  isSnackBarOpen: false,
  mainViewConfig: {
    IMS_PORTAL_OR_CLINIC_PORTAL: '',
    show: []
  },
  gopStats: {
    closedQty: '',
    inProgressQty: '',
    openQty: ''
  },
  gopStatsLoading: false,
  loading: {
    mainPageLoading: false,
    newGopSearchPatient: false,
    newGopCreate: false,
    uploadDocument: false
  }
};


export const viewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SNACKBAR_OPEN:
      return { ...state, isSnackBarOpen: action.payload.isOpen, snackbarErrorMessage: action.payload.message }
    case RESET_VIEW:
      return { ...INITIAL_STATE }
    case SET_MAIN_VIEW_CONFIG:
      return { ...state, mainViewConfig: action.payload }
    case SET_GOP_STATS:
      return { ...state, gopStats: action.payload }
    case SET_GOP_STATS_LOADING:
      return { ...state, gopStatsLoading: action.payload }
    case SET_LOADING_GOP_SEARCH_PATIENT:
      return { ...state, loading: { ...state.loading, newGopSearchPatient: action.payload } }
    case SET_LOADING_GOP_CREATE:
      return { ...state, loading: { ...state.loading, newGopCreate: action.payload } }
    case SET_LOADING_MAIN_PAGE:
      return { ...state, loading: { ...state.loading, mainPageLoading: action.payload } }
    case SET_LOADING_UPLOAD_DOCUMENT:
      return { ...state, loading: { ...state.loading, uploadDocument: action.payload } }
    case ADD_MESSAGE_TO_SNACKBAR:
      return { ...state, snackbarMessages: [...state.snackbarMessages, { ...action.payload }] }
    case REMOVE_MESSAGE_FROM_SNACKBAR:
      return { ...state, snackbarMessages: state.snackbarMessages.filter((message) => message.id !== action.payload) }
    case SET_VIEW:
      return {...state, ...action.payload }
    default:
      return state;
  }
};
