import React, { useEffect, useState } from 'react';
import {
  Box, Typography, InputAdornment,
  OutlinedInput, IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getBillRemarks, saveBillRemark, } from '../../../redux/actions/gop.actions';
import { VIEW_CONFIG } from '../../../constants';
import SendIcon from '@mui/icons-material/Send';


const BillRemarks = ({ bill, editable, show }) => {
  const [remark, setRemark] = useState('');
  const [allRemarks, setAllRemarks] = useState([]);
  const billRemarks = useSelector(state => state.gop.billRemarks)
  const dispatch = useDispatch()


  useEffect(() => {
    if (bill?.value?.remarks_section_id) {
      dispatch(getBillRemarks({ remarks_section_id: bill?.value?.remarks_section_id }))
    }
  }, [bill?.value?.remarks_section_id])

  useEffect(() => {
    // console.log('billRemarks', billRemarks);
    const billRemarkIndex = billRemarks.findIndex(item => item.remarks_section_id === bill?.value?.remarks_section_id)
    if (billRemarkIndex >= 0) {
      setAllRemarks(billRemarks?.[billRemarkIndex]?.remarks)
    } else {
      setAllRemarks([])
    }
  }, [billRemarks])

  return (
    <Box display='flex' flex={1} flexDirection='column' flexWrap='wrap'>
      <Typography fontSize={'12px'} fontFamily={'Globersemibold'} color={'primary'} pb={2} px={2}>Remarks</Typography>
      {/*{editable?.includes?.(VIEW_CONFIG.REMARKS.BILL) && <OutlinedInput*/}
      <OutlinedInput
        placeholder="Type Here"
        // variant="outlined"
        sx={{ ml: 2, mr: 3, mb: 2 }}
        multiline
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              color="primary"
              disabled={!remark}
              onClick={() => {
                dispatch(saveBillRemark({ remarks_section_id: bill?.value?.remarks_section_id, text: remark }))
                setRemark('');
              }}
              edge="end"
            >
              <SendIcon />
            </IconButton>
          </InputAdornment>
        }
        value={remark}
        onChange={(value) => setRemark(value.target.value)}
      />
      {allRemarks?.length > 0 && allRemarks.map((item, index) =>
        <Box
          key={index.toString()}
          sx={{
            ml: 2,
            mr: 3,
            mb: 2,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'grey',
            p: 1,
        }}>
          <Typography sx={{ wordBreak: 'break-word'}}>{`${item?.value?.user?.name} ${item?.value?.a_updated_at_ddmmyyyyhhmmss}:`}</Typography>
          <Typography sx={{ wordBreak: 'break-word'}}>{item?.value?.post?.text}</Typography>
        </Box>)}
    </Box>
  )
}


export default BillRemarks;
