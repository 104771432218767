import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { VIEW_CONFIG } from '../../../../constants'
import { DatePickerComponent, EditableSelectObjectControl, TimePickerComponent } from '../../../../shared';
import { resetSelectedGop, saveEditGop, updateSelectedGop } from '../../../../redux/actions/gop.actions';
import { GOP_BTN, GOP_DROPDOWNS } from "../../../../constants/viewConfig";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

const StyledTextField = ({ value = '', label = '', disabled = true, onChange = () => { } }) => {
  return (
    <TextField
      onChange={(event) => { console.log('event', event.target.value); onChange(event.target.value) }}
      disabled={disabled}
      label={label}
      value={value}
      sx={{ m: 2 }}
      variant="filled"
      multiline
    />
  )
}


const PatientDetailsTab = () => {
  const dispatch = useDispatch();
  const selectedGop = useSelector(state => state.gop.selectedGop);
  const show = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.show) || [];
  const editable = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.editable);
  const dropdowns = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.dropdowns);
  const ADMISSION = VIEW_CONFIG.GOP_TEXT_FIELDS.ADMISSION;
  const {
    keys: {
      gop_id = '',
    } = {},
    value: {
      patient: {
        firstName = '',
        lastName = '',
        nic = '',
        dob = '',
        policyNum = '',
        familyIdMemberId = '',
      } = {},
      caseDetails: {
        a_caseDetailsUnsavedChangesExist = false,
        admission: {
          patientId = '',
          date = '',
          time = '',
          lengthOfStay = '',
          roomType = {},
          roomNumber = ''
        } = {}
      } = {}
    } = {}
  } = selectedGop || {};

  // useEffect(() => {
  //   console.log('selectedGop', selectedGop)
  // }, [selectedGop])



  return (
    <Box p={2}>
      <Box display='flex' flexDirection='row' >
        <StyledTextField label='first name' value={firstName} />
        <StyledTextField label='last name' value={lastName} />
      </Box>
      <Box display='flex' flexDirection='row' >
        <StyledTextField label='nic' value={nic} />
        <StyledTextField label='date of birth' value={dob} />
      </Box>
      <Box display='flex' flexDirection='row' >
        <StyledTextField label='policy number' value={policyNum} />
        <StyledTextField label='family / member id' value={familyIdMemberId} />
      </Box>

      <Typography sx={{ my: 2, mx: 2, fontSize: 16, color: 'secondary.main' }}>Admission information</Typography>

      <Box display='flex' flexDirection='row' alignItems='center'>
        <StyledTextField label='Patient admission id' value={patientId} onChange={(value) => dispatch(updateSelectedGop('admission', { patientId: value }))} disabled={!editable?.includes?.(ADMISSION.PATIENT_ID)} />
        <DatePickerComponent
          label="Date of Admission"
          value={date}
          valueOnChange={(newValue) =>
            dispatch(updateSelectedGop('admission', { date: newValue }))
          }
          isDisabled={!editable?.includes?.(ADMISSION.DATE_OF_ADMISSION)}
          disableFutureDate={true}
          readOnly={true}
        />
      </Box>
      <Box display='flex' flexDirection='row' alignItems='center' >
        <Box sx={{ m: 2 }}>
          <TimePickerComponent
            label={'Time'}
            isDisabled={!editable?.includes?.(ADMISSION.TIME_OF_ADMISSION)}
            value={time}
            valueOnChange={(value) => {
              dispatch(updateSelectedGop('admission', { time: value }))
            }}
          />
        </Box>
        <EditableSelectObjectControl
          valueObj={roomType}
          propertyName={'roomType'}
          label={'Room Type'}
          items={dropdowns?.[GOP_DROPDOWNS.ROOM_TYPE]?.items}
          isDisabled={!editable?.includes?.(GOP_DROPDOWNS.ROOM_TYPE)}
          onChange={(value) => {
            dispatch(updateSelectedGop('admission', { roomType: value }))
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <StyledTextField label='Room number' value={roomNumber} onChange={(value) => dispatch(updateSelectedGop('admission', { roomNumber: value }))} disabled={!editable?.includes?.(ADMISSION.ROOM_NUMBER)} />
        <StyledTextField label='Expected length of stay' value={lengthOfStay} onChange={(value) => dispatch(updateSelectedGop('admission', { lengthOfStay: value }))} disabled={!editable?.includes?.(ADMISSION.LENGTH_OF_STAY)} />
      </Box>
      {
        show.includes(GOP_BTN.SAVE_CASE_DETAILS) && a_caseDetailsUnsavedChangesExist
        &&
        <Button color='secondary' variant="text" startIcon={<SaveIcon />}
          sx={{ fontWeight: "bold", fontSize: '14px', mr: 2 }}
          onClick={() => { dispatch(saveEditGop()) }}
        >
          Save
        </Button>
      }
      {
        show.includes(GOP_BTN.CANCEL_CASE_DETAILS) && a_caseDetailsUnsavedChangesExist &&
        <Button color='secondary' variant="text" startIcon={<CancelIcon />}
          sx={{
            fontWeight: "bold",
            fontSize: '14px',
          }}
          onClick={() => { dispatch(resetSelectedGop(gop_id)) }}
        >
          Cancel
        </Button>
      }
    </Box >
  );
};

export default PatientDetailsTab;