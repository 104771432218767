import React, { useEffect, useState } from 'react';
import {
  Box, Grid, Typography,
  Button, InputAdornment,
  IconButton, OutlinedInput,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ControllerTextField, AutocompleteDropdown } from './FormComponents';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { numberWithCommas } from '../../../utils';
import { GOP_FEES_CATASTROPHE_INPUTS } from '../../../constants/viewConfig';
import {
  getCatExternalFeesRemarks, getCatInternalFeesRemarks,
  saveCatExternalFeesRemark, saveCatInternalFeesRemark
} from '../../../redux/actions/fees.actions';
import SendIcon from '@mui/icons-material/Send';




const FeesForm = ({ control, setIsFeeDetailsOpen, setSelectedFeeDetails, setSelectedFeeDetailsSectionKey, GOP_FEES_COLUMNS, type }) => {
  const dispatch = useDispatch();
  const selectedGop = useSelector(state => state.gop?.selectedGop)
  const externalCatastropheRemarks = useSelector(state => state.fees?.externalCatastropheRemarks) || [];
  const internalCatastropheRemarks = useSelector(state => state.fees?.internalCatastropheRemarks) || [];
  const a_viewConfig = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig);
  const {
    internal_remarks_section_id = '',
    external_remarks_section_id = '',
    draftFees : fees = [],
  } = useSelector(state => state.fees?.[type]) || {}
  const [totalAmounts, setTotalAmounts] = useState({
    claimed: 0,
    payable: 0,
    paid: 0
  })
  

  useEffect(() => {
    if (!!external_remarks_section_id) {
      dispatch(getCatExternalFeesRemarks({ remarks_section_id: external_remarks_section_id }))
    }
  }, [internal_remarks_section_id])

  useEffect(() => {
    if (!!internal_remarks_section_id) {
      dispatch(getCatInternalFeesRemarks({ remarks_section_id: internal_remarks_section_id }))
    }
  }, [external_remarks_section_id])



  useEffect(() => {
    // console.log('fees', fees)
    let initAmounts = {
      claimed: 0,
      payable: 0,
      paid: 0
    }
    fees?.forEach((section) => {
      section?.items?.forEach((it) => {
        initAmounts.claimed += it.claimed_amt
        initAmounts.payable += it.payable_amt
        initAmounts.paid += it.paid_amt
      })
    })
    initAmounts.claimed = numberWithCommas(initAmounts.claimed)
    initAmounts.payable = numberWithCommas(initAmounts.payable)
    initAmounts.paid = numberWithCommas(initAmounts.paid)
    setTotalAmounts(initAmounts)
  }, [fees])
  
  
  const FeesHeader = ({ viewConfig, totalAmounts }) => {
    const {
      claimed = '',
      payable = '',
      paid = ''
    } = totalAmounts || {}
  
    return (
      <Grid container pt={2}>
        <Grid item xs={10.5}>
          <Grid container>
            {/* first column */}
            <Grid item xs={4.5}>
            </Grid>
            {/* claimed column */}
            {viewConfig?.show?.includes?.(GOP_FEES_COLUMNS.CLAIMED) &&
              <Grid item xs={2.5}>
                <Typography sx={{fontWeight: '700'}}>{claimed}</Typography>
                <Typography
                  sx={{
                    fontWeight: '700',
                    color: viewConfig?.editable?.includes?.(GOP_FEES_COLUMNS.CLAIMED) ? 'primary.main' : '#979797'
                  }}>Claimed</Typography>
              </Grid>
            }
            {/* payable column */}
            {viewConfig?.show?.includes?.(GOP_FEES_COLUMNS.PAYABLE) &&
              <Grid item xs={2.5}>
                <Typography sx={{fontWeight: '700'}}>{payable}</Typography>
                <Typography sx={{
                  fontWeight: '700',
                  color: viewConfig?.editable?.includes?.(GOP_FEES_COLUMNS.PAYABLE) ? 'primary.main' : '#979797'
                }}>Payable</Typography>
              </Grid>
            }
            {/* paid column */}
            {viewConfig?.show?.includes?.(GOP_FEES_COLUMNS.PAID) &&
              <Grid item xs={2.5}>
                <Typography sx={{fontWeight: '700'}}>{paid}</Typography>
                <Typography sx={{
                  fontWeight: '700',
                  color: viewConfig?.editable?.includes?.(GOP_FEES_COLUMNS.PAID) ? 'primary.main' : '#979797'
                }}>Amount Paid</Typography>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const Subitem = ({ value, subitem, parentKey, viewConfig, sectionKey }) => {
    if (subitem.type === 'DROPDOWN') {
      return (
        <Box>
          {/* {value && <Typography>{value}</Typography>} */}
          <AutocompleteDropdown
            options={subitem.items}
            control={control}
            disabled={!viewConfig?.editable?.includes?.(GOP_FEES_COLUMNS.SUBITEMS)}
            itemKey={`${sectionKey}.${parentKey}.${subitem.k}`}
            label={subitem.title}
            sx={{ mr: 2 }}
          />
        </Box>
      )
    } else {
      return (
        <ControllerTextField
          control={control}
          itemKey={`${sectionKey}.${parentKey}.${subitem.k}`}
          isNum={subitem.type === 'TEXT_FIELD_DECIMAL'}
          disabled={!viewConfig?.editable?.includes?.(GOP_FEES_COLUMNS.SUBITEMS)}
          label={subitem.title}
          sx={{ mr: 2 }}
          variant="standard" />
      )
    }
  }

  const FeesRow = ({ fee, viewConfig, sectionKey }) => {
    const { sub_items = [] } = fee;

    return (
      <Grid container pt={1} pl={1}>
        {/* title column */}
        <Grid item xs={10.5}>
          <Grid container >
            <Grid item xs={4.5} flexDirection={'column'}>
              <Typography pt={2}>{fee.title}</Typography>
              {/* {sub_items?.length > 0 && fee.sub_items.map((subitem, index) => <Subitem value={fee[subitem.k]} sectionKey={sectionKey} parentKey={fee?.k} key={subitem.k} viewConfig={viewConfig} subitem={subitem} />)} */}
            </Grid>
            {/* claimed column */}
            {viewConfig?.show?.includes?.(GOP_FEES_COLUMNS.CLAIMED) && <Grid item xs={2.5}>
              <ControllerTextField
                control={control}
                isNum={true}
                itemKey={`${sectionKey}.${fee.k}.claimed_amt`}
                disabled={!viewConfig?.editable?.includes?.(GOP_FEES_COLUMNS.CLAIMED)}
                label={'Amount'}
                sx={{ mr: 2 }}
                variant="standard" />
            </Grid>}
            {/* payable column */}
            {viewConfig?.show?.includes?.(GOP_FEES_COLUMNS.PAYABLE) && <Grid item xs={2.5}>
              <ControllerTextField
                control={control}
                isNum={true}
                itemKey={`${sectionKey}.${fee.k}.payable_amt`}
                disabled={!viewConfig?.editable?.includes?.(GOP_FEES_COLUMNS.PAYABLE)}
                label={'Amount'}
                sx={{ mr: 2 }}
                variant="standard" />
            </Grid>}
            {/* paid column */}
            {viewConfig?.show?.includes?.(GOP_FEES_COLUMNS.PAID) && <Grid item xs={2.5}>
              <ControllerTextField
                control={control}
                isNum={true}
                itemKey={`${sectionKey}.${fee.k}.paid_amt`}
                disabled={!viewConfig?.editable?.includes?.(GOP_FEES_COLUMNS.PAID)}
                label={'Amount'}
                sx={{ mr: 2 }}
                variant="standard" />
            </Grid>}
            {sub_items?.length > 0
              && <Grid item xs={12} >
                {fee.sub_items.map((subitem, index) => <Subitem value={fee[subitem.k]} sectionKey={sectionKey} parentKey={fee?.k} key={subitem.k} viewConfig={viewConfig} subitem={subitem} />)}
              </Grid>
            }
          </Grid>
        </Grid>
        {viewConfig?.show?.includes?.(GOP_FEES_COLUMNS.DETAILS) && <Grid item pt={2} xs={1.5}>
          <Button onClick={() => {
            setSelectedFeeDetails(fee);
            setSelectedFeeDetailsSectionKey(sectionKey)
            setIsFeeDetailsOpen(true);
          }}>
            <ArrowForwardIosIcon fontSize='small' color='secondary' />
          </Button>
        </Grid>}
      </Grid>
    )
  }

  const FeesCategory = ({ fees, viewConfig, sectionKey }) => {
    return (
      <Grid container>
        {fees?.section_title && <Typography fontSize={'18px'} fontFamily={'Globerbold'} color={'primary'} pl={2} pt={1}>{fees.section_title}</Typography>}
        {fees?.items?.map?.((item, index) => (<FeesRow sectionKey={sectionKey} key={item?.k} fee={item} viewConfig={viewConfig} />))}
      </Grid>
    )
  }

  
  const RemarksSection = ({ viewConstant, title, remarks_section_id, allRemarks, saveRemark }) => {
    const [remark, setRemark] = useState('');


    return a_viewConfig?.show?.includes?.(viewConstant) && remarks_section_id ? (
      <Box display='flex' flex={1} flexDirection='column' flexWrap='wrap'>
        <Typography fontSize={'18px'} fontFamily={'Globerbold'} color={'primary'} p={3}>{title}</Typography>
        {a_viewConfig?.editable?.includes?.(viewConstant) && <OutlinedInput
          placeholder="Type Here"
          // variant="outlined"
          sx={{ ml: 3, mr: 3, mb: 2 }}
          multiline
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                color="primary"
                disabled={!remark}
                onClick={() => {
                  dispatch(saveRemark({ remarks_section_id, text: remark }))
                  setRemark('');
                }}
                edge="end"
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          }
          value={remark}
          onChange={(value) => setRemark(value.target.value)}
        />}
        {allRemarks?.length > 0 && allRemarks.map((item, index) =>
          <Box
            key={index.toString()}
            sx={{
              ml: 3,
              mr: 3,
              mb: 2,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: 'grey',
              p: 1,
          }}>
            <Typography sx={{ wordBreak: 'break-word'}}>{`${item?.value?.user?.name} ${item?.value?.a_updated_at_ddmmyyyyhhmmss}:`}</Typography>
            <Typography sx={{ wordBreak: 'break-word'}}>{item?.value?.post?.text}</Typography>
          </Box>)}
      </Box>
    ) : (<></>)
  }

  return (
    <Box
      sx={{
        backgroundColor: '#fafafa',
        marginBottom: 3,
        marginLeft: 1,
        flex: 1
      }}>
      <Grid container flexDirection={'column'}>
        <FeesHeader viewConfig={selectedGop?.value?.a_viewConfig} totalAmounts={totalAmounts} />
        {/* <Button onClick={() => { console.log(getValues())}}>getValues</Button> */}
        {fees?.length > 0 && fees.map((feeKey, index) => (<FeesCategory key={feeKey?.k} fees={feeKey} sectionKey={feeKey?.k} viewConfig={selectedGop?.value?.a_viewConfig} />))}
      </Grid>
      <RemarksSection
        viewConstant={GOP_FEES_CATASTROPHE_INPUTS.EXTERNAL_REMARKS}
        title={'External Remarks'}
        remarks_section_id={external_remarks_section_id}
        allRemarks={externalCatastropheRemarks}
        saveRemark={saveCatExternalFeesRemark}
      />
      <RemarksSection
        viewConstant={GOP_FEES_CATASTROPHE_INPUTS.INTERNAL_REMARKS}
        title={'Internal Remarks'}
        remarks_section_id={internal_remarks_section_id}
        allRemarks={internalCatastropheRemarks}
        saveRemark={saveCatInternalFeesRemark}
      />
    </Box>
  );
};

export default FeesForm;
