import { axiosErrorHandler, postRequest } from "../../utils/axios";
import {
  SET_OPEN_MODAL_DOCUMENT,
  SET_DOCUMENT_CATEGORY,
  RESET_DOCUMENTS,
  SET_MASTER_FILE_DOCUMENTS
} from "../actionTypes";
// import { entityTypeAndID } from "../../utils/entityTypeAndID";
import { store } from '../../App'
import { getPresignedUrlForMasterFile, uploadFileToDrive } from '../../utils/upload'
import { setLoadingUploadDocument } from "./view.actions";

export const setOpenModalDocument = (boolean) => {
  return {
    type: SET_OPEN_MODAL_DOCUMENT,
    payload: boolean
  }
}
export const setDocumentCategory = (category) => {
  return {
    type: SET_DOCUMENT_CATEGORY,
    payload: category
  }
}

export const resetDocuments = () => {
  return {
    type: RESET_DOCUMENTS
  }
}

export const setMasterFileDocuments = (files) => {
  return {
    type: SET_MASTER_FILE_DOCUMENTS,
    payload: files
  }
}

export const createMasterFileItem = ({ file }) => {
  return async dispatch => {
    try {
      const user = store.getState()?.auth?.user;
      const presignUrlResponse = await getPresignedUrlForMasterFile(file, user.owner_type, user.owner_id);
      console.log('createMasterFileItem resp ==', presignUrlResponse.data)
      await uploadFileToDrive(presignUrlResponse.data, file)
      dispatch(setLoadingUploadDocument(false))
      dispatch(getAllMasterFiles())
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - createMasterFileItem - ', error)
      dispatch(setLoadingUploadDocument(false))
    }
  }
}

export const getAllMasterFiles = () => {
  return async dispatch => {
    try {
      const user = store.getState().auth.user;
      let resp = await postRequest(`/entity/${user.owner_type}/${user.owner_id}/master-file/get-all`, { data: {} })
      console.log('getAllMasterFiles resp ==', resp.data)
      dispatch(setMasterFileDocuments(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getAllMasterFiles - ', error)
    }
  }
}

export const masterFileChangeState = ({master_file_id, state}) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/master-file/${master_file_id}/do-action/${state}`, { data: {} })
      console.log('masterFileChangeState resp ==', resp.data)
      dispatch(getAllMasterFiles())
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - masterFileChangeState - ', error)
    }
  }
}
