import * as React from "react";
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
} from "recharts";
import Title from "./Title";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const ClaimsAndCasesChart = () => {
  const casesStatus = useSelector(state => state.gop.overviewStats?.casesStatus)
  const navigate = useNavigate();

  return (
    <>
      <Title>Cases / Claims Status</Title>
      <ResponsiveContainer>
        <BarChart width={100} height={100}  onClick={(data) => { navigate('/cases/' + data?.activePayload?.[0]?.payload?.caseProgress?.toLowerCase?.(), { state: { type: data?.activePayload?.[0]?.payload?.caseProgress }}) }} data={casesStatus}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="amt" fill="#B1E9EF" barSize={50} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default ClaimsAndCasesChart;
