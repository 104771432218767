import { useEffect, useState } from "react";
import {
  Avatar, Table, TableBody,
  TableCell, TableHead, TableRow,
  Typography, Button, Box
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Title from "./Title";
import { useDispatch, useSelector } from 'react-redux';
import AssignToModal from "./AssignToModal";
import { setOpenModalAssignTo, setAssignToGopId, setSelectedGop, assignToMeGop } from "../../../redux/actions/gop.actions";
import { useNavigate } from "react-router-dom";
import deepCopy from "../../../utils/deepCopy";
import { GOP_BTN } from "../../../constants/viewConfig";
import { addMessageToSnackbar } from "../../../redux/actions/view.actions";
import { OverviewCasesAndClaimsSearch } from "../../../shared";
import _ from "lodash";

const CasesAndClaimsTableIMS = () => {
  const dispatch = useDispatch();
  const allGops = useSelector(state => state.gop.allGops)
  const mainViewConfig = useSelector(state => state.view.mainViewConfig)
  const [allGopsSorted, setAllGopsSorted] = useState([]);
  const [searched, setSearched] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setAllGopsSorted(sortGopsByTimestamp(allGops))
  }, [allGops])

  const TableCellClick = ({ isOnClick = true, children, gop }) => {
    return (
      <TableCell onClick={isOnClick ? () => {
        if (gop?.value?.a_viewConfig?.case_progress?.length !== 0) {
          dispatch(setSelectedGop(gop))
          navigate(`/cases/${gop?.value?.a_viewConfig?.case_progress?.[0]?.toLowerCase?.()}`, { state: { type: gop?.value?.a_viewConfig?.case_progress?.[0] } })
        } else {
          dispatch(addMessageToSnackbar(`GOP's case progress not found`, 'info'))
        }
      } : () => { }}>
        {children}
      </TableCell>
    )
  }

  const sortGopsByTimestamp = (gops) => {
    return deepCopy(gops).sort((a, b) => {
      return b.value.a_updated_at_timestamp - a.value.a_updated_at_timestamp;
    })
  }

  const requestSearch = (filterValue) => {
    const filtered = allGops.filter((row) => {
      return Object.keys(row.value).some((key) => {
        let firstLevelFound = false
        if (typeof row?.value[key] === 'string') {
          firstLevelFound = row?.value[key]?.toLowerCase()?.includes(filterValue?.toLowerCase())
        }
        let firstNameFound = row?.value?.patient?.firstName?.toLowerCase()?.includes(filterValue?.toLowerCase())
        let lastNameFound = row?.value?.patient?.lastName?.toLowerCase()?.includes(filterValue?.toLowerCase())
        return (firstLevelFound || firstNameFound || lastNameFound)
      });
    });
    setAllGopsSorted(sortGopsByTimestamp(filtered));
  }

  const onHandleSearchValueChange = (event) => {
    setSearched(event.target.value)
    const handleChangeDebounce = _.debounce((value) => { requestSearch(value) }, 1000)
    handleChangeDebounce(event.target.value);
  }

  const clearSearch = () => {
    setSearched("")
    setAllGopsSorted(sortGopsByTimestamp(allGops));
  }

  return (
    <>
      <Box>
        <OverviewCasesAndClaimsSearch
          searchValue={searched}
          onChangeValue={onHandleSearchValueChange}
          clearSearch={clearSearch} />
      </Box>
      <Title>Cases / Claims</Title>
      <Table size="small">
        <TableHead sx={{ backgroundColor: "#B1E9EF" }}>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Clinic</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>GOP ID</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Member Name</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Family Name</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Claim Number</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Last Updated</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Assignee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allGops?.length > 0 && allGopsSorted.map((gop, index) => {
            const {
              keys: {
                gop_id = ''
              } = {},
              value: {
                patient: {
                  firstName = '',
                  lastName = '',
                } = {},
                caseDetails: {
                  admission: {
                    patientId = ''
                  } = {}
                } = {},
                a_clinic: {
                  name: clinicName = ''
                } = {},
                // assignedToCLINIC_CLAIM_ADMIN = {},
                a_updated_at_ddmmyyyyhhmmss = '',
                assigned_to_ims_claim_handler: {
                  name: assignedClaimHandler = ''
                } = {},
                // assignedToIMS_PCL = {},
                // assignedToIMS_MEDICAL_OFFICER = {},
                a_viewConfig: {
                  show = [],
                } = {},
                a_status_title = '',
                a_status_color = 'orange',
                claim: {
                  claimNum = ''
                } = {}
              } = {}
            } = gop || {}
            return (<TableRow key={patientId + index}>
              <TableCellClick gop={gop}>{clinicName}</TableCellClick>
              <TableCellClick gop={gop}>{gop_id}</TableCellClick>
              <TableCellClick gop={gop}>{firstName}</TableCellClick>
              <TableCellClick gop={gop}>{lastName}</TableCellClick>
              <TableCellClick gop={gop}>{claimNum}</TableCellClick>
              <TableCellClick gop={gop}>
                <CircleIcon
                  sx={{
                    fontSize: "5px",
                    marginRight: 1,
                    color: a_status_color
                  }}
                />
                {a_status_title}
              </TableCellClick>
              <TableCellClick gop={gop}>{a_updated_at_ddmmyyyyhhmmss}</TableCellClick>
              <TableCellClick isOnClick={!!assignedClaimHandler} gop={gop}>
                {assignedClaimHandler && <div style={{ display: "flex", flexDirection: "row" }}>
                  <Avatar
                    sx={{
                      width: "25px",
                      height: "25px",
                      fontSize: "14px",
                      backgroundColor: "#14B2C0",
                      marginRight: "10px",
                    }}
                  >
                    M
                  </Avatar>

                  <Typography variant="body2">{assignedClaimHandler}</Typography>
                </div>}
                {!assignedClaimHandler && show.includes(GOP_BTN.ASSIGN_TO_ME) &&
                  <Button variant='text' color='secondary' onClick={() => {
                    // dispatch(setOpenModalAssignTo(true));
                    // dispatch(setAssignToGopId(gop_id));
                    dispatch(setSelectedGop(gop));
                    dispatch(assignToMeGop({ navigate }))
                  }}>Assign</Button>}
                {!assignedClaimHandler && mainViewConfig?.show?.includes?.(GOP_BTN.ASSIGN_TO_FROM_OVERVIEW) &&
                  <Button variant='text' color='secondary' onClick={() => {
                    dispatch(setOpenModalAssignTo(true));
                    dispatch(setAssignToGopId(gop_id));
                  }}>Assign To</Button>}
              </TableCellClick>
            </TableRow>)
          }
          )}
        </TableBody>
      </Table>
      <AssignToModal handleClose={() => { dispatch(setOpenModalAssignTo(false)); dispatch(setAssignToGopId('')) }} />
    </>
  );
};

export default CasesAndClaimsTableIMS;
