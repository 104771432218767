import React, { useState } from "react";
import {
  Box, Paper, Grid, TableContainer, Table, TableHead, TableRow,
  TableCell, TableBody, TableFooter, TablePagination
} from "@mui/material";

const PolicyScheduleOfBenefits = ({ rows = [] }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Grid container >
      <Grid item xs={12} sm={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '100%' }} size="small" aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>*</TableCell>
                <TableCell align="center">UYR</TableCell>
                <TableCell align="center">STATS</TableCell>
                <TableCell align="left">CLAIMDETAILS</TableCell>
                <TableCell align="center">LIMIT</TableCell>
                <TableCell align="center">EXCESS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell>
                    {row.UYR}
                  </TableCell>
                  <TableCell align="left">
                    {row.STATS}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.CLAIMDETAILS}
                  </TableCell>
                  <TableCell align="right">
                    {row.LIMIT}
                  </TableCell>
                  <TableCell align="right">
                    {row.EXCESS}
                  </TableCell>
                </TableRow>
              ))}

              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={5}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>

    </Grid>
  )
}

export default PolicyScheduleOfBenefits;