const deepCopy = (src) => {
  let target = Array.isArray(src) ? [] : {};
  for (let prop in src) {
    let value = src[prop];
    // todo add typeof array and test
    if(value && (typeof value === 'object')) {
      target[prop] = deepCopy(value);
  } else {
      target[prop] = value;
  }
 }
    return target;
}

export default deepCopy;