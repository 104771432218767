

const ENTITY_TABLES = {
  clinic:"clinics",
  provider:"providers",
  cg:"cgs"
};
const ENTITY_TYPE_BY_TABLE = {
  clinics: "clinic",
  providers: "provider",
  cgs: "cg"
};

export const entityTypeAndID = (entity) => {
  return {
    entityType: ENTITY_TYPE_BY_TABLE[entity.keys?.table],
    entityID: entity.keys?.[ENTITY_TYPE_BY_TABLE[entity.keys?.table]+"_id"]
  }
}