// view_config_constants.js
const GOP_TITLE = {
  CLAIM_BILL_TO_BE_VALIDATED: 'CLAIM_BILL_TO_BE_VALIDATED',
  GUARANTEED_AMOUNT: 'GUARANTEED_AMOUNT',
  POLICY_EXPIRED: 'TITLE_POLICY_EXPIRED',
  POLICY_EXPIRED_MESSAGE_BOX: 'POLICY_EXPIRED_MESSAGE_BOX'

}

const CASE_PROGRESS = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
  AWAITING_MY_ACTION: 'AWAITING_MY_ACTION'
}


const GOP_BTN = {
  ADD_NEW: 'GOP_BTN_ADD_NEW',
  ASSIGN_TO_FROM_OVERVIEW: 'GOP_BTN_ASSIGN_TO_FROM_OVERVIEW',
  ASSIGN_TO_ME: 'GOP_BTN_ASSIGN_TO_ME',
  ADD_ANOTHER_BILL: 'GOP_BTN_ADD_ANOTHER_BILL',

  OPEN_FEES: 'GOP_BTN_OPEN_FEES',
  CAPTURE_DATA: 'GOP_BTN_CAPTURE_DATA',
  VALIDATE_BILL: 'GOP_BTN_VALIDATE_BILL',
  VIEW_BILL: 'GOP_BTN_VIEW_BILL',

  SAVE_CASE_DETAILS: 'GOP_BTN_SAVE_CASE_DETAILS',
  CANCEL_CASE_DETAILS: 'GOP_BTN_CANCEL_CASE_DETAILS',
  SAVE_ONE_FEE: 'GOP_BTN_SAVE_ONE_FEE',
  CANCEL_ONE_FEE: 'GOP_BTN_CANCEL_ONE_FEE',
  CANCEL_BILL: 'GOP_BTN_CANCEL_BILL',

  SAVE_BILL: 'GOP_BTN_SAVE_BILL',
  SAVE_AS_PROCESSED_BILL: 'GOP_BTN_SAVE_AS_PROCESSED_BILL',
  SAVE_AS_DONE_BILL: 'GOP_BTN_SAVE_AS_DONE_BILL',
  SAVE_AND_SEND_ADJUDICATION_BILL: 'GOP_BTN_SAVE_AND_SEND_ADJUDICATION_BILL',

  SAVE_INP_BILL: 'GOP_BTN_SAVE_INP_BILL',
  SAVE_CAT_BILL: 'GOP_BTN_SAVE_CAT_BILL',
  SAVE_INP_BILL_AS_COMPLETED: 'GOP_BTN_SAVE_INP_BILL_AS_COMPLETED',
  SAVE_CAT_BILL_AS_COMPLETED: 'GOP_BTN_SAVE_CAT_BILL_AS_COMPLETED',
  CANCEL_INP_BILL: 'GOP_BTN_CANCEL_INP_BILL',
  CANCEL_CAT_BILL: 'GOP_BTN_CANCEL_CAT_BILL',
  SAVE_AS_DONE_INP_BILL: 'GOP_BTN_SAVE_AS_DONE_INP_BILL',
  SAVE_AS_DONE_CAT_BILL: 'GOP_BTN_SAVE_AS_DONE_CAT_BILL',
  SAVE_AND_SEND_ADJUDICATION_INP_BILL: 'GOP_BTN_SAVE_AND_SEND_ADJUDICATION_INP_BILL',
  SAVE_AND_SEND_ADJUDICATION_CAT_BILL: 'GOP_BTN_SAVE_AND_SEND_ADJUDICATION_CAT_BILL',

  QUERY_ACTION_LIST: 'GOP_BTN_QUERY_ACTION_LIST'

}

const ENTITY_BTN = {
  ENTITY_BTN_ADD_ENTITY: 'ENTITY_BTN_ADD_ENTITY',
  ENTITY_BTN_EDIT_ENTITY: 'ENTITY_BTN_EDIT_ENTITY',

  ENTITY_BTN_ADD_CONTACT: 'ENTITY_BTN_ADD_CONTACT',
  ENTITY_BTN_EDIT_CONTACT: 'ENTITY_BTN_EDIT_CONTACT',

  ENTITY_BTN_ADD_USER: 'ENTITY_BTN_ADD_USER',
  ENTITY_BTN_EDIT_USER: 'ENTITY_BTN_EDIT_USER',
  ENTITY_BTN_DELETE_USER: 'ENTITY_BTN_DELETE_USER',
  ENTITY_BTN_REACTIVATION_USER: 'ENTITY_BTN_REACTIVATION_USER',
  ENTITY_BTN_DEACTIVATED_USER: 'ENTITY_BTN_DEACTIVATED_USER',

  ENTITY_BTN_ADD_ROLE: 'ENTITY_BTN_ADD_ROLE',
  ENTITY_BTN_EDIT_ROLE: 'ENTITY_BTN_EDIT_ROLE',
  ENTITY_BTN_DELETE_ROLE: 'ENTITY_BTN_DELETE_ROLE'
}

const SIDE_DOCK = {
  OVERVIEW: 'SIDE_DOCK_OVERVIEW',

  GROUP_MANAGEMENT: 'SIDE_DOCK_GROUP_MANAGEMENT',
  COMPANY_GROUPS: 'SIDE_DOCK_COMPANY_GROUPS',
  CLINICS: 'SIDE_DOCK_CLINICS',

  CASES_CLAIMS: 'SIDE_DOCK_CASES_CLAIMS',
  CASES_CLAIMS_IN_PROGRESS: 'SIDE_DOCK_CASES_CLAIMS_IN_PROGRESS',
  CASES_CLAIMS_OPEN: 'SIDE_DOCK_CASES_CLAIMS_OPEN',
  CASES_CLAIMS_CLOSED: 'SIDE_DOCK_CASES_CLAIMS_CLOSED',
  CASES_CLAIMS_AWAITING_MY_ACTION: 'SIDE_DOCK_CASES_CLAIMS_AWAITING_MY_ACTION',

  CASES_CLAIMS_OPEN_L: 'CASES_CLAIMS_OPEN_L',
  CASES_CLAIMS_CLOSED_L: 'CASES_CLAIMS_CLOSED_L',

  USERS_PERMISSIONS: 'SIDE_DOCK_USERS_PERMISSION',
  USERS_PERMISSIONS_USER_TYPES: 'SIDE_DOCK_USERS_PERMISSIONS_USER_TYPES',
  USERS_PERMISSIONS_PERMISSIONS_RULES: 'SIDE_DOCK_USERS_PERMISSIONS_PERMISSIONS_RULES',
  USERS_PERMISSIONS_USERS: 'SIDE_DOCK_USERS_PERMISSIONS_USERS',

  REPORTS: 'SIDE_DOCK_REPORTS',

  MASTER_FILE: 'SIDE_DOCK_MASTER_FILE',
  PRICE_LIST: 'SIDE_DOCK_PRICE_LIST'
}

const GOP_TEXT_FIELDS = {
  ADMISSION: {
    PATIENT_ID: 'PATIENT_ID',
    DATE_OF_ADMISSION: 'DATE_OF_ADMISSION',
    TIME_OF_ADMISSION: 'TIME_OF_ADMISSION',
    ROOM_NUMBER: 'ROOM_NUMBER',
    LENGTH_OF_STAY: 'LENGTH_OF_STAY'
  },
  TREATMENT_AND_DOCTOR: {
    TREATMENT_TYPE: 'TREATMENT_TYPE',
    DIAGNOSTICS: 'DIAGNOSTICS',
    OTHER_DIAGNOSTICS: 'OTHER_DIAGNOSTICS',
    CLINIC_COMMENT: 'CLINIC_COMMENT'
  }
}

const GOP_POLICY_DETAILS_LIST = {
  COVERAGE: 'GOP_POLICY_DETAILS_LIST_COVERAGE',
  TREATMENT: 'GOP_POLICY_DETAILS_LIST_TREATMENT',
  EXCLUSIONS: 'GOP_POLICY_DETAILS_LIST_EXCLUSIONS',
  CATASTROPHE: 'GOP_POLICY_DETAILS_LIST_CATASTROPHE',
  INPATIENT: 'GOP_POLICY_DETAILS_LIST_INPATIENT',
  CLAIMS_HISTORY: 'GOP_POLICY_DETAILS_LIST_CLAIMS_HISTORY',
  SOB: 'GOP_POLICY_DETAILS_LIST_SOB',
}

const GOP_CASE_DETAILS_SECTION_LIST = {
  SECTION_TREATMENT_AND_DOCTOR: 'SECTION_TREATMENT_AND_DOCTOR',
  SECTION_BILLING_INFORMATION: 'SECTION_BILLING_INFORMATION',
  SECTION_ADDITIONAL_INFORMATION: 'SECTION_ADDITIONAL_INFORMATION',

}

const GOP_CASE_DETAILS_TEXT_FIELDS = {
  GOP_CASE_DETAILS_TEXT_FIELD_CLAIMNUM: 'GOP_CASE_DETAILS_TEXT_FIELD_CLAIMNUM',
  GOP_CASE_DETAILS_TEXT_FIELD_SETNO: 'GOP_CASE_DETAILS_TEXT_FIELD_SETNO',
  ESTIMATE_AMOUNT: 'GOP_CASE_DETAILS_TEXT_FIELD_ESTIMATE_AMOUNT',
}
const GOP_DROPDOWNS = {
  ROOM_TYPE: 'DROPDOWN_ROOM_TYPE',
  ADMITTING_DOCTOR: 'DROPDOWN_ADMITTING_DOCTOR',
  OTHER_DOCTOR: 'DROPDOWN_OTHER_DOCTOR',
  DIAGNOSIS: 'DROPDOWN_DIAGNOSIS',
  TREATMENT_TYPE: 'DROPDOWN_TREATMENT_TYPE'
}
const CASE_DETAILS_DROPDOWNS = {
  STATSCODE1: 'DROPDOWN_STATSCODE1',
  STATSCODE2: 'DROPDOWN_STATSCODE2',
  CLAIM_DETAILS_1: 'DROPDOWN_CLAIM_DETAILS_1',
  CANCER: 'DROPDOWN_CANCER',
  ICD10CODE: 'DROPDOWN_ICD10CODE',
  HEALTHCARE_STATS1: 'DROPDOWN_HEALTHCARE_STATS1',
  CHRONICS_CONGENITAL: 'DROPDOWN_CHRONICS_CONGENITAL',
  CASE_DETAILS_CASE_STATUS: 'DROPDOWN_CASE_DETAILS_CASE_STATUS',
  FEES_TYPE: 'DROPDOWN_CASE_DETAILS_FEES_TYPE'

}
const OTHER_VALUES = {
  DOCTOR_NOT_IN_LIST: 'DOCTOR_NOT_IN_LIST'
}

// need to deprecate from here...
const GOP_FEES_COLUMNS = {
  CLAIMED: 'FEES_COLUMN_CLAIMED',
  PAYABLE: 'FEES_COLUMN_PAYABLE',
  PAID: 'FEES_COLUMN_AMT_PAID',
  SUBITEMS: 'FEES_COLUMN_SUBITEMS',
  DETAILS: 'FEES_COLUMN_DETAILS',
}

const GOP_FEES_INPUTS = {
  CLAIMED: 'FEES_INPUT_CLAIMED',
  PAYABLE: 'FEES_INPUT_PAYABLE',
  PAID: 'FEES_INPUT_AMT_PAID',
  EXTERNAL_REMARKS: 'FEES_EXTERNAL_REMARKS'
}
// ... till here.

const GOP_FEES_CATASTROPHE_COLUMNS = {
  CLAIMED: 'FEES_CATASTROPHE_COLUMN_CLAIMED',
  PAYABLE: 'FEES_CATASTROPHE_COLUMN_PAYABLE',
  PAID: 'FEES_CATASTROPHE_COLUMN_AMT_PAID',
  SUBITEMS: 'FEES_CATASTROPHE_COLUMN_SUBITEMS',
  DETAILS: 'FEES_CATASTROPHE_COLUMN_DETAILS',
}
const GOP_FEES_INPATIENT_COLUMNS = {
  CLAIMED: 'FEES_INPATIENT_COLUMN_CLAIMED',
  PAYABLE: 'FEES_INPATIENT_COLUMN_PAYABLE',
  PAID: 'FEES_INPATIENT_COLUMN_AMT_PAID',
  SUBITEMS: 'FEES_INPATIENT_COLUMN_SUBITEMS',
  DETAILS: 'FEES_INPATIENT_COLUMN_DETAILS',
}
const GOP_FEES_INPATIENT_INPUTS = {
  CLAIMED: 'FEES_INPATIENT_INPUT_CLAIMED',
  PAYABLE: 'FEES_INPATIENT_INPUT_PAYABLE',
  PAID: 'FEES_INPATIENT_INPUT_AMT_PAID',
  EXTERNAL_REMARKS: 'FEES_INPATIENT_EXTERNAL_REMARKS'
}

const GOP_FEES_CATASTROPHE_INPUTS = {
  CLAIMED: 'FEES_CATASTROPHE_INPUT_CLAIMED',
  PAYABLE: 'FEES_CATASTROPHE_INPUT_PAYABLE',
  PAID: 'FEES_CATASTROPHE_INPUT_AMT_PAID',

  EXTERNAL_REMARKS: 'FEES_CATASTROPHE_EXTERNAL_REMARKS',
  INTERNAL_REMARKS: 'FEES_CATASTROPHE_INTERNAL_REMARKS'
}

const GOP_FEES_TABS = {
  INPATIENT: 'FEES_TAB_INPATIENT',
  CATASTROPHE: 'FEES_TAB_CATASTROPHE'
}
const GOP_FEES_TYPES = {
  INPATIENT: 'INPATIENT',
  CATASTROPHE: 'CATASTROPHE',
  INPATIENT_CATASTROPHE: 'INPATIENT_CATASTROPHE'
}
const GOP_POLICY_DETAILS = {
  CLINIC_SIDE: {
    COVERAGE: GOP_POLICY_DETAILS_LIST.COVERAGE,
    TREATMENT: GOP_POLICY_DETAILS_LIST.TREATMENT
  },
  IMS_SIDE: {
    EXCLUSIONS: GOP_POLICY_DETAILS_LIST.EXCLUSIONS,
    CATASTROPHE: GOP_POLICY_DETAILS_LIST.CATASTROPHE,
    INPATIENT: GOP_POLICY_DETAILS_LIST.INPATIENT,
    CLAIMS_HISTORY: GOP_POLICY_DETAILS_LIST.CLAIMS_HISTORY,
    SOB: GOP_POLICY_DETAILS_LIST.SOB,
  },
  BOTH_SIDES: {}
};
const PAGES = {
  VALIDATE_BILL: { path: '/cases/validateBill', params: {}},
  VIEW_BILL: { path: '/cases/viewBill', params: {}},
  CAPTURE_DATA: { path: '/cases/captureData', params: {}},
  CLINICS: { path: '/clinics', params: {}},
  COMPANY_GROUPS: { path: '/companyGroups', params: {}},
  OVERVIEW: { path: '/cases/overview', params: {}},
  CASES: {
    OPEN: { path: '/cases/open', params: {type: CASE_PROGRESS.OPEN}},
    CLOSED: { path: '/cases/closed', params: {type: CASE_PROGRESS.CLOSED}},
    IN_PROGRESS: { path: '/cases/in_progress', params: {type: CASE_PROGRESS.IN_PROGRESS}},
    AWAITING_MY_ACTION: { path: '/cases/awaiting_my_action', params: {type: CASE_PROGRESS.AWAITING_MY_ACTION}}
  },
  L_CASES: {
    OPEN: { path: '/lcases/open', params: {}},
    CLOSED: { path: '/lcases/closed', params: {}}
  },
  PAGE_CHANGE_PASSWORD: { path: '/auth/change-password', params: {}},
  PAGE_FORGOT_PASSWORD_STEP1: { path: '/auth/forgot-password?step=1', params: {}},
  PAGE_FORGOT_PASSWORD_STEP2: { path: '/auth/forgot-password?step=2', params: {}},
  PAGE_FORGOT_PASSWORD_STEP3: { path: '/auth/forgot-password?step=3', params: {}},

  PAGE_RESET_PASSWORD_STEP1: { path: '/auth/reset-password?step=1', params: {}},
  PAGE_RESET_PASSWORD_STEP2: { path: '/auth/reset-password?step=2', params: {}},
  PAGE_RESET_PASSWORD_STEP3: { path: '/auth/reset-password?step=3', params: {}},

  MASTER_FILE: { path: '/master-file', params: {}},
}

const L_GOP_DROPDOWNS = {
  L_CASE_STATUS_OPEN: 'DROPDOWN_L_CASE_STATUS_OPEN',
  L_CASE_STATUS_CLOSED: 'DROPDOWN_L_CASE_STATUS_CLOSED'
}
const MASTER_FILE_BTN = {
  ADD_NEW: 'MASTER_FILE_BTN_ADD_NEW'
}

const MASTER_FILE_DROPDOWNS = {
  MASTER_FILE_STATUS: 'DROPDOWN_MASTER_FILE_STATUS'
}

const QUERY_TYPES = {
  CLINIC_QUERY: 'CLINIC_QUERY',
  SWAN_QUERY: 'CLINIC_QUERY',
  PCL_QUERY: 'CLINIC_QUERY',
  MEDICAL_QUERY: 'CLINIC_QUERY',

}
const QUERY = {
  BTN_CREATE_CHAT: 'QUERY_BTN_CREATE_CHAT',
  BTN_ADD_USER_TO_CHAT: 'QUERY_BTN_ADD_USER_TO_CHAT',
  BTN_RESOLVE_CHAT: 'QUERY_BTN_RESOLVE_CHAT',
  BTN_REOPEN_CHAT: 'QUERY_BTN_REOPEN_CHAT',

  INPUT_REPLY: 'QUERY_INPUT_REPLY',

}

const ALL_DROPDOWNS_VALUES = Object.values ({
  ...GOP_DROPDOWNS, ...CASE_DETAILS_DROPDOWNS, ...L_GOP_DROPDOWNS, ...MASTER_FILE_DROPDOWNS
})

const REMARKS = {
  FEE_ITEM_INTERNAL: 'REMARK_FEE_INTERNAL',
  FEE_ITEM_EXTERNAL: 'REMARK_FEE_EXTERNAL',
  FEE_TAB_INTERNAL: 'REMARK_FEE_TAB_INTERNAL',
  FEE_TAB_EXTERNAL: 'REMARK_FEE_TAB_EXTERNAL',
  BILL: 'REMARK_BILL',
  GOP_BILL: 'REMARK_GOP_BILL',
  INTERIM_BILL: 'REMARK_INTERIM_BILL',
  FINAL_BILL: 'REMARK_FINAL_BILL',
  GOP_VETTING: 'REMARK_GOP_VETTING',
  DISALLOWED_ITEMS_EXTERNAL: 'REMARK_DISALLOWED_ITEMS_EXTERNAL'
}

const DISALLOWED_ITEMS = {
  SECTION: "DISALLOWED_ITEMS_SECTION",
  INPUTS: "DISALLOWED_ITEMS_INPUTS",
  DIS_ITEMS_BTN: "DIS_ITEMS_BTN",
  ADD_BTN: "DISALLOWED_ITEM_ADD_BTN",
  REMOVE_BTN: "DISALLOWED_ITEM_REMOVE_BTN",
  SAVE_BTN: "DISALLOWED_ITEMS_SAVE_BTN"
};


const BILLS = {
  REMOVE_FILE_BTN: "BILLS_REMOVE_FILE_BTN"
};

const GOP_FILE = {
  CAN_BE_TRASHED: "GOP_FILE_CAN_BE_TRASHED"
};

export {
  GOP_TITLE,
  CASE_PROGRESS,
  GOP_DROPDOWNS,
  GOP_BTN,
  ENTITY_BTN,
  SIDE_DOCK,
  GOP_TEXT_FIELDS,
  GOP_POLICY_DETAILS,
  GOP_POLICY_DETAILS_LIST,
  GOP_CASE_DETAILS_SECTION_LIST,
  CASE_DETAILS_DROPDOWNS,
  OTHER_VALUES,
  GOP_FEES_COLUMNS,
  GOP_FEES_INPUTS,
  GOP_FEES_TABS,
  GOP_FEES_INPATIENT_COLUMNS,
  GOP_FEES_CATASTROPHE_COLUMNS,
  GOP_FEES_INPATIENT_INPUTS,
  GOP_FEES_CATASTROPHE_INPUTS,
  GOP_FEES_TYPES,
  GOP_CASE_DETAILS_TEXT_FIELDS,
  L_GOP_DROPDOWNS,
  PAGES,
  MASTER_FILE_DROPDOWNS,
  MASTER_FILE_BTN,
  ALL_DROPDOWNS_VALUES,
  REMARKS,
  DISALLOWED_ITEMS,
  BILLS,
  GOP_FILE,
}

//  constants.js

export const BILL_TYPE = {
  GUARANTEE_OF_PAYMENT: 'GUARANTEE_OF_PAYMENT',
  INTERIM_BILL: 'INTERIM_BILL',
  FINAL_BILL: 'FINAL_BILL'
}

export const BILL_TYPE_TITLE = {
  GUARANTEE_OF_PAYMENT: 'Guarantee of payment',
  INTERIM_BILL: 'Interim bill',
  FINAL_BILL: 'Final bill'
}

export const LOADING_STATE = {
  IS_LOADING: 'IS_LOADING',
  LOADING_SUCCESSFUL: 'LOADING_SUCCESSFUL',
  LOADING_FAILED: 'LOADING_FAILED',
}

export const PRICELIST_BUTTONS = {
  EXTRACT: "EXTRACT_PLIST_BTN",
  DELETE_XLS: "DELETE_XLS_PLIST_BTN",
  DELETE_EXTRACTED_DATA: "DELETE_EXTRACTED_DATA_PLIST_BTN",
  DEACTIVATE: 'DEACTIVATE_PLIST_BTN',
  REACTIVATE: 'REACTIVATE_PLIST_BTN'
};

export const PRICELIST_DROPDOWNS = {
  VIEW_TEMPLATE: "VIEW_TEMPLATE_DROPDOWN",
  EXTRACTION_TEMPLATE: "EXTRACTION_TEMPLATE_DROPDOWN",
}

export const PRICELIST_WB_STATUS = {
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  EXTRACTING: 'EXTRACTING',
  EXTRACTED_SUCCESSFULLY: 'EXTRACTED_SUCCESSFULLY',
  EXTRACTED_WITH_ERRORS: 'EXTRACTED_WITH_ERRORS',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED'
};