import React, { useState } from "react";
import { Box, Typography, ButtonBase, Paper, Grid, IconButton } from "@mui/material";
import { useDispatch } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const TextItem = ({ value, title }) => {
  return (
    !!value ? (<Box display='flex' alignItems='center' flexDirection='row' p={1} >
      <Box display='flex' alignItems='flex-start' flexDirection='column' pl={1}>
        <Typography sx={{ fontFamily: 'Globersemibold', color: 'primary.main', letterSpacing: '1.5px', textTransform: 'uppercase', fontSize: 10 }}>{title}</Typography>
        <Typography sx={{ fontFamily: 'Globerregular', fontSize: 14 }}>{value}</Typography>
      </Box>
    </Box>) : (<></>)
  )
};

const ClaimsHistoryCard = ({ claim }) => {
  const {
    DATE_OF_OCCURENCE = '-',
    CLAIM_ID = '-',
    AMT_PAID = '-',
    CLM_STATUS = '-',
    AMT_OS = '-',
    EXCESS_AMT = '-',
    // CLM_DETAILS = '-',
    CLM_DETAILS1 = '',
    CLM_DETAILS2 = '',
    CLM_DETAILS3 = '',
    CLM_DETAILS4 = '',
    CLM_DETAILS5 = '',
    HCARESTATS1DETAILS = '',
    HCARESTATS2DETAILS = '',
    HCARESTATS3DETAILS = '',
    ICD10 = '-',
    STATS = '-',
    POLICE_STATION = '',
    PLACE_OF_OCCURENCE = '',
    UYR = '-'
  } = claim || {};
  const [isOpen, setIsOpen] = useState(false); 



  return (
    <Paper
      elevation={3}
      sx={{
        marginTop: 3, marginBottom: 3, marginLeft: 2,
        borderRadius: '5px', overflow: 'hidden', maxWidth: '500px'
      }}>
      {/* first row cards claim history */}
      <Grid container pl={1} pr={1}>
        <Grid item sm={4}><TextItem title={'date of occurence'} value={DATE_OF_OCCURENCE} /></Grid>
        <Grid item sm={4}><TextItem title={'claim id'} value={CLAIM_ID} /></Grid>
        <Grid item sm={4}><TextItem title={'amount paid'} value={AMT_PAID} /></Grid>
      </Grid>

      {/* second row cards claim history */}
      <Grid container pl={1} pr={1}>
        <Grid item sm={4}><TextItem title={'status'} value={CLM_STATUS} /></Grid>
        <Grid item sm={4}><TextItem title={'amount o/s'} value={AMT_OS} /></Grid>
        <Grid item sm={4}><TextItem title={'excess'} value={EXCESS_AMT} /></Grid>
      </Grid>
      {/* third row cards claim history */}
      <Grid container pl={1} pr={1}>
        <Grid item sm={4}><TextItem title={'uyr'} value={UYR} /></Grid>
        <Grid item sm={4}><TextItem title={'statistic code'} value={STATS} /></Grid>
        <Grid item sm={4}><TextItem title={'ICD10 code'} value={ICD10} /></Grid>
        <Grid item sm={12}><Box sx={{ borderTop: '1px solid #c4c4c4' }} ml={2} mr={2} /></Grid>
      </Grid>
      {/* third row cards claim history */}
      <Grid container pl={1} pr={1} justifyContent='flex-end' >
        <Grid item sm={2}><Box ml={2} mr={2} />
          <IconButton color='secondary' onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Grid>
      </Grid>
      {isOpen && <Grid container pl={1} pr={1} pb={1} >
        <Grid item sm={12}><Box sx={{ borderTop: '1px solid #c4c4c4' }} ml={2} mr={2} /></Grid>
        <Grid item sm={12}><TextItem title={'h/care stats 1'} value={HCARESTATS1DETAILS} /></Grid>
        <Grid item sm={12}><TextItem title={'h/care stats 2'} value={HCARESTATS2DETAILS} /></Grid>
        <Grid item sm={12}><TextItem title={'h/care stats 3'} value={HCARESTATS3DETAILS} /></Grid>
        {(!!HCARESTATS1DETAILS || !!HCARESTATS2DETAILS || !!HCARESTATS3DETAILS) &&
          <Grid item sm={12}><Box sx={{ borderTop: '1px solid #c4c4c4' }} ml={2} mr={2} /></Grid>
        }
        <Grid item sm={12}><TextItem title={'place of occurrence'} value={PLACE_OF_OCCURENCE} /></Grid>
        <Grid item sm={12}><TextItem title={'police station'} value={POLICE_STATION} /></Grid>
        {(!!PLACE_OF_OCCURENCE || !!POLICE_STATION) &&
          <Grid item sm={12}><Box sx={{ borderTop: '1px solid #c4c4c4' }} ml={2} mr={2} /></Grid>
        }
        <Grid item sm={12}><TextItem title={'details of claim 1'} value={CLM_DETAILS1} /></Grid>
        <Grid item sm={12}><TextItem title={'details of claim 2'} value={CLM_DETAILS2} /></Grid>
        <Grid item sm={12}><TextItem title={'details of claim 3'} value={CLM_DETAILS3} /></Grid>
        <Grid item sm={12}><TextItem title={'details of claim 4'} value={CLM_DETAILS4} /></Grid>
        <Grid item sm={12}><TextItem title={'details of claim 5'} value={CLM_DETAILS5} /></Grid>

      </Grid>}
    </Paper>
  )
}

export default ClaimsHistoryCard;