import React, { useEffect, useState } from 'react';
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { Close, DeleteOutlined } from "@mui/icons-material";
import NotificationsItem from "./components/NotificationsItem";
import { useDispatch, useSelector } from 'react-redux';
import { setShowNotificationsDrawer } from '../../redux/actions/notifications.action';

const NotificationsPage = () => {
  const dispatch = useDispatch()
  const showNotificationsDrawer = useSelector(state => state.notifications.showNotificationsDrawer);
  const notifications = useSelector(state => state.notifications.notifications);
  const unseenNotificatons = useSelector(state => state.notifications.unseenNotificatons);

  useEffect(() => {
    // console.log("notifications - ", notifications);
   }, [notifications])

  return (
    <Drawer
      PaperProps={{
        sx: { width: "25%" },
      }}
      anchor={'right'}
      open={showNotificationsDrawer}
      onClose={() => dispatch(setShowNotificationsDrawer(false))}
    >
      <Box pl={'20px'} pr={'20px'} pt={'20px'} display='flex' alignItems='center'>
        <Typography
          sx={{
            color: '#053ad3',
            fontSize: "24px",
            fontFamily: 'Globersemibold'
          }}>
          Notifications
        </Typography>

        {!!unseenNotificatons && <Box justifyContent='center' alignItems='center'
          sx={{
            height: "24px",
            width: "24px",
            backgroundColor: '#eb0781',
            borderRadius: "60px",
            marginLeft: "10px",
            padding: "5px"
          }}>
          <Typography sx={{ color: 'white', fontFamily: 'globersemibold', textAlign: 'center' }}>
            {unseenNotificatons}
          </Typography>
        </Box>
        }

        <Box style={{ flexGrow: 1 }} />

        <IconButton onClick={() => dispatch(setShowNotificationsDrawer(false))}>
          <Close color={'secondary'} />
        </IconButton>
      </Box>

      <Box sx={{
        paddingLeft: "20px",
        paddingRight: "20px",
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          width: '5px',
          height: '120px'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#d90b7c',
          outline: '1px solid #d90b7c',
          marginRight: '3px'
        }
      }}>

        {
          notifications.map((item) => {
            const {
              keys: {
                notification_id = ''
              } = {},
              value: {
                avatar_url = '',
                state = '',
                datetime_str = '',
                msg = '',
                title = '',
                buttons = []
              } = {}
            } = item || {}
            return (
              <NotificationsItem key={notification_id}
                imageItem={avatar_url}
                state={state}
                notification_id={notification_id}
                title={title}
                timestamp={datetime_str}
                content={msg}
                buttons={buttons}
                onDeleteClick={() => console.log('onDeleteClick')}
              />
            )
          })
        }
      </Box>

    </Drawer>
  )
}
export default NotificationsPage