export const compareTimeStamp = (oldTime, newTime) => {
  const oldtime = parseInt(oldTime) ? parseInt(oldTime) : 0;
  const newtime = parseInt(newTime) ? parseInt(newTime) : 0;

  return oldtime < newtime;
}

export const numberWithCommas = (x) => {
  if (x) {
    return `Rs ${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  } else {
    return 'Rs 0';
  }
};


export const numberWithCommasAndDecimalAfterDot = (val) => {
  if (val) {
    let str = val.toString();
    let split = str.split('.');
    let decimal = split[1];
    if (!decimal) {
      decimal = '';
    }
    else {
      decimal = decimal.length > 1 ? `.${decimal.substring(0, 2)}` : `.${decimal}0`;
    }
    return `Rs ${split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + decimal}`;
  }
  else {
    return 'Rs 0';
  }
}