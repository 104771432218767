import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Box, Button, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Typography } from "@mui/material";
import { forgotPasswordDoAction } from "../../../redux/actions/auth.actions";
import { useNavigate } from "react-router-dom";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { onPasswordTestInputChange } from '../../../utils/onChangeFunction';

const PasswordControl = ({ id = '', label = '', value = '', showPassword, onHandleChangePassword = () => { }, onHandleShowPassword = () => { }, onHandleKeyDown = () => { } }) => {
  return (
    <FormControl
      fullWidth
      variant="filled"
      sx={{
        m: "20px 0",
      }}
    >
      <InputLabel
        htmlFor={id}
        sx={{
          fontSize: "12px",
          color: "#00b7cd",
          fontWeight: "bold",
        }}
      >
        {label}
      </InputLabel>
      <FilledInput
        id={id}
        type={showPassword ? "text" : "password"}
        placeholder="Insert password"
        value={value}
        onChange={onHandleChangePassword}
        sx={{
          color: "rgba(59, 64, 69, 0.87)",
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onHandleShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}

const ForgotPasswordStep3 = ({ location, from, forgotPassState, setForgotPassState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const action = 'INSERT_NEW_PASSWORD';
  let [newPassword, setNewPassword] = useState("");
  let [showPassword, setShowPassword] = useState(false);
  let [confirmPassword, setConfirmPassword] = useState("");
  let [showConfirmPassword, setShowConfirmPassword] = useState(false);
  let [passwordValidationRules, setPasswordValidationRules] = useState({});
  const [errorsPasswordList, setErrorsPasswordList] = useState([]);

  useEffect(() => {
    console.log('location.state', location.state)
    setPasswordValidationRules(location.state?.password_validation_rules)
  }, [location.state])

  const isValidPasswords = () => {
    return !!(newPassword.length && confirmPassword.length && (newPassword === confirmPassword));
  };

  const changePassword = (value) => {
    setNewPassword(value)
    setForgotPassState({ ...forgotPassState, newPassword: value })
  }

  const handleChangePassword = (event) => {
    onPasswordTestInputChange(
      event.target.value,
      changePassword,
      setErrorsPasswordList,
      'passwordRulesExpanded',
      passwordValidationRules
    )
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // const keyPressNewPasswordConfirmHandle = (e) => {
  //   if (e.keyCode === 13) {
  //     setConfirmPassword(e.target.value);
  //     dispatch(forgotPasswordDoAction({ action, ...forgotPassState, newPassword: newPassword, navigate, from }));
  //   }
  // };

  return (<Box pt={5} sx={{
    width: "500px",
    display: "flex",
    flexDirection: 'column',
    margin: "0 auto",
    minHeight: "100px",
  }}>
    <Box sx={{ margin: "20px auto", display: "flex", flexDirection: 'row', }}>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 'bold'
        }}>Reset your password by choosing a new password</Typography>
    </Box>

    <Box>
      <PasswordControl id="password" label="PASSWORD"
        value={newPassword}
        showPassword={showPassword}
        onHandleChangePassword={handleChangePassword}
        onHandleShowPassword={handleClickShowPassword}
      />
      {errorsPasswordList.map(({ isValid, msg }, index) => {
        return (
          <Typography key={index} sx={{ display: 'flex', margin: '5px 0' }}>
            {isValid ? <CheckIcon fontSize="small" color="success" /> : <CloseIcon fontSize="small" color="error" />}
            {/* <CloseIcon fontSize="small" color="error" />{" "} */}
            <span
              style={{
                color: isValid ? "green" : "#d32f2f",
                fontSize: "13px",
                fontFamily: "Globerbold",
              }}
            >
              {msg}
            </span>
          </Typography>
        );
      })}
    </Box>
    <Box>
      <PasswordControl id="confirm_password" label="CONFIRM PASSWORD"
        value={confirmPassword}
        showPassword={showConfirmPassword}
        onHandleChangePassword={handleChangeConfirmPassword}
        onHandleShowPassword={handleClickShowConfirmPassword}
      // onHandleKeyDown={keyPressNewPasswordConfirmHandle}
      />
    </Box>
    <Button
      variant="contained"
      sx={{
        height: "36px",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#00b7cd",
        borderRadius: 0,
        m: "20px 0",
        color: "#fff",
      }}
      disabled={!isValidPasswords()}
      onClick={() =>
        dispatch(forgotPasswordDoAction({ action, ...forgotPassState, newPassword: newPassword, navigate, from }))
      }
    >
      Reset password
    </Button>
  </Box >
  );
}

export default ForgotPasswordStep3;

