import { postRequest } from "../../utils/axios";
import {
  SET_CLINICS,
  SET_COMPANY_GROUPS,
  SET_ROLES,
  SET_SELECTED_GROUP,
  SET_GROUP_CONTACTS,
  SET_USERS,
  SET_OPEN_MODAL_ENTITY,
  SET_OPEN_MODAL_GROUP_SUCCESS,
  SET_OPEN_MODAL_ENTITY_SUCCESS,
  SET_OPEN_MODAL_GROUP,
  SET_OPEN_MODAL_USER,
  RESET_ENTITIES,
  SET_NEW_PERMISSIONS,
  SET_SELECTED_PERMISSION,
  SET_SELECTED_ROLE_ID,
  SET_OPEN_MODAL_ENTITY_EDIT,
  SET_OPEN_MODAL_ADD_EDIT_CONTACT,
  SET_VALIDATION_PASSWORD_RULES
} from "../actionTypes";
import { entityTypeAndID } from "../../utils/entityTypeAndID";
import { store } from '../../App'
import { getPresignUrlForUpload, getPublicUrlForLogo, uploadFileToDrive } from '../../utils/upload'
import { axiosErrorHandler } from "../../utils/axios";



export const createCompanyGroup = ({ entity, contactPersons, logo }) => {
  return async dispatch => {
    try {
      // console.log('logo', logo);
      const presignUrl = await getPresignUrlForUpload(logo);
      await uploadFileToDrive(presignUrl.data, logo)
      const publicUrl = await getPublicUrlForLogo(presignUrl.data.uuid);
      console.log('publicUrl', publicUrl)
      console.log('add company group ', entity);
      let resp = await postRequest('/entity/cg/create', {
        data: { value: { ...entity, logoUrl: publicUrl.data.publicUrl } }
      })
      console.log('createCompanyGroup resp ==', resp)
      await Promise.all(contactPersons.map(async (contact) => {
        let respContact = await postRequest(`/entity/cg/${resp?.data?.keys?.cg_id}/contact/create`, { data: { value: contact } })
        console.log('add contact to group resp', respContact)
      }))
      dispatch(setOpenModalGroup(false));
      dispatch(setOpenModalGroupSuccess(true));
      dispatch(getCompanyGroups())
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - createCompanyGroup - ', error)
    }
  }
}

export const createEntity = ({ entity, contactPersons, logo }) => {
  return async dispatch => {
    try {
      const parentEntity = store.getState().entities.selectedCompany
      const presignUrl = await getPresignUrlForUpload(logo);
      await uploadFileToDrive(presignUrl.data, logo)
      const publicUrl = await getPublicUrlForLogo(presignUrl.data.uuid);
      console.log('publicUrl', publicUrl)
      console.log('add entity ', entity);
      let resp = await postRequest('/entity/clinic/create', {
        data: { value: { ...entity, logoUrl: publicUrl.data.publicUrl } }
      })
      console.log('createEntity resp ==', resp)
      await Promise.all(contactPersons.map(async (contact) => {
        let respContact = await postRequest(`/entity/clinic/${resp?.data?.keys?.clinic_id}/contact/create`, { data: { value: contact } })
        console.log('add contact to entity resp', respContact)
      }))
      dispatch(setOpenModalEntity(false));
      dispatch(setOpenModalEntitySuccess(true));
      dispatch(setSelectedGroup(await getEntityDetails(entityTypeAndID(parentEntity))));
      // dispatch(getClinics());
      // console.log('createEntity resp12 ==', resp12)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - createEntity - ', error)
    }
  }
}

export const createRoleForEntity = ({ role, entityID, entityType }) => {
  return async dispatch => {
    try {
      console.log('createRole', role, entityID, entityType);
      let resp = await postRequest(`/entity/${entityType}/${entityID}/role/create`, {
        data: { value: { name: role } }
      })
      console.log('createRole resp ==', resp)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - createRole - ', error)
    }
  }
}

export const createUser = ({ user, entityID, entityType, roles }) => {
  return async dispatch => {
    try {
      console.log('createUser', user, entityID, entityType);
      let resp = await postRequest(`/entity/${entityType}/${entityID}/user/create`, {
        data: { value: user }
      })
      console.log('createUser resp ==', resp)
      dispatch(setOpenModalUser(false));
      await Promise.all(roles.map(async (role) => {
        let respRole = await postRequest(`/role/${role.keys.role_id}/user/${resp.data.keys.user_id}/add`, {})
        console.log('add user to role resp', respRole)
      }))
      dispatch(getUsers(entityTypeAndID(store.getState().entities.selectedCompany)))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - createUser - ', error)
    }
  }
}

export const updateUser = ({ user, user_id, entityID, entityType, role }) => {
  return async dispatch => {
    try {
      console.log('updateUser', user, user_id, entityID, entityType);
      let resp = await postRequest(`/entity/${entityType}/${entityID}/user/${user_id}/update`, {
        data: { value: user }
      })
      console.log('updateUser resp ==', resp)
      dispatch(setOpenModalUser(false));
      let respUpdateRole = await postRequest(`/role/${role.keys.role_id}/user/${resp.data.keys.user_id}/add-exclusively`, {})
      console.log('respUpdateRole', respUpdateRole)
      dispatch(getUsers(entityTypeAndID(store.getState().entities.selectedCompany)))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateUser - ', error)
    }
  }
}

export const setRoles = (roles) => {
  return {
    type: SET_ROLES,
    payload: roles
  }
}

export const setSelectedRoleId = (roleId) => {
  return {
    type: SET_SELECTED_ROLE_ID,
    payload: roleId
  }
}

export const setNewPermissions = (permissions) => {
  return {
    type: SET_NEW_PERMISSIONS,
    payload: permissions
  }
}

export const setSelectedPermission = ({ allocatedIndex, actionIndex, type }) => {
  return {
    type: SET_SELECTED_PERMISSION,
    payload: {
      allocatedIndex,
      actionIndex,
      type
    }
  }
}

export const setOpenModalEntity = (boolean) => {
  return {
    type: SET_OPEN_MODAL_ENTITY,
    payload: boolean
  }
}

export const setOpenModalGroup = (boolean) => {
  return {
    type: SET_OPEN_MODAL_GROUP,
    payload: boolean
  }
}

export const setOpenModalEntitySuccess = (boolean) => {
  return {
    type: SET_OPEN_MODAL_ENTITY_SUCCESS,
    payload: boolean
  }
}

export const setOpenModalGroupSuccess = (boolean) => {
  return {
    type: SET_OPEN_MODAL_GROUP_SUCCESS,
    payload: boolean
  }
}

export const setOpenModalUser = (boolean) => {
  return {
    type: SET_OPEN_MODAL_USER,
    payload: boolean
  }
}

export const setOpenModalEntityEdit = (boolean) => {
  return {
    type: SET_OPEN_MODAL_ENTITY_EDIT,
    payload: boolean
  }
}
export const setOpenModalAddEditContact = (boolean) => {
  return {
    type: SET_OPEN_MODAL_ADD_EDIT_CONTACT,
    payload: boolean
  }
}

export const setClinics = (clinics) => {
  return {
    type: SET_CLINICS,
    payload: clinics
  }
}

export const setCompanyGroups = (companyGroups) => {
  return {
    type: SET_COMPANY_GROUPS,
    payload: companyGroups
  }
}

export const setUsers = (users) => {
  return {
    type: SET_USERS,
    payload: users
  }
}

export const setSelectedGroup = (group) => {
  return {
    type: SET_SELECTED_GROUP,
    payload: group
  }
}

export const setContactsForGroup = (contacts) => {
  return {
    type: SET_GROUP_CONTACTS,
    payload: contacts
  }
}

export const setValidationPasswordRules = (rules) => {
  return {
    type: SET_VALIDATION_PASSWORD_RULES,
    payload: rules
  }
}


export const getEntityDetails = async ({ entityType, entityID }) => new Promise(async (resolve, reject) => {
  try {
    let resp = await postRequest(`/entity/${entityType}/${entityID}/get-details`, { data: {} })

    console.log('getEntityDetails resp ==', resp.data)
    if (!!resp?.data) {
      resolve(resp.data)
    } else {
      reject({ msg: 'No data in response' })
    }
  } catch (err) {
    reject(err);
  }
})




export const getCompanyGroups = () => {
  return async dispatch => {
    try {
      let resp = await postRequest('/entity/cg/get-all', { data: {} })
      console.log('getCompanyGroups resp ==', resp.data)
      const companyGroups = await Promise.all(resp?.data?.map?.(async (item) => await getEntityDetails(entityTypeAndID(item))))
      dispatch(setCompanyGroups(companyGroups))

    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getCompanyGroups - ', error)
    }
  }
}

export const updateRoleByID = () => {
  return async dispatch => {
    try {
      const roleID = store.getState().entities.selectedRoleId;
      const newPermissions = store.getState().entities.rolePermissions;
      const selectedCompany = store.getState().entities.selectedCompany;
      let resp = await postRequest(`/role/${roleID}/permission/update`, {
        data: {
          value: { permissions: newPermissions }
        }
      })
      console.log('updateRoleByID resp ==', resp.data)
      dispatch(getRoles(entityTypeAndID(selectedCompany)))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateRoleByID - ', error)
    }
  }
}
export const resetRoleByID = (selectedRole) => {
  return async dispatch => {
    try {
      const roleID = store.getState().entities.selectedRoleId;
      const selectedCompany = store.getState().entities.selectedCompany;
      let resp = await postRequest(`/role/${roleID}/permission/reset`, {
        data: {
          value: { owner_type: selectedRole.value.owner_type }
        }
      })
      console.log('resetRoleByID resp ==', resp.data)
      dispatch(getRoles(entityTypeAndID(selectedCompany)))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - resetRoleByID - ', error)
    }
  }
}


export const getClinics = () => {
  return async dispatch => {
    try {
      let resp = await postRequest('/entity/clinic/get-all', { data: {} })
      console.log('getClinics resp ==', resp.data)
      dispatch(setClinics(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getClinics - ', error)
    }
  }
}

export const getRoles = ({ entityType, entityID }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/entity/${entityType}/${entityID}/role/get-all`, { data: {} })
      console.log('getRoles resp ==', resp.data)
      dispatch(setRoles(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getRoles - ', error)
    }
  }
}

export const getContactsForGroup = ({ entityType, entityID }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/entity/${entityType}/${entityID}/contact/get-all`, { data: {} })
      console.log('getContactsForGroup resp ==', resp.data)
      dispatch(setContactsForGroup(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getContactsForGroup - ', error)
    }
  }
}

export const getUsers = ({ entityType, entityID }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/entity/${entityType}/${entityID}/user/get-all`, { data: {} })
      console.log('getUsers resp ==', resp.data)
      dispatch(setUsers(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getUsers - ', error)
    }
  }
}

export const updateCompanyGroupEntity = ({ entity, logo }) => {
  return async dispatch => {
    try {
      console.log('logo', logo)
      const requestPathParams = entityTypeAndID(store.getState().entities.selectedCompany)
      const requestPath = `/entity/${requestPathParams.entityType}/${requestPathParams.entityID}/update`
      let requestBodyValue = {
        ...entity
      }
      if (logo) {
        const presignUrl = await getPresignUrlForUpload(logo);
        await uploadFileToDrive(presignUrl.data, logo)
        const publicUrl = await getPublicUrlForLogo(presignUrl.data.uuid);
        console.log('publicUrl', publicUrl)
        requestBodyValue = {
          ...requestBodyValue,
          logoUrl: publicUrl.data.publicUrl
        }
      }
      console.log('updateCompanyGroupEntity ', requestBodyValue);
      let resp = await postRequest(requestPath, {
        data: { value: { ...requestBodyValue } }
      })
      console.log('updateCompanyGroupEntity resp ==', resp)
      dispatch(setOpenModalEntityEdit(false));
      dispatch(getCompanyGroups())
      dispatch(setSelectedGroup(await getEntityDetails(requestPathParams)))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateCompanyGroupEntity - ', error)
    }
  }
}

export const updateClinicEntity = ({ entity, logo }) => {
  return async dispatch => {
    try {
      console.log('logo', logo)
      const requestPathParams = entityTypeAndID(store.getState().entities.selectedCompany)
      const requestPath = `/entity/${requestPathParams.entityType}/${requestPathParams.entityID}/update`
      let requestBodyValue = {
        ...entity
      }
      if (logo) {
        const presignUrl = await getPresignUrlForUpload(logo);
        await uploadFileToDrive(presignUrl.data, logo)
        const publicUrl = await getPublicUrlForLogo(presignUrl.data.uuid);
        console.log('publicUrl', publicUrl)
        requestBodyValue = {
          ...requestBodyValue,
          logoUrl: publicUrl.data.publicUrl
        }
      }
      console.log('updateClinicEntity ', requestBodyValue);
      let resp = await postRequest(requestPath, {
        data: { value: { ...requestBodyValue } }
      })
      console.log('updateClinicEntity resp ==', resp)
      dispatch(setOpenModalEntityEdit(false));
      dispatch(getCompanyGroups())
      dispatch(setSelectedGroup(await getEntityDetails(requestPathParams)))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateClinicEntity - ', error)
    }
  }
}

export const addNewContact = ({ contact }) => {
  return async dispatch => {
    try {
      const requestPathParams = entityTypeAndID(store.getState().entities.selectedCompany)
      const requestPath = `/entity/${requestPathParams.entityType}/${requestPathParams.entityID}/contact/create`
      let requestBodyValue = {
        ...contact
      }
      // console.log('addNewContact requestBody', requestBodyValue);
      let respContact = await postRequest(requestPath, { data: { value: { ...requestBodyValue } } })
      // console.log('addNewContact resp ==', respContact)
      dispatch(setOpenModalAddEditContact(false));
      dispatch(setSelectedGroup(await getEntityDetails(requestPathParams)))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - addNewContact - ', error)
    }
  }
}

export const updateContact = ({ contact, contact_id }) => {
  return async dispatch => {
    try {
      const requestPathParams = entityTypeAndID(store.getState().entities.selectedCompany)
      const requestPath = `/entity/${requestPathParams.entityType}/${requestPathParams.entityID}/contact/${contact_id}/update`
      let requestBodyValue = {
        ...contact
      }
      // console.log('updateContact requestBody', requestBodyValue);
      let respContact = await postRequest(requestPath, { data: { value: { ...requestBodyValue } } })
      // console.log('updateContact resp ==', respContact)
      dispatch(setOpenModalAddEditContact(false));
      dispatch(setSelectedGroup(await getEntityDetails(requestPathParams)))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateContact - ', error)
    }
  }
}

export const getValidationPasswordRules = () => {
  return async dispatch => {
    try {
      const requestPathParams = entityTypeAndID(store.getState().entities.selectedCompany)
      const requestPath = `/validation/${requestPathParams.entityType}/${requestPathParams.entityID}/password-rules/get`
      let respRules = await postRequest(requestPath, {})
      console.log('getValidationPasswordRules resp ==', respRules.data)
      dispatch(setValidationPasswordRules(respRules.data));
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getValidationPasswordRules - ', error)
    }
  }
}

export const updateValidationPasswordRules = (rules) => {
  return async dispatch => {
    try {
      const requestPathParams = entityTypeAndID(store.getState().entities.selectedCompany)
      const requestPath = `/validation/${requestPathParams.entityType}/${requestPathParams.entityID}/password-rules/upsert`
      let respRules = await postRequest(requestPath, {
        data: {
          value: {
            passwordRulesShort: rules
          }
        }
      })
      console.log('updateValidationPasswordRules resp ==', respRules.data)
      dispatch(setValidationPasswordRules(respRules.data));
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateValidationPasswordRules - ', error)
    }
  }
}

export const userActivityDoAction = (action, user) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/user/${user.keys.user_id}/do_action/${action}`, { data: {} })
      console.log('userActivityDoAction resp ==', resp.data)
      dispatch(getUsers(entityTypeAndID(store.getState().entities.selectedCompany)))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - userActivityDoAction - ', error)
    }
  }
}



export const resetEntities = () => {
  return {
    type: RESET_ENTITIES,
  }
}

