import { postRequest, axiosErrorHandler } from "../../utils/axios";
import {
  ADD_NEW_NOTIFICATION,
  RESET_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  SET_SHOW_NOTIFICATIONS_DRAWER,
  SET_UNSEEN_NOTIFICATIONS_COUNT,
  UPDATE_NOTIFICATION_STATE_TO_SEEN
} from "../actionTypes";
import { store } from '../../App'


export const setShowNotificationsDrawer = (boolean) => {
  return {
    type: SET_SHOW_NOTIFICATIONS_DRAWER,
    payload: boolean
  }
}

export const setNotifications = (notifications) => {
  return {
    type: SET_NOTIFICATIONS,
    payload: notifications
  }
}

export const setUnseenNotificationsCount = (data) => {
  return {
    type: SET_UNSEEN_NOTIFICATIONS_COUNT,
    payload: data
  }
}

export const addNewNotification = (data) => {
  return {
    type: ADD_NEW_NOTIFICATION,
    payload: data
  }
}
export const updateNotificationStateToSeen = (data) => {
  return {
    type: UPDATE_NOTIFICATION_STATE_TO_SEEN,
    payload: data
  }
}

export const resetNotifications = () => {
  return {
    type: RESET_NOTIFICATIONS
  }
}

export const getAllNotifications = () => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/notification/get-all`, { data: {} })
      console.log('getAllNotifications resp ==', resp.data)
      const unseenArr = resp.data.filter((it) => { return it.value.state === 'UNSEEN' })
      dispatch(setUnseenNotificationsCount(unseenArr.length))
      dispatch(setNotifications(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getAllNotifications - ', error)
    }
  }
}

export const setNotificationStateSeen = (notification_id) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/notification/${notification_id}/set-seen`, { data: {} })
      console.log('setNotificationStateSeen resp ==', resp.data)
      dispatch(updateNotificationStateToSeen(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - setNotificationStateSeen - ', error)
    }
  }
}