// import myValidationObj from "./validations-rules";

const validateItem = (validationID, valueEntered, validationRules) => {
  let isValid = false;
  let isValidGeneral = false;

  try {
    const validations = Object.values(validationRules[validationID]);

    let response = {
      isValidGeneral: isValidGeneral,
      validations: [],
    };

    let validationsArray = [];

    validations.map((validateItem) => {
      const regexExp = new RegExp(validateItem.regex);
      const doesValueMatch = regexExp.test(valueEntered);

      doesValueMatch === false ? (isValid = false) : (isValid = true);

      validationsArray.push({
        isValid: isValid,
        key: validateItem.key,
        msg: validateItem.msg,
        regex: validateItem.regex,
      });
    });

    response.validations = validationsArray;
    isValidGeneral = validationsArray.some(
      (validation) => validation.isValid === false
    );

    isValidGeneral === true
      ? (response.isValidGeneral = false)
      : (response.isValidGeneral = true);

    return response;
  } catch (e) {
    console.log({ e });
    return e;
  }
};

export default validateItem;
