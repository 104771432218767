import axios from "axios";
import { useSelector } from "react-redux";
import { addSnackbarVariant, store } from '../App'
import { logOut } from "../redux/actions/auth.actions";
import { resetDocuments } from "../redux/actions/documents.actions";
import { resetGop } from "../redux/actions/gop.actions";
import { addMessageToSnackbar, resetViewState } from "../redux/actions/view.actions";
import { resetEntities } from "../redux/actions/entities.actions";
import { setSnackBarOpen } from "../redux/actions/view.actions";
import { resetNotifications } from "../redux/actions/notifications.action";
import { setIFrameOpen } from "../shared/QueryFrame";


// const BASE_URL = 'https://dev-swan-clinic-portal-cloud-api.swanforlife.link'
// const BASE_URL = 'http://localhost:3000/'
const BASE_URL = process.env.REACT_APP_API_URL;

export const postRequest = (url, body = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = store.getState().auth.token

      if (!body.data) {
        body.data = {};
      }
      if (!body.data.keys) {
        body.data.keys = {};
      }
      body.data.keys.env = process.env.REACT_APP_ENV;
      // body.data.keys.env = 'TEST';
      // body.data.keys.env = 'DEV';
      let response = await axios({
        method: "POST",
        baseURL: BASE_URL,
        url: url,
        data: body,
        headers: {
          "Content-Type": "application/json",
          authorization: token ? token : ''
        }
      });
      let responseData = response.data
      if (responseData.dataset) {
        resolve({ data: responseData.dataset })
        if (responseData.IS_FAILED === 0 && responseData.snackbarMsg) {
          store.dispatch(addMessageToSnackbar(responseData.snackbarMsg, 'success'))
        }
      }
      else {
        resolve(response)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export const axiosErrorHandler = (error) => {
  if (error.response) {
    if (!!error.response.data &&
      !!error.response.data.message &&
      (error.response.data.message === 'Token revoked!' ||
        error.response.data.message === "Provided token's information is outdated. Please renew token." ||
        error.response.data.message === 'Unauthorized!')) {
      // console.log('unathorized', error.response.data.message)
      setIFrameOpen(false);
      store.dispatch(logOut());
      store.dispatch(resetEntities());
      store.dispatch(resetDocuments());
      store.dispatch(resetGop());
      store.dispatch(resetViewState());
      store.dispatch(resetNotifications());
      // store.dispatch(logOut());
      // store.dispatch(resetClaimsState());
    }
    // Request made and server responded
    // console.log('axiosErrorHandler error.response.data', error.response.data);
    const response = error.response.data
    const errorMsg = !!response.message ? response.message : !!response.msg ? response.msg : 'Unexpected error.'
    // store.dispatch(setSnackBarOpen( true, errorMsg ))
    store.dispatch(addMessageToSnackbar(errorMsg, 'error'))
    // console.log(error.response.status);
    // console.log(error.response.headers);
    return error.response.data;
  } else if (error.request) {
    // The request was made but no response was received
    // console.log('error request', error.request);
    const response = error.request;
    const errorMsg = !!response.message ? response.message : !!response.msg ? response.msg : 'Unexpected error.'
    // store.dispatch(setSnackBarOpen(true, errorMsg))
    store.dispatch(addMessageToSnackbar(errorMsg, 'error'))
    return error.request;
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('axiosErrorHandler error.message', error.message);
    // store.dispatch(setSnackBarOpen(true, error.message ? error.message : 'Unexpected error.'))
    store.dispatch(addMessageToSnackbar(error.message ? error.message : 'Unexpected error.', 'error'))
    return error.message;
  }
}



