import React, { useEffect } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Permissions } from '../media/icons/permissions.svg';
import { ReactComponent as PermissionsBlue } from '../media/icons/permissionsblue.svg';
import { ReactComponent as Users } from '../media/icons/users.svg';
import { ReactComponent as UsersBlue } from '../media/icons/usersblue.svg';
import { useSelector } from 'react-redux';
import SecurityIcon from '@mui/icons-material/Security';
// import { ReactComponent as Clinic } from '../media/icons/clinics.svg';
// import { ReactComponent as ClinicBlue } from '../media/icons/clinicsblue.svg';

const CompanyDrawer = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const selectedCompany = useSelector(state => state.entities.selectedCompany)
  const {
    value: { logoUrl = 'https://dev-swan-drive.s3.eu-central-1.amazonaws.com/CP_DEV_LOGO/c-care.png'},
  } = selectedCompany || {}

  const sharedSx = {
    borderRadius: 0,
    width: '100%',
    justifyContent: 'flex-start',
    borderColor: 'primary.main',
    textAlign: 'left'
  }

  const DrawerGroupItem = ({ text, selected, onClick }) => (
    <Button
      sx={{
        borderBottom: 3,
        justifyContent: 'flex-start',
        marginTop: 4,
        fontWeight: 'bold',
        color: 'white',
        paddingLeft: 0,
        ...sharedSx,
        borderColor: selected ? 'primary.main' : 'transparent'
      }}
      onClick={onClick}
    >{text}</Button>
  );

  const DrawerListItem = ({ activeIcon, inactiveIcon, text, selected, onClick }) => (
    <Button
      startIcon={selected ? activeIcon : inactiveIcon}
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderRight: selected ? 3 : 0,
        backgroundColor: selected ? '#dafbff' : 'transparent',
        color: selected ? '#0038cd' : 'white',
        paddingLeft: 2,
        paddingTop: 1,
        marginTop: 1,
        ...sharedSx,
        '&:hover': !selected ? {
          backgroundColor: 'rgba(0, 122, 255, 0.5)',
          color: 'white' 
        } : {
          backgroundColor: 'rgba(218, 251, 255, 0.8)',
          color: '#0038cd',
        }
      }}>
      {text}
    </Button>
  );

  // const navigate = useNavigate();

  // const handleNavigate = (route) => {
  //   navigate(route);
  // }


  return (
    <Box>
      <Paper elevation={2} sx={{ p: 1, m: 5, maxWidth: 140, maxHeight: 140 }} >
        <img src={logoUrl} width={120} height={120} />
      </Paper>
      <DrawerGroupItem
        text='Overview'
        onClick={() => navigate('overview', { replace: true })}
        selected={location.pathname === '/companyDetails/overview'}/>
      {/* <DrawerGroupItem
        text='Cases/Claims'
        onClick={() => navigate('cases', { replace: true })}
        selected={location.pathname === '/companyDetails/cases'}/> */}
      <DrawerGroupItem
        text='Users & permissions'
        onClick={() => navigate('permissions', { replace: true })}
        selected={location.pathname === '/companyDetails/permissions' || location.pathname === '/companyDetails/users' || location.pathname === '/companyDetails/password-management'} />
      <DrawerListItem
        onClick={() => navigate('permissions', { replace: true })}
        text={'Permissions & Rules'}
        activeIcon={<PermissionsBlue />}
        inactiveIcon={<Permissions />}
        selected={location.pathname === '/companyDetails/permissions'}
      />
      <DrawerListItem
        onClick={() => navigate('users', { replace: true })}
        text={'Users'}
        activeIcon={<UsersBlue />}
        inactiveIcon={<Users />}
        selected={location.pathname === '/companyDetails/users'} />
      <DrawerListItem
        onClick={() => navigate('password-management', { replace: true })}
        text={'Password'}
        activeIcon={<SecurityIcon sx={{color: "#00b7cd"}} />}
        inactiveIcon={<SecurityIcon />}
        selected={location.pathname === '/companyDetails/password-management'} />
    </Box>
  );
};

export default CompanyDrawer;