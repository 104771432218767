import React, { useEffect, useState } from 'react';
import {
  Dialog, Button,
  Typography, Toolbar,
  AppBar, List,
  ListItem, ListItemText,
  IconButton, Slide,
  Divider, DialogTitle,
  DialogContent,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// import { makeStyles } from '@material-ui/core/styles';
import { loginEmailPass } from '../../../redux/actions/auth.actions';


const SelectRoleDialog = ({ open, onClose, email, password, navigate, from }) => {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const actors = useSelector(state => state.auth.actors);

  // useEffect(() => {
  //   console.log('actors', actors);
  // }, [actors])

  return (
      <Dialog scroll='paper' fullWidth maxWidth={'sm'} open={open} onClose={onClose}>
        <DialogTitle>Select Role</DialogTitle>
        <DialogContent dividers>
          <List>
            {actors?.map?.((actor, index) =>
              <div key={actor.value + index.toString}>
                <ListItem button
                  onClick={() => {
                    dispatch(loginEmailPass({ email, password, navigate, from, selectedActor: actor?.value }))
                    // dispatch(loginWithoutOtp({ username, actors: [actor]}))
                    onClose()
                }}>
                  <ListItemText primary={actor.title} />
                </ListItem>  
                <Divider />
              </div>
            )}
          </List>
        </DialogContent>
      </Dialog>
  );
}

// const useStyles = makeStyles((theme) => ({
//   titleContainer: {
//     backgroundColor: '#003086',
//     color: '#FFF'
//   },
//   title: {
//     marginLeft: theme.spacing(2),
//     flex: 1,
//   },
// }));

export default SelectRoleDialog;