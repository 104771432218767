import React, { useEffect, useState } from 'react';
import {
  Box, TextField, MenuItem,
  FormControl, Select, InputLabel
} from '@mui/material';
import _ from 'lodash';

const DisableTextField = ({ value = '', label = '', disabled = true }) => {
  return (
    <TextField
      disabled={disabled}
      label={label}
      value={value}
      fullWidth
      multiline
      variant="filled"
    />
  )
}

const EditableSelectObjectControl = ({ label = '', valueObj = {}, propertyName = '', items = [], onChange = () => { }, isDisabled = true }) => {
  const [textFieldValue, setTextFieldValue] = useState('')
  useEffect(() => {
    if (valueObj) {
      // console.log(valueObj)
      let item = _.find(items, { value: valueObj.value })
      if (item) {
        setTextFieldValue(item?.title)
      }
    }
    else {
      setTextFieldValue('')
    }
  }, [valueObj, items])
  return (
    <>
      {isDisabled && <DisableTextField label={label} value={textFieldValue} disabled={isDisabled} />}
      {!isDisabled &&
        <FormControl disabled={isDisabled}
          variant="filled" fullWidth >
          <InputLabel id={propertyName + '-label'}>{label}</InputLabel>
          <Select
            labelId={propertyName + '-label'}
            value={JSON.stringify(valueObj)}
            onChange={(event) => onChange(JSON.parse(event.target.value))}
            label={label}
            variant={'filled'}
            multiline
          >
            {items?.map((item, index) => (
              <MenuItem key={index.toString()} value={JSON.stringify(item)}>{item.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    </>
  )
}

export default EditableSelectObjectControl