import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Dialog,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DropdownWithValue } from "../../../shared";
import { useSelector, useDispatch } from "react-redux";
import {
  createUser,
  getRoles,
  getUsers,
  updateUser,
} from "../../../redux/actions/entities.actions";
import { entityTypeAndID } from "../../../utils/entityTypeAndID";
import { getRulesValidation, getRulesValidationErrorMsg } from "../../../utils/onChangeFunction";
import _ from "lodash";
import { addMessageToSnackbar } from "../../../redux/actions/view.actions";
import { useForm, Controller } from "react-hook-form";

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: ''
}

const ContactButton = ({ onClick, selected, title, color = "", disabled }) => {
  return (
    <Button
      sx={{
        width: "100%",
        mb: 2,
        backgroundColor: selected
          ? "rgba(0, 183, 205, 0.2)"
          : "rgba(0, 183, 205, 0.06)",
        borderRadius: 0,
        color: color === "secondary" ? "secondary.main" : "primary.main",
        "&:hover": {
          backgroundColor: "rgba(0, 183, 205, 0.25)",
        },
      }}
      variant="contained"
      disabled={disabled}
      onClick={() => onClick()}
    >
      {title}
    </Button>
  );
};

const ControllerField = ({ control, nameControl, validationKey, ...rest }) => {
  return (
    <Controller
      name={nameControl}
      control={control}
      defaultValue={''}
      rules={{ validate: { ...getRulesValidation(validationKey) } }}
      render={({
        field: { onChange, ...restField },
        fieldState: { invalid, error },
      }) => {
        console.log("invalid ", invalid);        return (<TextField
          sx={{ minWidth: 240, width: "100%" }}
          variant="filled"
          onChange={onChange}
          error={!!invalid}
          helperText={error ? (error?.message ? error.message : getRulesValidationErrorMsg(validationKey, error?.type)) : null}
          {...restField}
          {...rest}
        />)
      }}
    />
  )
}

const AddUserModal = ({ open, handleOpen, user, dialogMode }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.entities.users);
  const roles = useSelector((state) => state.entities.roles);
  const selectedCompany = useSelector((state) => state.entities.selectedCompany);
  const [selectedRole, setSelectedRole] = useState("");;
  const [userSelectedIndex, setUserSelectedIndex] = useState(
    users?.length ? users.length : 0
  );
  const { handleSubmit, control, reset, formState: { errors }, getValues, setValue } = useForm({
    defaultValues,
    mode: 'all'
  });

  useEffect(() => {
    setUserSelectedIndex(users.length)
  }, []);

  useEffect(() => {
    if (user) {
      let userIndex = _.findIndex(users, (item) => item?.keys?.user_id === user.keys?.user_id)
      setUserSelectedIndex(userIndex);
    }
    else {
      setUserSelectedIndex(users.length);
    }
  }, [user]);

  useEffect(() => {
    if (selectedCompany?.keys?.table) {
      dispatch(getRoles(entityTypeAndID(selectedCompany)));
      dispatch(getUsers(entityTypeAndID(selectedCompany)));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (userSelectedIndex === users.length) {
      reset(defaultValues)
      setSelectedRole("");
    } else {
      setValue('firstName', users?.[userSelectedIndex]?.value?.firstName, { shouldValidate: true, shouldDirty: true })
      setValue('lastName', users?.[userSelectedIndex]?.value?.lastName, { shouldValidate: true, shouldDirty: true })
      setValue('email', users?.[userSelectedIndex]?.value?.email, { shouldValidate: true, shouldDirty: true })
      setValue('phone', users?.[userSelectedIndex]?.value?.phone, { shouldValidate: true, shouldDirty: true })
      let role = _.find(roles, (item) => { return item.value.name === users?.[userSelectedIndex]?.value?.a_role_names?.[0] })
      setSelectedRole(role);
    }
  }, [userSelectedIndex, users.length, open]);

  useEffect(() => {
    console.log("role", selectedRole);
  }, [selectedRole]);

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={open}
      onClose={() => handleOpen(false)}
    >
      <Box pl={3} pr={3} pt={5} pb={5}>
        <Box
          display="flex"
          flexDirection="row"
          pb={5}
          justifyContent="space-between"
        >
          <Typography
            sx={{
              color: "primary.main",
              fontSize: 24,
              fontFamily: "Globersemibold",
              textTransform: "uppercase",
            }}
          >
            {dialogMode === 'ADD' ? 'Add New User' : 'Edit User'}
          </Typography>
          <IconButton onClick={() => handleOpen(false)}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Box>

        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
            sx={{ borderRight: 1, borderColor: "#e5e5e5", borderRadius: 0 }}
          >
            {users.map((item, index) => (
              <ContactButton
                title={item.value?.firstName + " " + item.value?.lastName}
                key={index.toString()}
                disabled={dialogMode === 'ADD' || index !== userSelectedIndex}
                selected={index === userSelectedIndex}
                onClick={() => {
                  setUserSelectedIndex(index);
                }}
              />
            ))}
            {dialogMode === 'ADD' &&
              <ContactButton
                title={"New"}
                key={"newcontact"}
                color="secondary"
                disabled={false}
                selected={users.length}
                onClick={() => {
                  setUserSelectedIndex(users.length);
                }}
              />
            }
          </Grid>
          <Grid item container xs={9} spacing={4}>
            <Grid item xs={6}>
              <DropdownWithValue
                label="Role"
                options={roles}
                value={selectedRole}
                onChange={setSelectedRole}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            {/* first row */}
            <Grid item xs={6}>
              <ControllerField control={control}
                nameControl={'firstName'}
                validationKey={'NAME_VALIDATION'}
                label={'First Name'}
              />
            </Grid>
            <Grid item xs={6}>
              <ControllerField control={control}
                nameControl={'lastName'}
                validationKey={'NAME_VALIDATION'}
                label={'Last Name'}
              />
            </Grid>

            {/* second row */}

            <Grid item xs={6}>
              <ControllerField control={control}
                nameControl={'email'}
                validationKey={'USER_MGMT_USERNAME'}
                label={'Email'}
                disabled={dialogMode === 'EDIT'}
              />
            </Grid>
            <Grid item xs={6}>
              <ControllerField control={control}
                nameControl={'phone'}
                validationKey={'MOBILE_NUMBER_VALIDATION'}
                label={'Phone Number'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mt={2}
        >
          {dialogMode === 'ADD' ?
            <Button
              color="secondary"
              disabled={userSelectedIndex !== users.length}
              variant="contained"
              onClick={handleSubmit((data) => {
                console.log("handleSubmit ADD data", data)
                console.log("handleSubmit ADD selectedRole", selectedRole)
                if (!selectedRole) {
                  dispatch(addMessageToSnackbar("Select user role!", 'info'))
                  return;
                }
                dispatch(
                  createUser({
                    user: data,
                    ...entityTypeAndID(selectedCompany),
                    roles: [selectedRole],
                  })
                );
              })}
            >
              add new user
            </Button>
            :
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSubmit((data) => {
                console.log("handleSubmit data", data)
                console.log("handleSubmit selectedRole", selectedRole)
                if (data && selectedRole) {
                  dispatch(
                    updateUser({
                      user: data,
                      user_id: user.keys.user_id,
                      ...entityTypeAndID(selectedCompany),
                      role: selectedRole
                    })
                  );
                }
              })}
            >
              Save
            </Button>
          }
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddUserModal;
