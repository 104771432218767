import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

const ErrorMessage = ({ message }) => {
  return (
    <Typography sx={{ display: 'flex', margin: '5px 0' }}>
      <CloseIcon fontSize="small" color="error" />{" "}
      <span
        style={{
          color: "#d32f2f",
          fontSize: "13px",
          fontFamily: "Globerbold",
        }}
      >
        {message}
      </span>
    </Typography>
  );
};

export default ErrorMessage;
