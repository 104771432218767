import React, { useEffect } from "react";
import {
    Dialog,
    Box,
    Typography,
    Button,
    IconButton,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    CircularProgress
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import {
    setSavePricelistModalOpen,
    setPricelistDraft,
    savePricelist,
    getAllPricelists
} from "../../../redux/actions/pricelist.actions";

import { DatePickerComponent } from "../../../shared";
// import ConfirmModal from "../ConfirmModal";

const SavePriceListModal = ({ open }) => {
    const dispatch = useDispatch();

    const pricelistDraft = useSelector((state) => state?.documents?.pricelistDraft) || {};
    const savePricelistLoading = useSelector((state) => state?.documents?.savePricelistLoading) || false;
    const clinicsList = useSelector((state) => state?.documents?.pricelistDraft?.a_viewConfig?.dropdowns?.CLINICS?.items) || [];

    const handleCloseModal = () => {
        dispatch(setSavePricelistModalOpen(false));
        dispatch(setPricelistDraft({}))

    }


    useEffect(() => {
        console.log('pricelistDraft', pricelistDraft);
    }, [pricelistDraft])

    return (
        <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleCloseModal}>
           <Box pl={3} pr={3} pt={3} pb={3}>
                    <Box display='flex' flexDirection='row' pb={2} justifyContent='space-between' alignItems='center'>
                        <Typography sx={{ color: 'secondary.main', fontSize: 20, fontFamily: 'Globersemibold', textTransform: 'uppercase' }}>
                            Edit price list
                        </Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon color='secondary' />
                        </IconButton>
                    </Box>
               <Box pb={2}><Typography>{pricelistDraft?.filename}</Typography></Box>
                    <Box pb={3} >
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="filled" sx={{ minWidth: 220 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Clinic</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={pricelistDraft?.clinic || ''}
                                        onChange={(e) => {dispatch(setPricelistDraft({...pricelistDraft, clinic: e.target.value}))}}
                                    >
                                        {clinicsList?.map((item, index) => (
                                            <MenuItem key={index.toString()+item} value={item}>{item}</MenuItem>
                                        ))}
                                        {/*<MenuItem key={'1'} value={'Wellkin'}>Wellkin</MenuItem>*/}
                                        {/*<MenuItem key={'2'} value={'Darne'}>Darne</MenuItem>*/}
                                        {/*{selectedGop?.value?.a_viewConfig?.dropdowns?.BILL_TYPE?.items?.map?.((item, index) =>*/}
                                        {/*    <MenuItem key={index.toString()} value={item?.value}>{item?.title}</MenuItem>*/}
                                        {/*)}*/}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DatePickerComponent label="Date From" value={pricelistDraft?.date_from}
                                                     valueOnChange={(newValue) => dispatch(setPricelistDraft({
                                                         ...pricelistDraft,
                                                         date_from: newValue
                                                     }))}
                                                     isDisabled={false}/>
                                <Box pt={3}> <DatePickerComponent pt={3} label="Date To" value={pricelistDraft?.date_to}
                                                                  valueOnChange={(newValue) => dispatch(setPricelistDraft({
                                                                      ...pricelistDraft,
                                                                      date_to: newValue
                                                                  }))}
                                                                  isDisabled={false}/></Box>



                            </Grid>
                        </Grid>
                    </Box>
               <Box display='flex'>
                   <Button variant="contained" color='secondary'
                           startIcon={<SaveIcon/>}
                           disabled={savePricelistLoading}
                           onClick={() => {
                               dispatch(savePricelist());
                           }}
                           sx={{
                                    fontWeight: 'bold',
                                    marginRight: '15px'
                                }}>
                            Save
                        </Button>
                        <Button variant="contained" color='secondary'
                                startIcon={<CancelIcon />}
                                disabled={savePricelistLoading}
                                onClick={ () => {
                                    handleCloseModal()
                                }}
                                sx={{
                                    fontWeight: 'bold',

                                }}>
                            Cancel
                        </Button>
                   {savePricelistLoading && <Box pl={3} ><CircularProgress sx={{alignSelf: 'center'}}  size={'20px'}/></Box>}

                    </Box>
                </Box>
        </Dialog >
    )
};

export default SavePriceListModal