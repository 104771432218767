// entities 

export const SET_COMPANY_GROUPS = 'SET_COMPANY_GROUPS';
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const SET_GROUP_CONTACTS = 'SET_GROUP_CONTACTS';
export const SET_CLINICS = 'SET_CLINICS';
export const SET_ROLES = 'SET_ROLES';
export const SET_USERS = 'SET_USERS';
export const SET_OPEN_MODAL_GROUP = 'SET_OPEN_MODAL_GROUP';
export const SET_OPEN_MODAL_GROUP_SUCCESS = 'SET_OPEN_MODAL_GROUP_SUCCESS';
export const SET_OPEN_MODAL_ENTITY = 'SET_OPEN_MODAL_ENTITY';
export const SET_OPEN_MODAL_ENTITY_SUCCESS = 'SET_OPEN_MODAL_ENTITY_SUCCESS';
export const SET_OPEN_MODAL_USER = 'SET_OPEN_MODAL_USER';
export const RESET_ENTITIES = 'RESET_ENTITIES';
export const SET_SELECTED_ROLE_ID = 'SET_SELECTED_ROLE_ID';
export const SET_NEW_PERMISSIONS = 'SET_NEW_PERMISSIONS';
export const SET_SELECTED_PERMISSION = 'SET_SELECTED_PERMISSION';
export const SET_OPEN_MODAL_ENTITY_EDIT = 'SET_OPEN_MODAL_ENTITY_EDIT';
export const SET_OPEN_MODAL_ADD_EDIT_CONTACT = 'SET_OPEN_MODAL_ADD_EDIT_CONTACT';

// validation

export const SET_VALIDATION_PASSWORD_RULES = 'SET_VALIDATION_PASSWORD_RULES';

// auth
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const LOG_OUT = 'LOG_OUT';
export const SET_PROVIDER_USERS = 'SET_PROVIDER_USERS';
export const SET_PROVIDER_ROLES = 'SET_PROVIDER_ROLES';
export const SET_AUTH_SELECT_ROLE_DIALOG_OPEN = 'SET_AUTH_SELECT_ROLE_DIALOG_OPEN';
export const ADD_PROFILE_TO_PROFILES = 'ADD_PROFILE_TO_PROFILES';
export const SET_CHOOSE_PROFILE_MODAL_OPEN = 'SET_CHOOSE_PROFILE_MODAL_OPEN';
export const SET_AUTH_REDUCER = 'SET_AUTH_REDUCER';


// nats
export const SET_NATS_CONFIG = "SET_NATS_CONFIG";
export const SET_CONNECTED_STATUS = "SET_CONNECTED_STATUS";
export const SET_CONNECTED_STATUS_REJECTED = "SET_CONNECTED_STATUS_REJECTED";

// documents
export const RESET_DOCUMENTS = 'RESET_DOCUMENTS';
export const SET_OPEN_MODAL_DOCUMENT = 'SET_OPEN_MODAL_DOCUMENT'
export const SET_DOCUMENT_CATEGORY = 'SET_DOCUMENT_CATEGORY'
export const SET_MASTER_FILE_DOCUMENTS = 'SET_MASTER_FILE_DOCUMENTS'
export const SET_PRICELIST_DRAFT = 'SET_PRICELIST_DRAFT'
export const SET_SAVE_PRICELIST_MODAL_OPEN = 'SET_SAVE_PRICELIST_MODAL_OPEN'
export const SET_ALL_PRICELISTS = 'SET_ALL_PRICELISTS'
export const SET_SAVE_PRICELIST_LOADING = 'SET_SAVE_PRICELIST_LOADING'
export const SET_GET_PRICELISTS_LOADING = 'SET_GET_PRICELISTS_LOADING'
export const SET_PRICELIST = 'SET_PRICELIST'




// gop
export const RESET_GOP = 'RESET_GOP';
export const SET_ALL_GOP = 'SET_ALL_GOP';
export const SET_SELECTED_GOP = 'SET_SELECTED_GOP';
export const UPDATE_SELECTED_GOP = 'UPDATE_SELECTED_GOP';
export const RESET_SELECTED_GOP = 'RESET_SELECTED_GOP';
export const SET_GET_ONE_GOP_LOADING = 'SET_GET_ONE_GOP_LOADING';
export const RESET_GOP_LOADING = 'RESET_GOP_LOADING';
export const RESET_DRAFT_GOP = 'RESET_DRAFT_GOP';
export const SET_DRAFT_OBJ = 'SET_DRAFT_OBJ';
export const SET_DRAFT_GOP_ID = 'SET_DRAFT_GOP_ID';
export const SET_DRAFT_GOP_PATIENT = 'SET_DRAFT_GOP_PATIENT';
export const SET_DRAFT_GOP_CASE_ADMISSION = 'SET_DRAFT_GOP_CASE_ADMISSION';
export const SET_DRAFT_GOP_CASE_TREATMENT = 'SET_DRAFT_GOP_CASE_TREATMENT';
export const SET_DRAFT_GOP_CASE_BILLING = 'SET_DRAFT_GOP_CASE_BILLING';
export const SET_GOP_SEARCH_RESULT_PATIENT_DETAILS = 'SET_GOP_SEARCH_RESULT_PATIENT_DETAILS';
export const SET_OPEN_GOPS = 'SET_OPEN_GOPS';
export const SET_PENDING_GOPS = 'SET_PENDING_GOPS';
export const SET_CLOSED_GOPS = 'SET_CLOSED_GOPS';
export const SET_SELECTED_GOP_DOCS = 'SET_SELECTED_GOP_DOCS';
export const UPDATED_ONE_AND_SELECTED_GOP = 'UPDATED_ONE_AND_SELECTED_GOP';
export const SET_OPEN_MODAL_GOP_SUCCESS = 'SET_OPEN_MODAL_GOP_SUCCESS';
export const SET_OPEN_MODAL_GOP_ASSIGN_TO = 'SET_OPEN_MODAL_GOP_ASSIGN_TO';
export const SET_SELECTED_GOP_POLICY_DETAILS = 'SET_SELECTED_GOP_POLICY_DETAILS';
export const SET_SELECTED_GOP_CLAIMS_HISTORY = 'SET_SELECTED_GOP_CLAIMS_HISTORY';
export const SET_POSSIBLE_IMS_CLAIM_HANDLERS = 'SET_POSSIBLE_IMS_CLAIM_HANDLERS';
export const SET_ASSIGN_TO_GOP_ID = 'SET_ASSIGN_TO_GOP_ID';
export const SET_GOP_DOCS_LENGTH = 'SET_GOP_DOCS_LENGTH';
export const SET_OVERVIEW_STATS = 'SET_OVERVIEW_STATS';
export const SET_OPEN_MODAL_ADD_ANOTHER_BILL = 'SET_OPEN_MODAL_ADD_ANOTHER_BILL';
export const SET_POLICY_INPATIENT_CATASTROPHE_DATA = 'SET_POLICY_INPATIENT_CATASTROPHE_DATA';
export const SET_POLICY_EXCLUSIONS_DATA = 'SET_POLICY_EXCLUSIONS_DATA';
export const SET_POLICY_COVERAGE_TREATMENT_DATA = 'SET_POLICY_COVERAGE_TREATMENT_DATA';
export const UPDATE_WHERE_WITH_WHAT_BY_GOP_ID = 'UPDATE_WHERE_WITH_WHAT_BY_GOP_ID';
export const RESET_BILL_REMARKS = 'RESET_BILL_REMARKS';
export const SET_BILL_REMARKS = 'SET_BILL_REMARKS';
export const SET_VETTING_REMARKS = 'SET_VETTING_REMARKS';
export const RESET_VETTING_REMARKS = 'RESET_VETTING_REMARKS';
export const SET_GOP_CHATS = 'SET_GOP_CHATS';
export const SET_GOP_BILL_DOCS = 'SET_GOP_BILL_DOCS';
export const SET_OPEN_MODAL_CONFIRM_UPDATE_BILL = 'SET_OPEN_MODAL_CONFIRM_UPDATE_BILL';
export const SET_MODAL_CONFIRM_UPDATE_BILL = 'SET_MODAL_CONFIRM_UPDATE_BILL';


// draft bill
export const SET_DRAFT_BILL_LOADING = 'SET_DRAFT_BILL_LOADING';
export const SET_DRAFT_BILL = 'SET_DRAFT_BILL';
export const SET_DRAFT_BILL_DOCS = 'SET_DRAFT_BILL_DOCS';



// view 

export const RESET_VIEW = 'RESET_VIEW';
export const SET_SNACKBAR_OPEN = 'SET_SNACKBAR_OPEN';
export const SET_MAIN_VIEW_CONFIG = 'SET_MAIN_VIEW_CONFIG';
export const SET_GOP_STATS = 'SET_GOP_STATS';
export const SET_GOP_STATS_LOADING = 'SET_GOP_STATS_LOADING';
export const SET_LOADING_GOP_CREATE = 'SET_LOADING_GOP_CREATE';
export const SET_LOADING_MAIN_PAGE = 'SET_LOADING_MAIN_PAGE';
export const ADD_MESSAGE_TO_SNACKBAR = 'ADD_MESSAGE_TO_SNACKBAR';
export const REMOVE_MESSAGE_FROM_SNACKBAR = 'REMOVE_MESSAGE_FROM_SNACKBAR';
export const SET_VIEW = 'SET_VIEW';


// loading
export const SET_LOADING_GOP_SEARCH_PATIENT = 'SET_LOADING_GOP_SEARCH_PATIENT';
export const SET_LOADING_UPLOAD_DOCUMENT = 'SET_LOADING_UPLOAD_DOCUMENT';

// Notifications

export const SET_SHOW_NOTIFICATIONS_DRAWER = 'SET_SHOW_NOTIFICATIONS_DRAWER';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_UNSEEN_NOTIFICATIONS_COUNT = 'SET_UNSEEN_NOTIFICATIONS_COUNT';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';
export const UPDATE_NOTIFICATION_STATE_TO_SEEN = 'UPDATE_NOTIFICATION_STATE_TO_SEEN';

// Fees
export const SET_FEES = 'SET_FEES';
export const UPDATE_DRAFT_FEE_ITEM = 'UPDATE_DRAFT_FEE_ITEM';
export const UPDATE_DRAFT_FEE_SUBITEM = 'UPDATE_DRAFT_FEE_SUBITEM';
export const SET_SELECTOR_SUBITEMS_MODAL_OPEN = 'SET_SELECTOR_SUBITEMS_MODAL_OPEN';
export const SET_SUBITEMS_SELECTOR_CONFIG = 'SET_SUBITEMS_SELECTOR_CONFIG';
export const RESET_DRAFT_FEES = 'RESET_DRAFT_FEES';
export const SET_LOADING_SAVE_FEES = 'SET_LOADING_SAVE_FEES'
export const SET_LOADING_SAVE_ONE_FEE = 'SET_LOADING_SAVE_ONE_FEE'
export const SET_LOADING_SUBMIT_FEES = 'SET_LOADING_SUBMIT_FEES'
export const SET_LOADING_GET_FEES = 'SET_LOADING_GET_FEES'
export const SET_FEE_REMARKS = 'SET_FEE_REMARKS'
export const SET_CAT_EXTERNAL_FEES_REMARKS = 'SET_CAT_EXTERNAL_FEES_REMARKS'
export const SET_CAT_INTERNAL_FEES_REMARKS = 'SET_CAT_INTERNAL_FEES_REMARKS'
export const SET_DISALLOWED_ITEMS = 'SET_DISALLOWED_ITEMS'
export const SET_DISALLOWED_ITEMS_LOADING = 'SET_DISALLOWED_ITEMS_LOADING'
export const SET_DIS_ITEMS_REMARKS = 'SET_DIS_ITEMS_REMARKS'
export const SET_PRICELIST_ACTIVE_ITEMS_DROPDOWN = 'SET_PRICELIST_ACTIVE_ITEMS_DROPDOWN'

// L1 L3 cases(gops)

export const SET_OPEN_L_CASES_LOADING = 'SET_OPEN_L_CASES_LOADING'
export const SET_OPEN_L_CASES = 'SET_OPEN_L_CASES'
export const SET_CLOSED_L_CASES_LOADING = 'SET_CLOSED_L_CASES_LOADING'
export const SET_CLOSED_L_CASES = 'SET_CLOSED_L_CASES'
export const RESET_L_CASES = 'RESET_L_CASES'


