import {
  SET_OPEN_L_CASES,
  SET_CLOSED_L_CASES,
  SET_OPEN_L_CASES_LOADING,
  SET_CLOSED_L_CASES_LOADING,
  RESET_L_CASES
} from '../actionTypes';

const INITIAL_STATE = {
  loadingOpenCasesL: false,
  loadingClosedCasesL: false,
  openCasesL: {
    columns: [],
    claims: []
  },
  closedCasesL: {
    columns: [],
    claims: []
  }
};


export const l1l3Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OPEN_L_CASES_LOADING:
      return { ...state, loadingOpenCasesL: action.payload }
    case SET_CLOSED_L_CASES_LOADING:
      return { ...state, loadingClosedCasesL: action.payload }
    case SET_OPEN_L_CASES:
      return { ...state, openCasesL: action.payload }
    case SET_CLOSED_L_CASES:
      return { ...state, closedCasesL: action.payload }
    case RESET_L_CASES:
      return { ...INITIAL_STATE }
    default:
      return state;
  }
};

