import { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { getPossibleImsHandlers, assignToClaimHandler } from "../../../redux/actions/gop.actions";
import { DropdownWithName } from "../../../shared";

const AssignToModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const possibleImsClaimHandlers = useSelector(state => state.gop.possibleImsClaimHandlers)
  const openModalAssignTo = useSelector(state => state.gop.modals.openModalAssignTo)
  const gopID = useSelector(state => state.gop.assignToGopID)
  const [selectedHandler, setSelectedHandler] = useState('')

  useEffect(() => {
    dispatch(getPossibleImsHandlers());
  }, [])

  return (
    <Dialog maxWidth={'xs'} fullWidth={true} open={openModalAssignTo} onClose={handleClose}>
      <Box pl={3} pr={3} pt={3} pb={3}>
        <Box display='flex' flexDirection='row' pb={2} justifyContent='space-between' alignItems='center'>
          <Typography sx={{ color: 'secondary.main', fontSize: 20, fontFamily: 'Globersemibold', textTransform: 'uppercase' }}>
            Select Claim Handler
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon color='secondary' />
          </IconButton>
        </Box>
        <DropdownWithName
          sx={{ mb: 3 }}
          label='Handlers'
          options={possibleImsClaimHandlers}
          value={selectedHandler}
          onChange={setSelectedHandler}
        />
        <Box display='flex' justifyContent='space-around'>
          <Button variant="text" color='secondary'
            onClick={handleClose}
            sx={{
              fontWeight: 'bold'
            }}>
            Cancel
          </Button>
          <Button variant="contained" color='info'
            onClick={() => {
              if (selectedHandler) {
                dispatch(assignToClaimHandler({ gopID, userID: selectedHandler.user_id }))
              }
            }}
            sx={{
              backgroundColor: 'primary.main',
              color: "#fff",
              fontWeight: 'bold'
            }}>
            Assign
          </Button>
        </Box>
      </Box>
    </Dialog >
  )
};

export default AssignToModal