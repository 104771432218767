import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
// import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {  DocumentCategory, FileViewer } from '../../../../shared';
import { createGop } from '../../../../redux/actions/gop.actions';
import { useNavigate } from 'react-router-dom';
import { getDownloadFileUrl } from '../../../../utils/upload';
import { LoadingButton } from '@mui/lab';

const Documents = ({ gop_id, gop_docs, display, bill_id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newGopCreateLoading = useSelector(state => state?.view?.loading?.newGopCreate || false) 
  const [showFileViewer, setShowFileViewer] = useState(false)
  const [filePreviewLink, setFilePreviewLink] = useState('')

  useEffect(() => {
    setShowFileViewer(false)
  }, [gop_id]);

  const openDownloadFileLink = async (file) => {
    let link = await getDownloadFileUrl(gop_id, file.s3_uuid)
    setFilePreviewLink(link)
    // const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
    // if (newWindow) newWindow.opener = null
    setShowFileViewer(true)
  }

  return (
    <Box p={4} flex={1} sx={{display: 'flex'}}>

      {!showFileViewer && <Box flex={1}>
        {gop_docs?.map?.((sectionObj, index) => {
          return (<DocumentCategory bill_id={bill_id} gop_id={gop_id} section={sectionObj} index={index} key={sectionObj.section} openFile={openDownloadFileLink} />)
        })}
        {
          display === 'CREATE' ?
            <Box mt={3}>
              <LoadingButton
                loading={newGopCreateLoading}
                color='secondary'
                sx={{
                  fontSize: '14px',
                  fontWeight: '500',
                  width: 345,
                }}
                onClick={() => { dispatch(createGop({ navigate })) }}
                variant="contained">Create New GOP</LoadingButton>
            </Box>
            : null
        }
      </Box>
      }
      {showFileViewer && <FileViewer link={filePreviewLink} hide={()=> setShowFileViewer(false)}></FileViewer>}
    </Box >
  );
};

export default Documents;