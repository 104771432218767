import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Dialog,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  createCompanyGroup,
  createEntity,
} from "../../../redux/actions/entities.actions";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownWithValue, UploadContainer } from "../../../shared";
import { entityTypeAndID } from "../../../utils/entityTypeAndID";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import InputAdornment from "@mui/material/InputAdornment";
import { getRulesValidation, getRulesValidationErrorMsg } from "../../../utils/onChangeFunction";
import { useForm, Controller } from "react-hook-form";


// const TextFieldPrepared = ({
//   label,
//   startAdornment,
//   id,
//   value,
//   mutatorFunction,
//   editable,
//   validator,
//   mutatorErrorFunction,
// }) => (
//   <TextField
//     autoComplete="off"
//     sx={{ minWidth: 240, width: "100%" }}
//     label={label}
//     InputProps={{
//       startAdornment: startAdornment ? (
//         <InputAdornment position="start">{startAdornment}</InputAdornment>
//       ) : (<></>),
//     }}
//     variant="filled"
//     disabled={!editable}
//     value={value}
//     onChange={(e) =>
//       onUserInputChange(
//         e.target.value,
//         mutatorFunction,
//         validator,
//         mutatorErrorFunction
//       )
//     }
//     id={id}
//   />
// );

const ControllerField = ({ control, nameControl, validationKey, ...rest }) => {
  return (
    <Controller
      name={nameControl}
      control={control}
      defaultValue={''}
      rules={{ validate: { ...getRulesValidation(validationKey) } }}
      render={({
        field: { onChange, ...restField },
        fieldState: { invalid, error },
      }) => {
        return (<TextField
          sx={{ minWidth: 240, width: "100%" }}
          variant="filled"
          onChange={onChange}
          error={!!invalid}
          helperText={error ? (error?.message ? error.message : getRulesValidationErrorMsg(validationKey, error?.type)) : null}
          {...restField}
          {...rest}
        />)
      }}
    />
  )
}

const ContactButton = ({ onClick, selected, title, color = "" }) => {
  return (
    <Button
      sx={{
        width: "100%",
        mb: 2,
        backgroundColor: selected
          ? "rgba(0, 183, 205, 0.2)"
          : "rgba(0, 183, 205, 0.06)",
        borderRadius: 0,
        color: color === "secondary" ? "secondary.main" : "primary.main",
        "&:hover": {
          backgroundColor: "rgba(0, 183, 205, 0.25)",
        },
      }}
      variant="contained"
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

const ContactPersonInformation = ({ contactPersons, setContactPersons }) => {
  const [contactSelectedIndex, setContactSelectedIndex] = useState(contactPersons.length);
  const [isEdit, setIsEdit] = useState(false);
  const defaultValueContact = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  }
  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: { ...defaultValueContact },
    mode: 'all'
  });

  const handleSubmitContactForm = (formData) => {
    console.log("contactForm - ", formData)
    if (isEdit) {
      console.log("Edit contact record")
      let newContacts = [...contactPersons]
      newContacts[contactSelectedIndex] = { ...formData }
      setContactPersons([
        ...newContacts
      ]);
      setIsEdit(!isEdit)
    }
    else {
      console.log("Add contact record")
      let newContacts = [...contactPersons, { ...formData }]
      setContactPersons(newContacts);
      setContactSelectedIndex(newContacts.length)
      reset(defaultValueContact)
    }
  }

  const setEditContactData = (item) => {
    setValue('firstName', item?.firstName, { shouldValidate: true, shouldDirty: true })
    setValue('lastName', item?.lastName, { shouldValidate: true, shouldDirty: true })
    setValue('email', item?.email, { shouldValidate: true, shouldDirty: true })
    setValue('phone', item?.phone, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    setIsEdit(false);
  }, [contactSelectedIndex]);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        pt={5}
        pb={4}
        justifyContent="space-between"
      >
        <Typography
          sx={{
            color: "secondary.main",
            fontSize: 24,
            fontFamily: "Globersemibold",
          }}
        >
          Contact person information
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={3}
          sx={{ borderRight: 1, borderColor: "#e5e5e5", borderRadius: 0 }}
        >
          {contactPersons.map((item, index) => (
            <ContactButton
              title={item.firstName + " " + item.lastName}
              key={index.toString()}
              selected={contactSelectedIndex === index}
              onClick={() => {
                setEditContactData(item)
                setContactSelectedIndex(index);
              }}
            />
          ))}
          <ContactButton
            title={"New Contact"}
            key={"newcontact"}
            color="secondary"
            selected={contactSelectedIndex === contactPersons.length}
            onClick={() => {
              reset(defaultValueContact)
              setContactSelectedIndex(contactPersons.length);
            }}
          />
        </Grid>
        <Grid item container xs={9} spacing={4}>
          {/* first row */}
          <Grid item xs={6}>
            <ControllerField control={control}
              nameControl={'firstName'}
              validationKey={'NAME_VALIDATION'}
              label={"First Name"}
              disabled={!(contactSelectedIndex === contactPersons.length || isEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <ControllerField control={control}
              nameControl={'lastName'}
              validationKey={'NAME_VALIDATION'}
              label={"Last Name"}
              disabled={!(contactSelectedIndex === contactPersons.length || isEdit)}
            />
          </Grid>

          {/* second row */}
          <Grid item xs={6}>
            <ControllerField control={control}
              nameControl={'email'}
              validationKey={'USER_MGMT_USERNAME'}
              label={"Email"}
              disabled={!(contactSelectedIndex === contactPersons.length || isEdit)}
            />
          </Grid>
          <Grid item xs={6}>
            <ControllerField control={control}
              nameControl={'phone'}
              validationKey={'MOBILE_NUMBER_VALIDATION'}
              label={"Phone Number"}
              disabled={!(contactSelectedIndex === contactPersons.length || isEdit)}
            />
          </Grid>
          {(contactSelectedIndex !== contactPersons.length && !isEdit) && (
            <Grid item xs={10} display="flex" flexDirection="row">
              <Button color="secondary" onClick={() => setIsEdit(!isEdit)}>
                <EditIcon sx={{ marginRight: "1rem" }} />
                EDIT
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  const newArrDeleted = [...contactPersons];
                  newArrDeleted.splice(contactSelectedIndex, 1);
                  setContactPersons(newArrDeleted);
                  setContactSelectedIndex(newArrDeleted.length);
                  reset(defaultValueContact)
                }}
              >
                <DeleteOutlinedIcon sx={{ marginRight: "1rem" }} /> DELETE
              </Button>
            </Grid>
          )}
          {(contactSelectedIndex === contactPersons.length || isEdit) &&
            <Grid item xs={12} display="flex" flexDirection="row">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit(handleSubmitContactForm)}
              >
                {contactSelectedIndex === contactPersons.length ? 'ADD CONTACT' : 'SAVE CHANGES'}
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
    </Box>
  );
};

const CreateCompanyGroupModal = ({ open, handleOpen, type }) => {
  const dispatch = useDispatch();
  const [parentCompany, setParentCompany] = useState("");
  const [contactPersons, setContactPersons] = useState([]);
  const [selectedFile, setFile] = useState("");
  const selectedCompany = useSelector((state) => state.entities.selectedCompany);
  const defaultValuesCompany = {
    name: '',
    email: '',
    phone: '',
    address: '',
    description: ''
  }

  const { handleSubmit, control, reset } = useForm({
    defaultValues: { ...defaultValuesCompany },
    mode: 'all'
  });

  const handleSaveAction = (formData) => {
    if (type === "group" && contactPersons.length > 0 && !!selectedFile) {
      dispatch(
        createCompanyGroup({
          entity: { ...formData },
          contactPersons,
          logo: selectedFile,
        })
      );
    }
    else if (type !== "group" && contactPersons.length > 0 && !!selectedFile && parentCompany) {
      dispatch(
        createEntity({
          entity: {
            ...formData,
            category: "clinic",
            parent_type: entityTypeAndID(parentCompany).entityType,
            parent_id: entityTypeAndID(parentCompany).entityID,
          },
          contactPersons,
          logo: selectedFile,
        })
      );
    }
  }

  useEffect(() => {
    reset(defaultValuesCompany)
    setContactPersons([])
    setFile("")
  }, [open]);

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={open}
      onClose={() => handleOpen(false)}
    >
      <Box pl={3} pr={3} pt={5} pb={5}>
        <Box
          display="flex"
          flexDirection="row"
          pb={5}
          justifyContent="space-between"
        >
          <Typography
            sx={{
              color: "primary.main",
              fontSize: 24,
              fontFamily: "Globersemibold",
              textTransform: "uppercase",
            }}
          >
            {type === "group" ? "create new company group" : "add new entity"}
          </Typography>
          <IconButton onClick={() => handleOpen(false)}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Box>

        <Grid container spacing={4}>
          {/* first row */}
          <Grid item xs={4}>
            <ControllerField control={control}
              nameControl={'name'}
              validationKey={'GROUP_NAME_VALIDATION'}
              label={type === "group" ? "Group Name" : "Entity Title"}
              disabled={false}
            />
          </Grid>
          <Grid item xs={4}>
            <ControllerField control={control}
              nameControl={'email'}
              validationKey={'USER_MGMT_USERNAME'}
              label={"Email Address"}
              disabled={false}
            />
          </Grid>
          <Grid item xs={4}>
            <UploadContainer selectedFile={selectedFile} setFile={setFile} />
          </Grid>

          {/* optional row */}

          {type !== "group" && (
            <>
              <Grid item xs={6}>
                <Dropdown
                  options={["Clinic"]}
                  onChange={() => { }}
                  value="Clinic"
                  label="Category"
                  loading={false}
                  variant="filled"
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={6}>
                <DropdownWithValue
                  options={[selectedCompany]}
                  onChange={setParentCompany}
                  value={parentCompany}
                  label="Parent Company/Group"
                  loading={false}
                  variant="filled"
                  sx={{ width: "100%" }}
                />
              </Grid>
            </>
          )}

          {/* second row */}

          <Grid item xs={4}>
            <ControllerField control={control}
              nameControl={'phone'}
              validationKey={'MOBILE_NUMBER_VALIDATION'}
              label={"Phone"}
              disabled={false}
            />
          </Grid>
          <Grid item xs={8}>
            <ControllerField control={control}
              nameControl={'address'}
              validationKey={'ADDRESS_VALIDATION'}
              label={"Address"}
              disabled={false}
            />
          </Grid>

          {/* third row */}

          <Grid item xs={12}>
            <ControllerField control={control}
              nameControl={'description'}
              validationKey={'DESCRIPTION_VALIDATION'}
              label={"Short Description"}
              disabled={false}
            />
          </Grid>
        </Grid>
        <ContactPersonInformation
          contactPersons={contactPersons}
          setContactPersons={setContactPersons}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mt={2}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit(handleSaveAction)}
          >
            {type === "group" ? "add group" : "create new item"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateCompanyGroupModal;
