import React, { useRef, useState, useEffect } from 'react';
import { Box, Toolbar, Typography, IconButton, Badge, Grid, Button, Dialog, Modal,  } from '@mui/material';
import { Logout, Notifications, CloseOutlined } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../redux/actions/auth.actions';
import { resetEntities } from '../redux/actions/entities.actions';
import { resetDocuments } from '../redux/actions/documents.actions';
import { resetGop } from '../redux/actions/gop.actions';
import { resetViewState } from '../redux/actions/view.actions';
import { NotificationsPage } from '../pages';
import { getAllNotifications, resetNotifications, setShowNotificationsDrawer } from '../redux/actions/notifications.action';
import { setChooseProfileModalOpen } from '../redux/actions/profiles.actions';
import { closeConnection } from '../utils/connectNats';
import { setIFrameOpen } from './QueryFrame';

// import { ReactComponent as SWNLOGOWHITE } from '../media/logos/swan_logo_white.svg';
// import blueBackgroundBanner from "../media/backgroundImages/blueBackgroundBanner.png";


const Header = () => {
  const dispatch = useDispatch()
  const toolbarRef = useRef();
  const [toolbarHeight, setToolbarHeight] = useState(0);
  const user = useSelector(state => state.auth.user) || {}
  const unseenNotificatons = useSelector(state => state.notifications.unseenNotificatons);

  console.log("unseenNotificatons - ", unseenNotificatons);
  useEffect(() => {
    if (toolbarRef.current) {
      setToolbarHeight(toolbarRef.current.offsetHeight)
    }
  }, [toolbarRef])

  const handleLogout = async () => {
    await closeConnection();
    setIFrameOpen(false);
    dispatch(logOut());
    dispatch(resetEntities());
    dispatch(resetDocuments());
    dispatch(resetGop());
    dispatch(resetViewState());
    dispatch(resetNotifications());
  }


  return (
    <Toolbar
      ref={toolbarRef}
      disableGutters={true}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#f6f6f6',
        justifyContent: 'flex-end',
        alignItems: 'center',
        // width: '100%',
        position: 'relative',
        top: 0
      }}>
      <Box pr={2}>
        <IconButton onClick={() => { dispatch(setShowNotificationsDrawer(true)) }}>
          <Badge badgeContent={unseenNotificatons} color="error" max={1000}>
            <Notifications sx={{ color: unseenNotificatons ? '#d32f2f' : 'primary.main' }} />
          </Badge>
        </IconButton>
      </Box>
      <Box
        pl={4} pr={4}
        sx={{ borderLeft: 2, borderColor: 'primary.main' }}
        display='flex'
        flexDirection='row'
      >
          <IconButton onClick={() => {dispatch(setChooseProfileModalOpen(true))}}>
              <PersonIcon color='info' />
          </IconButton>
        {/*<PersonIcon color='info' />*/}
        <Box pl={2}>
          <Typography style={{ color: 'primary.grey', fontFamily: 'Globerbold', fontSize: 14 }}>{user.firstName + ' ' + user.lastName}</Typography>
          <Typography style={{ color: 'primary.grey', fontSize: 12, marginTop: -5, fontFamily: 'Globersemibold' }}>{user.actorTitle}</Typography>
        </Box>
      </Box>
      <Button
        sx={{
          // display:'flex',
          // flexDirection: 'column',
          // flex: 1,
          height: '100%',
          p: 0
        }}
        onClick={handleLogout}
      >
        <Logout sx={{
          height: toolbarHeight,
          minHeight: 48,
          color: 'white',
          paddingLeft: 3,
          paddingRight: 3,
          alignSelf: 'center',
          backgroundColor: 'primary.main'
        }} />
      </Button>
      <NotificationsPage />

    </Toolbar>


  );
  // export const useToolbarHeight = () => {
  //   return [toolbarHeight];
  // }
};

export default Header;