import {
  SET_NOTIFICATIONS,
  SET_SHOW_NOTIFICATIONS_DRAWER,
  SET_UNSEEN_NOTIFICATIONS_COUNT,
  RESET_NOTIFICATIONS,
  ADD_NEW_NOTIFICATION,
  UPDATE_NOTIFICATION_STATE_TO_SEEN
} from '../actionTypes';

const INITIAL_STATE = {
  notifications: [],
  unseenNotificatons: 0,
  showNotificationsDrawer: false
};


export const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload }
    case SET_SHOW_NOTIFICATIONS_DRAWER:
      return { ...state, showNotificationsDrawer: action.payload }
    case SET_UNSEEN_NOTIFICATIONS_COUNT:
      return { ...state, unseenNotificatons: action.payload }
    case ADD_NEW_NOTIFICATION: {
      const notification = action.payload;
      let modifyNotifications = []
      let thisNotifications = state.notifications;
      if (thisNotifications.find((it) => { return it.keys.notification_id === notification.keys.notification_id })) {
        console.log('can not add notification, id already exists in array')
        modifyNotifications = [...thisNotifications]
      } else {
        console.log('add notification to the beginning of array')
        modifyNotifications = [notification, ...thisNotifications]
      }
      //count unseen notifications after modify arr 
      const unseenArr = modifyNotifications.filter((it) => { return it.value.state === 'UNSEEN' })
      return { ...state, notifications: modifyNotifications, unseenNotificatons: unseenArr.length }
    }
    case UPDATE_NOTIFICATION_STATE_TO_SEEN: {
      let updateNtfts = [...state.notifications];
      let ntft = action.payload;
      const indexOfOne = updateNtfts.findIndex((item) => item.keys.notification_id === ntft?.keys?.notification_id);
      if (indexOfOne !== -1) {
        updateNtfts[indexOfOne] = { ...updateNtfts[indexOfOne], value: { ...updateNtfts[indexOfOne].value, state: 'SEEN' } }
      }
      const unseenArr = updateNtfts.filter((it) => { return it.value.state === 'UNSEEN' })
      return { ...state, notifications: updateNtfts, unseenNotificatons: unseenArr.length }
    }
    case RESET_NOTIFICATIONS:
      return { ...INITIAL_STATE }
    default:
      return state;
  }
};

