import * as React from "react";
import { useSelector } from "react-redux";
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  Legend,
} from "recharts";
import Title from "./Title";


const ClaimsAndCasesChart = () => {
  const allocations = useSelector(state => state.gop.overviewStats?.allocations)

  return (
    <>
      <Title>Allocations</Title>
      <ResponsiveContainer>
        <BarChart height={100} data={allocations}>
          <XAxis dataKey="name" />
          <YAxis type="number" hide />
          <Tooltip />
          <Legend
            layout="vertical"
            verticalAlign="middle"
            align="right"
            iconType="circle"
            // style={{ marginLeft: 55 }}
          />
          <Bar dataKey="opened" stackId="a" fill="#EA3E96" barSize={50} />
          <Bar dataKey="inReview" stackId="a" fill=" #001F72" barSize={50} />
          <Bar dataKey="inQuery" stackId="a" fill="#7FDBE6" barSize={50} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default ClaimsAndCasesChart;