import React from 'react';
// import { Box, Fab } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { PageContainer, PageBodyContainer, DrawerContainer, CompanyDrawer } from '../../shared';
import { Navigate, Outlet } from 'react-router';
import { useSelector } from 'react-redux';
import { entityTypeAndID } from '../../utils/entityTypeAndID';

const CompanyDetails = () => {
  const selectedCompany = useSelector(state => state.entities.selectedCompany)

  return entityTypeAndID(selectedCompany)?.entityID ? (
    <PageContainer>
      <DrawerContainer
        isLogo={false}
        description=''
        underline={false}
        isBack={true}
      >
        <CompanyDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Outlet />
      </PageBodyContainer>
    </PageContainer>
  ) : (
    <Navigate to='/' />
  );
};

export default CompanyDetails;