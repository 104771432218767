import React, { useEffect, useState, useRef, ReactElement } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Viewer, Worker, RotateDirection } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

const FileViewer = ({ hide, link }) => {
  const documentWrapperRef = useRef(null)
  const [pageWidth, setPageWidth] = useState(0)
  const rotatePluginInstance = rotatePlugin();
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;


  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          CurrentScale,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          ZoomIn,
          ZoomOut,
          Print,
          Download,
          EnterFullScreen
        } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <div>
              <Button sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                startIcon={<ArrowBackIcon />}
                onClick={() => hide()}>
                Back
              </Button>
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomOut />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <CurrentScale />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomIn />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding: '0px 2px', width: '4rem' }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: '0px 2px' }}>
              / <NumberOfPages />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <RotateBackwardButton />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <RotateForwardButton />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <EnterFullScreen />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Download />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Print />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => { return [] },
    renderToolbar
  });

  const handleError = (error) => {
    console.log(error);
    return (
      <div
        style={{
          alignItems: 'center',
          border: '1px solid rgba(0, 0, 0, 0.3)',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            backgroundColor: '#e53e3e',
            borderRadius: '0.25rem',
            color: '#fff',
            padding: '0.5rem',
          }}
        >
          {error.message}
        </div>
        <div style={{
          marginTop: '10px'
        }}>
          <Button sx={{ color: "rgba(0, 0, 0, 0.87)" }}
            startIcon={<ArrowBackIcon />}
            variant='outlined'
            onClick={() => hide()}>
            Back
          </Button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    console.log("documentWrapperRef - ", documentWrapperRef.current.clientWidth)
    setPageWidth((documentWrapperRef.current.clientWidth - 10))
  }, []);


  return (
    <Box flex={1} ref={documentWrapperRef} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box display='flex' sx={{ overflow: 'hidden', height: '600px', width: pageWidth }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
          <Viewer
            fileUrl={link}
            plugins={[
              defaultLayoutPluginInstance,
              rotatePluginInstance
            ]}
            renderError={handleError}
          />
        </Worker>
      </Box>
    </Box >
  );
};

export default FileViewer;