import {
  SET_FEES,
  RESET_DRAFT_FEES,
  UPDATE_DRAFT_FEE_ITEM,
  UPDATE_DRAFT_FEE_SUBITEM,
  SET_SELECTOR_SUBITEMS_MODAL_OPEN,
  SET_SUBITEMS_SELECTOR_CONFIG,
  SET_LOADING_SAVE_FEES,
  SET_LOADING_SAVE_ONE_FEE,
  SET_LOADING_SUBMIT_FEES,
  SET_LOADING_GET_FEES,
  SET_FEE_REMARKS,
  SET_CAT_EXTERNAL_FEES_REMARKS,
  SET_CAT_INTERNAL_FEES_REMARKS,
  SET_DISALLOWED_ITEMS,
  SET_DISALLOWED_ITEMS_LOADING,
  SET_DIS_ITEMS_REMARKS,
  SET_PRICELIST_ACTIVE_ITEMS_DROPDOWN
} from '../actionTypes';

const INITIAL_STATE = {
  inpatient: {
    fees: [],
    draftFees: [],
    title: '',
  },
  catastrophe: {
    fees: [],
    draftFees: [],
    title: '',
  },
  selectorConfig: {
    options: [],
    title: '',
    sectionKey: '',
    parentKey: '',
    subitemKey: ''
  },
  openSelectorModal: false,
  loading: {
    saveFees: false,
    saveOneFee: false,
    submitLoading: false,
    getFees: false
  },
  disallowedItems: {
    disallowed_items: [],
    sections: [],
    externalRemarksSectionId: ''
  },
  externalRemarksDisItems: [],
  disallowedItemsLoading: false,
  feeRemarks: [],
  pricelistActiveItemsDropdown: []
};


export const feesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FEES:
      if (!action.payload?.type || !action?.payload?.data?.FEES) {
        return { ...state };
      }
      return {
        ...state,
        [action.payload.type]: {
          fees: [...action.payload.data.FEES],
          external_remarks_section_id: action?.payload?.data?.external_remarks_section_id,
          internal_remarks_section_id: action?.payload?.data?.internal_remarks_section_id,
          title: action.payload.data.title,
          draftFees: [...action.payload.data.FEES]
        }
      }
    case UPDATE_DRAFT_FEE_ITEM:
      let feeItem = '';
      let newDraftFees = state[action.payload.type].draftFees;
      const section = newDraftFees.find((section) => section.k === action.payload.sectionKey);

      if (section?.items) {
        feeItem = section.items.find((item) => item.k === action.payload.feeKey);
        if (typeof feeItem === 'object' && feeItem !== null) {
          feeItem[action.payload.valueKey] = action.payload.value;
        }
      }
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          draftFees: newDraftFees
        }
      }

    case UPDATE_DRAFT_FEE_SUBITEM:
      let selectedFeeItem = '';
      let selectedFeeItemIndex = -1;
      let newDraftFeesArr = state[action.payload.type].draftFees;
      const selectedSectionIndex = newDraftFeesArr.findIndex((section) => section.k === action.payload.sectionKey);
      const selectedSection = newDraftFeesArr[selectedSectionIndex];

      if (selectedSection?.items) {
        selectedFeeItemIndex = selectedSection.items.findIndex((item) => item.k === action.payload.parentKey);
        selectedFeeItem = selectedSection?.items?.[selectedFeeItemIndex];
        if (typeof selectedFeeItem === 'object' && selectedFeeItem !== null && selectedFeeItemIndex > -1) {
          const updatedSelectedFeeItem = { ...selectedFeeItem, [action.payload.subitemKey]: action.payload.value }
          // selectedFeeItem[action.payload.subitemKey] = action.payload.value;
          // console.log('updatedSelectedFeeItem', updatedSelectedFeeItem,)
          newDraftFeesArr[selectedSectionIndex].items[selectedFeeItemIndex] = updatedSelectedFeeItem;
        }
      }
      // console.log('action.payload', action.payload,  'newDraftFeesArr', newDraftFeesArr)
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          draftFees: newDraftFeesArr
        }
      }

    case SET_SELECTOR_SUBITEMS_MODAL_OPEN:
      return { ...state, openSelectorModal: action.payload }

    case SET_SUBITEMS_SELECTOR_CONFIG:
      return { ...state, selectorConfig: { ...action.payload } }

    case RESET_DRAFT_FEES:
      return { ...state, draftFees: [...INITIAL_STATE.draftFees] }

    case SET_LOADING_SAVE_FEES:
      return { ...state, loading: { ...state.loading, saveFees: action.payload } }
    case SET_LOADING_SAVE_ONE_FEE:
      return { ...state, loading: { ...state.loading, saveOneFee: action.payload } }
    case SET_LOADING_SUBMIT_FEES:
      return { ...state, loading: { ...state.loading, submitLoading: action.payload } }
    case SET_LOADING_GET_FEES:
      return { ...state, loading: { ...state.loading, getFees: action.payload } }
    case SET_FEE_REMARKS:
      return { ...state, feeRemarks: action.payload }
    case SET_CAT_EXTERNAL_FEES_REMARKS:
      return { ...state, externalCatastropheRemarks: action.payload }
    case SET_CAT_INTERNAL_FEES_REMARKS:
      return { ...state, internalCatastropheRemarks: action.payload }
    case SET_DISALLOWED_ITEMS:
      return { ...state, disallowedItems: { ...state.disallowedItems, ...action.payload } }
    case SET_DISALLOWED_ITEMS_LOADING:
      return { ...state, disallowedItemsLoading: action.payload }
    case SET_DIS_ITEMS_REMARKS:
      return { ...state, externalRemarksDisItems: action.payload }
    case SET_PRICELIST_ACTIVE_ITEMS_DROPDOWN:
      return { ...state, pricelistActiveItemsDropdown: action.payload }
    default:
      return state;
  }
};

