import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Accordion, AccordionSummary, AccordionDetails, Grid, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { getPolicyDetails, getClaimsHistory, getPolicyInpatientCatastropheInformation, getPolicyExclusionsData, getPolicyTreatmentCoverageData } from '../../../../redux/actions/gop.actions';
import { COLORS } from '../../../../constants';
import ClaimsHistoryCard from './ClaimsHistoryCard'
import { GOP_POLICY_DETAILS_LIST, LOADING_STATE } from '../../../../constants/viewConfig';
import PolicyInpatientCatastropheData from './PolicyInpatientCatastropheData';
import PolicyExclusionsData from './PolicyExclusionsData';
import PolicyCoverageTreatmentData from './PolicyCoverageTreatmentData';
import PolicyScheduleOfBenefits from './PolicyScheduleOfBenefits';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';


const StyledAccordionSummary = ({ title = '', control = '', loadingState = '' }) => {
  return (
    <AccordionSummary
      sx={{
        color: COLORS.LIGHT_BLUE_COLOR,
        borderBottom: "1px solid #e5e5e5",
        padding: 0,
      }}
      expandIcon={<ExpandMoreIcon color='secondary' />}
      aria-controls={"panel-content-" + control}
      id={"panel-header-" + control}
    >
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', mr: 2 }}>
        <Typography>{title}</Typography>
        {loadingState === LOADING_STATE.IS_LOADING && <CircularProgress sx={{alignSelf: 'center'}} size={'16px'}/>}
        {loadingState === LOADING_STATE.LOADING_FAILED && <CancelIcon sx={{ color: COLORS.PINK_COLOR }} />}
        {loadingState === LOADING_STATE.LOADING_SUCCESSFUL && <DoneIcon />}
      </Box>
    </AccordionSummary>
  )
}


const PolicyDetailsTab = () => {
  const dispatch = useDispatch();
  const selectedGop = useSelector(state => state.gop.selectedGop);
  const { data: claimsHistory = [], loadingState: loadingClaimsHistory = '' } = selectedGop?.value?.claimsHistory || {};
  const { data: inpatientCatastropheInfo = [], loadingState: loadingInpatientCatastropheInfo = '' } = selectedGop?.value?.policyInpatientCatastropheInfo || {};
  const { data: exclusions = [], loadingState: loadingExclusions = '' } = selectedGop?.value?.policyExclusions || {};
  const { data: coverageTreatment = [], loadingState: loadingCoverageTreatment = '' } = selectedGop?.value?.policyCoverageTreatment || {};
  const { data: SOB = [], loadingState: loadingSOB = '' } = selectedGop?.value?.policySOB || [];
  const show = useSelector(state => state.gop.selectedGop?.value?.a_viewConfig?.show) || [];

  useEffect(() => {
    dispatch(getPolicyDetails())
  }, [selectedGop?.keys?.gop_id])



  return (
    <Box p={2} display='flex' flexDirection='column'>
      {(show.includes?.(GOP_POLICY_DETAILS_LIST.COVERAGE) || show.includes?.(GOP_POLICY_DETAILS_LIST.TREATMENT)) &&
        <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
          <StyledAccordionSummary title='COVERAGE / TREATMENT' loadingState={loadingCoverageTreatment} control='inpatient-info' />
          <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
            {coverageTreatment?.length > 0 && coverageTreatment.map((item, index) => (
              <PolicyCoverageTreatmentData key={index.toString()} row={item} />
            ))}
          </AccordionDetails>
        </Accordion>}

      {show.includes?.(GOP_POLICY_DETAILS_LIST.SOB) &&
        <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
          <StyledAccordionSummary title='SCHEDULE OF BENEFITS' loadingState={loadingSOB} control='schedule-of-benefits' />
          <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
            {SOB?.length > 0 && <PolicyScheduleOfBenefits rows={SOB} />}
          </AccordionDetails>
        </Accordion>
      }
      {(show.includes?.(GOP_POLICY_DETAILS_LIST.INPATIENT) || show.includes?.(GOP_POLICY_DETAILS_LIST.CATASTROPHE)) &&
        <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
          <StyledAccordionSummary title='INPATIENT / CATASTOPHE INFORMATION' loadingState={loadingInpatientCatastropheInfo} control='inpatient-info' />
          <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
            {inpatientCatastropheInfo?.length > 0 && inpatientCatastropheInfo.map((itemCategory, index) => (
              <PolicyInpatientCatastropheData key={index.toString()} sectionData={itemCategory} />
            ))}
          </AccordionDetails>
        </Accordion>}
      {show.includes?.(GOP_POLICY_DETAILS_LIST.EXCLUSIONS) &&
        <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
          <StyledAccordionSummary title='EXCLUSIONS' loadingState={loadingExclusions} control='exclusions' />
          <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
            {exclusions?.length > 0 && exclusions.map((itemCategory, index) => (
              <PolicyExclusionsData key={index.toString()} sectionData={itemCategory} />
            ))}
          </AccordionDetails>
        </Accordion>}
      {show.includes?.(GOP_POLICY_DETAILS_LIST.CLAIMS_HISTORY) &&
        <Accordion sx={{ boxShadow: "none", '&:before': { opacity: 0 } }}>
          <StyledAccordionSummary title='CLAIMS HISTORY' loadingState={loadingClaimsHistory} control='claim-history' />
          <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
            {claimsHistory?.length > 0 && claimsHistory?.map?.((item, index) =>
              <ClaimsHistoryCard key={index.toString()} claim={item} />)}
          </AccordionDetails>
        </Accordion>}
    </Box>
  );
};

export default PolicyDetailsTab;