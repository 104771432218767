import React from "react";
import { Box, Typography, Grid } from "@mui/material";

const PolicyCoverageTreatmentData = ({ row }) => {

  return (
    <Grid container mt={0.5} mb={0.5}>
      <Grid item xs={2} sm={2}>
        <Typography sx={{ fontSize: '14px', fontFamily: 'Globersemibold' }}>{row.COVERAGE_FIELD}</Typography>
      </Grid>
      <Grid item xs={2} sm={2}>
        <Typography sx={{ fontSize: '14px', fontFamily: 'Globersemibold' }}>{row.STATS}</Typography>
      </Grid>
      <Grid item xs={4} sm={4}>
        <Typography sx={{ fontSize: '14px', fontFamily: 'Globersemibold' }}>{row.TREATMENT_TYPE}</Typography>
      </Grid>
    </Grid>
  )
}

export default PolicyCoverageTreatmentData;