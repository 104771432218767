import React from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";


const modalData = {
  "CONFIRM_GOP_STATUS_CHANGE": {
    headerTitle: 'Do you confirm "ACTION_TITLE" action?',
    subTitle: '',
    textBtnOK: "Confirm",
    textBtnCancel: "Cancel"
  },
  "CONFIRM_CREATE_GOP_IN_EXPIRED_POLICY": {
    headerTitle: 'This GOP will be created on an expired policy. Do you wish to continue?',
    subTitle: '',
    textBtnOK: "Yes",
    textBtnCancel: "No"
  },
  "CONFIRM_DELETE_FILE": {
    headerTitle: 'Do you confirm delete file?',
    subTitle: '',
    textBtnOK: "Confirm",
    textBtnCancel: "Cancel"
  },
  "CONFIRM_BILL_UPDATE": {
    headerTitle: '',
    subTitle: '',
    textBtnOK: "Confirm",
    textBtnCancel: "Cancel"
  },
}

const _getHeaderTitleText = (type, actionTitle) => {
  switch (type) {
    case 'CONFIRM_GOP_STATUS_CHANGE':
      return modalData[type]?.headerTitle.replace('ACTION_TITLE', actionTitle)
    case 'CONFIRM_CREATE_GOP_IN_EXPIRED_POLICY':
      return modalData[type]?.headerTitle;
    case 'CONFIRM_DELETE_FILE':
      return modalData[type]?.headerTitle;
    default:
      return 'Do you confirm this action?'
  }
}

const ConfirmModal = ({ open, type, handleClickBtnCancel, handleClickBtnConfirm, actionTitle = '', confirmation_msg = '' }) => {
  // console.log("Type - ", type);

  const _renderHeaderModal = () => {
    const headerTitleText = confirmation_msg ? confirmation_msg : _getHeaderTitleText(type, actionTitle);
    return (
      <Typography color='secondary' sx={{
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center'
      }}>
        {headerTitleText}
      </Typography>
    )
  }

  return (
    <Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleClickBtnCancel}>
      <Box pl={4} pr={4} pt={5} pb={5}>
        <Box>
          {_renderHeaderModal()}
        </Box>
        <Box pt={5} display='flex' justifyContent='space-around'>
          <Button
            color='secondary'
            sx={{
              fontWeight: "bold",
              fontSize: '14px',
              width: '100px'
            }}
            onClick={handleClickBtnCancel}
            variant="contained"
          >
            {modalData[type].textBtnCancel}
          </Button>
          <Button
            color='info'
            sx={{
              fontWeight: "bold",
              fontSize: '14px',
              color: '#fff',
              width: '100px'
            }}
            onClick={handleClickBtnConfirm}
            variant="contained"
          >
            {modalData[type].textBtnOK}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
};

export default ConfirmModal
