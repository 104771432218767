import { combineReducers } from 'redux';
import { entitiesReducer } from './entities.reducer'
import { authReducer } from './auth.reducer'
import { documentsReducer } from './documents.reducer'
import { gopReducer } from './gop.reducer'
import { viewReducer } from './view.reducer'
import { notificationsReducer } from './notifications.reducer'
import { feesReducer } from './fees.reducer'
import { l1l3Reducer } from './l1l3cases.reducer'
import { profilesReducer} from './profiles.reducer'


export default combineReducers({
  entities: entitiesReducer,
  auth: authReducer,
  documents: documentsReducer,
  gop: gopReducer,
  view: viewReducer,
  notifications: notificationsReducer,
  fees: feesReducer,
  l1l3Cases: l1l3Reducer,
  profiles: profilesReducer
});
