import { axiosErrorHandler, postRequest } from "../../utils/axios";
import { v4 as uuidv4 } from "uuid"
import {
  RESET_VIEW,
  SET_SNACKBAR_OPEN,
  ADD_MESSAGE_TO_SNACKBAR,
  SET_MAIN_VIEW_CONFIG,
  SET_GOP_STATS,
  SET_GOP_STATS_LOADING,
  SET_LOADING_GOP_SEARCH_PATIENT,
  SET_LOADING_UPLOAD_DOCUMENT,
  SET_LOADING_GOP_CREATE,
  SET_LOADING_MAIN_PAGE,
  REMOVE_MESSAGE_FROM_SNACKBAR, SET_VIEW,
} from "../actionTypes";



export const setSnackBarOpen = (isOpen, message) => {
  return {
    type: SET_SNACKBAR_OPEN,
    payload: {
      isOpen: isOpen,
      message: message
    },
  };
}
/**
 * 
 * @param {string} message 
 * @param {string} variant - default | error | success | warning | info
 * @returns 
 */
export const addMessageToSnackbar = (message, variant = 'default') => {
  return {
    type: ADD_MESSAGE_TO_SNACKBAR,
    payload: {
      id: uuidv4(),
      message,
      variant
    },
  };
}

/**
 * 
 * @param {string} msgId message ID 
 * @returns 
 */
export const removeFromSnackbar = (msgId) => {
  return {
    type: REMOVE_MESSAGE_FROM_SNACKBAR,
    payload: msgId,
  };
}

export const setMainViewConfig = (config) => {
  return {
    type: SET_MAIN_VIEW_CONFIG,
    payload: config,
  };
}

export const setLoadingGopSearchPatient = (val) => {
  return {
    type: SET_LOADING_GOP_SEARCH_PATIENT,
    payload: val,
  };
}
export const setLoadingUploadDocument = (val) => {
  return {
    type: SET_LOADING_UPLOAD_DOCUMENT,
    payload: val,
  };
}

export const setGopStatsLoading = (boolean) => {
  return {
    type: SET_GOP_STATS_LOADING,
    payload: boolean,
  };
}

export const setGopCreateLoading = (boolean) => {
  return {
    type: SET_LOADING_GOP_CREATE,
    payload: boolean,
  };
}
export const setMainPageLoading = (boolean) => {
  return {
    type: SET_LOADING_MAIN_PAGE,
    payload: boolean,
  };
}

export const getGopStats = () => {
  return async dispatch => {
    try {
      dispatch(setGopStatsLoading(true))
      let resp = await postRequest(`/gop/get-stats`, { data: {} })
      console.log('gopGetStats resp ==', resp.data)
      dispatch(setGopStats(resp.data))
      dispatch(setGopStatsLoading(false))
    } catch (err) {
      dispatch(setGopStatsLoading(false))
      const error = axiosErrorHandler(err);
      console.error('error - gopGetStats - ', error)
    }
  }
}

export const setGopStats = (data) => {
  return {
    type: SET_GOP_STATS,
    payload: data,
  };
}


export const resetViewState = () => {
  return {
    type: RESET_VIEW
  }
}

export const setView = (obj) => {
  return {
    type: SET_VIEW,
    payload: obj
  }
}