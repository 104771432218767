import React, { useState, useEffect } from 'react';
import { Box, Fab, Typography, TextField, InputAdornment } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { PageContainer, PageBodyContainer, DrawerContainer, IMSDrawer } from '../../shared';
import {
  CompanyGroupCard,
  CreateCompanyGroupModal,
  // NewGroupAddedModal
} from './components';
import { Add } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import { getClinics, setOpenModalGroupSuccess, setOpenModalGroup } from '../../redux/actions/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { entityTypeAndID } from '../../utils/entityTypeAndID';
import { SuccessAddModal } from '../../shared';
import ClinicsCard from '../CompanyDetails/components/ClinicsCard';



const Clinics = () => {
  const dispatch = useDispatch();
  const clinics = useSelector(state => state.entities.clinics)
  const user = useSelector(state => state.auth.user)
  const openModalGroup = useSelector(state => state.entities.modals?.openModalGroup)
  const openModalGroupSuccess = useSelector(state => state.entities.modals?.openModalGroupSuccess)

  useEffect(() => {
    dispatch(getClinics())    
  }, [])


  return (
    <PageContainer>
      <DrawerContainer
        isLogo={true}
        description={user.owner_type === 'provider' ? 'Inpatient Management System' : 'Clinic Portal'}
        underline={true}
        // isBack={true}
      >
        <IMSDrawer />
      </DrawerContainer>
      <PageBodyContainer>
        <Box display='flex' flexDirection='row' alignItems='center' pl={15} pr={8} pb={3} pt={1}>
          <Typography sx={{ color: "#053ad3", fontSize: "48px", pr: 8 }}>
            Clinics
          </Typography>
          <TextField
            id="input-with-icon-textfield"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{color: "#eb0781"}} />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </Box>
        <Box pl={8} pr={8} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          { clinics && clinics.length > 0 && clinics.map((entity) => <ClinicsCard entity={entity} key={entity._id} />)}
        </Box>
      </PageBodyContainer>
      {/* <Fab
        sx={{ position:'fixed', bottom: 42, right: 42, fontFamily: 'Globerbold'}}
        variant="extended"
        color={'secondary'}
        onClick={() => dispatch(setOpenModalGroup(true))}>
        <Add/>
        ADD NEW
      </Fab> */}
      {/* <CreateCompanyGroupModal open={openModalGroup} handleOpen={(boolean) => dispatch(setOpenModalGroup(boolean))} type={'group'} /> */}
      {/* <SuccessAddModal open={openModalGroupSuccess} handleOpen={(boolean) => dispatch(setOpenModalGroupSuccess(boolean))} type={'group'} /> */}
    </PageContainer>
  );
};

export default Clinics;