import { postRequest } from "../../utils/axios";
import {
  SET_ALL_GOP,
  SET_SELECTED_GOP,
  UPDATE_SELECTED_GOP,
  RESET_SELECTED_GOP,
  SET_GET_ONE_GOP_LOADING,
  RESET_GOP_LOADING,
  SET_GOP_SEARCH_RESULT_PATIENT_DETAILS,
  SET_DRAFT_GOP_PATIENT,
  SET_DRAFT_GOP_CASE_ADMISSION,
  SET_DRAFT_GOP_CASE_TREATMENT,
  SET_DRAFT_GOP_CASE_BILLING,
  SET_OPEN_GOPS,
  SET_PENDING_GOPS,
  SET_CLOSED_GOPS,
  RESET_DRAFT_GOP,
  SET_DRAFT_GOP_ID,
  SET_DRAFT_OBJ,
  SET_SELECTED_GOP_DOCS,
  RESET_GOP,
  UPDATED_ONE_AND_SELECTED_GOP,
  SET_OPEN_MODAL_GOP_SUCCESS,
  SET_GOP_DOCS_LENGTH,
  SET_OVERVIEW_STATS,
  SET_SELECTED_GOP_POLICY_DETAILS,
  SET_SELECTED_GOP_CLAIMS_HISTORY,
  SET_POSSIBLE_IMS_CLAIM_HANDLERS,
  SET_OPEN_MODAL_GOP_ASSIGN_TO,
  SET_ASSIGN_TO_GOP_ID,
  SET_OPEN_MODAL_ADD_ANOTHER_BILL,
  SET_POLICY_INPATIENT_CATASTROPHE_DATA,
  SET_POLICY_EXCLUSIONS_DATA,
  SET_POLICY_COVERAGE_TREATMENT_DATA,
  UPDATE_WHERE_WITH_WHAT_BY_GOP_ID,
  RESET_BILL_REMARKS,
  SET_BILL_REMARKS,
  SET_GOP_CHATS,
  SET_VETTING_REMARKS,
  RESET_VETTING_REMARKS,
  SET_DRAFT_BILL_LOADING,
  SET_DRAFT_BILL,
  SET_DRAFT_BILL_DOCS,
  SET_GOP_BILL_DOCS,
  SET_OPEN_MODAL_CONFIRM_UPDATE_BILL,
  SET_MODAL_CONFIRM_UPDATE_BILL
} from "../actionTypes";
// import { entityTypeAndID } from "../../utils/entityTypeAndID";
import { store } from '../../App'
import { getPresignUrlForUploadDocFile, uploadFileToDrive } from "../../utils/upload";
import { axiosErrorHandler } from "../../utils/axios";
import { addMessageToSnackbar, getGopStats, setGopCreateLoading, setLoadingGopSearchPatient, setLoadingUploadDocument, setMainPageLoading } from "./view.actions";
import { setShowNotificationsDrawer } from "./notifications.action";
import deepCopy from "../../utils/deepCopy";
import { CASE_PROGRESS } from "../../constants/viewConfig";
import commandHandler from "../../utils/commandHandler";



export const setAllGop = (gops) => {
  return {
    type: SET_ALL_GOP,
    payload: gops
  }
}

export const setOpenGops = (gops) => {
  return {
    type: SET_OPEN_GOPS,
    payload: gops
  }
}

export const setPendingGops = (gops) => {
  return {
    type: SET_PENDING_GOPS,
    payload: gops
  }
}

export const setClosedGops = (gops) => {
  return {
    type: SET_CLOSED_GOPS,
    payload: gops
  }
}

export const setSelectedGop = (gop) => {
  return {
    type: SET_SELECTED_GOP,
    payload: { ...gop }
  }
}

export const setDraftObj = (gop) => {
  return {
    type: SET_DRAFT_OBJ,
    payload: gop
  }
}

export const setSearchResultPatientDetails = (search) => {
  return {
    type: SET_GOP_SEARCH_RESULT_PATIENT_DETAILS,
    payload: search
  }
}

export const setDraftGopId = (id) => {
  return {
    type: SET_DRAFT_GOP_ID,
    payload: id
  }
}

export const setDraftGopPatientDetails = (patientDetails) => {
  return {
    type: SET_DRAFT_GOP_PATIENT,
    payload: patientDetails
  }
}

export const setDraftGopCaseAdmission = (details) => {
  return {
    type: SET_DRAFT_GOP_CASE_ADMISSION,
    payload: details
  }
}

export const setDraftGopCaseTreatment = (details) => {
  return {
    type: SET_DRAFT_GOP_CASE_TREATMENT,
    payload: details
  }
}

export const setDraftGopCaseBilling = (details) => {
  return {
    type: SET_DRAFT_GOP_CASE_BILLING,
    payload: details
  }
}

export const resetDraftGop = () => {
  return {
    type: RESET_DRAFT_GOP,
  }
}

export const setSelectedGopPolicyDetails = (details) => {
  return {
    type: SET_SELECTED_GOP_POLICY_DETAILS,
    payload: details
  }
}

export const setSelectedGopClaimsHistory = (history) => {
  return {
    type: SET_SELECTED_GOP_CLAIMS_HISTORY,
    payload: history
  }
}

export const setPossibleImsClaimHandlers = (handlers) => {
  return {
    type: SET_POSSIBLE_IMS_CLAIM_HANDLERS,
    payload: handlers
  }
}

export const setSelectedGopDocs = (docs) => {
  return {
    type: SET_SELECTED_GOP_DOCS,
    payload: docs
  }
}

export const setDraftBillDocs = (docs) => {
  return {
    type: SET_DRAFT_BILL_DOCS,
    payload: docs
  }
}
export const setGopBillDocs = (docs, gop_id, bill_id) => {
  return {
    type: SET_GOP_BILL_DOCS,
    payload: { docs, gop_id, bill_id }
  }
}
export const setOpenModalGOPSuccess = (boolean) => {
  return {
    type: SET_OPEN_MODAL_GOP_SUCCESS,
    payload: boolean
  }
}
export const setOpenModalAssignTo = (boolean) => {
  return {
    type: SET_OPEN_MODAL_GOP_ASSIGN_TO,
    payload: boolean
  }
}

export const setOpenModalAddAnotherBill = (boolean) => {
  return {
    type: SET_OPEN_MODAL_ADD_ANOTHER_BILL,
    payload: boolean
  }
}


export const setAssignToGopId = (id) => {
  return {
    type: SET_ASSIGN_TO_GOP_ID,
    payload: id
  }
}

export const updateOneAndSelectedGop = (gop, isDraft) => {
  return {
    type: UPDATED_ONE_AND_SELECTED_GOP,
    payload: {
      gop,
      isDraft
    }
  }
}

export const updateWhereWithWhatByGopID = (data) => {
  return {
    type: UPDATE_WHERE_WITH_WHAT_BY_GOP_ID,
    payload: data
  }
}

export const setGopDocsLength = (length) => {
  return {
    type: SET_GOP_DOCS_LENGTH,
    payload: length
  }
}

export const setOverviewStats = (stats) => {
  return {
    type: SET_OVERVIEW_STATS,
    payload: stats
  }
}

export const updateSelectedGop = (type, data) => {
  return {
    type: UPDATE_SELECTED_GOP,
    payload: { type: type, data }
  }
}

export const resetSelectedGop = (gop_id) => {
  return {
    type: RESET_SELECTED_GOP,
    payload: gop_id
  }
}

export const setPolicyInpatientCatastropheInfo = (data) => {
  return {
    type: SET_POLICY_INPATIENT_CATASTROPHE_DATA,
    payload: data
  }
}

export const setPolicyExclusionsData = (data) => {
  return {
    type: SET_POLICY_EXCLUSIONS_DATA,
    payload: data
  }
}

export const setPolicyCoverageTreatmentData = (data) => {
  return {
    type: SET_POLICY_COVERAGE_TREATMENT_DATA,
    payload: data
  }
}
export const setGetOneGopLoading = ({ status, gop_id }) => {
  return {
    type: SET_GET_ONE_GOP_LOADING,
    payload: { status, gop_id }
  }
}

export const resetGopLoading = () => {
  return {
    type: RESET_GOP_LOADING
  }
}


export const resetBillRemarks = () => {
  return {
    type: RESET_BILL_REMARKS
  }
}

export const setBillRemarks = (data) => {
  return {
    type: SET_BILL_REMARKS,
    payload: data
  }
}

export const setVettingRemarks = (data) => {
  return {
    type: SET_VETTING_REMARKS,
    payload: data
  }
}

export const resetVettingRemarks = () => {
  return {
    type: RESET_VETTING_REMARKS,
  }
}

export const setGopChats = (data) => {
  return {
    type: SET_GOP_CHATS,
    payload: data
  }
}


export const getBillRemarks = ({ remarks_section_id }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`remarks/${remarks_section_id}/get-all`)
      console.log('getBillRemarks resp ==', resp.data)
      dispatch(setBillRemarks({ remarks_section_id, remarks: resp.data }))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getBillRemarks - ', error)
    }
  }
}

export const getVettingRemarks = ({ remarks_section_id }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`remarks/${remarks_section_id}/get-all`)
      console.log('getVettingRemarks resp ==', resp.data)
      dispatch(setVettingRemarks({ remarks_section_id, remarks: resp.data }))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getVettingRemarks - ', error)
    }
  }
}


export const saveBillRemark = ({ remarks_section_id, text }) => {
  return async dispatch => {
    try {
      if (text) {
        let resp = await postRequest(`remarks/${remarks_section_id}/remark/add`, {
          data: {
            value: {
              post: { text }
            }
          }
        })
        console.log('saveBillRemark resp ==', resp.data)
        dispatch(setBillRemarks({ remarks_section_id, remarks: resp.data }))
      }
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - saveBillRemark - ', error)
    }
  }
}


export const saveRemarkPromise = ({ remarks_section_id, text }) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (text) {
        let resp = await postRequest(`remarks/${remarks_section_id}/remark/add`, {
          data: {
            value: {
              post: { text }
            }
          }
        })
        console.log('saveBillRemark resp ==', resp.data)
        // dispatch(setBillRemarks({ remarks_section_id, remarks: resp.data }))
        resolve();
      }
    } catch (err) {
      const error = axiosErrorHandler(err);
      reject()
      console.error('error - saveBillRemark - ', error)
    }
  })
}


export const getOneGop = () => {
  return async dispatch => {
    try {
      const selectedGop = store.getState().gop.selectedGop;
      dispatch(setGetOneGopLoading({ status: true, gop_id: selectedGop?.keys?.gop_id }))
      let resp = await postRequest(`/gop/${selectedGop?.keys?.gop_id}/get`, { data: {} })
      console.log('getOneGop resp ==', resp.data)
      dispatch(updateOneAndSelectedGop(resp.data, false))
      dispatch(getGopDocs(selectedGop?.keys?.gop_id))
      dispatch(setGetOneGopLoading({ status: false, gop_id: selectedGop?.keys?.gop_id }))
    } catch (err) {
      const error = axiosErrorHandler(err);
      const selectedGop = store.getState().gop.selectedGop;
      dispatch(setGetOneGopLoading({ status: false, gop_id: selectedGop?.keys?.gop_id }))
      console.error('error - getOneGop - ', error)
    }
  }
}



export const getAllGops = () => {
  return async dispatch => {
    try {
      const user = store.getState().auth.user;
      let resp = await postRequest(`/entity/${user.owner_type}/${user.owner_id}/gop/get-all`, { data: {} })

      console.log('getAllGops resp ==', resp.data)
      dispatch(setAllGop(resp.data))
      dispatch(resetGopLoading())
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getAllGops - ', error)
    }
  }
}

export const getGopsByType = ({ type = 'IN_PROGRESS' }) => {
  return async dispatch => {
    try {
      const user = store.getState().auth.user;
      // console.log('auth', store.getState().auth.user.owner_type);
      let resp = await postRequest(`/entity/${user.owner_type}/${user.owner_id}/gop/get-all-by-pending-open-closed/${type}`, { data: {} })
      console.log('getGopsByType resp ==', resp.data)
      // const detailedGop = await Promise.all(resp?.data?.map?.(async (item ) => await getGopDetails(entityTypeAndID(item))))
      if (type === 'IN_PROGRESS') {
        dispatch(setPendingGops(resp.data))
      }
      if (type === 'OPEN') {
        dispatch(setOpenGops(resp.data))
      }
      if (type === 'CLOSED') {
        dispatch(setClosedGops(resp.data))
      }
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getGopsByType - ', error)
    }
  }
}

export const saveEditGop = () => {
  return async dispatch => {
    try {
      let gop = deepCopy(store.getState().gop.selectedGop);
      console.log('saveEditGop gop', gop)
      delete gop?.value?.caseDetails?.a_caseDetailsUnsavedChangesExist;
      // console.log('auth', store.getState().auth.user.owner_type);
      let respUpdate = await postRequest(`entity/${gop.value.owner_type}/${gop.value.owner_id}/gop/${gop.keys.gop_id}/update`, { data: { value: { ...gop.value } } })
      console.log('saveEditGop update resp ==', respUpdate.data)
      dispatch(updateOneAndSelectedGop(respUpdate.data, false))
      //TODO move to api
      if (respUpdate.data.value.status === 'EDIT_GOP') {
        let resp = await postRequest(`/gop/${gop.keys.gop_id}/do-action/NEW_GOP`, { data: {} })
        console.log('saveEditGop resp ==', resp.data)
        dispatch(updateOneAndSelectedGop(resp.data, false))
      }
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - saveEditGop - ', error)
    }
  }
}


export const createDraftGop = () => {
  return async dispatch => {
    try {
      const user = store.getState().auth.user;
      // console.log('auth', store.getState().auth.user.owner_type);
      let resp = await postRequest(`/entity/${user.owner_type}/${user.owner_id}/gop/create`, { data: {} })
      console.log('createDraftGop resp ==', resp.data)
      dispatch(setDraftGopId(resp?.data?.keys?.gop_id))
      dispatch(setDraftObj(resp?.data));
      dispatch(getGopDocs(resp?.data?.keys?.gop_id));
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - createDraftGop - ', error)
    }
  }
}
export const updateDraftGop = () => {
  return async dispatch => {
    try {
      const user = store.getState().auth.user;
      const draftObj = store.getState().gop.draftObj;
      const draftGop = store.getState().gop.draftGop;
      const draftIdGop = store.getState().gop.draftIdGop;
      let respUpdate = await postRequest(`entity/${user.owner_type}/${user.owner_id}/gop/${draftIdGop}/update`, {
        data: {
          value: {
            ...draftObj.value,
            patient: { ...draftGop.patient },
            caseDetails: {
              // ...draftObj.value.caseDetails,
              admission: { ...draftGop.caseDetails.admission },
              treatmentAndDoctor: { ...draftGop.caseDetails.treatmentAndDoctor },
              billing: [...draftGop.caseDetails.billing]
            },
          }
        }
      })
      console.log('updateDraftGop resp ==', respUpdate.data)
      dispatch(getPolicyDetailsOfDraftGop())


    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateDraftGop - ', error)
    }
  }
}

export const createGop = ({ navigate }) => {
  return async dispatch => {
    try {
      const draftObj = store.getState().gop.draftObj;
      const draftGop = store.getState().gop.draftGop;
      const draftIdGop = store.getState().gop.draftIdGop;
      const user = store.getState().auth.user;
      dispatch(setGopCreateLoading(true));
      // console.log('auth', store.getState().auth.user.owner_type);
      // TODO REMOVE STATUS FROM UPDATE METHOD
      let respUpdate = await postRequest(`entity/${user.owner_type}/${user.owner_id}/gop/${draftIdGop}/update`, {
        data: {
          value: {
            ...draftObj.value,
            patient: { ...draftGop.patient },
            caseDetails: {
              // ...draftObj.value.caseDetails,
              admission: { ...draftGop.caseDetails.admission },
              treatmentAndDoctor: { ...draftGop.caseDetails.treatmentAndDoctor },
              billing: [...draftGop.caseDetails.billing]
            },

            status: 'NEW_GOP'
          },
          a_needsValidation: 1,
        }
      })
      console.log('createGop update resp ==', respUpdate.data)
      // dispatch(updateOneAndSelectedGop(respUpdate.data, true))
      let resp = await postRequest(`/gop/${draftIdGop}/do-action/NEW_GOP`, { data: {} })
      console.log('createGop resp ==', resp.data)
      dispatch(updateOneAndSelectedGop(resp.data, true))

      navigate('/cases/open', { state: { type: CASE_PROGRESS.OPEN } });
      dispatch(resetDraftGop());
      dispatch(setOpenModalGOPSuccess(true));
      dispatch(setGopCreateLoading(false));

      // const detailedGop = await Promise.all(resp?.data?.map?.(async (item ) => await getGopDetails(entityTypeAndID(item))))
    } catch (err) {
      dispatch(setGopCreateLoading(false));
      const error = axiosErrorHandler(err);
      console.error('error - createGop - ', error)
    }
  }
}

export const changeStatusGop = ({ state }) => {
  return async dispatch => {
    try {
      dispatch(setMainPageLoading(true))
      const selectedGop = store.getState().gop.selectedGop;
      let resp = await postRequest(`/gop/${selectedGop?.keys?.gop_id}/do-action/${state}`, { data: {} })
      console.log('changeStatusGop resp ==', resp.data)
      dispatch(updateOneAndSelectedGop(resp.data, false))
      dispatch(getGopStats())
      dispatch(getAllGops())
      dispatch(setMainPageLoading(false))
      // const detailedGop = await Promise.all(resp?.data?.map?.(async (item ) => await getGopDetails(entityTypeAndID(item))))
    } catch (err) {
      dispatch(setMainPageLoading(false))
      const error = axiosErrorHandler(err);
      console.error('error - changeStatusGop - ', error)
    }
  }
}

export const assignToMeGop = ({ navigate }) => {
  return async dispatch => {
    try {

      const selectedGop = store.getState().gop.selectedGop;
      let resp = await postRequest(`/gop/${selectedGop?.keys?.gop_id}/assign-to-me`, { data: {} })
      console.log('assignToMeGop resp ==', resp.data)
      dispatch(updateOneAndSelectedGop(resp.data, false))
      console.log('resp.data.value ==', resp.data.value)

      // TODO navigate to landing view rework to CMDS

      if (resp.data.value.a_landing_view) {
        navigate(resp.data.value.a_landing_view)
      }
      dispatch(getGopStats())
      // const detailedGop = await Promise.all(resp?.data?.map?.(async (item ) => await getGopDetails(entityTypeAndID(item))))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - assignToMeGop - ', error)
    }
  }
}

export const searchPatientDetails = ({ memberName, familyName, dob, nic, policyNum, familyIdMemberId, cardNumber }) => {
  return async dispatch => {
    try {
      let resp = await postRequest('/gop/patient/search', {
        data: {
          value: { memberName, familyName, dob, nic, policyNum, familyIdMemberId, cardNumber }
        }
      })
      console.log('searchPatientDetails resp ==', resp.data)
      dispatch(setSearchResultPatientDetails(resp.data))
      dispatch(setLoadingGopSearchPatient(false))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - searchPatientDetails - ', error)
      dispatch(setSearchResultPatientDetails([]))
      dispatch(setLoadingGopSearchPatient(false))
    }
  }
}

export const getClaimsHistory = () => {
  return async dispatch => {
    let gop_id = store.getState().gop.selectedGop?.keys?.gop_id;
    try {
      // console.log('getClaimsHistory store.getState().gop.selectedGop?.patient?.fullPolicyNum ==', store.getState().gop.selectedGop?.value?.patient?.fullPolicyNum)
      let resp = await postRequest(`/gop/${gop_id}/patient/get-claims-history`, {})
      console.log('getClaimsHistory resp ==', resp.data)
      dispatch(setSelectedGopClaimsHistory(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getClaimsHistory - ', error)
    }
  }
}

export const getOverviewStats = () => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/overview/get-stats`, { data: {} })
      console.log('getOverviewStats resp ==', resp.data)
      dispatch(setOverviewStats(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getOverviewStats - ', error)
    }
  }
}

export const getPolicyDetailsOfDraftGop = () => {
  return async dispatch => {
    try {
      const gop_id = store.getState().gop.draftIdGop;
      console.log('getPolicyDetailsOfDraftGop: gop_id', gop_id)
      await postRequest(`/gop/${gop_id}/patient/get-policy-details`)
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPolicyDetails - ', error)
    }
  }
}

export const getPolicyDetails = () => {
  return async dispatch => {
    try {
      const gop_id = store.getState().gop.selectedGop?.keys?.gop_id;
      await postRequest(`/gop/${gop_id}/patient/get-policy-details`)
      // console.log('getPolicyDetails resp ==', resp.data)
      // dispatch(setSelectedGopPolicyDetails(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPolicyDetails - ', error)
    }
  }
}

export const getPolicyInpatientCatastropheInformation = () => {
  return async dispatch => {
    try {
      const gop_id = store.getState().gop.selectedGop?.keys?.gop_id;
      const resp = await postRequest(`/gop/${gop_id}/get-inpatient-catastrophe-information`, { data: { value: { fullPolicyNum: store.getState().gop.selectedGop?.value?.patient?.fullPolicyNum } } })
      dispatch(setPolicyInpatientCatastropheInfo(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPolicyInpatientCatastropheInformation - ', error)
    }
  }
}

export const getPolicyExclusionsData = () => {
  return async dispatch => {
    try {
      let gop_id = store.getState().gop.selectedGop?.keys?.gop_id;
      let resp = await postRequest(`/gop/${gop_id}/get-exclusions`, { data: { value: { fullPolicyNum: store.getState().gop.selectedGop?.value?.patient?.fullPolicyNum } } })
      dispatch(setPolicyExclusionsData(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPolicyExclusionsData - ', error)
    }
  }
}

export const getPolicyTreatmentCoverageData = () => {
  return async dispatch => {
    try {
      let treatmentTypeKey = store.getState().gop.selectedGop?.value?.patient?.treatmentTypeKey;
      let inPatientOption = store.getState().gop.selectedGop?.value?.patient?.inPatientOption;
      let resp = await postRequest(`/gop/patient/get-treatment-types`, { data: { value: { treatmentTypeKey, inPatientOption } } })
      dispatch(setPolicyCoverageTreatmentData(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPolicyTreatmentCoverageData - ', error)
    }
  }
}

export const getPossibleImsHandlers = () => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/gop/get-ims-possible-assignees`, { data: {} })
      console.log('getPossibleImsHandlers resp ==', resp.data)
      dispatch(setPossibleImsClaimHandlers(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getPossibleImsHandlers - ', error)
    }
  }
}

export const assignToClaimHandler = ({ gopID, userID }) => {
  return async dispatch => {
    try {
      let resp = await postRequest(`/gop/${gopID}/user/${userID}/assign`, { data: {} })
      console.log('assignToClaimHandler resp ==', resp.data)
      dispatch(updateOneAndSelectedGop(resp.data, false))
      dispatch(setOpenModalAssignTo(false));
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - assignToClaimHandler - ', error)
    }
  }
}

export const getGopDocs = (gop_id) => {
  return async dispatch => {
    try {
      if (!gop_id) {
        const selectedGop = store.getState().gop.selectedGop;
        if (!selectedGop?.keys?.gop_id) {
          return
        }
        gop_id = selectedGop?.keys?.gop_id
      }

      let resp = await postRequest(`/gop/${gop_id}/file/get-all`, { data: {} })
      console.log('getGopDocs resp ==', resp.data)
      dispatch(setSelectedGopDocs(resp.data))
      // const detailedGop = await Promise.all(resp?.data?.map?.(async (item ) => await getGopDetails(entityTypeAndID(item))))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getGopDocs - ', error)
    }
  }
}

export const getBillDocs = ({ gop_id, bill_id }) => {
  return async dispatch => {
    try {
      const draftBill = store.getState().gop.draftBill
      if (!gop_id) {
        const selectedGop = store.getState().gop.selectedGop;
        if (!selectedGop?.keys?.gop_id) {
          return
        }
        gop_id = selectedGop?.keys?.gop_id
      }

      let resp = await postRequest(`/gop/${gop_id}/bill/${bill_id}/get-files`, { data: {} })
      console.log('getBillDocs resp ==', resp.data)

      if (draftBill && draftBill?.keys?.bill_id) {
        dispatch(setDraftBillDocs(resp?.data?.billFiles))
      }
      else {
        dispatch(setGopBillDocs(resp?.data?.billFiles, gop_id, bill_id))
        dispatch(getGopDocs(gop_id));
      }
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getBillDocs - ', error)
    }
  }
}

export const setDraftBillLoading = (boolean) => {
  return {
    type: SET_DRAFT_BILL_LOADING,
    payload: boolean
  }
}

export const setDraftBill = (data) => {
  return {
    type: SET_DRAFT_BILL,
    payload: data
  }
}


export const createDraftBill = () => {
  return async dispatch => {
    let gop_id = store.getState().gop.selectedGop?.keys?.gop_id;
    try {
      dispatch(setDraftBill({}))
      dispatch(setDraftBillLoading(true))
      let resp = await postRequest(`/gop/${gop_id}/create-draft-bill`, {})
      console.log('createDraftBill resp ==', resp.data)
      dispatch(setDraftBill(resp?.data?.data))
      dispatch(setDraftBillLoading(false))
    } catch (err) {
      dispatch(setDraftBillLoading(false))
      const error = axiosErrorHandler(err);
      console.error('error - createDraftBill - ', error)
    }
  }
}


export const updateBill = ({ type, amount, params }) => {
  return async dispatch => {
    const gop_id = store.getState().gop.selectedGop?.keys?.gop_id;
    const bill_id = store.getState().gop.draftBill?.keys?.bill_id;
    const draftBill = store.getState().gop.draftBill;


    try {
      let resp = await postRequest(
        `/gop/${gop_id}/bill/${bill_id}/update`,
        {
          data: {
            ...draftBill,
            value: {
              ...draftBill.value,
              type,
              amount
            },
            ...params
          }
        }
      )
      console.log('updateBill resp ==', resp.data);
      if (resp.data.CMDS) {
        commandHandler({dispatch, commands: resp.data.CMDS})
      } else {
        dispatch(setOpenModalAddAnotherBill(false))
        dispatch(setDraftBill({}));
        dispatch(getOneGop())
        // dispatch(setSelectedGopClaimsHistory(resp.data))
      }

    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - updateBill - ', error)
    }
  }
}

export const deleteFile = ({ gop_id, file_id, bill_id = '' }) => {
  return async dispatch => {
    try {
      console.log(`${gop_id} - ${file_id} - ${bill_id}`)
      let resp = await postRequest(`/gop/${gop_id}/file/${file_id}/trash`, {})
      console.log('deleteFile resp ==', resp.data)
      if (bill_id) {
        dispatch(getBillDocs({ gop_id, bill_id }));
      } else {
        dispatch(getGopDocs(gop_id));
      }
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - deleteFile - ', error)
    }
  }
}


export const addAnotherBillToGop = ({ gop_id, type, amount }) => {
  return async dispatch => {
    try {
      console.log("gop_id - ", gop_id)
      console.log("type - ", type)
      console.log("amount - ", amount)
      let resp = await postRequest(`/gop/${gop_id}/bill/add`, { data: { value: { type, amount } } })
      console.log('addAnotherBillToGop resp ==', resp.data)
      dispatch(updateOneAndSelectedGop(resp.data, false))
      dispatch(setOpenModalAddAnotherBill(false))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - addAnotherBillToGop - ', error)
    }
  }
}

export const getChatsForGop = ({ gop_id }) => {
  return async dispatch => {
    try {
      console.log("gop_id - ", gop_id)
      let resp = await postRequest(`/q/getChatsForGop`, { data: { gop_id, chat_type: 'GOP_CHAT' } })
      console.log('getChatsForGop resp ==', resp.data)
      dispatch(setGopChats(resp.data))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - getChatsForGop - ', error)
    }
  }
}

export const uploadFile = ({ gop_id, file, section, bill_id }) => {
  return async dispatch => {
    try {
      const presignUrl = await getPresignUrlForUploadDocFile(gop_id, file, section, bill_id);
      console.log('uploadFile resp ==', presignUrl.data)
      await uploadFileToDrive(presignUrl.data, file)
      dispatch(getAllGops())
      if (bill_id) {
        dispatch(getBillDocs({ gop_id, bill_id }))
      } else {
        dispatch(getGopDocs(gop_id))
      }
      dispatch(setLoadingUploadDocument(false))
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - uploadFile - ', error)
      dispatch(setLoadingUploadDocument(false))
      // dispatch(setSearchResultPatientDetails([]))
    }
  }
}

export const countGopDocs = () => {
  return async dispatch => {
    try {
      let docs = store.getState().gop.selectedGopDocs;
      let init = 0;
      docs.forEach(section => {
        init += section.files.length
      })
      dispatch(setGopDocsLength(init))

    } catch (err) {
      console.error('error - countGopDocs - ', err)
      dispatch(setGopDocsLength(0))
    }
  }
}

export const loadGopData = (gopID, navigate) => {
  return async dispatch => {
    try {
      const allGops = store.getState().gop.allGops;
      let findGop = allGops.find((gop) => { return gop.keys.gop_id === gopID });
      if (!findGop) {
        dispatch(addMessageToSnackbar(`GOP with id ${gopID} not found`, 'info'))
        return
      }
      let navigationGopType = findGop?.value?.a_viewConfig?.case_progress?.[0];
      if (!navigationGopType) {
        dispatch(addMessageToSnackbar(`GOP's case progress not found`, 'info'))
        return
      }
      console.log("loadGopData #gop = ", findGop)
      console.log("loadGopData #navigationGopType = ", navigationGopType)
      dispatch(setSelectedGop(findGop))
      dispatch(getGopDocs(findGop?.keys?.gop_id))
      dispatch(setShowNotificationsDrawer(false))
      navigate(`/cases/${navigationGopType?.toLowerCase?.()}`, { state: { type: navigationGopType } });
    } catch (err) {
      const error = axiosErrorHandler(err);
      console.error('error - loadGopData - ', error)
    }
  }
}


export const resetGop = () => {
  return {
    type: RESET_GOP,
  }
}

export const setOpenModalConfirmUpdateBill = (boolean) => {
  return {
    type: SET_OPEN_MODAL_CONFIRM_UPDATE_BILL,
    payload: boolean
  }
};

export const setModalConfirmUpdateBill = (obj) => {
  return {
    type: SET_MODAL_CONFIRM_UPDATE_BILL,
    payload: obj
  }
}