import React from "react";
import {
  Dialog,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useDropzone } from 'react-dropzone';

const AddDocumentModal = ({ open, handleOpen, handleUploadFile }) => {
  const dispatch = useDispatch();
  const [fileTitle, setFileTitle] = useState('');
  const [description, setDescription] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [selectedFile, setFile] = useState();
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    multiple: false,
    onDrop: (acceptedFiles) => {
      console.log("acceptedFiles - ", acceptedFiles);
      if (acceptedFiles.length) {
        // let file = Object.assign(acceptedFiles[0], {
        //   preview: URL.createObjectURL(acceptedFiles[0])
        // })

        setFile(acceptedFiles[0]);

      }
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map(({ file, errors }) => {
        console.log("file - ", file);
        console.log("error - ", errors);
      })
    }
  });

  const handleCloseModal = () => {
    setFile(null);
    handleOpen(false);
  }

  const handleUpload = () => {
    handleUploadFile(selectedFile)
    handleCloseModal()
  }

  return (
    <Dialog maxWidth={'xs'} fullWidth={true} open={open} onClose={handleCloseModal}>
      <Box pl={3} pr={3} pt={3} pb={3}>
        <Box display='flex' flexDirection='row' pb={2} justifyContent='space-between' alignItems='center'>
          <Typography sx={{ color: 'secondary.main', fontSize: 20, fontFamily: 'Globersemibold', textTransform: 'uppercase' }}>
            Add New Document
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon color='secondary' />
          </IconButton>
        </Box>
        <Box pr={3} pl={3} pb={3} display='flex' flexDirection='column'>
          <Box pb={1}>
            <Box {...getRootProps({ className: 'dropzone' })}
              sx={{
                width: '100%',
                border: 'solid 1px #c4c4c4',
                color: '#eb0781',
                height: '170px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: "pointer"
              }}>
              <input {...getInputProps()} />
              {selectedFile ?
                <Button color='secondary' onClick={() => { setFile(null) }} sx={{ zIndex: '100' }}><CancelIcon /></Button>
                : <AddCircleOutlineIcon />}

              <Typography pt={1}
                sx={{ fontSize: "12px", color: "#c4c4c4", }}
              >
                {selectedFile ? selectedFile.name : 'Drop document to be uploaded here'}

              </Typography>

            </Box>
          </Box>
          {/* <TextField
            sx={{
              paddingBottom: "10px"
            }}
            autoComplete="off"
            label='FILE TITLE'
            variant='filled'
            value={fileTitle}
            onChange={e => setFileTitle(e.target.value)}
            id='file-title'
          />
          <TextField
            sx={{
              paddingBottom: "10px"
            }}
            autoComplete="off"
            label='DESCRIPTION'
            variant='filled'
            value={description}
            onChange={e => setDescription(e.target.value)}
            id='description'
          />
          <FormControl fullWidth variant='filled'>
            <InputLabel id="doctype-label">DOCUMENT TYPE</InputLabel>
            <Select
              labelId="doctype-label"
              id="doctype"
              value={documentType}
              onChange={e => setDocumentType(e.target.value)}
            >
              <MenuItem value={'Type 1'}>Type 1</MenuItem>
              <MenuItem value={'Type 2'}>Type 2</MenuItem>
              <MenuItem value={'Type 3'}>Type 3</MenuItem>
            </Select>
          </FormControl> */}
        </Box>
        <Box display='flex' justifyContent='space-around'>
          <Button variant="text" color='secondary'
            onClick={handleCloseModal}
            sx={{
              fontWeight: 'bold'
            }}>
            Cancel
          </Button>
          <Button variant="contained" color='info'
            onClick={handleUpload}
            sx={{
              backgroundColor: 'primary.main',
              color: "#fff",
              fontWeight: 'bold'
            }}>
            Add new Document
          </Button>
        </Box>
      </Box>
    </Dialog >
  )
};

export default AddDocumentModal